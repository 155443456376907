import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Sector, } from "recharts";

const data = [
  { name: 'Giá', value: 40, color: '#1890FF' },
  { name: 'Trải nghiệm', value: 21, color: '#13C2C2' },
  { name: 'Phong cách', value: 9, color: '#2FC25B' },
  { name: 'Thái độ', value: 8, color: '#FACC14' },
  { name: 'Bàn giao', value: 7, color: '#F04864' },
  { name: 'Hồ sơ', value: 6, color: '#8543E0' },
  { name: 'Kinh doanh', value: 5, color: '#3436C7' },
  { name: 'Bàn giao xe', value: 4, color: '#223273' },
];

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, index } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      {
        index !== data.length -1
          ?
            <>
              <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
              <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
              <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#000000A6">
                <tspan x={ex + (cos >= 0 ? 1 : -1) * 12} dy="0em">{payload.name}:</tspan>
                <tspan x={ex + (cos >= 0 ? 1 : -1) * 12} dy="1.2em" fontWeight="bold">{`${(percent * 100).toFixed(0)}%`}</tspan>
              </text>
            </>
          :
            <>
              <path d={`M${sx},${sy}L${mx},${my}`} stroke={fill} fill="none" />
              <circle cx={mx} cy={my} r={2} fill={fill} stroke="none" />
              <text x={mx + (cos >= 0 ? 1 : -1) * 12} y={my} textAnchor={textAnchor} fill="#000000A6">
                <tspan x={mx + (cos >= 0 ? 1 : -1) * 8 } dy="0em">{payload.name}:</tspan>
                <tspan x={mx + (cos >= 0 ? 1 : -1) * 8 } dy="1.2em" fontWeight="bold">{`${(percent * 100).toFixed(0)}%`}</tspan>
              </text>
            </>
      }
    </g>
  );
};

const ARCCSupportStatisticsPieChart: React.FC = () => {
  return (
    <div className="arc-card">
      <div className="arc-card__head">
        <span className="title">Biểu đồ thống kê các loại hỗ trợ khách hàng</span>
        <div className="control">
          <Button className="rs-button">
            <DownloadOutlined />
            <span>Xuất báo cáo</span>
          </Button>
        </div>
      </div>

      <div className="arc-card__content arcc-support-pie-chart">
        <ResponsiveContainer aspect={625 / 400}>
          <PieChart
            width={400}
            height={400}
            className="rs-recharts rs-recharts-tooltip rs-recharts-legend"
          >
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              label={renderActiveShape}
              fill="#8884d8"
              dataKey="value"
              startAngle={90}
              endAngle={-270}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Legend
              layout="vertical"
              align="right"
              verticalAlign="middle"
              iconType="circle"
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ARCCSupportStatisticsPieChart;
