import { CloseOutlined, GlobalOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons"
import { Form, FormInstance, FormProps, Input, Modal, notification, Radio, Select, Switch } from "antd"
import TextArea from "antd/es/input/TextArea"
import { useEffect, useRef, useState } from "react"
import { useForm } from 'antd/lib/form/Form';
import './style.scss'
import useToast from "../../../../../hook/useToast";
import * as selectOptionsApi from '../../../../../api/selectOptions/selectOptionsApi';
import { fommatSelectOption } from "../../../../../until";
import *as unitApi from "../../../../../api/unit/unitApi";
import useThrottle from "../../../../../hook/useThrottle";
import { addGroup } from "../../../../../api/groupCustomer/groupCustomerApi";

// import { useNavigate } from "react-router-dom";
interface ModalProps {
  isOpen: boolean
  isAddCustomer?: boolean
  listCustomer?: React.Key[]

  onSubmitFormModalAdd?: (condition: boolean, iSsuccess: boolean, errorMessage?: string, id?: number) => void;
  onSubmitFormModalEdit?: (condition: boolean, iSsuccess: boolean, errorMessage?: string, id?: number) => void;
  onSubmit?: (value: boolean) => void
  closeModal: () => void
}

const ModalUpdateInfoGroup: React.FC<ModalProps> = ({
  isOpen,
  isAddCustomer = true,
  listCustomer,
  onSubmitFormModalAdd,
  closeModal,
  onSubmit,
}) => {

  const pushToast = useToast();
  const [form] = useForm();
  const inputRef = useRef<FormInstance>(null);

  const [isShare, setIsShare] = useState(0)
  const [valuesTagGroup, setValuesTagGroup] = useState<number[]>([]);
  const [openTagGroup, setOpenTagGroup] = useState(false);
  const [unit, setUnit] = useState<number | undefined>(undefined)
  const [condition, setcondition] = useState(false)

  const [sLTag, setSLTag] = useState<selectOptionsApi.ITag[]>([])
  const [sLUnit, setSLUnit] = useState<unitApi.IUnit[]>([])


  const gSLTag = async () => {
    const result = await selectOptionsApi.getListTag()
    if (result?.status) {
      setSLTag(result.data)
    } else {
      pushToast(result?.message, "warning")
    }
  }

  const gSLUnit = async () => {
    const result = await unitApi.getListUnit()
    if (result?.status) {
      setSLUnit(result.data)
    } else {
      pushToast(result?.message, "warning")
    }
  }


  const handleChangeTagGroup = (selectedValues: number[]) => {
    setValuesTagGroup(selectedValues);
    setOpenTagGroup(false)
    form.setFieldsValue({ typetag: selectedValues }); // Update field value
    form.validateFields(['typetag']); // Validate field to update error status
  };

  const handleChangeUnit = (value: number) => {
    setUnit(value);
    form.setFieldsValue({ unit: value });
  }

  const submitForm = () => {
    if (inputRef.current) {
      inputRef.current.submit();
    }
  };
  const onFinish: FormProps["onFinish"] = (values) => {
    // if (onSubmit) {
    //   onSubmit(condition);
    // }
    // isShare

    if (isAddCustomer) {
      onAddSubmitFormModal(values)
    } else {
      onSubmit?.(false);
    }
  };

  const onAddSubmitFormModal = useThrottle(async (values: any) => {
    const status = isShare ? 1 : 0
    const { unit, namegroup, introduce, typetag } = values
    const type = condition ? 1 : 0

    const data = listCustomer?.map(item => Number(item))

    const result = await addGroup(
      status, namegroup.trim(), introduce.trim(), typetag, type, unit, data
    )
    if (result?.status) {
      onSubmitFormModalAdd?.(condition, true, result.message, result.data.id)
    } else {
      onSubmitFormModalAdd?.(condition, false, result.message)
    }

  }, 1000)


  useEffect(() => {
    if (isOpen && sLTag.length === 0) {
      gSLTag()
      gSLUnit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  useEffect(() => {
    if (isOpen) {

    } else {
      setIsShare(0)
      setValuesTagGroup([])
      setcondition(false)
      setUnit(undefined)
      setOpenTagGroup(false)

      form.setFieldValue('unit', undefined)
      form.setFieldValue('namegroup', undefined)
      form.setFieldValue('introduce', "")
      form.setFieldValue('typetag', undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])
  return (
    <Form
      form={form}
      ref={inputRef}
      onFinish={onFinish}
      initialValues={{ remember: true }}
    >
      <Modal
        title={<div className="bg-FCFCFD d-flex justify-betweeen align-center py-4 px-6 w-full border-b-F0F0F0">
          <span className="text-base text-344054 fw-500 font-medium">{isAddCustomer ? "Thêm thông tin nhóm" : "Cập nhật thông tin nhóm"}</span>
          <CloseOutlined className="text-base text-8B8B8B" onClick={closeModal} />
        </div>}
        open={isOpen}
        onCancel={closeModal}
        centered
        className="w-modal modal-content-update-group ant-modal-size"
        closeIcon={false}
        footer={<div className="bg-FCFCFD d-flex justify-betweeen align-center py-2 px-6 w-full border-t-F0F0F0">
          <span className="text-sm text-98A2B3 cursor-pointer" onClick={closeModal}>Làm mới thông tin</span>
          <button className="text-white text-sm bg-096DD9 border-none shadow-btn-save rounded-2 h-8 px-15-px"
            onClick={submitForm}>
            Lưu
          </button>
        </div>}
      >
        <div className="p-6 d-flex flex-column gap-18-px">
          <div className="w-313-px d-flex flex-column">
            <Radio.Group value={isShare} onChange={(e) => setIsShare(e.target.value)} className="w-full ">
              <Radio.Button value={0} className="w-138-px h-8 border-start-radio p-none-wrapper" >
                <div className="d-flex align-center gap-2 h-8 px-3">
                  <UserOutlined className="text-sm" />
                  <p className="text-sm font-regular">Nhóm riêng tư</p>
                </div>
              </Radio.Button>
              <Radio.Button value={1} className=" h-8 border-end-radio p-none-wrapper">
                <div className="d-flex align-center gap-2 h-8  px-3">
                  <GlobalOutlined className="text-sm" />
                  <p className="text-sm font-regular white-space-nowrap">Nhóm có thể chia sẻ</p>
                </div>
              </Radio.Button>
            </Radio.Group>

          </div>

          <div className="d-flex flex-column gap-18-px">
            {
              isShare === 1 && (
                <div className="d-flex flex-column gap-1">
                  <p className="text-sm  font-regular text-344054">Chọn đơn vị</p>
                  <Form.Item
                    name="unit"
                    rules={[{ required: isShare === 1, message: 'Chọn đơn vị!' }]}
                    className="m-none-form-item text-explan"
                  >
                    <div className='w-full'>
                      <Select
                        placeholder="Chọn đơn vị"
                        value={unit}
                        onChange={handleChangeUnit}
                        options={fommatSelectOption(sLUnit)}
                        className="custome-select-t "
                        popupClassName='popup-select'
                      />
                    </div>
                  </Form.Item>
                </div>

              )
            }
            <div className="d-flex flex-column gap-1">
              <p className="text-sm  font-regular text-344054">Tên nhóm</p>
              <Form.Item
                name="namegroup"
                rules={[{ required: true, message: 'Nhập tên nhóm!' }]}
                className="m-none-form-item text-explan"
              >
                <Input
                  placeholder="Tên nhóm"
                  className="bg-FCFCFD border-F2F4F7 text-sm rounded-2  font-regular  input-custom  h-8"
                />
              </Form.Item>
            </div>

            <div className="d-flex flex-column gap-1">
              <p className="text-sm  font-regular text-344054">Giới thiệu về nhóm này</p>
              <Form.Item
                name="introduce"
                rules={[{ required: true, message: 'Giới thiệu về nhóm!' }]}
                className="m-none-form-item text-explan"
              >
                {/* <div className="d-flex flex-column"> */}
                <TextArea placeholder='Giới thiệu về nhóm này' className="text-area-hover custom-text-area bg-FCFCFD text-area-input border-F2F4F7" />
                {/* </div> */}
              </Form.Item>
            </div>

            <div className="d-flex flex-column gap-1">
              <p className="text-sm  font-regular text-344054">Phân loại nhóm khách hàng bằng thẻ tag</p>
              <Form.Item
                name="typetag"
                rules={[{ required: true, message: 'Chọn nhóm khách hàng bằng thẻ tag' }]}
                className="m-none-form-item text-explan"
              >
                <div className="d-flex flex-column gap-3 w-full">
                  <Select
                    placeholder="Phân loại nhóm khách hàng bằng thẻ tag"
                    mode="multiple"
                    value={valuesTagGroup}
                    options={fommatSelectOption(sLTag)}
                    onChange={handleChangeTagGroup}
                    onDropdownVisibleChange={setOpenTagGroup}
                    open={openTagGroup}
                    className="custom-select-mutiple d-none-span "
                    popupClassName='popup-select'
                    suffixIcon={<SearchOutlined />}
                  />
                  <div className={`${valuesTagGroup.length !== 0 ? ' d-flex align-center gap-2' : 'none'}`}>
                    {
                      valuesTagGroup.map((value, index) => {
                        const selectedOption = fommatSelectOption(sLTag).find(option => option.value === value);
                        if (!selectedOption) return null;
                        return (
                          <p key={index} className="bg-F0F5FF border-ADC6FF rounded-2 text-2F54EB text-xs px-2 d-flex align-center gap-3-px h-22-px font-regular">
                            {selectedOption.label}
                            <CloseOutlined className="text-84878C text-xs cursor-pointer" onClick={() => handleChangeTagGroup(valuesTagGroup.filter(v => v !== value))} />
                          </p>
                        );
                      })
                    }
                  </div>
                </div>
              </Form.Item>
            </div>

          </div>



          {
            isAddCustomer &&
            <div className="d-flex align-center justify-between gap-6">
              <span className="text-sm text-344054 font-regular">Tự động thêm khách hàng có cùng điều kiện</span>
              <Switch
                checked={condition}
                onChange={() => setcondition(!condition)}
                className="custom-switch switch-handle switch-checked switch-inner size-text-checked siz-text-unchecked switch-inset switch-before switch-m switch-mt m-none-switch"
              />
            </div>
          }
        </div>
      </Modal>
    </Form >
  )
}
export default ModalUpdateInfoGroup
