import { PlusCircleOutlined, RetweetOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import ModalCreateGroupCustomer from '../../Modal/ModalCreateGroupCustomer';
import './style.scss';
import { IDuplicate } from '../../../../../../api/groupCustomer/groupCustomerApi';
import { formatPrice } from '../../../../../../until';

interface DoubleCircleChartProps {
  infoDuplicate?: IDuplicate
  reset: () => void
}

const DoubleCircleChart: React.FC<DoubleCircleChartProps> = ({
  infoDuplicate,
  reset
}) => {

  const [openCreateGroupCustomer, setOpenCreateGroupCustomer] = useState<boolean>(false);

  return (
    <div className='w-full d-flex gap-6 justify-center align-center'>
      <ModalCreateGroupCustomer
        isOpen={openCreateGroupCustomer}
        infoDuplicate={infoDuplicate}
        closeModal={() => setOpenCreateGroupCustomer(!openCreateGroupCustomer)}
      />
      <div className="d-flex flex-column align-center justify-center gap-4">
        <div className=" double-circle-chart-background  radius-chart-1 "
        >
          <div className="double-circle-chart-background-2 radius-chart-2"
            style={{
              transform: ` translate(-${Number(infoDuplicate?.percent || 0)}%, -50%)`,
            }}
          >
          </div>
        </div>
        <div className="d-flex  gap-2 justify-end align-center w-full">
          <div className="d-flex  gap-2 justify-center align-center">
            <div className="group-1"></div>
            <span className='text-xs text-8C8C8C font-regular'>{infoDuplicate?.group1.title}</span>

            <div className="d-flex  gap-2 justify-center align-center">
              <div className="group-2"></div>
              <span className='text-xs text-8C8C8C font-regular'>{infoDuplicate?.group2.title}</span>
            </div>
          </div>
        </div>

      </div>
      <div className={`d-flex flex-column gap-4
      ${((Number(infoDuplicate?.percent) > 40 && Number(infoDuplicate?.percent) < 70) && 'pl-20')
        || (Number(infoDuplicate?.percent) > 60 && 'pl-13')
        || (Number(infoDuplicate?.percent) === 100 && 'pl-none')
        || (Number(infoDuplicate?.percent) < 30 && 'pl-32')
        || ((Number(infoDuplicate?.percent) > 20 && Number(infoDuplicate?.percent) < 50) && 'pl-28')}`}
      >
        <div className=" d-flex gap-4 justify-start ">
          <div className="d-flex flex-column align-start border-right-F2F4F7 pr-6 gap-1">
            <span className="text-sm font-regular text-344054">Số KH trùng lặp giữa 2 nhóm</span>
            <div className='d-flex flex-column'>
              <span className="text-30-px font-medium fw-500 text-344054">{formatPrice(infoDuplicate?.quantity || 0)} </span>
              <span className="text-sm text-8C8C8C">khách hàng</span>
            </div>

          </div>
          <div className="flex flex-column align-items-start ">
            <span className="text-sm font-regular text-344054">Tỉ lệ trùng lặp giữa 2 nhóm</span>
            <span className="text-30-px font-medium fw-500 text-344054">{Number(infoDuplicate?.percent)} %</span>
          </div>
        </div>
        <div className='d-flex flex-column gap-10-px'>
          <button
            className='border-none bg-096DD9 h-8 px-4 text-white text-sm font-regular rounded-2 d-flex align-center gap-2 shadow-btn-save w-259-px'
            onClick={() => setOpenCreateGroupCustomer(true)}
          >
            <PlusCircleOutlined className='text-sm text-white' />
            <span>Tạo nhóm mới (loại bỏ tập trùng)</span>
          </button>
          <button
            className='border-none bg-white h-8 px-4 text-344054 text-sm font-regular rounded-2 d-flex align-center justify-center gap-2 shadow-btn-save border-EAECF0  w-259-px'
            onClick={reset}
          >
            <RetweetOutlined className='text-sm text-344054' />
            <span>Đặt lại</span>
          </button>
        </div>

      </div>

    </div>
  );
};

export default DoubleCircleChart;
