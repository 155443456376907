import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const data = [
  { name: '2020-11', value: 3001 },
  { name: '2020-12', value: 3250 },
  { name: '2021-01', value: 3100 },
  { name: '2021-02', value: 2900 },
  { name: '2021-03', value: 2920 },
  { name: '2021-04', value: 3010 },
  { name: '2021-05', value: 2890 },
  { name: '2021-06', value: 2950 },
  { name: '2021-07', value: 3110 },
  { name: '2021-08', value: 3188 },
  { name: '2021-09', value: 2900 },
  { name: '2021-10', value: 3348 },
  { name: '2021-11', value: 3864 },
  { name: '2021-12', value: 3501 },
  { name: '2022-01', value: 3300 },
  { name: '2022-02', value: 2950 },
  { name: '2022-03', value: 3032 },
  { name: '2022-04', value: 2987 },
  { name: '2022-05', value: 2890 },
  { name: '2022-06', value: 2576 },
  { name: '2022-07', value: 2872 },
  { name: '2022-08', value: 2998 },
  { name: '2022-09', value: 3054 },
  { name: '2022-10', value: 3001 },
  { name: '2022-11', value: 2894 },
  { name: '2022-12', value: 2948 },
  { name: '2023-01', value: 2763 },
  { name: '2023-02', value: 2605 },
  { name: '2023-03', value: 2811 },
  { name: '2023-04', value: 2987 },
  { name: '2023-05', value: 3250 },
  { name: '2023-06', value: 3576 },
  { name: '2023-07', value: 3376 },
  { name: '2023-08', value: 3188 },
  { name: '2023-09', value: 2900 },
  { name: '2023-10', value: 3348 },
  { name: '2023-11', value: 3864 },
  { name: '2023-12', value: 3501 },
]

const ARCGrowthChart: React.FC = () => {
  return (
    <div className="arc-card primary-gradient">
      <div className="arc-card__head">
        <span className="title">Biểu đồ tăng trưởng khách hàng</span>
        <div className="control">
          <Button
            className="rs-button"
          >
            <DownloadOutlined />
            <span>Xuất báo cáo</span>
          </Button>
        </div>
      </div>

      <div className="arc-card__content customer-grow-chart">
        <ResponsiveContainer aspect={539/368}>
          <AreaChart
            width={539}
            height={368}
            data={data}
            className="rs-recharts"
          >
            <defs>
              <linearGradient id="areaGradientPrimary7" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#5874D7" stopOpacity={0.2}/>
                <stop offset="100%" stopColor="#5874D7" stopOpacity={0}/>
              </linearGradient>
            </defs>

            <CartesianGrid
              strokeDasharray="3 3"
              vertical={false}
            />

            <XAxis
              dataKey="name"
              interval={data.length - 2}
            />

            <YAxis
              width={40}
            />

            <Tooltip />

            <Area
              type="monotone"
              name="Khách hàng"
              dataKey="value"
              stroke="#168CF9"
              fill="url(#areaGradientPrimary7)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

export default ARCGrowthChart