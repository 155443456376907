import React, { useEffect } from 'react';
import { Breadcrumb } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom';
import './BreadcrumbBlock.scss'

interface Props {
  items: Array<any>;
  title: string;
  backLink?: string;
  description?: string;
}

const BreadcrumbBlock: React.FC<Props> = ({items, title, backLink, description}) => {
  useEffect(() => {
    document.title = 'THACO AUTO CRM';
  }, []);
  return (
    <div className="app-breadcrumb-block">
      <Breadcrumb
        items={items}
      />

      <div className="app-breadcrumb-title">
        {
          backLink
            && <Link to={ backLink }><ArrowLeftOutlined /></Link>
        }
        <div className="title">{ title }</div>
        {
          description
            && <div className="description">{ description }</div>
        }
      </div>
    </div>
  );
}

export default BreadcrumbBlock;
