import React from "react";
import ARCCStatisticalCard from "../../../components/Cards/ARCCStatisticalCard";
import { DashboardOutlined, PieChartOutlined, } from "@ant-design/icons";
import './ARCCStatisticalCards.scss'

const ARCCStatisticalCards: React.FC = () => {
  return(
    <div className="arcc-statistical-cards">
      <ARCCStatisticalCard
        title={'Tổng quan'}
        icon={<DashboardOutlined />}
        items={[
          { title: 'Số liên hệ', number: 30000, type: 'increase', percent: 12 },
          { title: 'Đã xử lý', number: 29800, type: 'increase', percent: 2 },
          { title: 'Tỉ lệ xử lý', number: '98%', type: 'increase', percent: 2 },
        ]}
      />
      <ARCCStatisticalCard
        title={'Nguồn'}
        icon={<PieChartOutlined />}
        items={[
          { title: 'Hotline', number: 1000, type: 'decrease', percent: 2 },
          { title: 'Facebook', number: 10000, type: 'decrease', percent: 2 },
          { title: 'Zalo', number: 10000, type: 'decrease', percent: 2 },
        ]}
        className="sunset-orange"          
      />
    </div>
  )
}

export default ARCCStatisticalCards