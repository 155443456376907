import React, { useEffect, useState } from 'react';
import { Drawer, Input, Switch, Tag, message, } from 'antd';
import { BellOutlined, ClockCircleOutlined, CopyOutlined, DownloadOutlined, ShoppingCartOutlined, TagOutlined, TeamOutlined, } from '@ant-design/icons';
import './ManualCampaignPopup.scss';
import CampaignNetworkCard from '../../../components/Cards/CampaignNetworkCard';

interface Props {
  isShow?: boolean
  onPopupClose?: () => void
}

const AutomatedCampaignDetailPopup: React.FC<Props> = (props) => {
  const { isShow = false, onPopupClose } = props
  const [open, setOpen] = useState(isShow)

  useEffect(() => {
    setOpen(isShow)
  }, [isShow])

  const onClose = () => {
    setOpen(false)
    if (onPopupClose) {
      onPopupClose()
    }
  }

  async function copyTextToClipboard(text: any) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleCopyClick = (copyText: any) => {
    copyTextToClipboard(copyText)
      .then(() => {
        message.success({
          content: 'Copied',
          className: 'rs-message'
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const focusTagRendered = (type: string) => {
    let result
    switch (type) {
      case 'promotion':
        result = (
          <Tag className="rs-tag volcano">
              <TagOutlined />
            <span>Khuyến mãi</span>
          </Tag>
        )
        break;

      case 'notification':
        result = (
          <Tag className="rs-tag daybreak-blue">
              <BellOutlined />
            <span>Thông báo</span>
          </Tag>
        )
        break;

      case 'marketing':
        result = (
          <Tag className="rs-tag golden-purple">
              <ShoppingCartOutlined />
            <span>Tiếp thị</span>
          </Tag>
        )
        break;

      case 'customer-care':
        result = (
          <Tag className="rs-tag magenta">
              <TeamOutlined />
            <span>CSKH</span>
          </Tag>
        )
        break;

      default:
        result = (
          <Tag className="rs-tag daybreak-blue">
              <BellOutlined />
            <span>Thông báo</span>
          </Tag>
        )
        break;
    }
    return result
  }

  return (
    <Drawer
      title="Chi tiết chiến dịch"
      rootClassName="rs-drawer w-580"
      className="manual-campaign-popup"
      closeIcon={false}
      open={open}
      footer={false}
      onClose={onClose}
    >
      <div className="voucher-add__content">
        <div className="block">
          <div className="flex gap-12px">
            <Switch
              defaultChecked
              className="rs-switch"
            />
            <div className="flex gap-8px">
              <span className="neutral-gray-400">Mã chiến dịch</span>
              <span className="neutral-gray-400">#53326622</span>
              <CopyOutlined
                className="primary-7"
                onClick={() => handleCopyClick('#53326622')}
              />
            </div>
          </div>

          <span className="fsz-16 lh-24 font-medium">Gửi thông báo chào mừng thành viên</span>

          <span className="neutral-gray-400">Tạo lúc 13:51 2021-02-05</span>
        </div>

        <div className="block">
          <span className="font-medium lh-24 fsz-16">Mục tiêu</span>
          <div className="focus-tags">
            { focusTagRendered('notification') }
          </div>
        </div>

        <div className="block">
          <span className="fsz-16 lh-24 font-medium">Tập khách hàng nhận</span>
          <div className="block-flex receive-customer-item">
            <div className="flex">
              <span className="neutral-gray-400">Khách hàng mới&nbsp;</span>
              <span className="primary-6">(24.000)</span>
            </div>
            <div className="flex align-center gap-8px">
              <span className="neutral-gray-400 fsz-12 lh-20">Tải danh sách</span>
              <DownloadOutlined
                className="primary-6 cursor-pointer"
              />
            </div>
          </div>
          <div className="block-flex receive-customer-item">
            <div className="flex">
              <span className="neutral-gray-400">Khách hàng nữ&nbsp;</span>
              <span className="primary-6">(24.000)</span>
            </div>
            <div className="flex align-center gap-8px">
              <span className="neutral-gray-400 fsz-12 lh-20">Tải danh sách</span>
              <DownloadOutlined
                className="primary-6 cursor-pointer"
              />
            </div>
          </div>
        </div>

        <div className="block">
          <span className="fsz-16 lh-24 font-medium">Điều kiện</span>
          <div className="flex flex-column gap-16px">
            <div className="condition-item-detail">
              <Input defaultValue='Mua hàng' className="rs-input rs-input--32"/>
              <Input defaultValue="Bằng" className="rs-input rs-input--32"/>
              <Input addonBefore="Lượt" defaultValue={1} className="rs-input-group rs-input-group--32"/>
            </div>
          </div>
          <div className="flex flex-column gap-16px">
            <div className="condition-item-detail">
              <Input defaultValue='Điểm tích luỹ' className="rs-input rs-input--32"/>
              <Input defaultValue="Bằng" className="rs-input rs-input--32"/>
              <Input addonBefore="Điểm" defaultValue={2000} className="rs-input-group rs-input-group--32"/>
            </div>
          </div>
        </div>

        <div className="block">
          <span className="fsz-16 lh-24 font-medium">Kênh & nội dung và trạng thái nhận</span>

          <div className="campaign-network-cards flex flex-column gap-16px">
            <CampaignNetworkCard
              networkImg='/images/campaign/zalo.svg'
              networkName='ZNS'
              code='ZMS01'
              numberOfSuccess={24000}
              numberOfFail={1000}
              // progressing={true} // true: đang gửi | false: đã gửi
              // progressingNumber={10000} // nếu đang gửi thì truyền số lượng đã gửi được đến hiện tại
              // total={48000}
              // onDetail={} // sự kiện xem chi tiết
              // onDownload={} // sự kiện tải về
            />
            <CampaignNetworkCard
              networkImg='/images/campaign/sms.svg'
              networkName='SMS'
              code='SMS01'
              numberOfSuccess={24000}
              numberOfFail={1000}
              // progressing={true} // true: đang gửi | false: đã gửi
              // progressingNumber={10000} // nếu đang gửi thì truyền số lượng đã gửi được đến hiện tại
              // total={48000}
              // onDetail={} // sự kiện xem chi tiết
              // onDownload={} // sự kiện tải về
            />
            <CampaignNetworkCard
              networkImg='/images/campaign/mail.svg'
              networkName='Email Marketing'
              code='EM01'
              numberOfSuccess={24000}
              numberOfFail={1000}
              // progressing={true} // true: đang gửi | false: đã gửi
              // progressingNumber={10000} // nếu đang gửi thì truyền số lượng đã gửi được đến hiện tại
              // total={48000}
              // onDetail={} // sự kiện xem chi tiết
              // onDownload={} // sự kiện tải về
            />
          </div>
        </div>

        <div className="block">
          <span className="fsz-16 lh-24 font-medium">Thời gian gửi</span>
          <div className="flex gap-8px neutral-gray-400">
            <ClockCircleOutlined />
            <span className="fsz-12 lh-20 neutral-gray-400">Khi đạt điều kiện</span>
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export default AutomatedCampaignDetailPopup
