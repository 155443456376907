// import EmptyComponentKH from '../../../components/EmptyComponent/EmptyComponentKH';
import './Step.scss'
import { PlusOutlined } from '@ant-design/icons';
import Input, { SearchProps } from 'antd/es/input';
// import { Select } from 'antd';

interface Props {
  className?: string;
  onClose?: () => void;
}

const Step1: React.FC<Props> = ({ className, onClose }) => {
  const { Search } = Input;
  const onSearch: SearchProps['onSearch'] = (value, _e, info) => console.log(info?.source, value);

  const handleOnClose = () => {
    if (onClose) {
      onClose()
    }
  }

  // const handleChange = (value: string) => {
  //   console.log(`selected ${value}`);
  // };

  return (
    <>
      {/* Empty */}
      {/* <div className="ctrl-list-data-buttom-link">
        <div className="list-data empty-item">
          <div className="one-data t-pr-24 border-right-EAECF0">
            <div className="title">Dữ liệu ưu tiên làm dữ liệu chính</div>
            <div className="select-search">
              <Select
                className='t-select-ctrl t-border-FCFCFD-dr-8-hei-40'
                defaultValue="DDMS - Sales"
                onChange={handleChange}
                popupClassName='t-ctrl-popup-select'
                options={[
                  { value: 1, label: 'DDMS - Sales 1' },
                  { value: 2, label: 'DDMS - Sales 2' },
                  { value: 3, label: 'DDMS - Sales 3' },
                  { value: 4, label: 'DDMS - Sales 4' },
                ]}
              />
              <div className='t-search-block'>
                <Search className='t-border-FCFCFD-dr-8-hei-40' placeholder="Tìm khách hàng" onSearch={onSearch} />
              </div>
            </div>
            <EmptyComponentKH />
          </div>
          <div className="one-data t-pl-24">
            <div className="title">Dữ liệu phụ</div>
            <div className="select-search">
              <Select
                className='t-select-ctrl t-border-FCFCFD-dr-8-hei-40'
                defaultValue="DDMS - Sales"
                onChange={handleChange}
                popupClassName='t-ctrl-popup-select'
                options={[
                  { value: 1, label: 'DDMS - Sales 1' },
                  { value: 2, label: 'DDMS - Sales 2' },
                  { value: 3, label: 'DDMS - Sales 3' },
                  { value: 4, label: 'DDMS - Sales 4' },
                ]}
              />
              <div className='t-search-block'>
                <Search className='t-border-FCFCFD-dr-8-hei-40' placeholder="Tìm khách hàng" onSearch={onSearch} />
              </div>
            </div>
            <EmptyComponentKH />
          </div>
        </div>

        <div className="btn-pooling-data-block">
          <div className="ctrl-btn-pooling-data">
            <div className="btn">Bước tiếp theo</div>
          </div>
        </div>
      </div> */}

      <div className="ctrl-list-data-buttom-link">
        <div className="list-data">
          <div className="one-data t-pr-24 border-right-EAECF0">
            <div className="title">Dữ liệu ưu tiên làm dữ liệu chính</div>
            <div className="select-search">
              <div className='t-search-block w-100'>
                <Search className='t-border-FCFCFD-dr-8-hei-40' placeholder="Tìm khách hàng" onSearch={onSearch} />
              </div>
            </div>
            <div className="info-block">
              <div className="title">Thông tin cá nhân</div>
              <div className="list-info">
                <div className="one-info">
                  <div className="left">Tình trạng</div>
                  <div className="right">
                    <div className="status type-1">Chưa gộp</div>
                  </div>
                </div>
                <div className="one-info">
                  <div className="left">Tình trạng hôn nhân</div>
                  <div className="right">Đã kết hôn</div>
                </div>
                <div className="one-info">
                  <div className="left">Nguồn</div>
                  <div className="right">DDMS - Sales</div>
                </div>
                <div className="one-info">
                  <div className="left">Thuộc</div>
                  <div className="right">Đại lý bán hàng ABC</div>
                </div>
                <div className="one-info">
                  <div className="left">Nghề nghiệp</div>
                  <div className="right">Chưa cập nhật</div>
                </div>
                <div className="one-info">
                  <div className="left">Ngày sinh</div>
                  <div className="right">08/08/1997</div>
                </div>
                <div className="one-info">
                  <div className="left">Giới tính</div>
                  <div className="right">Nam</div>
                </div>
                <div className="one-info">
                  <div className="left">Số điện thoại</div>
                  <div className="right">0977721242</div>
                </div>
                <div className="one-info">
                  <div className="left">Email</div>
                  <div className="right">Hoangkimquoc97@gmail.com</div>
                </div>
                <div className="one-info">
                  <div className="left">Địa chỉ</div>
                  <div className="right">245E/1 Hoàng Văn Thụ, Tân Bình, TPHCM</div>
                </div>
              </div>
            </div>
          </div>

          <div className="one-data t-pl-24 t-pr-24 border-right-EAECF0">
            <div className="title">Dữ liệu phụ</div>
            <div className="select-search">
              <div className='t-search-block w-100'>
                <Search className='t-border-FCFCFD-dr-8-hei-40' placeholder="Tìm khách hàng" onSearch={onSearch} />
              </div>
            </div>
            <div className="info-block">
              <div className="title">Thông tin cá nhân</div>
              <div className="list-info">
                <div className="one-info">
                  <div className="left">Tình trạng</div>
                  <div className="right">
                    <div className="status type-1">Chưa gộp</div>
                  </div>
                </div>
                <div className="one-info">
                  <div className="left">Tình trạng hôn nhân</div>
                  <div className="right">Đã kết hôn</div>
                </div>
                <div className="one-info">
                  <div className="left">Nguồn</div>
                  <div className="right">IPCC</div>
                </div>
                <div className="one-info">
                  <div className="left">Thuộc</div>
                  <div className="right">Đại lý bán hàng ABC</div>
                </div>
                <div className="one-info">
                  <div className="left">Nghề nghiệp</div>
                  <div className="right">Chưa cập nhật</div>
                </div>
                <div className="one-info">
                  <div className="left">Ngày sinh</div>
                  <div className="right">Chưa cập nhật</div>
                </div>
                <div className="one-info">
                  <div className="left">Giới tính</div>
                  <div className="right">Chưa cập nhật</div>
                </div>
                <div className="one-info">
                  <div className="left">Số điện thoại</div>
                  <div className="right">Chưa cập nhật</div>
                </div>
                <div className="one-info">
                  <div className="left">Email</div>
                  <div className="right">Hoangkimquoc97@gmail.com</div>
                </div>
                <div className="one-info">
                  <div className="left">Địa chỉ</div>
                  <div className="right">245E/1 Hoàng Văn Thụ, Tân Bình, TPHCM</div>
                </div>
              </div>
            </div>
          </div>

          <div className="one-data t-pl-24 ctrl-btn-add-new">
            <div className="btn-add-new-block">
              <div className="btn"><PlusOutlined /></div>
              <div className="text">Thêm nguồn dữ liệu phụ</div>
            </div>
          </div>
        </div>
        <div className="btn-pooling-data-block">
          <div className="ctrl-btn-pooling-data">
            <div className="btn" onClick={handleOnClose}>Gộp dữ liệu</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Step1;
