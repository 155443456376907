import { Button, Tag } from "antd";
import React, { useState } from "react";
import { History } from "../../api/customer/customerApi";
import { URL_CAR, URL_CAR_CATEGORY } from "../../env";
import * as historyApi from '../../api/history/historyApi';
import { carDefault, logoCarDefault } from "../ExportImgFigma";
import './VehicleItem.scss';

interface Props {
  listOwnedVehicle?: History[];
  onButtonClick: (info: boolean) => void;
  setData?: (data: historyApi.IHistoryCarDetail) => void;
}

const OwnedVehicle: React.FC<Props> = ({
  listOwnedVehicle,
  setData,
  onButtonClick
}) => {
  const handleClick = (historyId: number) => {
    gHistoryCarInfo(historyId)
    onButtonClick(true)
  };


  const gHistoryCarInfo = async (historyId: number) => {
    const result = await historyApi.getListHistoryCarInfo(historyId)
    if (result?.status) {
      if (setData) {
        setData(result.data)
      }
    } else {

    }
  }

  return (
    <div >
      {
        listOwnedVehicle && listOwnedVehicle.map((item, i) =>
          <div
            key={i}
            className="vehicle-item vehicle-item--owned"
          >
            <div className="vehicle-item__info">
              <img
                src={URL_CAR + item?.imageCar}
                alt="BMW-Series-7"
                className="img"
              // onError={(e) => {
              //   e.currentTarget.src = carDefault;
              // }}
              />
              <div className="content">
                <div className="top">
                  <img
                    src={URL_CAR_CATEGORY + item.imageCategoryCar}
                    alt="BMW" className="logo"
                    onError={(e) => {
                      e.currentTarget.src = logoCarDefault;
                    }}
                  />
                  <div className="item">{item.car}</div>
                  <div className="item">Phiên bản {item.version}</div>
                  <div className="item">{item.bks}</div>
                </div>
                <div className="bottom">
                  <Tag className='rs-tag neutral-gray'>{`${item?.carLine} - ${item?.segmentsVehicle} Chỗ`}</Tag>
                  <Tag className='rs-tag neutral-gray'>Sở hữu {item.purchaseDate}</Tag>
                </div>
              </div>
            </div>
            <div className="vehicle-item__right">
              <Button
                className='rs-button'
                onClick={() => handleClick(item.historyId)}
              >Chi tiết thông tin</Button>
            </div>
            <img src="/images/brand-icon--blue.svg" alt="THACO AUTO" className="vehicle-item__brand-icon" />
          </div>
        )
      }
    </div>
  )
}

export default OwnedVehicle