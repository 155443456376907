import axiosClient, { ResponseSuccess, ResponseSuccessTotal } from "../axiosClient";

export interface INotificationTemplate {
    title: string
    status: number
    description: string
    targetId: number
    code?: string,
    iamgeTypeZNS?: string
}
export interface PropsParamFilter {
    targetId: number | undefined,
    networkId: number | undefined,
    status: number | undefined,
    type: number | undefined,
    dateStart: string | undefined,
    dateEnd: string | undefined
}


export const getListTemplate = async (
    limit?: number, pageNumber?: number, targetId?: number, networkId?: number, status?: number, type?: number, dateStart?: string, dateEnd?: string) => {
    const path = "notificationTemplate/listTemplate"
    const params = {
        limit, pageNumber, targetId, networkId, status, type, dateStart, dateEnd
    }
    const result: ResponseSuccessTotal<INotificationTemplate[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};


export const addNotificationTemplate = async (
    title: string, targetId: number, description: string, networkId?: number, typeZNSId?: number
) => {
    const path = "notificationTemplate/addNotificationTemplate"
    const params = {
        targetId, title, description, networkId, typeZNSId
    }
    const result: ResponseSuccess<[]> = await axiosClient.post(
        path, params
    );
    return result;
};
