import { ContactsOutlined, PhoneOutlined, UserAddOutlined } from "@ant-design/icons";
import { Button, Form, FormInstance, FormProps, Input, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import * as IssuedCardsApi from '../../api/rank/issuedCardApi';
import * as RankApi from '../../api/rank/rankApi';
import * as SelectOptionApi from '../../api/selectOptions/selectOptionsApi';
import useToast from "../../hook/useToast";
import { fommatSelectOption } from "../../until";
import './ShowAddCard.scss';


type FieldType = {
  username: string;
  phone: number;
  iduser: string;
  rank: number;

};
interface Props {
  className?: string;
  onClose?: () => void;
  onShowHistory?: () => void;
  dataRank: RankApi.IRank[]
  getListIssuedCards: () => void
}

const { Search } = Input;

const ShowAddCard: React.FC<Props> = ({ className, onClose, onShowHistory, dataRank, getListIssuedCards }) => {
  const [form] = useForm();

  const inputRef = useRef<FormInstance<FieldType>>(null);
  const pushToast = useToast();
  const [arrayCustomer, setArrayCustomer] = useState<SelectOptionApi.ISelectOption[]>([])
  const [idCustomer, setIdCustomer] = useState<number>()
  const submitForm = () => {
    if (inputRef.current) {
      inputRef.current.submit();
    }
  };

  const onFinish: FormProps<FieldType>["onFinish"] = async (values: FieldType) => {
    if (idCustomer && values && onClose) {
      const result = await IssuedCardsApi.addCardIssuance(idCustomer, values.rank, values.iduser)
      if (result?.status) {
        pushToast(result?.message, "success", 2000)
        onClose()
        getListIssuedCards()
      } else {
        pushToast(result?.message, "warning")
      }
    }
  };


  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(e.target as Node)) {
        if (onClose) {
          onClose();
        }
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [onClose]);

  const [selectedRank, setSelectedRank] = useState<number | undefined>(undefined);

  const handleChange = (value: number) => {
    setSelectedRank(value);
  };

  const handleSelectCustomer = (value: number) => {
    setIdCustomer(value)
    const selectedOption = arrayCustomer.find(option => option.value === value);
    if (selectedOption) {
      let nameAndPhone = selectedOption.label.split(" (");
      form.setFieldValue('username', nameAndPhone[0])
      form.setFieldValue('phone', nameAndPhone[1].slice(0, -1))
    }
  }

  //  fix
  const getListUnreleased = async () => {
    const result = await SelectOptionApi.getListUnreleased()
    if (result?.status) {
      // setSLStaff(result.data)
      setArrayCustomer(fommatSelectOption2(result.data))
      let randomValue = `THE${moment().toDate().getFullYear()}${(moment().toDate().getMonth() + 1).toString().padStart(2, '0')}${moment().toDate().getDate().toString().padStart(2, '0')}${moment().toDate().getHours().toString().padStart(2, '0')}${Math.floor(Math.random() * 100)}`
      form.setFieldValue('iduser', randomValue)
    } else {
      pushToast(result?.message, "warning")
    }
  }

  const fommatSelectOption2 = (arr: SelectOptionApi.IListCustomerBenefit[]) => {
    const newData = arr?.map(item => ({
      value: item.id,
      label: item.name + ' (' + item.phone + ')'
    }));
    return newData
  }


  useEffect(() => {
    getListUnreleased()
  }, [])

  return (
    <div
      ref={popupRef}
      className="filter-setting add-card-issuance"
      id="addcardissuance"
    >
      <div className="title-add-filter">
        <span>Phát hành thẻ</span>
      </div>
      <div className="content-filter-setting t-scrollbar">
        <Select
          showSearch
          className="custome-select"
          popupClassName='popup-select'
          placeholder="Tìm và chọn khách hàng"
          options={arrayCustomer}
          getPopupContainer={() => document.getElementById('addcardissuance') || document.body}
          onChange={(value) => handleSelectCustomer(value)}
        />

        <div className="title-or">Thông tin khách hàng </div>

        <Form
          ref={inputRef}
          form={form}
          className="form-block"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <div className="d-flex flex-column gap-1">
            <p className="text-sm  font-regular text-344054">Họ và tên</p>
            <Form.Item<FieldType>
              name="username"
              rules={[{ required: true, message: 'Vui lòng nhập họ và tên!' }]}
            >
              <Input size="large" placeholder="Họ và tên" disabled prefix={<UserAddOutlined />} />
            </Form.Item>
          </div>

          <div className="d-flex flex-column gap-1">
            <p className="text-sm  font-regular text-344054">Số điện thoại</p>
            <Form.Item<FieldType>
              name="phone"
              rules={[{ required: true, message: 'Vui lòng nhập số điện thoại!' }]}
            >
              <Input size="large" placeholder="Số điện thoại" disabled prefix={<PhoneOutlined />} />
            </Form.Item>
          </div>

          <div className="d-flex flex-column gap-1">
            <p className="text-sm  font-regular text-344054">Chọn hạng thẻ</p>
            <Form.Item<FieldType>
              name="rank"
              rules={[{ required: true, message: 'Vui lòng chọn hạng!' }]}
              className="input-block"
            >
              <Select
                className='t-select-ctrl'
                placeholder="Chọn hạng thẻ"
                onChange={handleChange}
                value={selectedRank}
                popupClassName='t-ctrl-popup-select'
                getPopupContainer={() => document.getElementById('addcardissuance') || document.body}
                options={fommatSelectOption(dataRank)}
              />
            </Form.Item>
          </div>

          <div className="d-flex flex-column gap-1">
            <p className="text-sm  font-regular text-344054">Mã thành viên</p>
            <Form.Item<FieldType>
              name="iduser"
              rules={[{ required: true, message: 'Vui lòng nhập mã thành viên!' }]}
            >
              <Input size="large" placeholder="Mã thành viên (Để trống hệ thống tự sinh)" prefix={<ContactsOutlined />} />
            </Form.Item>
          </div>

        </Form>
      </div>
      <div className="footer-filter-block">
        <div className="btn-block">
          <Button className="btn btn-primary" onClick={submitForm}>
            <div className="text">
              Phát hành
            </div>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ShowAddCard
