import axiosClient, { ResponseSuccess, ResponseSuccessTotal } from "../axiosClient";
import { Phone } from "../selectOptions/selectOptionsApi";

//Thông tin khách hàng
export interface relationshipProp {
    name: string,
    hobby: number[],
    relationship: number | null
}
export interface ICustomerDetail {
    id: number
    image: string
    userName: string
    CCCD: string
    birthday: string
    gender: number
    email: string
    address: string
    profession: string
    statusMarital: number
    hobby: Item[]
    relatives: Rela[]
    rank: Rank
    classifys: Item[]
    history: History[]
    carCare: CarCare[]
    phone: Phone2[]
}
export interface Item {
    id: number
    title: string
}

export interface Rela {
    name: string
    relationship: Item
    hobby: Item[]
}


export interface Rank {
    icon: string
    title: string
}

export interface History {
    historyId: number
    bks: string
    categoryCar: string
    car: string
    imageCar?: string
    imageCategoryCar?: string
    code: string
    purchaseDate: string
    licensePlates: string
    version: string
    carLine: string
    segmentsVehicle: string
}

export interface CarCare {
    categoryCar: string
    car: string
    imageCar?: string
    imageCategoryCar?: string
    version: string
    carLine: string
    segmentsVehicle: string
}

//list
export interface DataTypeCustomer {
    key: any;
    action: string;
    stt: number;
    name: string;
    cccd: string;
    birthday: string;
    gender: number;
    phone: string;
    email: string;
    interestedCar: string;
}
export interface ICustomer {
    id: number
    name: string
    CCCD: string
    phone: string
    birthday: string
    gender: number
    profession: string
    statusMarital: number
    address: string
    email: string
    province: string
    interestedCar: string
}

export interface IDetailCustomer {
    id: number
    userName: string
    image: string
    CCCD: any
    birthday: string
    gender: number
    email: string
    address: string
    profession: string
    statusMarital: number
    hobby: string[]
    relatives: Rela[]
    classifys: string[]
    history: any[]
    carCare: CarCare[]
    rank: any
    groupCustomer: string[]
    phone: Phone[]
    agent: string
}

export interface ICustomerDetail2 {
    id: number
    userName: string
    image: string
    CCCD: string
    birthday: string
    gender: number
    email: string
    address: string
    profession: string
    statusMarital: number
    hobby: Item[]
    relatives: Rela2[]
    classifys: Item[]
    history: History2[]
    carCare: CarCare2[]
    rank: any
    groupCustomer: Item[]
    phone: Phone2[]
    agent: Item
    province: Item
}

export interface Phone2 {
    phone: string
    type: number
}

export interface CarCare2 {
    id: number
    categoryCar: string
    imageCategoryCar: string
    car: string
    imageCar: string
    version: string
    carLine: string
    segmentsVehicle: string
}
export interface History2 {
    idCar?: number
    categoryCar?: string
    imageCategoryCar: string
    car?: string
    code: string
    bks?: string
    imageCar: string
    purchaseDate: string
    licensePlates?: string
    version: string
    carLine: string
    segmentsVehicle: string
}
export interface Rela2 {
    name: string
    relationship: Item
    hobby: Item[]
}

export const getListCustomer = async (
    limit?: number, pageNumber?: number, search?: string, filterCustomerId?: number, type?: 0 | 1 | 2, categoryCarId?: number, priceLowest?: number, priceHighest?: number, dateMaintenanceStart?: string, dateMaintenanceEnd?: string, gender?: number, birthdayStart?: string, birthdayEnd?: string, areaId?: number[], provinceId?: number[]
) => {
    const path = "customer/listCustomer"
    const params = {
        limit, pageNumber, search, filterCustomerId, type, categoryCarId, priceLowest, priceHighest, dateMaintenanceStart, dateMaintenanceEnd, gender, birthdayStart, birthdayEnd, areaId, provinceId
    }
    const result: ResponseSuccessTotal<ICustomer[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Thông tin khách hàng
export const getInfoCustomer = async (id: number) => {
    const path = "customer/infoCustomer"
    const params = {
        id
    }
    const result: ResponseSuccess<ICustomerDetail> = await axiosClient.get(path, { params });
    return result;
};
// add kh

export const addCustomer = async (
    username: string,
    gender: number,
    statusMarital: number,
    profession: string,
    phone: Array<{ phone: string, type: number }>,
    birthday: string,
    email: string,
    provinceId: number | null,
    address: string,
    image: File | null,
    groupCustomerId: number[],
    tagsId: number[],
    hobbyId: number[],
    carId: number[],
    relationshipList: Array<relationshipProp>

) => {
    const url = `customer/addCustomer`;
    const params = {
        username,
        gender,
        statusMarital,
        profession,
        phone,
        birthday,
        email,
        provinceId,
        address,
        image,
        groupCustomerId,
        tagsId,
        hobbyId,
        carId,
        relationshipList,
    }
    const result: ResponseSuccess<[]> = await axiosClient.post(
        url, params
    );
    return result;
}

// update

export const updateCustomer = async (
    id: number,
    username: string,
    gender: number,
    statusMarital: number,
    profession: string,
    phone: Array<{ phone: string, type: number }>,
    image: File | null,
    birthday: string,
    provinceId: number | null,
    email: string,
    address: string,
    groupCustomerId: number[],
    tagsId: number[],
    hobbyId: number[],
    carId: number[],
    relationshipList: Array<relationshipProp>

) => {
    const url = `customer/editCustomer`;
    const params = {
        id,
        username,
        gender,
        statusMarital,
        profession,
        phone,
        image,
        birthday,
        provinceId,
        email,
        address,
        groupCustomerId,
        tagsId,
        hobbyId,
        carId,
        relationshipList
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        url, params
    );
    return result;
}

//info
export const getDetailCustomer = async (id: number) => {
    const path = "customer/infoCustomer"
    const params = {
        id
    }
    const result: ResponseSuccess<ICustomerDetail2> = await axiosClient.get(path, { params });
    return result;
};

//Xóa khách hàng
export const deleteCustomer = async (
    id: number
) => {
    const path = "customer/deleteCustomer"
    const params = {
        id
    }
    const result: ResponseSuccess<[]> = await axiosClient.delete(
        path, { params }
    );
    return result;
};