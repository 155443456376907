import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { QuestionCircleOutlined, MenuOutlined, CloseOutlined } from '@ant-design/icons'
import './Header.scss'

const AppHeader: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const triggerMenu = () => {
    const appWidget = document.getElementsByClassName('app-widget')[0]
    setIsOpen(!isOpen)
    appWidget.classList.toggle('app-widget-show')
  }

  return (
    <div className="app-header">
      <Link to={'/'} className="app-header-left">
        <img src="/logo.svg" alt="THACO AUTO CRM" />
      </Link>

      <div className="app-header-right">
        <div className="item">
          <QuestionCircleOutlined />
        </div>
        <div className="item">
          <div className="avatar-trigger">
            <img src="/images/user-avatar.svg" alt="" />
            <span className="name">Serati Ma</span>
          </div>
        </div>
        <div
          className="item menu-trigger"
          onClick={triggerMenu}
        >
          {
            isOpen
              ? <CloseOutlined />
              : <MenuOutlined />
          }
        </div>
      </div>
    </div>
  );
}

export default AppHeader;