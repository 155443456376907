/* eslint-disable no-useless-escape */
import * as selectOptionsApi from '../src/api/selectOptions/selectOptionsApi';
import * as RankApi from '../src/api/rank/rankApi';
import * as IssuedCardsApi from '../src/api/rank/issuedCardApi';
import * as CustomerApi from '../src/api/customer/customerApi';
import * as XLSX from 'xlsx';


export const paserParams = (params: any, valueIsFail: any, allow?: CheckValueOptions) => {
    for (let i in params) {
        if (typeof (params[i]) === 'object') {
            params[i] = paserParams(params[i], valueIsFail, allow)
        } else {
            params[i] = parseValue(params[i], valueIsFail, allow)
        }
    }
    return params
}

export const parseValue = (value: any, valueIsFail: any, allow?: CheckValueOptions) => {

    if (!allow?.isNull) {
        if (value == null) {
            return valueIsFail;
        }
        if (Number.isNaN(value)) {
            return valueIsFail;
        }
    }

    if (!allow?.isEmpty) {
        if (['string', 'object'].includes(typeof (value)) && value.length === 0) {
            return valueIsFail;
        }
    }
    return value;
}
interface CheckValueOptions {
    isEmpty?: boolean,
    isNull?: boolean,
}

export const dataPagination = (totalRow: number, rowNumber: number, currentPage: number) => {

    var tongTrang = Math.ceil(totalRow / rowNumber) === 0 ? 1 : Math.ceil(totalRow / rowNumber)
    var tongDongTrangCuoi = totalRow - (rowNumber * (Math.floor(totalRow / rowNumber)))
    var hienThiTu = rowNumber * currentPage
    var hienThiDen = rowNumber * (currentPage + 1)
    return {
        totalPage: tongTrang,
        currentPage: currentPage,
        totalLastLine: tongDongTrangCuoi,
        hienThiTu: hienThiTu,
        hienThiDen: hienThiDen
    }
}

export const regexPhoneNumber = (phone: selectOptionsApi.Phone[]) => {
    const regexPhoneNumber = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;
    const validate = phone.map((item) => {
        return item.phone.match(regexPhoneNumber) ? true : false
    })
    return validate
}
export const validateEmail = (email: string): boolean => {
    // Regular expression for validating email addresses
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return emailRegex.test(email);
};


export const fommatSelectOption = (arr: selectOptionsApi.ICarCompany[] | selectOptionsApi.IRelative[] | RankApi.IRank[] | IssuedCardsApi.IListRankEdit[] | selectOptionsApi.IListAgent[]) => {
    const newData = arr?.map(item => ({
        value: item.id,
        label: item.title
    }));
    return newData
}

export const fommatSelectOptionCustomer = (arr: CustomerApi.ICustomer[]) => {
    const newData = arr?.map(item => ({
        value: item.id,
        label: item.name
    }));
    return newData
}
// Functions xử lý khi có parent ID để find ra title Vùng miền. Toàn quốc
export function findParentIdLevelOne(units: selectOptionsApi.dataConvert[], targetId: number | null, level: number): string {
    // console.log(targetId);
    if (targetId === null) {
        return '';
    }
    const data = units.find((item) => item.id === targetId);
    if (data?.level === level) {
        return data.title;
    } else {
        return findParentIdLevelOne(units, data?.parentId ?? null, level);
    }
}

export function flattenDonViArray(arr: selectOptionsApi.UnitLevel[]): selectOptionsApi.dataConvert[] {
    const result: { id: number, title: string, level: number, parentId: number | null }[] = [];

    function traverse(data: selectOptionsApi.UnitLevel[]) {
        for (const item of data) {
            result.push({ id: item.id, title: item.title, level: item.level, parentId: item.parentId });
            if (item.children && item.children.length > 0) {
                traverse(item.children);
            }
        }
    }
    traverse(arr);
    return result;
}

export const formatPrice = (val: number | string | undefined) => {
    if (val != undefined) {
        let v = val.toString();
        return v.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    } else {
        return "";
    }
};

// Tắng % khi fill input add customer
export const changePercent = (percent: number, percentUp: number, stringOld: string, stringNew: string) => {
    if (stringNew.length && !stringOld.length) {
        return (percent + percentUp)
    } else {
        if (stringOld.length && !stringNew.length) {
            return (percent - percentUp)
        }
        else {
            return percent
        }

    }
}

export const changePercentDate = (percent: number, percentUp: number, stringOld: string, stringNew: string) => {
    if (stringNew !== null && !stringOld.length) {
        return (percent + percentUp)
    } else {
        if (stringOld.length && stringNew === null) {
            return (percent - percentUp)
        }
        else {
            return percent
        }

    }
}


export const changePercentGroup = (percent: number, percentUp: number, stringOld: number[], stringNew: number[]) => {
    if (stringNew.length !== 0 && !stringOld.length) {
        return (percent + percentUp)
    } else {
        if (stringNew.length === 0) {
            return (percent - percentUp)
        }
        else {
            return percent
        }

    }
}

export const fomartListAreaAndListProvince = (data: string[]) => {
    let ListArea: number[] = [], listProvince: number[] = [];
    const uniqueListArea = new Set<number>();

    data.forEach((acc) => {
        const xx = acc.toString()
        const [before, after] = xx.split('-');
        const area = +before;
        if (!uniqueListArea.has(area)) {
            ListArea.push(area);
            uniqueListArea.add(area);
        }
        if (after) {
            listProvince.push(+after);
        }
    });

    return { ListArea, listProvince };
};


export const convertStringToHTML = (value: string) => {
    return <div dangerouslySetInnerHTML={{ __html: value }} ></div>
}

export const exportExcel = (convertData: any, message?: string): boolean => {
    try {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(convertData);
        const workbook: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Danh sách');

        const excelBuffer: ArrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });

        const saveAsExcel = (buffer: ArrayBuffer, fileName: string): void => {
            const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
            const link: HTMLAnchorElement = document.createElement('a');
            link.href = window.URL.createObjectURL(data);
            link.download = fileName;
            link.click();
        };

        saveAsExcel(excelBuffer, `${message}.xlsx`);
        return true; // Export successful
    } catch (error) {
        console.error('Error exporting Excel:', error);
        return false; // Export failed
    }
}