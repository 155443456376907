import { Drawer, Form, FormInstance, Input, message, Select } from "antd"
import { useForm } from 'antd/lib/form/Form';
import { useEffect, useRef } from "react";
import type { FormProps } from 'antd';
import './style.scss'
import TextArea from "antd/es/input/TextArea";
import * as BenefitApi from '../../../api/rank/benefitApi';
import useToast from "../../../hook/useToast";

interface DrawerProps {
  isOpen: boolean
  closeDrawer: () => void
  dataInfoBenefit?: BenefitApi.IInfoBenefit
  getListBenefit: () => void
}
const DrawerEditBenefit: React.FC<DrawerProps> = ({
  isOpen,
  dataInfoBenefit,
  closeDrawer,
  getListBenefit
}) => {
  const [form] = useForm();
  const pushToast = useToast();
  const inputRef = useRef<FormInstance>(null);
  const submitForm = () => {
    if (inputRef.current) {
      inputRef.current.submit();
    }
  };
  const onFinish: FormProps<any>['onFinish'] = async (values) => {
    if (dataInfoBenefit) {
      const result = await BenefitApi.editBenefit(dataInfoBenefit.id, values.codecar, values.description);
      if (result?.status) {
        pushToast(result.message, "success", 2000)
        closeDrawer()
        getListBenefit()
      } else {
        pushToast(result.message, "error", 2000)
      }
    }

  };
  form.setFieldValue('codecar', dataInfoBenefit?.title)
  form.setFieldValue('description', dataInfoBenefit?.description)
  return (
    <Form
      form={form}
      ref={inputRef}
      onFinish={onFinish}
      initialValues={{ remember: true }}
    >
      <Drawer
        title={<p className="font-medium fw-500 text-344054 py-3 px-6 bg-FCFCFD">Chỉnh sửa quyền lợi</p>}
        placement="right"
        rootClassName="custom-drawer"
        closable={false}
        onClose={closeDrawer}
        open={isOpen}
        footer={
          <div className="py-2 px-6 bg-FCFCFD d-flex justify-between align-center">
            <p className="text-sm text-98A2B3 font-regular cursor-pointer">Làm mới thông tin</p>
            <button onClick={submitForm} className="border-none bg-096DD9 rounded-2 px-15-px  h-8  text-sm font-regular text-white">Chỉnh sửa quyền lợi</button>
          </div>
        }
      >
        <div className=" d-flex flex-column">

          <div className="d-flex flex-column gap-18-px">
            <p className="text-base fw-500 font-medium text-344054">Tên quyền lợi</p>
            <Form.Item
              name="codecar"
              rules={[{ required: true, message: 'Nhập họ và tên!' }]}
              className="m-none-form-item text-explan"
            >
              <Input
                placeholder="Tên quyền lợi"
                className="custom-input-card bg-FCFCFD border-F2F4F7 text-sm rounded-2  font-regular   h-10"
              />
            </Form.Item>
            <p className="text-base fw-500 font-medium text-344054">Mô tả quyền lợi</p>

            <Form.Item
              name="description"
              rules={[{ required: true, message: 'Vui lòng mô tả quyền lợi!' }]}
              className="m-none-form-item text-explan"
            >
              <TextArea
                className="t-textarea bg-FCFCFD border-F2F4F7 rounded-2"
                rows={2}
                placeholder="Thành viên có quyền lợi này sẽ được những gì?"
              />
            </Form.Item>
          </div>
        </div>
      </Drawer>
    </Form>

  )
}
export default DrawerEditBenefit
