import React from 'react'
import RelativeItem from './RelativeItem'
import './RelativeList.scss'
import { Rela } from '../../api/customer/customerApi'

interface Props {
  listRelatives?: Rela[]
  items?: Array<any>
}

const RelativeList: React.FC<Props> = ({
  listRelatives
}) => {
  return (
    <div className="relative-list">
      {
        listRelatives && listRelatives.map((item, index) => (
          <RelativeItem
            key={index}
            item={item}
          />
        ))
      }
    </div>
  )
}

export default RelativeList;
