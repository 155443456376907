import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from './slices/userSlice';
import toastReducer from './slices/toastSlice';
import infoUserSlice from './slices/infoUserSlice';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ["dataAcccount", "infoUserSlice"]
}

const rootReducer = combineReducers({
    infoUserSlice: infoUserSlice,
    dataAcccount: userReducer,
    notifications: toastReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
    })
});
export const persistor = persistStore(store);
export default store;