import { ShoppingCartOutlined, SettingOutlined, BellOutlined, UserOutlined, UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { PaginationProps, Timeline, Pagination, Input, DatePicker, Select, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import './InteractiveHistory.scss';
import * as selectOptionsApi from '../../../api/selectOption/selectOptionsApi';
import { dataPagination, fommatSelectOption } from '../../../until';
import useDebounce from '../../../hook/useDebounce';
import * as historyApi from '../../../api/history/historyApi';
import useToast from '../../../hook/useToast';

const { Search } = Input
const { RangePicker } = DatePicker;
interface ITimeLine {
  dot: JSX.Element;
  color?: string | undefined;
  label: string;
  children: JSX.Element;
}
interface Props {
  id: number;
}
const InteractiveHistoryTab: React.FC<Props> = ({
  id
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    gCLTypeInteraction()
    return () => {
      setFilterName("")
      setFilterDate(["", ""])
      setFilterCLTypeInteraction(undefined)

      setPagination({ current: 1, pageSize: 10, total: 0 })

      setListTimeLine([])
    }
  }, [])


  const pushToast = useToast();

  const [filterName, setFilterName] = useState<string>()
  const [filterDate, setFilterDate] = useState(["", ""])
  const [filterCLTypeInteraction, setFilterCLTypeInteraction] = useState<number>()

  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });

  const [sLTypeInteraction, setSLTypeInteraction] = useState<selectOptionsApi.ICarCompany[]>([])
  const [listTimeLine, setListTimeLine] = useState<ITimeLine[]>([])

  const gCLTypeInteraction = async () => {
    const result = await selectOptionsApi.getCLTypeInteraction()
    if (result?.status) {
      setSLTypeInteraction(result.data)
    } else {
      pushToast(result?.message, 'error', 2000)

    }
  }

  const gListHistory = useDebounce(async (pageCurent: number = 1, pageSize: number = 10) => {
    const result = await historyApi.getListHistory(
      id, pageCurent, pageSize, filterName, filterDate[0], filterDate[1], filterCLTypeInteraction
    )
    if (result?.status) {
      convertData(result.data)
      setPagination({ current: pageCurent, total: result.total, pageSize })
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)


  const convertData = (listTypeInteraction: historyApi.IHistory[]) => {
    const convertData: ITimeLine[] = []
    listTypeInteraction.map((item) =>
      convertData.push(convertUI(item))
    )

    setListTimeLine(convertData)
  }

  const convertUI = (typeInteraction: historyApi.IHistory) => {
    switch (typeInteraction.typeInteractionId) {
      // Mua hàng
      case 1:
        return {
          dot: (
            <div className="timeline-dot timeline-dot--success">
              <ShoppingCartOutlined />
            </div>
          ),
          label: typeInteraction.dateTransaction,
          children: (
            <div className="timeline-content">
              <div className="title">Mua hàng</div>
              <div className="content">
                <span className="neutral-gray-400">Mua&nbsp;</span>
                <span>{typeInteraction.carName}&nbsp;</span>
                <span className="neutral-gray-400">tại&nbsp;</span>
                <span>{typeInteraction.agentName}</span>
              </div>
            </div>
          ),
        }
      // Bảo dương
      case 2:
        return {
          dot: (
            <div className="timeline-dot timeline-dot--primary">
              <SettingOutlined />
            </div>
          ),
          label: typeInteraction.dateTransaction,
          children: (
            <div className="timeline-content">
              <div className="title">Bảo dưỡng</div>
              <div className="content">
                <span className="neutral-gray-400">Bảo dưỡng xe&nbsp;</span>
                <span>{typeInteraction.carName}&nbsp;</span>
                <span className="neutral-gray-400">tại&nbsp;</span>
                <span>{typeInteraction.agentName}</span>
              </div>
            </div>
          ),
        }
      // Marketing
      case 3:
        return {
          dot: (
            <div className="timeline-dot timeline-dot--magenta">
              <BellOutlined />
            </div>
          ),
          label: '02/01/2024',
          children: (
            <div className="timeline-content">
              <div className="title">Marketing</div>
              <div className="content">
                <span className="neutral-gray-400">Khách hàng được thêm vào chiến dịch&nbsp;</span>
                <span>Email Marketing Tết 2024</span>
              </div>
            </div>
          ),
        }
      // Bảo hành
      case 4:
        return {
          dot: (
            <div className="timeline-dot timeline-dot--purple">
              <SettingOutlined />
            </div>
          ),
          label: typeInteraction.dateTransaction,
          children: (
            <div className="timeline-content">
              <div className="title">Bảo hành</div>
              <div className="content">
                <span className="neutral-gray-400">Bảo hành xe&nbsp;</span>
                <span>{typeInteraction.carName}&nbsp;</span>
                <span className="neutral-gray-400">tại&nbsp;</span>
                <span>{typeInteraction.agentName}</span>
              </div>
            </div>
          ),
        }
      //Liên hệ chăm sóc khách hàng
      case 5:
        return {
          dot: (
            <div className="timeline-dot timeline-dot--volcano">
              <UserOutlined />
            </div>
          ),
          label: typeInteraction.dateTransaction,
          children: (
            <div className="timeline-content">
              <div className="title">Liên hệ chăm sóc khách hàng</div>
              <div className="content">
                <span className="neutral-gray-400">Nhân viên&nbsp;</span>
                <span>{typeInteraction?.nameStaff} (NV124)&nbsp;</span>
                <span className="neutral-gray-400">tạo phiếu&nbsp;</span>
                <span>{typeInteraction.typeSupportTitle}&nbsp;</span>
                <span className="neutral-gray-400">cho khách hàng</span>
              </div>
            </div>
          ),
        }

      default:
        return {
          dot: (
            <div className="timeline-dot timeline-dot--volcano">
              {/* <UserOutlined /> */}
            </div>
          ),
          label: '02/01/2024',
          children: (
            <div className="timeline-content">
            </div>
          ),
        }
    }
  }

  const onPaginationChange: PaginationProps['onChange'] = (pageNumber, pageSize) => {
    gListHistory(pageNumber, pageSize)
  }

  const onDateChange = (date: any, dateString: string[], info: any) => {
    const isNotEmpty = dateString.some(item => item !== "");
    if (isNotEmpty) {
      setFilterDate(dateString)
    } else {
      setFilterDate(["", ""])
    }
  }


  useEffect(() => {
    if (id)
      gListHistory()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterName, filterDate, filterCLTypeInteraction])

  return (
    <div className="interactive-history-tab">
      <div className="interactive-history-tab__head">
        <Search
          placeholder="Tìm kiếm"
          className='rs-search'
          value={filterName}
          onChange={(e) => setFilterName(e.target.value)}
        />

        <RangePicker
          className="rs-range-picker"
          popupClassName="rs-range-picker-dropdown"
          onCalendarChange={onDateChange}
        />

        <Select
          placeholder="Loại tương tác"

          className='rs-select'
          popupClassName='rs-select-dropdown'
          options={
            fommatSelectOption(sLTypeInteraction)
          }
          onChange={setFilterCLTypeInteraction}

        />

        <Button
          className='rs-button neutral-gray-200'
        >
          <UploadOutlined />
          <span>Nhập dữ liệu</span>
        </Button>

        <Button
          className='rs-button neutral-gray-200'
        >
          <DownloadOutlined />
          <span>Xuất dữ liệu</span>
        </Button>
      </div>

      <Timeline
        items={listTimeLine}
        mode='left'
        className='rs-timeline'
      />

      <div className="interactive-history-tab__foot">
        <Pagination
          showSizeChanger
          current={pagination.current}
          pageSize={pagination.pageSize}
          total={pagination.total}
          showTotal={(total) => `Tổng cộng ${dataPagination(pagination.total, pagination.pageSize, pagination.current).totalPage} trang`}
          className='rs-pagination'
          onChange={onPaginationChange}
        />
      </div>
    </div>
  );
}

export default InteractiveHistoryTab;