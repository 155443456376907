import { DatePicker, DatePickerProps, Radio, RadioChangeEvent } from 'antd';
import React from 'react';
import VIPCareCard from '../../../components/Cards/VIPCareCard';
import { DashboardOutlined } from '@ant-design/icons';

const onRadioSourceChange = (e: RadioChangeEvent) => {
  // console.log(`radio checked:${e.target.value}`);
};

const onMonthChange: DatePickerProps['onChange'] = (date, dateString) => {
  // console.log(date, dateString);
};

const VIPCareReport: React.FC = () => {
  return (
    <div className="vip-care-report">
      <div className="top">
        <span className="font-medium fsz-16 lh-24">Báo cáo hoạt động CSKH VIP</span>

        <div className="control">
          <Radio.Group
            defaultValue="01-2024"
            className="rs-radio-group rs-radio-group--button"
            onChange={onRadioSourceChange}
          >
            <Radio.Button
              value="11-2023"
              className="rs-radio rs-radio--button"
            >
              <span>Tháng 11/2023</span>
            </Radio.Button>

            <Radio.Button
              value="12-2023"
              className="rs-radio rs-radio--button"
            >
              <span>Tháng 12/2023</span>
            </Radio.Button>

            <Radio.Button
              value="01-2024"
              className="rs-radio rs-radio--button"
            >
              <span>Tháng 1/2024</span>
            </Radio.Button>
          </Radio.Group>
          <DatePicker
            onChange={onMonthChange}
            picker="month"
            placeholder="Chọn tháng"
            className="rs-date-picker"
            popupClassName="rs-date-picker-dropdown"
          />
        </div>
      </div>

      <VIPCareCard
        title={'Tổng quan'}
        icon={<DashboardOutlined />}
        items={[
          { title: 'Số khách hàng VIP', number: 30000, },
          { title: 'Khách đã chăm sóc', number: 2900, },
          { title: 'Khách chưa chăm sóc', number: 100, },
          { title: 'Tỉ lệ chăm sóc', number: '100%', },
        ]}
      />
    </div>
  );
}

export default VIPCareReport;