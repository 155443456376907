import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Select, Spin } from 'antd';
import type { SelectProps } from 'antd';
import debounce from 'lodash/debounce';
import { API_URL } from '../../env';

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
  disabled?: boolean
  onChangeValue?: (value: UserValue[]) => void;
}

function DebounceSelect<
  ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any,
>({ fetchOptions, debounceTimeout = 800, disabled, onChangeValue, ...props }: DebounceSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<ValueType[]>([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      disabled={disabled}
      labelInValue
      filterOption={false}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      className="rs-select"
      popupClassName="rs-select-dropdown"
      {...props}
      options={options}
      onFocus={() => debounceFetcher('')}
      onSearch={debounceFetcher}
    />
  );
}

// Usage of DebounceSelect
interface UserValue {
  label: string;
  value: number;
}

async function fetchUserList(username: string): Promise<UserValue[]> {
  // const url = `customer/listCustomer?limit=10&pageNumber=1&search=${username}`;
  const url = `customer/listCustomer?search=${username}`;
  return fetch(`${API_URL}${url}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
    .then((response) => response.json())
    .then((body) =>
      body.data.map(
        (user: any) => ({
          label: user.name,
          value: user.id,
        }),
      ),
    );
}

interface Props {
  initValue?: any,
  disabled?: boolean
  placeholder?: string,
  onChange?: (value: UserValue[]) => void;
  onChangeValue?: (value: UserValue[]) => void;
}

const SelectCustomer: React.FC<Props> = ({
  onChange, initValue = [], placeholder = 'Tìm kiếm số điện thoại, hoặc email', disabled, onChangeValue
}) => {
  const [value, setValue] = useState<UserValue[]>([]);

  // INITIAL VALUE
  useEffect(() => {
    if (initValue.length > 0) {
      setValue(initValue)
    }
  }, [initValue])

  useEffect(() => {
    if (onChange) {
      const temp = [] as any
      value.map((item) => {
        temp.push({
          value: item.value,
          label: item.label,
        })
        return true
      });
      onChange(temp);
    }
  }, [value]);

  return (
    <DebounceSelect
      mode="multiple"
      value={value}
      placeholder={placeholder}
      fetchOptions={fetchUserList}
      onChange={(newValue) => {
        setValue(newValue as UserValue[]);
        onChangeValue?.(newValue as UserValue[])
      }}
      style={{ width: '100%' }}
      disabled={disabled}
    />
  );
};

export default SelectCustomer;
