import { paserParams } from "../../until";
import axiosClient, { ResponseSuccess, ResponseSuccessTotal } from "../axiosClient";

//Customer Care SL
export interface ISLCustomerCare {
    typeSupport: TypeSupport[]
    supportChannel: SupportChannel[]
    satisfactionLevel: SatisfactionLevel[]
}

export interface TypeSupport {
    id: number
    title: string
    icon: string
}

export interface SupportChannel {
    id: number
    title: string
    icon: string
}

export interface SatisfactionLevel {
    id: number
    title: string
    icon: string
}
//add
export interface IAddCareCustomer {
    id: number
    customerId: number
    adminId: number
    title: string
    description: string
    typeSupportId: number
    supportChanelId: number
    satisfactionLevelId: number
    status: number
    delf: number
}

//list
export interface ICareCustomer {
    id: number
    title: string
    description: string
    titletypeSupport: string
    iconSupportChanel: string
    timestamp: string
    satisfactionLevel: SatisfactionLevel
    admin: Admin
}

export interface SatisfactionLevel {
    icon: string
    title: string
}

export interface Admin {
    username: string
    code: string
}

export const getCLCustomerCare = async () => {
    const path = "careCustomer/infomationAdd"
    const params = {

    }
    const result: ResponseSuccess<ISLCustomerCare> = await axiosClient.get(path, { params });
    return result;
};

//Thêm mới chăm sóc khách hàng
export const addCareCustomer = async (
    customerId: Number, title: string, typeSupportId: number, supportChanelId: number, satisfactionLevelId: number, description: string
) => {
    const url = `/careCustomer/addCareCustomer`;
    const params = {
        customerId, title, typeSupportId, supportChanelId, satisfactionLevelId, description
    }
    const result: ResponseSuccess<IAddCareCustomer> = await axiosClient.post(
        url, paserParams(params, undefined)
    );
    return result;
}


//Danh sách chăm sóc khách hàng
export const getListCareCustomer = async (
    id: number, pageNumber?: number, limit?: number, typeSupport?: number, supportChanel?: number, dateStart?: string, dateEnd?: string
) => {
    const path = "/careCustomer/listCareCustomer"
    const params = {
        id, pageNumber, limit, typeSupport, supportChanel, dateStart, dateEnd
    }
    const result: ResponseSuccessTotal<ICareCustomer[]> = await axiosClient.get(path, { params });
    return result;
};



