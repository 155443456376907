import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Input, Pagination, PaginationProps, Radio, Select } from 'antd';
import './CareFeedback.scss'
import { UploadOutlined, DownloadOutlined, HeartOutlined } from '@ant-design/icons';
import FeedbackItem from '../../../components/Feedback/FeedbackItem';
import FeedbackListVertical from '../../../components/Feedback/FeedbackListVertical';
import * as customerCareApi from '../../../api/customerCare/customerCareApi';
import { ICON_SATISFACTION_LEVEL, ICON_SUPPORT_CHANEL, ICON_SUPPORT_TYPE } from '../../../env';
import useThrottle from '../../../hook/useThrottle';
import { dataPagination } from '../../../until';
import useDebounce from '../../../hook/useDebounce';
import useToast from '../../../hook/useToast';

const { RangePicker } = DatePicker;
const { TextArea } = Input;
interface Props {
  id: number;
}
const CareFeedbackTab: React.FC<Props> = ({
  id
}) => {

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    gCLCustomerCare()
    return () => {
      resetDataAddCareCustomer()
      resetDataFilterCareCustomer()
    }
  }, [])

  const [addtitle, setAddTitle] = useState("")
  const [addTypeSupportId, setAddTypeSupportId] = useState<number>()
  const [addSupportChanelId, setAddSupportChanelId] = useState<number>()
  const [addSatisfactionLevelId, setAddSatisfactionLevelId] = useState<number>()
  const [addDescription, setAddDescription] = useState("")

  const [filterDate, setFilterDate] = useState(["", ""])
  const [filterTypeSupportId, setFilterTypeSupportId] = useState<number>()
  const [filterSupportChanelId, setFilterSupportChanelId] = useState<number>()

  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
  const pushToast = useToast()

  const [sLCustomerCare, setSLCustomerCare] = useState<customerCareApi.ISLCustomerCare>()
  const [listCustomerCare, setListCustomerCare] = useState<customerCareApi.ICareCustomer[]>([])


  const gCLCustomerCare = async () => {
    const result = await customerCareApi.getCLCustomerCare()
    if (result?.status) {
      setSLCustomerCare(result.data)
    } else {
      pushToast(result?.message, 'error', 2000)
    }
  }

  const glistCustomerCare = useDebounce(async (pageCurent: number = 1, pageSize: number = 10) => {
    const result = await customerCareApi.getListCareCustomer(id, pageCurent, pageSize, filterTypeSupportId, filterSupportChanelId, filterDate[0], filterDate[1])
    if (result?.status) {
      setListCustomerCare(result.data)
      setPagination({ current: pageCurent, total: result.total, pageSize })
    } else {
      pushToast(result?.message, 'error', 2000)

    }
  }, 1000)

  const aCareCustomer = async () => {
    const result = await customerCareApi.addCareCustomer(
      id, addtitle, Number(addTypeSupportId), Number(addSupportChanelId), Number(addSatisfactionLevelId), addDescription
    )
    if (result?.status) {
      pushToast(result?.message, 'success', 2000)
      resetDataAddCareCustomer()
      resetDataFilterCareCustomer()
      glistCustomerCare()
    } else {
      pushToast(result?.message, 'error', 2000)
    }
  }



  const checkVariablesWhenAdding = useThrottle(() => {
    if (!addtitle.trim()) {
      pushToast("Vui lòng nhập tiêu đề!", 'error', 2000)

      return
    }
    if (!addTypeSupportId) {
      pushToast("Vui lòng chọn loại hỗ trợ!", 'error', 2000)

      return
    }
    if (!addSupportChanelId) {
      pushToast("Vui lòng chọn nguồn!", 'error', 2000)

      return
    }
    if (!addSatisfactionLevelId) {
      pushToast("Vui lòng chọn mức độ hài lòng!", 'error', 2000)

      return
    }
    if (!addDescription.trim()) {
      pushToast("Vui lòng nhập nội dung!", 'error', 2000)

      return
    }

    aCareCustomer()
  }, 1000)



  const onDateChange = (date: any, dateString: string[], info: any) => {
    const isNotEmpty = dateString.some(item => item !== "");
    if (isNotEmpty) {
      setFilterDate(dateString)
    } else {
      setFilterDate(["", ""])
    }
  }

  const onPaginationChange: PaginationProps['onChange'] = (pageNumber, pageSize) => {
    glistCustomerCare(pageNumber, pageSize)
    // setPagination({ ...pagination, current: pageNumber, pageSize })
  }

  const resetDataAddCareCustomer = () => {
    setAddTitle("")
    setAddTypeSupportId(undefined)
    setAddSupportChanelId(undefined)
    setAddSatisfactionLevelId(undefined)
    setAddDescription("")
  }

  const resetDataFilterCareCustomer = () => {
    setFilterDate(["", ""])
    setFilterSupportChanelId(undefined)
    setFilterTypeSupportId(undefined)
  }

  useEffect(() => {
    if (id)
      glistCustomerCare()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDate, filterSupportChanelId, filterTypeSupportId])

  return (
    <div className="care-feedback-tab">
      <div className="care-feedback-tab__left">
        <div className="tab-left__block">
          <span className="tab-left__label">Tiêu đề</span>
          <Input
            placeholder='VD: Khách yêu cầu tư vấn dịch vụ rửa xe'
            className='rs-input'
            maxLength={50}
            value={addtitle}
            onChange={(e) => setAddTitle(e.target.value)}
          />
        </div>

        <div className="tab-left__block">
          <span className="tab-left__label">Loại hỗ trợ</span>
          <Radio.Group
            className="rs-radio-group rs-radio-group--button"
            value={addTypeSupportId}
            onChange={(e) => setAddTypeSupportId(e.target.value)}
          >
            {
              sLCustomerCare?.typeSupport && sLCustomerCare.typeSupport.map((item, i) =>
                <Radio.Button
                  key={i}
                  value={item.id}
                  className="rs-radio rs-radio--button"
                >
                  <span className='anticon anticon-plus-circle'>
                    <img
                      alt=''
                      className=''
                      src={ICON_SUPPORT_TYPE + item.icon}
                    />
                  </span>
                  <span>{item.title}</span>
                </Radio.Button>
              )
            }
          </Radio.Group>
        </div>

        <div className="tab-left__block">
          <span className="tab-left__label">Nguồn</span>
          <Radio.Group
            value={addSupportChanelId}
            className="rs-radio-group"
            onChange={(e) => setAddSupportChanelId(e.target.value)}
          >
            {
              sLCustomerCare?.supportChannel && sLCustomerCare?.supportChannel.map((item, i) =>
                <Radio.Button
                  className="rs-radio"
                  key={i}
                  value={item.id}
                >
                  <span className="icon">
                    <img
                      alt=''
                      src={ICON_SUPPORT_CHANEL + item.icon}
                    />
                  </span>
                  <span>{item.title}</span>
                </Radio.Button>
              )
            }

          </Radio.Group>
        </div>

        <div className="tab-left__block">
          <span className="tab-left__label">Mức độ hài lòng</span>
          <Radio.Group
            value={addSatisfactionLevelId}
            className="rs-radio-group rs-radio-group--button"
            onChange={(e) => setAddSatisfactionLevelId(e.target.value)}
          >
            {
              sLCustomerCare?.satisfactionLevel && sLCustomerCare?.satisfactionLevel.map((item, i) =>
                <Radio.Button
                  className="rs-radio rs-radio--button vertical"
                  key={i}
                  value={item.id}
                >
                  <img
                    className={item.id === addSatisfactionLevelId ? "active" : "un-active"}
                    alt=''
                    src={ICON_SATISFACTION_LEVEL + item.icon}
                  />
                  <span className="neutral-gray-400">{item.title}</span>
                </Radio.Button>
              )
            }
          </Radio.Group>
        </div>

        <div className="tab-left__block">
          <span className="tab-left__label">Ghi chú</span>
          <TextArea
            placeholder="Nhập nội dung trao đổi / ghi nhớ,..."
            className="rs-input rs-input--textarea"
            value={addDescription}
            onChange={(e) => setAddDescription(e.target.value)}
          />
        </div>

        <Button
          className='rs-button'
          type='primary'
          block
          onClick={checkVariablesWhenAdding}
        >
          <span>Thêm mới chăm sóc</span>
          <HeartOutlined />
        </Button>
      </div>

      <div className="care-feedback-tab__right">
        <div className="tab-right__head">
          <RangePicker
            className="rs-range-picker"
            popupClassName="rs-range-picker-dropdown"
            onCalendarChange={onDateChange}
          />

          <Select
            placeholder="Loại hỗ trợ"
            className='rs-select'
            popupClassName='rs-select-dropdown'
            options={
              sLCustomerCare?.typeSupport?.map(item => ({
                value: item.id,
                label: item.title
              }))
            }
            onChange={(e) => setFilterTypeSupportId(e)}
          />

          <Select
            placeholder="Nguồn"
            className='rs-select'
            popupClassName='rs-select-dropdown'

            options={
              sLCustomerCare?.supportChannel?.map(item => ({
                value: item.id,
                label: item.title
              }))
            }
            onChange={(e) => setFilterSupportChanelId(e)}

          />

          <Button
            className='rs-button neutral-gray-200'
          >
            <UploadOutlined />
          </Button>

          <Button
            className='rs-button neutral-gray-200'
          >
            <DownloadOutlined />
          </Button>
        </div>

        <FeedbackListVertical>
          {
            listCustomerCare && listCustomerCare.map((item, index) => (
              <FeedbackItem
                key={index}
                item={item}
              />
            ))
          }
        </FeedbackListVertical>

        <div className="tab-right__foot">
          <Pagination
            // showQuickJumper
            showSizeChanger
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            // showTotal={(total) => `Tổng cộng ${total} trang`}
            showTotal={(total) => `Tổng cộng ${dataPagination(pagination.total, pagination.pageSize, pagination.current).totalPage} trang`}
            className='rs-pagination'
            // locale={{ items_per_page: 'trang' }}
            onChange={onPaginationChange}
          />
        </div>
      </div>
    </div>
  );
}

export default CareFeedbackTab;