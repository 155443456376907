// import React, { useRef } from "react";
import { BellOutlined, DeleteOutlined, EditOutlined, InfoOutlined } from "@ant-design/icons"
// import EmptyComponentLinkText from '../../../components/EmptyComponent/EmptyComponentLinkText';
import { useNavigate } from 'react-router-dom'

const listNotificationZNS = [
  {
    id: 1,
    title: 'Chào mừng Thành Viên 01',
    Email: 'EMAIL01',
    notification: 'Thông báo',
    status: 'Chờ quản lý duyệt',
    img: '/images/exportImg/img-email.svg'
  },
  {
    id: 2,
    title: 'Chào mừng Thành Viên 02',
    Email: 'EMAIL01',
    notification: 'Thông báo',
    status: 'Chờ quản lý duyệt',
    img: '/images/exportImg/img-email.svg'
  },
  {
    id: 3,
    title: 'Chào mừng Thành Viên 03',
    Email: 'EMAIL01',
    notification: 'Thông báo',
    status: 'Chờ quản lý duyệt',
    img: '/images/exportImg/img-email.svg'
  },

  {
    id: 4,
    title: 'Chào mừng Thành Viên 01',
    Email: 'EMAIL01',
    notification: 'Thông báo',
    status: 'Chờ quản lý duyệt',
    img: '/images/exportImg/img-email.svg'
  },
  {
    id: 5,
    title: 'Chào mừng Thành Viên 02',
    Email: 'EMAIL01',
    notification: 'Thông báo',
    status: 'Chờ quản lý duyệt',
    img: '/images/exportImg/img-email.svg'
  },
  {
    id: 6,
    title: 'Chào mừng Thành Viên 03',
    Email: 'EMAIL01',
    notification: 'Thông báo',
    status: 'Chờ quản lý duyệt',
    img: '/images/exportImg/img-email.svg'
  },
  ,
  {
    id: 7,
    title: 'Chào mừng Thành Viên 02',
    Email: 'EMAIL01',
    notification: 'Thông báo',
    status: 'Chờ quản lý duyệt',
    img: '/images/exportImg/img-email.svg'
  },
  {
    id: 8,
    title: 'Chào mừng Thành Viên 03',
    Email: 'EMAIL01',
    notification: 'Thông báo',
    status: 'Chờ quản lý duyệt',
    img: '/images/exportImg/img-email.svg'
  },
]
const Email: React.FC = () => {
  const navigate = useNavigate()

  return (
    <div className='gap-4 fix-list-customer-group'>
      {/* <EmptyComponentLinkText
        content={"Bạn chưa có mẫu thông báo nào, thử"}
        link={'/'}
        namelink={'tạo mới ngay'}
      /> */}
      {
        listNotificationZNS.map((items, index) => (
          <div key={index} className='rounded-2 border-F2F4F7 d-flex flex-column overflow-hidden'>
            <div className='w-283-px h-176-px'>
              <img src={items?.img} alt="img-email" className='w-full h-full' />
            </div>
            <div className='d-flex flex-column gap-2 p-6 bg-FCFCFD'>
              <div className='d-flex align-center gap-2'>
                <div className='h-30-px px-10-px rounded-2 px-2 border-ADC6FF bg-F0F5FF text-2F54EB text-xs font-regular d-flex align-center'>
                  Email
                </div>
                <div
                  className='h-30-px px-10-px rounded-2 px-2 text-xs font-regular d-flex align-center
                border-40A9FF bg-E6F7FF text-1890FF'
                >
                  <div className='d-flex align-center gap-2'>
                    <BellOutlined className='text-18-px text-1890FF' />
                    Thông báo
                  </div>
                </div>
              </div>
              <p className='font-medium fw-500 text-344054 text-2-row'>{items?.title}</p>
              <p className='d-flex align-center text-667085 text-sm font-regular gap-2'>
                <img src="/images/exportImg/dot-blue.svg" alt="dot" className='w-6-px h-6-px' />
                {items?.status}
              </p>
            </div>
            <div className='d-flex justify-end gap-3 pb-6 bg-FCFCFD pr-6'>
              <InfoOutlined className='text-base text-344054 p-2 rounded-2 shadow-btn-zns bg-white' />
              <EditOutlined
                className='text-base text-344054 p-2 rounded-2 shadow-btn-zns bg-white cursor-pointer'
                onClick={() => navigate('/marketing/notification-templates-management/edit-email')}
              />
              <DeleteOutlined className='text-base text-F5222D p-2 rounded-2 shadow-btn-zns bg-white' />
            </div>
          </div>
        ))
      }
    </div>
  )
}

export default Email
