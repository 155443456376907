import React, { useEffect, useState } from 'react';
import Layout from '../../Layout';
// import EmptyComponentLink from '../../components/EmptyComponent/EmptyComponentLink';
import { CopyOutlined, DeleteOutlined, EditOutlined, EyeOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import * as RankApi from '../../api/rank/rankApi';
import { URL_RANK } from '../../env';
import useToast from '../../hook/useToast';
import './ClassList.scss';
import ShowRank from './ShowRank';
import * as BenefitApi from '../../api/rank/benefitApi';
import ModalDeleteManager from '../../components/ModalDeleteManager';

const ClassList: React.FC = () => {
  useEffect(() => {
    document.title = 'THACO AUTO CRM';
  }, []);

  const [isShowRank, setShowRank] = useState(false)
  const [openDeleteListRank, setOpenDeleteListRank] = useState(false)
  const [dataRank, setDataRank] = useState<RankApi.IRank[]>([])
  const [dataBenefitRank, setDataBenefitRank] = useState<BenefitApi.IBenefitRank>()
  const [total, setTotal] = useState<number>()
  const pushToast = useToast();
  const navigate = useNavigate()
  const [id, setId] = useState<number>()


  const getListRank = async () => {
    const result = await RankApi.getListRank();
    if (result.status) {
      setDataRank(result.data)
      setTotal(result.total)
    } else {
      pushToast(result.message, "error", 2000)

    }
  }

  const getListBenefitRank = async (id: number) => {
    const result = await BenefitApi.getListBenefitRank(id);
    if (result.status) {
      setDataBenefitRank(result.data);
    } else {
      pushToast(result.message, "error", 2000)

    }
  }
  const handleDelete = async () => {
    if (id) {
      const result = await RankApi.deleteRank(id);
      if (result?.status) {
        getListRank()
        setOpenDeleteListRank(false)
        pushToast(result.message, "success", 2000)

      } else {
        pushToast(result.message, "error", 2000)

      }
    }
  }
  useEffect(() => {
    getListRank()
  }, [])

  return (
    <div className='class-list-page'>
      <Layout>
        <ModalDeleteManager
          isOpen={openDeleteListRank}
          onConfirm={handleDelete}
          closeModal={() => setOpenDeleteListRank(!openDeleteListRank)}
          title="Bạn có chắc chắn xóa hạng thành viên này"
          titleDelete="Xóa hạng thành viên"
          content="Hạng thành viên và dữ liệu liên quan sẽ bị xóa vĩnh viễn
    không thể khôi phục"/>
        <Breadcrumb
          items={[
            {
              title: 'THACO AUTO CRM',
            },
            {
              title: <a href="/">Cấu hình</a>,
            },
            {
              title: 'Danh sách hạng',
            },
          ]}
        />
        <div className='title-page'>Danh sách hạng</div>
        {/* If Empty */}
        {/* <EmptyComponentLink /> */}

        <div className="count-add-rank">
          <div className="count-rank">Có {total} hạng đã được tạo ra</div>
          <Link to="/member-card/add-rank" className="t-btn-link-icon">
            <div className="icon-svg"><PlusCircleOutlined /></div>
            <div className="text">Thêm mới hạng</div>
          </Link>
        </div>

        <div className="list-rank">
          {dataRank.map((item, index) => (
            <div key={index} className="one-rank">
              <div className="img-box">
                <img src="/bg-rank.svg" alt="bg rank" />
              </div>
              <div className="top">
                <div className="icon-name-rank">
                  <div className="icon-svg">
                    <img src={URL_RANK + item.icon} alt="sv" />
                  </div>
                  <div className="name text-2-row">{item.title}</div>
                </div>
                <div className="list-icon-ctrl">
                  <div className="one-ctrl" onClick={() => navigate('/member-card/add-rank')}><CopyOutlined /></div>
                  <div className="one-ctrl" onClick={() => navigate(`/member-card/edit-rank/${item.id}`)}><EditOutlined /></div>
                  <div className="one-ctrl"
                    onClick={() => { getListBenefitRank(item.id); setShowRank(true) }} >
                    <EyeOutlined />
                  </div>
                  <div className="one-ctrl"
                    onClick={() => { setOpenDeleteListRank(true); setId(item.id) }}
                  >
                    <DeleteOutlined className='text-F5222D' />
                  </div>
                </div>
              </div>
              <div className="footer">Cần {item.price.toLocaleString('en-US')} điểm để đạt hạng này</div>
            </div>
          ))}
        </div>

        {
          isShowRank
            ? <ShowRank
              dataBenefitRank={dataBenefitRank}
              className="show"
              onClose={() => { setShowRank(false) }}
            />
            : null
        }

      </Layout>
    </div>
  );
}

export default ClassList;
