import React from "react";
import RightPopup from "../../components/Popup/RightPopup";
import { Progress } from "antd";
import { CrownOutlined } from "@ant-design/icons";
import './ShowDetailUserCard.scss';
import * as IssuedCardsApi from '../../api/rank/issuedCardApi';
import { URL_CUSTOMER, URL_RANK } from "../../env";

interface Props {
  className?: string;
  onClose?: () => void;
  dataInfoCardCustomer?: IssuedCardsApi.IInfoCardCustomer
}

const ShowDetailUserCard: React.FC<Props> = ({ className, dataInfoCardCustomer, onClose }) => {
  const handleOnClose = () => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <RightPopup
      title="Chi tiết"
      className={className}
      contentClassName="user-card-popup__content"
      onClose={handleOnClose}
    >
      <div className="rank-block">
        <div className="avata-block">
          <img
            src={URL_CUSTOMER + dataInfoCardCustomer?.infoCustomer.customer.image}
            alt="avatar"
          />
        </div>
        <div className="name-rank">
          <span className="font-medium rank-label">{dataInfoCardCustomer?.infoCustomer.customer.username}</span>
          <div className="rank">
            <img
              src={URL_RANK + dataInfoCardCustomer?.infoCustomer.rank.icon}
              alt=""
              className='rank-img'
            />
            <span className='rank-name'>{dataInfoCardCustomer?.infoCustomer.rank.title}</span>
          </div>
        </div>
      </div>

      <div className="rank-progress">
        <Progress
          percent={dataInfoCardCustomer?.point}
          showInfo={false}
          className="rs-progress primary"
        />
        {/* xử lý khi có API */}
        <span className="neutral-gray-500">Tiến độ thăng hạng {dataInfoCardCustomer?.point}%</span>
      </div>

      <div className="benefit-block">
        <div className="benefit-block__title">Quyền lợi hạng</div>
        <div className="benefit-card">
          {dataInfoCardCustomer?.listBenefit.map((item) => (
            <div className="item" key={item.id}>
              <CrownOutlined />
              <span className="neutral-gray-500">{item.description}</span>
            </div>
          ))}
          <img
            src="/images/brand-icon--blue.svg"
            alt="THACO AUTO"
            className="img-brand"
          />
        </div>
      </div>
      {dataInfoCardCustomer?.infoCustomer.status === 0 &&
        <div className="t-message">
          <span />
          <div className="text">Tạm khóa lúc {dataInfoCardCustomer.infoCustomer.timestamp}</div>
        </div>
      }
    </RightPopup>
  )
}

export default ShowDetailUserCard