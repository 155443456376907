import { Badge, Button } from 'antd';
import React, { useState } from 'react';
import * as VoucherApi from '../../api/voucher/voucherApi';
import './VoucherResultCard.scss'
import useToast from '../../hook/useToast';

interface Props {
  statusVoucher: boolean,
  id?: string,
  promotionName?: string,
  status?: string,
  condition?: string,
  discountType?: string,
  voucherType?: string,
  maxDiscount?: string,
  applyPerCustomer?: string,
  period?: string,
  // idVoucher: number,
  ctaClick?: () => void,
}

const VoucherResultCard: React.FC<Props> = (props) => {
  const {
    statusVoucher,
    id,
    // idVoucher,
    promotionName,
    status,
    condition,
    discountType,
    voucherType,
    maxDiscount,
    period,
    ctaClick,
  } = props

  const handleClick = () => {
    if (ctaClick) {
      ctaClick()
      // getInfoVoucher(idVoucher)
    }
  }


  const [dataVoucherDetail, setDataVoucherDetail] = useState<VoucherApi.IVoucherEdit>()
  const pushToast = useToast();

  const getInfoVoucher = async (id: number) => {
    const result = await VoucherApi.getInfoVoucher(id);
    if (result.status) {
      setDataVoucherDetail(result.data)
      // setStatusVoucher(result.data.statusConversion === 0 ? true : false)
    } else {
      // setSearched(false)
      // setSearching(false)
      // setDataLoopupVoucher(undefined)
      pushToast(result.message, "error", 2000)

    }
  }


  return (
    <div className="voucher-result-card">
      <div className="block">
        <div className="title">
          <span>Voucher&nbsp;</span>
          <span className="primary-7">{id}</span>
          <span>&nbsp;thuộc chương trình&nbsp;</span>
          <span className="primary-7">{promotionName}</span>
        </div>
      </div>

      <div className="block">
        <div className="list">
          <div className="item">
            <span className="font-medium fsz-16 lh-24">Trạng thái</span>
            <Badge
              className="rs-tag daybreak-blue--outlined"
            >{status}</Badge>
          </div>
          <div className="item">
            <span>Hình thức</span>
            <span>{discountType}</span>
          </div>
          <div className="item">
            <span>Điều kiện</span>
            <span>{condition}</span>
          </div>
          <div className="item">
            <span>Áp dụng cho</span>
            <span>{voucherType}</span>
          </div>
        </div>
      </div>

      <div className="block">
        <div className="list">
          <div className="item">
            <span>Giảm tối đa</span>
            <span className="discount-price">{maxDiscount}</span>
          </div>
          {/* <div className="item">
            <span>Mỗi khách áp dụng</span>
            <span>{ applyPerCustomer } lần</span>
          </div> */}
          <div className="item">
            <span>Thời gian áp dụng</span>
            <span>{period}</span>
          </div>
          {statusVoucher && <div className="item justify-center">
            <Button
              type="primary"
              className="rs-button"
              onClick={handleClick}
            >Quy đổi</Button>
          </div>}

        </div>
      </div>
    </div>
  );
}

export default VoucherResultCard;
