import { Form, Progress } from "antd"
import { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import BreadcrumbBlock from "../../../../components/BreadcrumbBlock/BreadcrumbBlock"
import Layout from "../../../../Layout"
import InfoPersonal from "../AddCustomer/Component/InfoPersonal"
import InfoRelative from "../AddCustomer/Component/InfoRelative"
import './style.scss'
import * as customerApi from '../../../../api/customer/customerApi';
import * as selectOptionsApi from '../../../../api/selectOptions/selectOptionsApi';
import useToast from "../../../../hook/useToast"
import { regexPhoneNumber, validateEmail } from "../../../../until"


const UpdateCustomer: React.FC = () => {
  // const [submit, setSubmit] = useState(true)
  const { id } = useParams()
  const [infoCustomer, setInfoCustomer] = useState<customerApi.ICustomerDetail2>()
  const navigate = useNavigate()

  // state add

  let errorsObj: {
    email: string,
    listRelatives: string,
    profession: string,
    userName: string;
    image: string;
    phone: string[];
    birthday: string;
    province: string;
    address: string;
    group: string;
    tag: string;
    hobby: string;
    car: string;
  } = { email: "", listRelatives: '', profession: "", userName: "", image: '', phone: [], birthday: "", province: "", address: "", group: "", tag: "", hobby: "", car: "" };
  const [errorObject, setErrorObject] = useState(errorsObj)
  const [error, setError] = useState(false)
  const [submit, setSubmit] = useState(true)
  const [province, setProvince] = useState<selectOptionsApi.ISelectOption[]>([])
  const [hobby, setHobby] = useState<selectOptionsApi.ISelectOption[]>([])
  const [tag, setTag] = useState<selectOptionsApi.ISelectOption[]>([])
  const [dataGroup, setDataGroup] = useState<selectOptionsApi.ISelectOption[]>([])
  const [dataRelatives, setDataRelatives] = useState<selectOptionsApi.ISelectOption[]>([])
  const [listRelatives, setListRelatives] = useState<selectOptionsApi.relationshipProp[]>([]);

  const [provinceId, setProvinceId] = useState<number | null>(null)
  const [valuesTag, setValuesTag] = useState<number[]>([]);
  const [valuesGroup, setValuesGroup] = useState<number[]>([]);
  const [valuesInterest, setValuesInterest] = useState<number[]>([]);
  const [valuesInterestRelative, setValuesInterestRelative] = useState<number[]>([]);
  const [valuesRelatives, setValuesRelatives] = useState<number | null>(null);
  const [userName, setUserName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [profession, setProfession] = useState<string>('');
  const [sex, setSex] = useState(0)
  const [marri, setMarri] = useState(0)
  const [date, setDate] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [image, setImage] = useState<string>('')
  const [phoneNumbers, setPhoneNumbers] = useState<selectOptionsApi.Phone[]>([{ phone: '', type: 1 }]);
  const [valueCar, setValueCar] = useState<number[]>([])
  const [percent, setPercent] = useState<number>(94)

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const pushToast = useToast();

  const listDetailCustomer = async () => {
    const result = await customerApi.getDetailCustomer(Number(id));
    if (result?.status) {
      // setInfoCustomer(result.data)
      setUserName(result.data.userName)
      setImage(result.data.image)
      setSex(result.data.gender)
      setMarri(result.data.statusMarital)
      setProfession(result.data.profession)
      setPhoneNumbers(result.data.phone)
      setDate(result.data.birthday)
      setEmail(result.data.email)
      setProvinceId(result.data.province.id)
      setAddress(result.data.address)
      const convertDataGroup = result.data.groupCustomer.map((item) => item.id)
      setValuesGroup(convertDataGroup)
      const convertDataTag = result.data.classifys.map((item) => item.id)
      setValuesTag(convertDataTag)
      const convertDataHobby = result.data.hobby.map((item) => item.id)
      setValuesInterest(convertDataHobby)
      const convertDataCar = result.data.carCare.map((item) => item.id)
      setValueCar(convertDataCar)
      // setDataDetailRelation(result.data.relatives)

      const convertListRelative: selectOptionsApi.relationshipProp[] = result.data.relatives.map((item) => ({
        name: item.name,
        relationship: item.relationship.id,
        hobby: item.hobby.map((hobby) => hobby.id)
      }))
      setListRelatives(convertListRelative)
    } else {
      console.log('object');
    }
  }


  useEffect(() => {
    if (id) {
      listDetailCustomer()
    } else {
      navigate(-1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, id])

  const gListProvice = async () => {
    const result = await selectOptionsApi.getListProvince();
    if (result?.status) {
      setProvince(convertData(result.data))
    } else {
      console.log('object');
    }
  }
  const gListHobby = async () => {
    const result = await selectOptionsApi.getListHobby();
    if (result?.status) {
      setHobby(convertData(result.data))
    } else {
      console.log('object');
    }
  }

  const gListTag = async () => {
    const result = await selectOptionsApi.getListTag();
    if (result?.status) {
      setTag(convertData(result.data))
    } else {
      console.log('object');
    }
  }

  const getListRelatives = async () => {
    const result = await selectOptionsApi.getListRelatives();
    if (result?.status) {
      setDataRelatives(convertData(result.data))
    } else {
      console.log('object');
    }
  }

  const gListGroup = async () => {
    const result = await selectOptionsApi.getListGroup();
    if (result?.status) {
      setDataGroup(convertData(result.data))
    } else {
      console.log('object');
    }
  }

  const convertData = (data: selectOptionsApi.IHobby[]) => {
    const convertData = data.map((item) => ({
      value: item.id,
      label: item.title,
    }))
    return convertData
  }
  useEffect(() => {
    gListProvice()
    gListHobby()
    gListTag()
    gListGroup()
    getListRelatives()
  }, [])


  const handleSave = async () => {
    if (!submit) {
      if (listRelatives.length === 0) {
        pushToast("Vui lòng thêm ít nhất 1 người thân!", "warning")
      } else {
        const result = await customerApi.updateCustomer(
          Number(id),
          userName,
          sex,
          marri,
          profession,
          phoneNumbers,
          uploadedFile,
          date,
          provinceId,
          email,
          address,
          valuesGroup,
          valuesTag,
          valuesInterest,
          valueCar,
          listRelatives,
        );
        if (result?.status) {
          pushToast(result.message, "success", 2000)
          setTimeout(() => {
            navigate('/customer/list')
          }, 2000)
        } else {
          pushToast(result.message, 'error')
        }
      }
    }
    let test = false
    if (date === '') {
      errorsObj.birthday = 'Vui lòng chọn ngày sinh!';
      test = true
      setError(true)
    }
    const phoneSet: Set<string> = new Set();

    let hasDuplicate = false;

    for (const item of phoneNumbers) {
      const { phone } = item;

      if (phoneSet.has(phone)) {
        hasDuplicate = true;
        break; // Thoát khỏi vòng lặp ngay khi gặp số điện thoại trùng
      } else {
        phoneSet.add(phone);
      }
    }
    if (hasDuplicate) {
      errorsObj.phone = ['Số điện thoại không được trùng lặp!'];
      setError(true)
      test = true
    }
    if (!hasDuplicate) {
      if (regexPhoneNumber(phoneNumbers).filter((value) => value === false).length > 0) {
        const data: string[] = regexPhoneNumber(phoneNumbers).map((value) => {
          if (value === true) {
            return ''
          } else {
            return 'Số điện thoại không đúng định dạng!'
          }
        })
        errorsObj.phone = data;
        test = true
        setError(true)
      }
    }
    if (userName === '') {
      errorsObj.userName = 'Vui lòng nhập họ và tên!';
      test = true
      setError(true)
    }
    if (email === '') {
      errorsObj.email = 'Vui lòng nhập địa chỉ email!';
      test = true
      setError(true)
    }
    if (!validateEmail(email)) {
      errorsObj.email = 'Email không đúng định dạng!';
      test = true
      setError(true)
    }
    if (profession === '') {
      errorsObj.profession = 'Vui lòng nhập nghề nghiệp!';
      test = true
      setError(true)
    }
    if (provinceId === null) {
      errorsObj.province = 'Vui lòng chọn thành phố!';
      test = true
      setError(true)
    }
    if (valuesGroup.length === 0) {
      errorsObj.group = 'Vui lòng chọn nhóm!';
      test = true
      setError(true)
    }
    if (valuesTag.length === 0) {
      errorsObj.tag = 'Vui lòng chọn thẻ tag!';
      test = true
      setError(true)
    }
    if (valuesInterest.length === 0) {
      errorsObj.hobby = 'Vui lòng chọn sở thích!';
      test = true
      setError(true)
    }
    if (valueCar.length === 0) {
      errorsObj.car = 'Vui lòng chọn xe quan tâm!';
      test = true
      setError(true)
    }
    setErrorObject(errorsObj)
    if (test) {
      return
    } else {
      setSubmit(false)
    }
  }
  return (
    <Layout>
      <BreadcrumbBlock
        items={[
          { title: <Link to="/">THACO AUTO CRM</Link>, },
          { title: <Link to="/customer/list">Khách hàng</Link>, },
          { title: <Link to="/customer/list">Danh sách khách hàng</Link>, },
          { title: 'Cập nhật thông tin', },
        ]}
        title='Cập nhật thông tin'
        backLink='/customer/list'
      />
      <div className="d-flex justify-center py-6 ">
        <div className="d-flex flex-column gap-34-px w-500-px">
          <p className="text-344054 text-sm fw-600 text-center font-bold">Thông tin cơ bản của khách hàng</p>
          <Form >
            <div className="d-flex align-center gap-6">
              <div className={`d-flex align-center  ${submit ? "gap-2" : ''}`}>
                {submit
                  ? (
                    <Progress
                      percent={percent}
                      className={`d-flex align-center custom-progress w-255-px h-progress text-progress d-none-block-progress bg-progress-slide gap-2 h-progress-outer`}

                    />
                  )
                  : (
                    <Progress
                      percent={percent}
                      className={`d-flex align-center custom-progress w-378-px h-progress text-progress d-none-block-progress size-check-circle w-none-progress gap-2  h-progress-outer`}
                    />
                  )}
                <p className="text-667085 text-sm font-regular"> {submit ? 'hoàn thiện thông tin' : ''} </p>
              </div>
              <button className="text-white text-base py-1 px-4 rounded-7-px  fw-500 bg-096DD9 border-none outline-none line-height-6 font-regular"
                onClick={handleSave}
              >
                {submit ? 'Tiếp theo' : 'Xác nhận'}
              </button>
            </div>
            {
              submit ? (<InfoPersonal
                image={image}
                sLProvince={province}
                sLHobby={hobby}
                sLTag={tag}
                sLGroup={dataGroup}
                setProvinceId={setProvinceId}
                provinceId={provinceId}
                valuesGroup={valuesGroup}
                setValuesGroup={setValuesGroup}
                valuesTag={valuesTag}
                setValuesTag={setValuesTag}
                valuesInterest={valuesInterest}
                setValuesInterest={setValuesInterest}
                userName={userName}
                setUserName={setUserName}
                sex={sex}
                setSex={setSex}
                marri={marri}
                setMarri={setMarri}
                date={date}
                setDate={setDate}
                address={address}
                setAddress={setAddress}
                phoneNumbers={phoneNumbers}
                setPhoneNumbers={setPhoneNumbers}
                valueCar={valueCar}
                setValueCar={setValueCar}
                errorObject={errorObject}
                error={error}
                setError={setError}
                uploadedFile={uploadedFile}
                setUploadedFile={setUploadedFile}
                email={email}
                setEmail={setEmail}
                profession={profession}
                setProfession={setProfession}
                percent={percent}
                setPercent={setPercent}
              />
              ) : (
                <InfoRelative
                  dataRelatives={dataRelatives}
                  setValuesRelatives={setValuesRelatives}
                  valuesRelatives={valuesRelatives}
                  sLHobby={hobby}
                  valuesInterestRelative={valuesInterestRelative}
                  setValuesInterestRelative={setValuesInterestRelative}
                  setListRelatives={setListRelatives}
                  listRelatives={listRelatives}
                  percent={percent}
                  setPercent={setPercent}
                />)
            }
          </Form>
        </div>
      </div>
    </Layout>
  )
}
export default UpdateCustomer
