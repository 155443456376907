import React, { ReactNode } from "react"
import './EmptyComponent.scss'

interface Props {
  content?: string
  children?: ReactNode
}

const EmptyComponent: React.FC<Props> = (props) => {
  const { content = 'Chưa có thông tin', children } = props

  return (
    <div className="empty-component">
      <div className="empty-component__inner">
        <img src="/images/empty-image.svg" alt="empty" />
        {
          content && <span className="title">{ content }</span>
        }
        { children }
      </div>
    </div>
  )
}

export default EmptyComponent
