import React, { Component } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
//var CanvasJSReact = require('@canvasjs/react-charts');
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
class ChartFunnel extends Component {
    constructor(props) {
        super(props);
        this.chart = React.createRef();
        this.state = {
            chartReady: false
        };
    }

    componentDidMount() {
        this.renderChart();
    }

    renderChart() {
        var dataPoint;
        const options = {
            animationEnabled: true,
            title:{
                text: ""
            },
            toolTip: {
                fontSize: '16',
                Content: "{x} : {y}"
            },
            data: [{
                type: "funnel",
                indexLabel: "{label} {percentage}",
                toolTipContent: "<b>{label}</b>: {y} <b>({percentage}%)</b>",
                neckWidth: '27%',
                neckHeight: 0,
                valueRepresents: "area",
                indexLabelFontColor: "#98A2B3",
                indexLabelFontSize: 16,
                dataPoints: [
                    { y: 300, label: "Đã gửi", color: "#0050B3" },
                    { y: 200, label: "Đã xem", color: "#1890FF" },
                    { y: 100, label: "Click CTA 13", color: "#40A9FF" }
                ]
            }]
        }
        //calculate percentage
        dataPoint = options.data[0].dataPoints;
        for(var i = 0; i < dataPoint.length; i++) {
            if(i === 0) {
                options.data[0].dataPoints[i].percentage = dataPoint[i].y;
            } else {
                options.data[0].dataPoints[i].percentage = dataPoint[i].y;
            }
        }
        this.setState({ options, chartReady: true });
    }

    render() {
        return (
            <div className='t-chart-block'>
                {this.state.chartReady && (
                    <CanvasJSChart options={this.state.options} />
                )}
                {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
            </div>
        );
    }
}
export default ChartFunnel;
