import { CloseOutlined } from "@ant-design/icons"
import { Modal, Select, Input } from "antd"
import './style.scss'
import { ICarCompany, IRelative, getCLCarLines } from "../../../../../api/selectOption/selectOptionsApi"
import { useEffect, useState } from "react"
import { fommatSelectOption } from "../../../../../until"
import useDebounce from "../../../../../hook/useDebounce"
import useToast from "../../../../../hook/useToast"
import useThrottle from "../../../../../hook/useThrottle"
import { addTypeVehicle } from "../../../../../api/typeVehicle/typeVehicleApi"
interface ModalProps {
  isOpen: boolean
  sLCategoryCar: ICarCompany[]
  onSubmitFormModal?: (iSsuccess: boolean, errorMessage?: string) => void;
  closeModal: () => void
}
const ModalCreateCatecoryCar: React.FC<ModalProps> = ({
  isOpen, sLCategoryCar,
  onSubmitFormModal, closeModal
}) => {
  const pushToast = useToast();

  let errorsObj = { typeVehicle: "", categoryCar: "", carLines: "", result: "" };
  const [errors, setErrors] = useState(errorsObj);

  const [typeVehicle, setTypeVehicle] = useState("")
  const [categoryCar, setCategoryCar] = useState<number>()
  const [carLines, setCarLines] = useState<number>()

  const [cLCarLines, setCLCarLines] = useState<IRelative[]>([])

  const changeCategoryCar = useDebounce(async (id: number) => {
    setCategoryCar(id)
    setCarLines(undefined)
    const result = await getCLCarLines(id)
    if (result?.status) {
      setCLCarLines(result.data)
    } else {
      pushToast(result?.message, "warning")
    }
  }, 200)

  const checkVariables = useThrottle(() => {
    let error = false;
    const errorObj = { ...errorsObj };
    if (!typeVehicle) {
      errorObj.typeVehicle = "Vui lòng nhập chủng loại xe!"
      error = true;
    }
    if (!categoryCar) {
      errorObj.categoryCar = "Vui lòng chọn tên thương hiệu xe!"
      error = true;
    }
    if (!carLines) {
      errorObj.carLines = "Vui lòng chọn dòng xe!"
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    } else {
      aTypeVehicle()
    }
  }, 1000)

  const aTypeVehicle = async () => {
    const result = await addTypeVehicle(typeVehicle, Number(categoryCar), Number(carLines))
    if (result?.status) {
      onSubmitFormModal?.(true)
    } else {
      onSubmitFormModal?.(false, result.message)
    }
  }

  useEffect(() => {
    if (isOpen) {

    } else {
      setErrors(errorsObj)

      setTypeVehicle("")
      setCategoryCar(undefined)
      setCarLines(undefined)

      setCLCarLines([])
    }
  }, [isOpen])
  return (
    <Modal
      title={<div className="bg-FCFCFD d-flex justify-betweeen align-center py-4 px-6 w-full border-b-F0F0F0">
        <span className="text-base text-344054 fw-500 font-medium">Tạo mới chủng loại xe</span>
        <CloseOutlined className="text-base text-8B8B8B" onClick={closeModal} />
      </div>}
      open={isOpen}
      onCancel={closeModal}
      centered
      className="w-modal modal-content-category ant-modal-size"
      closeIcon={false}
      footer={<div className="bg-FCFCFD d-flex justify-betweeen align-center py-2 px-6 w-full border-t-F0F0F0">
        <span className="text-sm text-98A2B3 cursor-pointer" onClick={closeModal}>Huỷ</span>
        <button className="text-white text-sm bg-096DD9 border-none shadow-btn-save rounded-2 h-8 px-15-px"
          onClick={checkVariables}>
          Lưu chủng loại xe mới
        </button>
      </div>}
    >
      <div className="p-6 d-flex flex-column gap-18-px">
        <div className="d-flex flex-column gap-1">
          <p className="text-sm  font-regular text-344054">Chủng loại xe</p>
          <div className="d-flex flex-column">
            <div className="w-full border-F2F4F7 bg-FCFCFD rounded-2 pl-3  py-1 h-8  overflow-hidden">
              <Input
                placeholder="Chủng loại xe"
                className="bg-FCFCFD text-sm border-none custom-input-focus none-focus-erro font-regular p-none-input  hover-none"
                maxLength={50}
                value={typeVehicle}
                onChange={(e) => setTypeVehicle(e.target.value)}
              />
            </div>
            {
              errors.typeVehicle &&
              <span className="text-F5222D text-base font-regular pl-2">{errors.typeVehicle}</span>
            }
          </div>
        </div>

        <div className="d-flex flex-column gap-1">
          <p className="text-sm  font-regular text-344054">Tên thương hiệu xe</p>
          <div className='w-full'>
            <Select
              showSearch
              placeholder="Tên thương hiệu xe"
              className="custome-select-t "
              popupClassName='popup-select'
              options={fommatSelectOption(sLCategoryCar)}
              value={categoryCar}
              onChange={(e) => changeCategoryCar(e)}
            />
            {
              errors.categoryCar &&
              <span className="text-F5222D text-base font-regular pl-2">{errors.categoryCar}</span>
            }
          </div>
        </div>
        <div className="d-flex flex-column gap-1">
          <p className="text-sm  font-regular text-344054">Dòng xe</p>
          <div className='w-full'>
            <Select
              showSearch
              placeholder="Dòng xe"
              className="custome-select-t "
              popupClassName='popup-select'
              options={fommatSelectOption(cLCarLines)}
              value={carLines}
              onChange={(e) => setCarLines(e)}
            />
            {
              errors.carLines &&
              <span className="text-F5222D text-base font-regular pl-2">{errors.carLines}</span>
            }
          </div>
        </div>


      </div>
    </Modal>
  )
}
export default ModalCreateCatecoryCar
