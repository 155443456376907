import { MinusCircleFilled, PlusCircleFilled } from '@ant-design/icons';
import { Tag } from 'antd';
import React, { useState } from 'react';
import RelativeList from '../../../components/Relative/RelativeList';
import OwnedVehicle from '../../../components/Vehicle/OwnedVehicle';
import './Step.scss';
// import InterestedVehicleList from '../../../components/Vehicle/InterestedVehicleList';
import EmptyComponent from '../../../components/EmptyComponent/EmptyComponent';
import RankBenefitPopup from './RankBenefitPopup';
import VehicleInfoPopup from './VehicleInfoPopup';
import WarrantyHistoryPopup from './WarrantyHistoryPopup';

import { Rela } from '../../../api/customer/customerApi'
// const onVIPChange = (checked: boolean) => {
//   console.log('vip change to', checked)
// }
const relativeItems: Rela[] = [
  {
    relationship: { id: 1, title: 'Mẹ' },
    name: 'Nguyễn Thu Hoài',
    hobby: [{ id: 1, title: 'Âm nhạc' }],
  },
  {
    relationship: { id: 1, title: 'Mẹ' },
    name: 'Nguyễn Thu Hoài',
    hobby: [{ id: 1, title: 'Âm nhạc' }],
  },
]

interface Props {
  className?: string;
  goBack?: () => void;
  goNext?: () => void;
}

const Step2: React.FC<Props> = ({ className, goBack, goNext }) => {
  const [isShowAllPhoneNumber, setShowAllPhoneNumber] = useState(false)
  const showAllPhoneNumber = () => {
    setShowAllPhoneNumber(!isShowAllPhoneNumber)
  }

  const [isShowAllSource, setShowAllSource] = useState(false)
  const showAllSource = () => {
    setShowAllSource(!isShowAllSource)
  }

  const [isInfoPopupShow, setInfoPopupShow] = useState(false)
  const handleButtonInfoClick = (info: boolean) => {
    setInfoPopupShow(info)
  };

  const [isBenefitPopupShow, setBenefitPopupShow] = useState(false)
  const [isWarrantyHistoryShow, setWarrantyHistoryShow] = useState(false)

  // const handleChange = (value: string) => {
  //   console.log(`selected ${value}`);
  // };

  const handleOnBack = () => {
    if (goBack) {
      goBack()
    }
  }

  const handleOnNext = () => {
    if (goNext) {
      goNext()
    }
  }

  return (
    <div className='step2-pooling-data'>
      <div className="information-tab">
        <div className="information-tab__head">
          <div className="avatar-block">
            <img
              src="/images/default-avatar.svg"
              alt="NVHH"
              className="avatar-block__avatar ob-cover"
            />

            <div className="avatar-block__content">
              <div className="name">Nguyễn Văn Họ Hoàng</div>

              <div className="rank-block">
                <div className="item">
                  <div className="rank">
                    {/* empty thì có thêm class t-cl-fill*/}
                    <img
                      src="/images/medal/medal-df.svg"
                      alt="Vàng"
                      className='rank-img t-cl-filter'
                    />

                    <span className='rank-name'>Chưa xác định</span>
                    {/* <span className='rank-point primary-7'>(3000 Điểm)</span> */}
                  </div>
                </div>

                {/* <div className="item">
                  <span
                    className="font-medium primary-7 cursor-pointer"
                    onClick={() => setBenefitPopupShow(true)}
                  >Quyền lợi</span>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="information-tab__content">
          <div className="information-tab__content-left">
            <div className="block">
              <span className="font-bold">Thông tin cá nhân</span>
            </div>

            <div className="information-table-show">
              <div className="item">
                <span className="item-left">Trạng thái</span>
                <div className="item-right">
                  <div className="status type-1">Đang hoạt động</div>
                </div>
              </div>
              <div className="item">
                <span className="item-left">Tình trạng hôn nhân</span>
                <div className="item-right">
                  <span className='neutral-gray-400'>Đã kết hôn</span>
                </div>
              </div>
              <div className="item">
                <span className="item-left">Nguồn</span>
                <div className="item-right flex-column">
                  {
                    !isShowAllSource
                      ? <div className="first-item-of-list">
                        <span className='neutral-gray-400'>DDMS - Sales</span>
                        <Tag
                          className='rs-tag daybreak-blue no-border cursor-pointer'
                          onClick={showAllSource}
                        >
                          <PlusCircleFilled />
                          <span>2 nguồn</span>
                        </Tag>
                      </div>
                      : <>
                        <span className='neutral-gray-400'>DDMS - Sales</span>
                        <span className='neutral-gray-400'>IPCC</span>
                        <Tag
                          className='rs-tag daybreak-blue no-border cursor-pointer'
                          onClick={showAllSource}
                        >
                          <MinusCircleFilled />
                          <span>Thu gọn</span>
                        </Tag>
                      </>
                  }
                </div>
              </div>
              <div className="item">
                <span className="item-left">Thuộc</span>
                <div className="item-right">
                  <span className='neutral-gray-400'>Đại lý bán hàng ABC</span>
                </div>
              </div>
              <div className="item">
                <span className="item-left">Nghề nghiệp</span>
                <div className="item-right">
                  <span className='neutral-gray-400'>Chưa cập nhật</span>
                </div>
              </div>
              <div className="item">
                <span className="item-left">Ngày sinh</span>
                <div className="item-right">
                  <span className='neutral-gray-400'>08/08/1997</span>
                </div>
              </div>
              <div className="item">
                <span className="item-left">Giới tính</span>
                <div className="item-right">
                  <span className='neutral-gray-400'>Nam</span>
                </div>
              </div>
              <div className="item">
                <span className="item-left">Số điện thoại</span>
                <div className="item-right flex-column">
                  {
                    !isShowAllPhoneNumber
                      ? <div className="first-item-of-list">
                        <span className='neutral-gray-400'>0977721242</span>
                        <Tag
                          className='rs-tag daybreak-blue no-border cursor-pointer'
                          onClick={showAllPhoneNumber}
                        >
                          <PlusCircleFilled />
                          <span>2 số phụ</span>
                        </Tag>
                      </div>
                      : <>
                        <span className='neutral-gray-400'>0977721242</span>
                        <span className='neutral-gray-400'>0977721241</span>
                        <span className='neutral-gray-400'>0977721240</span>
                        <Tag
                          className='rs-tag daybreak-blue no-border cursor-pointer'
                          onClick={showAllPhoneNumber}
                        >
                          <MinusCircleFilled />
                          <span>Thu gọn</span>
                        </Tag>
                      </>
                  }
                </div>
              </div>
              <div className="item">
                <span className="item-left">Email</span>
                <div className="item-right">
                  <span className='neutral-gray-400'>Hoangkimquoc97@gmail.com</span>
                </div>
              </div>
              <div className="item">
                <span className="item-left">Địa chỉ</span>
                <div className="item-right">
                  <span className='neutral-gray-400'>245E/1 Hoàng Văng Thụ, Tân Bình, TPHCM</span>
                </div>
              </div>
              <div className="item">
                <span className="item-left">Nhóm</span>
                <div className="item-right">
                  {/* <div className="ant-tags">
                    <Tag className='rs-tag golden-purple'>Âm nhạc</Tag>
                    <Tag className='rs-tag golden-purple'>Thể thao</Tag>
                  </div> */}

                  {/* If empty */}
                  <span className='neutral-gray-400'>Chưa xác định</span>
                </div>
              </div>
            </div>
          </div>

          <div className="information-tab__content-right">
            <div className="card-block">
              <div className="card-block__title">
                <span className="font-bold">Phân loại</span>
              </div>
              <div className="card-block__content">
                {/* <div className="ant-tags">
                  <Tag className='rs-tag geek-blue'>Doanh nghiệp</Tag>
                  <Tag className='rs-tag geek-blue'>Khó tính</Tag>
                  <Tag className='rs-tag geek-blue'>Giàu có</Tag>
                </div> */}

                {/* If empty */}
                <Tag className='rs-tag neutral-gray'>Chưa phân loại</Tag>
              </div>
            </div>

            <div className="card-block">
              <div className="card-block__title">
                <span className="font-bold">Sở thích</span>
              </div>
              <div className="card-block__content">
                {/* <div className="ant-tags">
                  <Tag className='rs-tag golden-purple'>Âm nhạc</Tag>
                  <Tag className='rs-tag golden-purple'>Thể thao</Tag>
                </div> */}

                {/* If empty */}
                <Tag className='rs-tag neutral-gray'>Chưa xác định</Tag>
              </div>
            </div>

            <div className="card-block">
              <div className="card-block__title">
                <span className="font-bold">Xe quan tâm</span>
              </div>
              <div className="card-block__content">
                {/* <InterestedVehicleList /> */}

                {/* If Empty */}
                <EmptyComponent />
              </div>
            </div>

            <div className="card-block">
              <div className="card-block__title">
                <span className="font-bold">Xe sở hữu</span>
              </div>
              <div className="card-block__content">
                <OwnedVehicle
                  onButtonClick={handleButtonInfoClick}
                />

                {/* If Empty */}
                {/* <EmptyComponent /> */}
              </div>
            </div>

            <div className="card-block">
              <div className="card-block__title">
                <span className="font-bold">Danh sách người thân</span>
              </div>
              <div className="card-block__content">
                <RelativeList
                  listRelatives={relativeItems}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="btn-pooling-data-block">
          <div className="ctrl-btn-pooling-data ctrl-2btn">
            <div className="btn btn-df" onClick={handleOnBack}>Bước trước</div>
            <div className="btn" onClick={handleOnNext}>Bước tiếp theo</div>
          </div>
        </div>
      </div>

      {
        isInfoPopupShow
          ? <VehicleInfoPopup
            className="show"
            onClose={() => { setInfoPopupShow(false) }}
            onShowHistory={() => {
              setInfoPopupShow(false)
              setWarrantyHistoryShow(true)
            }}
          />
          : null
      }
      {
        isWarrantyHistoryShow
          ? <WarrantyHistoryPopup
            className="show"
            onClose={() => { setWarrantyHistoryShow(false) }}
            onBack={() => {
              setInfoPopupShow(true)
              setWarrantyHistoryShow(false)
            }}
          />
          : null
      }
      {
        isBenefitPopupShow
          ? <RankBenefitPopup
            className="show"
            onClose={() => { setBenefitPopupShow(false) }}
          />
          : null
      }
    </div>
  );
}

export default Step2;
