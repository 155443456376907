import { CloseOutlined, DeleteOutlined } from "@ant-design/icons"
import { Modal, notification } from "antd"
interface ModalProps {
  isOpen: boolean
  numberCustomer: number
  onConfirm?: () => void
  closeModal: () => void

}
const ModalDeleteGroup: React.FC<ModalProps> = ({
  isOpen, numberCustomer,
  onConfirm, closeModal
}) => {

  return (
    <Modal
      title={<div className="bg-FCFCFD d-flex justify-betweeen align-center py-4 px-6 w-full border-b-F0F0F0">
        <span className="text-base text-344054 fw-500 font-medium">Bạn có chắc chắn xóa khách hàng khỏi nhóm?</span>
        <CloseOutlined className="text-base text-8B8B8B" onClick={closeModal} />
      </div>}
      open={isOpen}
      onCancel={closeModal}
      centered
      className="w-modal modal-content-warning ant-modal-size"
      closeIcon={false}
      footer={<div className="bg-FCFCFD d-flex justify-betweeen align-center py-2 px-6 w-full border-t-F0F0F0">
        <span className="text-sm text-98A2B3 cursor-pointer" onClick={closeModal}>Huỷ</span>
        <button className="text-F5222D text-sm bg-FFF1F0 border-F5222D shadow-btn-save rounded-2 h-8 px-15-px gap-10-px d-flex align-center"
          onClick={onConfirm}>
          <DeleteOutlined className="text-F5222D text-sm" />
          Xoá ({numberCustomer}) khách khỏi nhóm
        </button>
      </div>}
    >
      <div className="p-6 d-flex flex-column justify-center align-center gap-18-px">
        <img src='/images/exportImg/warning.svg' alt="warning" className="w-80-px h-80-px" />
        <span className="text-base font-regular fw-400 text-344054">Việc tìm lại khách hàng và thêm vào nhóm có thể sẽ mất thời gian hơn, hãy chắc chắn khách hàng bạn xoá không phù hợp với nhóm này</span>
      </div>
    </Modal>
  )
}
export default ModalDeleteGroup
