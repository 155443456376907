import React from 'react'
import './FeedbackListVertical.scss'

interface Props {
  children?: React.ReactNode
}

const FeedbackListVertical: React.FC<Props> = ({ children }) => {
  return (
    <div className="feedback-list-vertical">
      { children }
    </div>
  )
}

export default FeedbackListVertical