import axiosClient, { ResponseSuccess, ResponseSuccessTotal } from "../axiosClient";

export interface DatatypeHistoryMaintenance {
    type: string
    title: string
    info: Info
}

export interface Info {
    type: string
    bks: string
    vin: string
    carId: string
    id: string
    maintenanceDay: string
    finishDay: string
}
export interface ICarCompany {
    id: number
    title: string
    image: string
}

export interface IHistory {
    id: number
    dateTransaction: string
    customerId: number
    userName: string
    typeInteractionId: number
    typeInteractionName: string
    careAndFeedbackId?: number
    careAndFeedbackIdTitle?: string
    nameStaff?: string
    typeSupportTitle?: string
    carId?: number
    carCode?: string
    carName?: string
    agentId?: number
    agentName?: string
}
export interface IHistoryCarDetail {
    id: number
    customerId: number
    userName: string
    agentId: number
    agentName: string
    codeContract: string
    numberContract: string
    dateTransaction: string
    carId: number
    carCode: string
    carName: string
    carImage: string
    fuelId: number
    fuel: string
    segmentsVehicleId: number
    segmentsVehicle: string
    colorId: number
    color: string
    modelVehicleId: number
    modelVehicle: string
    typeBodyVehicleId: number
    typeBodyVehicle: string
    categoryCarId: number
    categoryCarName: string
    categoryCarImage: string
    countryId: number
    countryName: string
    countryImage: string
    VIN: string
    engineNumber: string
}

export interface IHistoryMaintenance {
    id: number
    carId: number
    carCode: string
    carName: string
    BKS: string
    VIN: string
    codeMaintenance: string
    kmNearest: number
    warrantyPeriod: number
    dateMaintenance: string
    dateComplete: string
    typeInteractionId: number
    typeInteractionName: string
}

export const getListHistoryMaintenance = async (
    limit?: number, pageNumber?: number, customerId?: string, carId?: string,
) => {
    const params = {
        customerId, carId, limit, pageNumber
    }
    const path = "history/listHistoryMaintenance"
    const result: ResponseSuccessTotal<IHistoryMaintenance[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};
//Danh sách lịch sử
export const getListHistory = async (
    customerId?: number, pageNumber?: number, limit?: number, searchName?: string, timeStart?: string, timeEnd?: string, interactionTypeId?: number,
) => {
    const params = {
        customerId, pageNumber, limit, searchName, timeStart, timeEnd, interactionTypeId
    }
    const path = "history/listHistory"
    const result: ResponseSuccessTotal<IHistory[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Thông tin lịch sử xe sở hữu
export const getListHistoryCarInfo = async (historyId: number,
) => {
    const params = {
        historyId
    }
    const path = "history/historyCarInfo"
    const result: ResponseSuccess<IHistoryCarDetail> = await axiosClient.get(
        path, { params }
    );
    return result;
};
