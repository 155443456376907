import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

const data = [
  { name: 'Hỗ trợ khách hàng', value: 15000, color: '#orange' },
  { name: 'Khiếu nại', value: 15000, color: '#blue' },
];

const ARCCStatisticalChart: React.FC = () => {
  const renderLegendText = (value: string, entry: any, index: number) => {
    return <span>{value} <span className="neutral-gray-400">({data[index].value})</span></span>
  };

  return (
    <div className="arc-card">
      <div className="arc-card__head">
        <span className="title">Biểu đồ thống kê</span>
        <div className="control">
          <Button className="rs-button">
            <DownloadOutlined />
            <span>Xuất báo cáo</span>
          </Button>
        </div>
      </div>

      <div className="arc-card__content">
        <ResponsiveContainer aspect={1}>
          <PieChart
            width={285}
            height={285}
            className="statistical-pie-chart rs-recharts-tooltip"
          >
            <defs>
              <linearGradient id="blue" x1="0" y1="0" x2="1" y2="0">
                <stop offset="0%" stopColor="#1890FF" stopOpacity={1} />
                <stop offset="100%" stopColor="#8CC8FF" stopOpacity={1} />
              </linearGradient>
              <linearGradient id="orange" x1="0" y1="0" x2="1" y2="0">
                <stop offset="0%" stopColor="#F59C23" stopOpacity={1} />
                <stop offset="100%" stopColor="#F55E09" stopOpacity={1} />
              </linearGradient>
            </defs>

            <Pie
              data={data}
              dataKey="value"
              cx="50%"
              cy="50%"
              innerRadius="70%"
              outerRadius="100%"
              fill="url(#blue)"
              startAngle={180}
              endAngle={-180}
            >
              {
                data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={`url(${entry.color})`}/>
                ))
              }
            </Pie>

            <Tooltip />
            <text
              x="50%"
              y="39%"
              textAnchor="middle"
              dominantBaseline="middle"
              className="number"
            >
              30,000
            </text>
            <text
              x="50%"
              y="47%"
              textAnchor="middle"
              dominantBaseline="middle"
              className="text"
            >
              Liên hệ
            </text>
            <Legend verticalAlign="bottom" layout="vertical" iconType="circle" formatter={renderLegendText}/>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ARCCStatisticalChart;
