import { paserParams } from "../../until";
import axiosClient, { ResponseSuccess, ResponseSuccessTotal } from "../axiosClient";
import { SortType } from "../carLine/carLineApi";

export interface ICategoryCar {
    id: number
    title: string
    image: string
    status: number
}

export interface ICategoryCarEdit {
    id: number
    title: string
    image: string
}

export const getListCategoryCar = async (
    limit?: number, pageNumber?: number, search?: string, sort?: SortType) => {
    const path = "categoryCar/listCategoryCar"
    const params = {
        limit, pageNumber, search, sort
    }
    const result: ResponseSuccessTotal<ICategoryCar[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Thông tin thương hiệu xe
export const getInfoCategoryCar = async (
    id: number) => {
    const path = "categoryCar/infoCategoryCar"
    const params = {
        id
    }
    const result: ResponseSuccessTotal<ICategoryCarEdit> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Thêm mới thương hiệu xe
export const addCategoryCar = async (
    title: string, image: File | null
) => {
    const url = `categoryCar/addCategoryCar`;
    const params = {
        title, image
    }
    const result: ResponseSuccess<[]> = await axiosClient.post(
        url, params
    );
    return result;
}

//Chỉnh sửa thương hiệu xe
export const editCategoryCar = async (
    id: number, title: string, image: File | null
) => {
    const url = `categoryCar/editCategoryCar`;
    const params = {
        id, title, image
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        url, params
    );
    return result;
}

//Xóa thương hiệu xe
export const deleteCategoryCar = async (
    id: Number
) => {
    const url = `categoryCar/deleteCategoryCar`;
    const params = {
        id
    }
    const result: ResponseSuccess<[]> = await axiosClient.delete(
        url, { params }
    );
    return result;
}

// Cập nhật trạng thái thương hiệu

export const updateActiveCategoryCar = async (
    id: number) => {
    const path = "categoryCar/activeCategoryCar"
    const params = {
        id
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        path, params
    );
    return result;
};