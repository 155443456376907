import React, { useEffect, useRef } from "react";
import { Checkbox, GetProp } from "antd";
import './FilterCustomerList.scss';

interface Props {
  className?: string;
  onClose?: (value : any, isChange: boolean) => void;
  onShowHistory?: () => void;
  columns?: any;
  defaultColumnsShow?: any;
}

const ShowSetting: React.FC<Props> = ({ className, onClose, onShowHistory, columns, defaultColumnsShow }) => {
  const [listColums, setListColums] = React.useState<any[]>([...columns]);
  const [listChoseColums, setListChoseColums] = React.useState<any[]>(localStorage.getItem('customerListColums') ? JSON.parse(localStorage.getItem('customerListColums') as string) : [...defaultColumnsShow]);
  const handleOnClose = () => {
    if (onClose) {
      onClose(listChoseColums, true)
    }
  }

  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(e.target as Node)) {
        if (onClose) {
          onClose(listChoseColums, false)
        }
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [onClose, listChoseColums]);


  const onChange: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues: any) => {
    setListChoseColums(checkedValues)
  };

  return (
    <div
      ref={popupRef}
      className="filter-setting"
    >
      <div className="title-add-filter">
        <span>Nâng cao</span>
      </div>
      <div className="content-filter-setting t-scrollbar">
        <div className="title-content">Tùy chỉnh trường hiển thị trên bảng</div>

        <div className="list-add-filter-content">
          <Checkbox.Group onChange={onChange} defaultValue={listChoseColums}>
            {
              listColums.map((item: any, index: number) => {
                return (
                  <Checkbox key={index} className="one-add-filter-content" value={item.key}>
                    <div className="text">{item.title}</div>
                  </Checkbox>
                )
              })
            }
            {/* <Checkbox className="one-add-filter-content" value="1">
              <div className="text">Hạng thành viên</div>
            </Checkbox>
            <Checkbox className="one-add-filter-content" value="2">
              <div className="text">Tình trạng hôn nhân</div>
            </Checkbox>
            <Checkbox className="one-add-filter-content" value="3">
              <div className="text">Họ và tên</div>
            </Checkbox>
            <Checkbox className="one-add-filter-content" value="4">
              <div className="text">Ngày/tháng/năm sinh</div>
            </Checkbox>
            <Checkbox className="one-add-filter-content" value="5">
              <div className="text">Giới tính</div>
            </Checkbox>
            <Checkbox className="one-add-filter-content" value="6">
              <div className="text">Số điện thoại</div>
            </Checkbox>
            <Checkbox className="one-add-filter-content" value="7">
              <div className="text">Email</div>
            </Checkbox>
            <Checkbox className="one-add-filter-content" value="8">
              <div className="text">Nghề nghiệp</div>
            </Checkbox>
            <Checkbox className="one-add-filter-content" value="9">
              <div className="text">Thành phố/Tỉnh</div>
            </Checkbox>
            <Checkbox className="one-add-filter-content" value="10">
              <div className="text">Địa chỉ</div>
            </Checkbox> */}
          </Checkbox.Group>
        </div>
      </div>
      <div className="footer-filter-block">
        <div className="btn-block">
          <div className="btn btn-primary" onClick={handleOnClose}>
            <div className="text">
              Lưu
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShowSetting
