import React, { useEffect, useState } from 'react';
import Layout from '../../Layout';
import { Breadcrumb, Input, Table, Pagination, ConfigProvider, notification } from 'antd';
import type { TableColumnsType, TableProps } from 'antd';
import {
  EyeOutlined, EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import type { SearchProps } from 'antd/es/input/Search';
import viVN from 'antd/locale/vi_VN';
import { Link } from 'react-router-dom';
import './PoolingDataAutoDetail.scss'
import PopupShowData from './ComponentPoolingData/PopupShowData';


interface DataType {
  key: number;
  name: string;
  phone: string;
  date: string;
}

const data: DataType[] = [
  {
    key: 1,
    phone: '039222345',
    date: '12:12:12 18/09/2024',
    name: 'Nguyễn Lê Hải Phong',
  },
  {
    key: 2,
    phone: '039222345',
    date: '12:12:12 18/09/2024',
    name: 'Tuấn Lê',
  },
  {
    key: 3,
    phone: '039222345',
    date: '12:12:12 18/09/2024',
    name: 'Tuấn Hải',
  },
  {
    key: 4,
    phone: '039222345',
    date: '12:12:12 18/09/2024',
    name: 'Minh Trần',
  },
  {
    key: 5,
    phone: '039222345',
    date: '12:12:12 18/09/2024',
    name: 'Hoàng Anh',
  },
  {
    key: 6,
    phone: '039222345',
    date: '12:12:12 18/09/2024',
    name: 'Trung Hà',
  },
  {
    key: 7,
    phone: '039222345',
    date: '12:12:12 18/09/2024',
    name: 'Khánh Hoàn',
  },
  {
    key: 8,
    phone: '039222345',
    date: '12:12:12 18/09/2024',
    name: 'Hạ Trang',
  },
  {
    key: 9,
    phone: '039222345',
    date: '12:12:12 18/09/2024',
    name: 'Trần Minh Đức',
  },
  {
    key: 10,
    phone: '039222345',
    date: '12:12:12 18/09/2024',
    name: 'Trúc Minh',
  },
  {
    key: 11,
    phone: '039222345',
    date: '12:12:12 18/09/2024',
    name: 'Trúc Minh',
  },
  {
    key: 12,
    phone: '039222345',
    date: '12:12:12 18/09/2024',
    name: 'Trúc Minh',
  },
  {
    key: 13,
    phone: '039222345',
    date: '12:12:12 18/09/2024',
    name: 'Trúc Minh',
  },
  {
    key: 14,
    phone: '039222345',
    date: '12:12:12 18/09/2024',
    name: 'Trúc Minh',
  },
  {
    key: 15,
    phone: '039222345',
    date: '12:12:12 18/09/2024',
    name: 'Trúc Minh',
  },
  {
    key: 16,
    phone: '039222345',
    date: '12:12:12 18/09/2024',
    name: 'Trúc Minh',
  },
];

const { Search } = Input;

const onSearch: SearchProps['onSearch'] = (value, _e, info) => console.log(info?.source, value);

const PoolingDataAutoDetail: React.FC = () => {
  useEffect(() => {
    document.title = 'THACO AUTO CRM';
  }, []);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [isShowData, setShowData] = useState(false);

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Hành động',
      dataIndex: '',
      align: 'center',
      render: () => (
        <>
          <div className="t-hd-table">
            <div className="item-hd-table" onClick={() => setShowData(true)}><span><EyeOutlined /></span></div>
            <Link to='/customer/pooling-data/merge-manually' className="item-hd-table"><span><EditOutlined /></span></Link>
            <div className="item-hd-table t-cl-F5222D"><span><DeleteOutlined /></span></div>
          </div>
        </>
      ),
    },
    {
      title: 'Họ và tên',
      dataIndex: 'name',
    },
    {
      title: 'Họ và tên',
      dataIndex: 'phone',
      align: 'center',
    },
    {
      title: 'Nguồn dữ liệu',
      dataIndex: '',
      align: 'center',
      render: () => (
        <>
          <div className="t-table-list-tags">
            <div className="item-tag">DDMS</div>
            <div className="item-tag">IPCC</div>
            <div className="item-tag">SALESFORCE</div>
          </div>
        </>
      ),
    },
    {
      title: 'Ngày thực hiện',
      dataIndex: 'date',
      align: 'right',
      sorter: {
        compare: (a, b) => a.key - b.key,
        multiple: 1,
      },
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onChangeTB: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  const setShowNoti = () => {
    setShowData(false)
    openNotification()
  };

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();
  const openNotification = () => {
    api['success']({
      message: 'Tạo Voucher thành công!',
      placement: 'bottomRight',
      className: 'single-message'
    });
  };

  return (
    <div className='pooling-data-auto-detail'>
      {contextHolder}
      {/* <Helmet>
        <title>THACO AUTO CRM</title>
      </Helmet> */}
      <Layout>
        <Breadcrumb
          items={[
            {
              title: 'THACO AUTO CRM',
            },
            {
              title: <a href="/customer/list">Khách hàng</a>,
            },
            {
              title: <a href="/customer/pooling-data">Gộp dữ liệu khách hàng</a>,
            },
            {
              title: 'Xác nhận dữ liệu gộp tự động',
            },
          ]}
        />

        <div className='title-page t-title-btn-back'>
          <Link to="/customer/pooling-data" className="icon-svg">
            <svg width="1em" height="1em" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.6564 7.80321H4.04305L10.3955 2.37463C10.4971 2.28713 10.4354 2.12463 10.3012 2.12463H8.69582C8.62508 2.12463 8.55796 2.14963 8.50536 2.19428L1.65045 8.04963C1.58767 8.10321 1.53732 8.16944 1.50281 8.24385C1.4683 8.31825 1.45044 8.39909 1.45044 8.48088C1.45044 8.56268 1.4683 8.64352 1.50281 8.71792C1.53732 8.79233 1.58767 8.85856 1.65045 8.91213L8.54526 14.8032C8.57247 14.8264 8.60512 14.8389 8.63959 14.8389H10.2993C10.4336 14.8389 10.4953 14.6746 10.3937 14.5889L4.04305 9.16035H14.6564C14.7363 9.16035 14.8016 9.09606 14.8016 9.01749V7.94606C14.8016 7.86749 14.7363 7.80321 14.6564 7.80321Z" fill="currentColor" fillOpacity="0.88" />
            </svg>
          </Link>
          Xác nhận dữ liệu gộp tự động
        </div>

        {/* Fillter block */}
        <div className='filter-block t-mb-18'>
          <div className='t-search-block w-100'>
            <Search placeholder="Tìm kiếm số điện thoại, hoặc email" onSearch={onSearch} />
          </div>
        </div>

        <div className="t-table-block t-table-pooling-data-auto-detail">
          <Table
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
            onChange={onChangeTB}
            pagination={false}
            showSorterTooltip={false}
          />

          <ConfigProvider locale={viVN}>
            <div className="pagination-block">
              <Pagination
                total={85}
                showSizeChanger
                showQuickJumper
                showTotal={(total) => `Tổng cộng ${total} trang`}
                locale={{ items_per_page: 'trang' }}
              />
            </div>
          </ConfigProvider>
        </div>

        {
          isShowData && (
            <PopupShowData
              onCloseData={() => { setShowData(false) }}
              onShowNoti={() => { setShowNoti() }}
            />
          )
        }
      </Layout>
    </div>
  );
}

export default PoolingDataAutoDetail;
