import React, { useEffect, useRef, useState } from 'react';
import './VoucherDetailTable.scss'
import { DownloadOutlined, EllipsisOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, FormInstance, Input, Modal, Select, Switch, Table, TableColumnsType, Tabs, TabsProps, notification, ConfigProvider, Pagination, PaginationProps } from 'antd';
import { SearchProps } from 'antd/es/input';
import viVN from 'antd/locale/vi_VN';
import useToast from '../../../hook/useToast';
import useDebounce from '../../../hook/useDebounce';
import * as voucherApi from '../../../api/voucher/voucherApi';
import { dataPagination } from '../../../until';
import useThrottle from '../../../hook/useThrottle';
import * as XLSX from 'xlsx';
import { LIMIT_EXPORT_EXCEL } from '../../../env';

// SEARCH
const { Search } = Input;


// TABs
const tabItems: TabsProps['items'] = [
  {
    label: 'Tất cả',
    key: "2",
  },
  {
    label: 'Chưa sử dụng',
    key: "0",
  },
  {
    label: 'Đã sử dụng',
    key: "1",
  },
];
// TABLE
interface DataType {
  STT: number
  key: React.Key
  ma_voucher?: string
  trang_thai: boolean
  khach_hang_doi?: string
  so_dien_thoai?: string
  thoi_gian_doi?: string
  id_cua_hang?: string
}

type FieldType = {
  customerName?: string;
  shopId?: string;
  date?: string;
}

type Props = {
  id: number
};

const VoucherDetailTable: React.FC<Props> = ({
  id
}) => {

  const columns: TableColumnsType<DataType> = [
    {
      title: 'STT',
      dataIndex: 'STT',
      sorter: {
        compare: (a, b) => a.STT - b.STT,
      },
      render: (text) => <div style={{ textAlign: 'center' }}>{text}</div>
    },
    {
      title: 'Hành động',
      dataIndex: '',
      sorter: false,
      render: (_, record) =>
        <Switch
          value={record.trang_thai}
          className="rs-switch rs-switch--sm"
          onChange={(e) => onChangeActiveCode(e, record.key)}
        />
    },
    {
      title: 'Mã Voucher',
      dataIndex: 'ma_voucher',
      key: 'ma_voucher',
      sorter: {
        compare: (a, b) => {
          if (a.ma_voucher && b.ma_voucher) {
            return a.ma_voucher.localeCompare(b.ma_voucher);
          }
          return 0;
        },
      },
    },
    {
      title: 'Khách hàng',
      dataIndex: 'khach_hang_doi',
      key: 'khach_hang_doi',
      sorter: {
        compare: (a, b) => {
          if (a.khach_hang_doi && b.khach_hang_doi) {
            return a.khach_hang_doi.localeCompare(b.khach_hang_doi);
          }
          return 0;
        },
      },
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'so_dien_thoai',
      key: 'so_dien_thoai',
      sorter: {
        compare: (a, b) => {
          if (a.so_dien_thoai && b.so_dien_thoai) {
            return a.so_dien_thoai.localeCompare(b.so_dien_thoai);
          }
          return 0;
        },
      },
    },
    {
      title: 'Ngày quy đổi',
      dataIndex: 'thoi_gian_doi',
      key: 'thoi_gian_doi',
      sorter: false,

    },
    {
      title: 'Cửa hàng/ đại lý',
      dataIndex: 'id_cua_hang',
      key: 'id_cua_hang',
      sorter: {
        compare: (a, b) => {
          if (a.id_cua_hang && b.id_cua_hang) {
            return a.id_cua_hang.localeCompare(b.id_cua_hang);
          } else if (a.id_cua_hang) {
            // Trường hợp chỉ có a có trường id_cua_hang, đưa a lên trên
            return -1;
          } else if (b.id_cua_hang) {
            // Trường hợp chỉ có b có trường id_cua_hang, đưa b lên trên
            return 1;
          } else {
            // Cả hai đều không có trường id_cua_hang, giữ nguyên thứ tự
            return 0;
          }
        },
      },
    },
  ];

  useEffect(() => {
    document.title = 'THACO AUTO CRM';
    return (() => {


      setDataFilter("")

      setPaginationAll({ current: 1, pageSize: 10, total: 0 })
      setPaginationUnused({ current: 1, pageSize: 10, total: 0 })
      setPaginationUsed({ current: 1, pageSize: 10, total: 0 })
    })

  }, []);

  // HANDLE MODAL
  const pushToast = useToast();

  const [isModalShow, setModalShow] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)

  const [activeKey, setActiveKey] = useState<"2" | "0" | "1">("2")

  const [dataFilter, setDataFilter] = useState("")

  const [paginationAll, setPaginationAll] = useState({ current: 1, pageSize: 10, total: 0 });
  const [paginationUnused, setPaginationUnused] = useState({ current: 1, pageSize: 10, total: 0 });
  const [paginationUsed, setPaginationUsed] = useState({ current: 1, pageSize: 10, total: 0 });

  const [listVoucherAll, setListVoucherAll] = useState<DataType[]>([])
  const [listVoucherUnused, setListVoucherUnused] = useState<DataType[]>([])
  const [listVoucherUsed, setListVoucherUsed] = useState<DataType[]>([])


  const gListVocherAll = useDebounce(async (pageCurent: number = 1, pageSize: number = 10) => {
    const result = await voucherApi.getListVoucherCode(id, pageSize, pageCurent, 2, dataFilter)
    if (result?.status) {
      setListVoucherAll(convertData(result.data, pageCurent, pageSize))
      setPaginationAll({ current: pageCurent, total: result.total, pageSize })
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const gListVocherUnused = useDebounce(async (pageCurent: number = 1, pageSize: number = 10) => {
    const result = await voucherApi.getListVoucherCode(id, pageSize, pageCurent, 0, dataFilter)
    if (result?.status) {
      setListVoucherUnused(convertData(result.data, pageCurent, pageSize))
      setPaginationUnused({ current: pageCurent, total: result.total, pageSize })
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const gListVocherUsed = useDebounce(async (pageCurent: number = 1, pageSize: number = 10) => {
    const result = await voucherApi.getListVoucherCode(id, pageSize, pageCurent, 1, dataFilter)
    if (result?.status) {
      setListVoucherUsed(convertData(result.data, pageCurent, pageSize))
      setPaginationUsed({ current: pageCurent, total: result.total, pageSize })
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const convertData = (data: voucherApi.IVoucherCode[], pageCurent: number, pageSize: number) => {
    var stt = (pageCurent - 1) * pageSize + 1;
    const convertData = data.map((item) => ({
      STT: stt++,
      key: item.id,
      ma_voucher: item.code,
      trang_thai: item.view ? true : false,
      khach_hang_doi: item.customerName,
      so_dien_thoai: item.phone,
      thoi_gian_doi: item.conversionDate,
      id_cua_hang: item.agentName,
    }))
    return convertData
  }

  const gListVoucherCodeByExcel = useThrottle(async () => {
    const result = await voucherApi.getListVoucherCode(
      id, LIMIT_EXPORT_EXCEL, 1, Number(activeKey), dataFilter
    )
    if (result?.status) {
      formattedDataExcel(result.data)
    } else {
      pushToast(result?.message, "warning")
    }
  }, 2000)


  const onPaginationChangeAll: PaginationProps['onChange'] = (pageNumber, pageSize) => {
    gListVocherAll(pageNumber, pageSize)
  }

  const onPaginationChangeUnused: PaginationProps['onChange'] = (pageNumber, pageSize) => {
    gListVocherUnused(pageNumber, pageSize)
  }

  const onPaginationChangeUsed: PaginationProps['onChange'] = (pageNumber, pageSize) => {
    gListVocherUsed(pageNumber, pageSize)
  }


  const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
  }

  const onChange = (value: string) => {
    setDataFilter(value)
  }

  const onChangeActiveCode = useThrottle(async (value: boolean, id: number) => {
    const result = await voucherApi.activeVoucherCode(id)
    if (result?.status) {
      switch (activeKey) {
        case "2":
          gListVocherAll()
          break;
        case "0":
          gListVocherUnused()
          break;
        case "1":
          gListVocherUsed()
          break;

        default:
          break;
      }
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)


  const formattedDataExcel = (data: voucherApi.IVoucherCode[]) => {
    const formattedData = data.map((item) => {
      return {
        "Trạng thái": item.view ? "Đang hoạt động" : "Không hoạt động",
        "Mã Voucher": item.code,
        "Khách hàng": item.customerName,
        "Số điện thoại": item.phone,
        "Ngày quy đổi": item.conversionDate,
        "Cửa hàng/ đại lý": item.agentName
      }
    }
    );
    exportExcel(formattedData)
  }

  const exportExcel = (convertData: any): boolean => {
    try {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(convertData);
      const workbook: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Danh sách');

      const excelBuffer: ArrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });

      const saveAsExcel = (buffer: ArrayBuffer, fileName: string): void => {
        const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
        const link: HTMLAnchorElement = document.createElement('a');
        link.href = window.URL.createObjectURL(data);
        link.download = fileName;
        link.click();
      };

      saveAsExcel(excelBuffer, `${"Danh sách voucher"}.xlsx`);
      return true; // Export successful
    } catch (error) {
      console.error('Error exporting Excel:', error);
      return false; // Export failed
    }
  }

  const handleOk = () => {
    setConfirmLoading(true)
    setTimeout(() => {
      setModalShow(false)
      setConfirmLoading(false)
      // openNotification()
    }, 1500)
  }

  // ON SUBMIT FORM
  const formRef = useRef<FormInstance<FieldType>>(null);

  const onSubmitForm = () => {
    if (formRef.current) {
      formRef.current.submit()
    }
  }

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();
  // const openNotification = () => {
  //   api['success']({
  //     message: 'Cập nhật Voucher thành công!',
  //     placement: 'bottomRight',
  //     className: 'single-message'
  //   });
  // };

  useEffect(() => {
    if (activeKey === "2")
      gListVocherAll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFilter, activeKey])

  useEffect(() => {
    if (activeKey === "0")
      gListVocherUnused()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFilter, activeKey])

  useEffect(() => {
    if (activeKey === "1")
      gListVocherUsed()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFilter, activeKey])

  return (
    <>
      {contextHolder}

      <div className="voucher-detail-table">
        <div className="top">
          <span className="title">Danh sách voucher</span>

          <div className="control">
            <Search
              placeholder="Tìm chương trình Voucher"
              allowClear
              className="rs-input-search"
              value={dataFilter}
              // onSearch={onSearch}
              onChange={(e) => onChange(e.target.value)}
            />

            {/* <Button className="rs-button rs-button--sm">
              <UploadOutlined />
              <span>Nhập DS</span>
            </Button> */}

            <Button
              className="rs-button rs-button--sm"
              onClick={gListVoucherCodeByExcel}
            >
              <DownloadOutlined />
              <span>Xuất DS</span>
            </Button>

            <Button
              className="rs-button"
              icon={<EllipsisOutlined />}
            />
          </div>
        </div>

        <Tabs
          activeKey={activeKey}
          items={tabItems}
          className='rs-tabs'
          onChange={(e: any) => setActiveKey(e)}
        />

        {
          activeKey === '2' && (
            <>
              <Table
                columns={columns}
                dataSource={listVoucherAll}
                showSorterTooltip={false}
                className="rs-table manual-campaign-table"
                pagination={false}
              />
              <ConfigProvider locale={viVN}>
                <div className="pagination-block">
                  <Pagination
                    showSizeChanger
                    current={paginationAll.current}
                    pageSize={paginationAll.pageSize}
                    total={paginationAll.total}
                    showTotal={(total) => `Tổng cộng ${dataPagination(paginationAll.total, paginationAll.pageSize, paginationAll.current).totalPage} trang`}
                    className='rs-pagination'
                    onChange={onPaginationChangeAll}

                  />
                </div>
              </ConfigProvider>
            </>
          )
        }

        {
          activeKey === '0' && (
            <>
              <Table
                columns={columns}
                dataSource={listVoucherUnused}
                showSorterTooltip={false}
                className="rs-table manual-campaign-table"
                pagination={false}
              />
              <ConfigProvider locale={viVN}>
                <div className="pagination-block">
                  <Pagination
                    showSizeChanger
                    current={paginationUnused.current}
                    pageSize={paginationUnused.pageSize}
                    total={paginationUnused.total}
                    showTotal={(total) => `Tổng cộng ${dataPagination(paginationUnused.total, paginationUnused.pageSize, paginationUnused.current).totalPage} trang`}
                    className='rs-pagination'
                    onChange={onPaginationChangeUnused}

                  />
                </div>
              </ConfigProvider>
            </>
          )
        }


        {
          activeKey === '1' && (
            <>
              <Table
                columns={columns}
                dataSource={listVoucherUsed}
                showSorterTooltip={false}
                className="rs-table manual-campaign-table"
                pagination={false}
              />
              <ConfigProvider locale={viVN}>
                <div className="pagination-block">
                  <Pagination
                    showSizeChanger
                    current={paginationUsed.current}
                    pageSize={paginationUsed.pageSize}
                    total={paginationUsed.total}
                    showTotal={(total) => `Tổng cộng ${dataPagination(paginationUsed.total, paginationUsed.pageSize, paginationUsed.current).totalPage} trang`}
                    className='rs-pagination'
                    onChange={onPaginationChangeUsed}
                  />
                </div>
              </ConfigProvider>
            </>
          )
        }


      </div>

      <Modal
        title="Cập nhật Voucher thủ công"
        open={isModalShow}
        centered
        confirmLoading={confirmLoading}
        footer={[
          <span
            key="back"
            className="neutral-gray-400 cursor-pointer"
            onClick={() => setModalShow(false)}
          >Hủy</span>,

          <Button
            key="submit"
            type="primary"
            loading={confirmLoading}
            onClick={onSubmitForm}
            className="rs-button"
          >
            Xác nhận đã quy đổi
          </Button>,
        ]}
        className="rs-modal w-400"
        onCancel={() => setModalShow(false)}
      >
        <Form
          ref={formRef}
          name="basic"
          autoComplete="off"
          className="rs-form voucher-update-form"
          onFinish={handleOk}
        >
          <Form.Item<FieldType>
            name="customerName"
            rules={[{ required: true, message: 'Vui lòng chọn khách hàng!' }]}
          >
            <Select
              placeholder="Tìm và chọn khách hàng"
              suffixIcon={<SearchOutlined />}
              className="rs-select"
              popupClassName="rs-select-dropdown"
              virtual={false}
              options={[
                {
                  value: 'nguyen-le-hai-phong',
                  label: 'Nguyễn Lê Hải Phong',
                },
                {
                  value: 'tuan-le',
                  label: 'Tuấn Lê',
                },
                {
                  value: 'tuan-hai',
                  label: 'Tuấn Hải',
                },
                {
                  value: 'minh-tran',
                  label: 'Minh Trần',
                },
                {
                  value: 'hoang-anh',
                  label: 'Hoàng Anh',
                },
                {
                  value: 'trung-ha',
                  label: 'Trung Hà',
                },
                {
                  value: 'khanh-hoan',
                  label: 'Khánh Hoàn',
                },
                {
                  value: 'ha-trang',
                  label: 'Hạ Trang',
                },
                {
                  value: 'tran-minh-duc',
                  label: 'Trần Minh Đức',
                },
              ]}
            />
          </Form.Item>

          <Form.Item<FieldType>
            name="shopId"
            rules={[{ required: true, message: 'Vui lòng nhập ID cửa hàng!' }]}
          >
            <Input
              placeholder="ID đại lý"
              className="rs-input rs-input--32"
            />
          </Form.Item>

          <Form.Item<FieldType>
            name="date"
            rules={[{ required: true, message: 'Vui chọn ngày quy đổi!' }]}
          >
            <DatePicker
              className="rs-date-picker d-flex"
              popupClassName="rs-date-picker-dropdown"
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default VoucherDetailTable;
