import { Value } from "sass"

export const dataGender = [
    {
        value: 0,
        title: "Nữ"
    },
    {
        value: 1,
        title: "Nam"
    },
    {
        value: 2,
        title: "Không xác định"
    }
]

export const dataStatusMarital = [
    {
        value: 0,
        title: "Độc thân"
    },
    {
        value: 1,
        title: "Đã kết hôn"
    },

]

export const dataSoft = [
    { value: 1, label: 'Từ A-Z' },
    { value: 0, label: 'Từ Z-A' },
]

export const dataTypeGroup = [
    { value: 0, label: 'Nhóm riêng tư' },
    { value: 1, label: 'Nhóm có thể chia sẻ' },
]
export const statusVoucher = [
    {
        label: 'Đang hoạt động',
        Value: 1,
    },
    {
        label: 'Đã kết thúc',
        Value: 0,
    },
    {
        label: 'Sắp diễn ra',
        Value: 2,
    },
]

export const typeReduced = [
    {
        label: 'Số tiền',
        Value: 0,
    },
    {
        label: 'Phần trăm',
        Value: 1,
    },

]
export const typeVoucher = [
    {
        label: 'Công khai',
        Value: 0,
    },
    {
        label: 'Cho nhóm',
        Value: 1,
    },
    {
        label: 'Cá nhân',
        Value: 2,
    },
]

export const DataSendingForm = [
    { value: 0, label: 'Ưu tiên gửi theo kênh' },
    { value: 1, label: 'Đồng thời gửi nhiều kênh' },
]

export const DataChannel = [
    { value: 1, label: 'SMS' },
    { value: 2, label: 'ZNS' },
    { value: 3, label: 'Email' },
]

export const StatusSendCampaign = [
    { value: 0, label: 'Đang gửi' },
    { value: 1, label: 'Đã gửi' },
    { value: 2, label: 'Chờ gửi' },
]

export const DataStatusButton = [
    { value: 0, label: 'Chưa kích hoạt' },
    { value: 1, label: 'Đang sử dụng' },
]
