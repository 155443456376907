import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import InterestedVehicle from './InterestedVehicle'
import { CarCare } from "../../api/customer/customerApi";


interface Props {
  listCarCare?: CarCare[];
}
const InterestedVehicleList: React.FC<Props> = ({
  listCarCare
}) => {
  const settings = {
    arrows: false,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1
  }

  return (
    <Slider
      {...settings}
      className="rs-slick dots-outer"
    >
      {/* {
        [1, 2, 3, 4].map((index) => (
          <InterestedVehicle key={index} />
        ))
      } */}

      {
        listCarCare && listCarCare.map((item, i) =>
          <InterestedVehicle
            key={i}
            infoCarCare={item}
          />
        )
      }
    </Slider>
  )
}

export default InterestedVehicleList