// import React, { useRef } from "react";
import { BellOutlined, DeleteOutlined, EditOutlined, InfoOutlined, ShoppingCartOutlined, TagOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import * as TemplateApi from '../../../api/marketing/templateApi';
import { URL_TEMPLATE } from '../../../env';
// import EmptyComponentLinkText from '../../../components/EmptyComponent/EmptyComponentLinkText';
// const listNotificationZNS = [
//   {
//     id: 1,
//     title: 'Tích luỹ điểm thưởng',
//     typeTagDefault: 'ZNS01',
//     typeTag: 'Khuyến mãi',
//     typestatus: 'Chờ quản lý duyệt',
//     img: '/images/exportImg/img-zns.svg'
//   },
//   {
//     id: 2,
//     title: 'Lấy lại mật khẩu',
//     typeTagDefault: 'ZNS02',
//     typeTag: 'Thông báo',
//     typestatus: 'Chờ quản lý duyệt',
//     img: '/images/exportImg/img-zns-2.svg'
//   },
//   {
//     id: 3,
//     title: 'Báo trạng thái đơn hàng',
//     typeTagDefault: 'ZNS03',
//     typeTag: 'Thông báo',
//     typestatus: 'Chờ quản lý duyệt',
//     img: '/images/exportImg/img-zns-3.svg'
//   },
//   {
//     id: 4,
//     title: 'Báo trạng thái đơn hàng',
//     typeTagDefault: 'ZNS03',
//     typeTag: 'Thông báo',
//     typestatus: 'Chờ quản lý duyệt',
//     img: '/images/exportImg/img-zns-3.svg'
//   },
//   {
//     id: 5,
//     title: 'Chương trình khuyến mãi mới',
//     typeTagDefault: 'ZNS04',
//     typeTag: 'Thông báo',
//     typestatus: 'Chờ quản lý duyệt',
//     img: '/images/exportImg/img-zns.svg'
//   },
//   {
//     id: 6,
//     title: 'Báo phí dịch vụ',
//     typeTagDefault: 'ZNS05',
//     typeTag: 'Tiếp thị',
//     typestatus: 'Chờ quản lý duyệt',
//     img: '/images/exportImg/img-zns-3.svg'
//   },
//   {
//     id: 7,
//     title: 'Báo phí dịch vụ',
//     typeTagDefault: 'ZNS05',
//     typeTag: 'Tiếp thị',
//     typestatus: 'Chờ quản lý duyệt',
//     img: '/images/exportImg/img-zns-3.svg'
//   },
//   {
//     id: 8,
//     title: 'Rating',
//     typeTagDefault: 'ZNS06',
//     typeTag: 'CSKH',
//     typestatus: 'Chờ quản lý duyệt',
//     img: '/images/exportImg/img-zns-4.svg'
//   }
// ]
interface Props {
  dataTemplate?: TemplateApi.INotificationTemplate[]
}
const ZNS: React.FC<Props> = (dataTemplate) => {
  const navigate = useNavigate()
  const dataOption = [{ value: 1, title: 'Khuyến mãi' }, { value: 2, title: 'Thông báo' }, { value: 3, title: 'Tiếp thị' }, { value: 4, title: 'CSKH' }]
  const dataOptionStatus = [{ value: 0, title: 'Từ chối' }, { value: 1, title: 'Đã duyệt' }, { value: 2, title: 'Chờ duyệt' }, { value: 3, title: 'Gửi mẫu' }]

  return (
    <div className='gap-4 fix-list-customer-group'>
      {/* <EmptyComponentLinkText
        content={"Bạn chưa có mẫu thông báo nào, thử"}
        link={'/'}
        namelink={'tạo mới ngay'}
      /> */}
      {
        dataTemplate && dataTemplate.dataTemplate?.map((items, index) => (
          <div key={index} className='rounded-2 border-F2F4F7 d-flex flex-column overflow-hidden'>
            <div className='w-283-px h-176-px'>
              <img src={URL_TEMPLATE + items.iamgeTypeZNS} alt="img-zns" className='w-full h-full' />
            </div>
            <div className='d-flex flex-column gap-2 p-6 bg-FCFCFD'>
              <div className='d-flex align-center gap-2'>
                <div className='h-30-px px-10-px rounded-2 px-2 border-ADC6FF bg-F0F5FF text-2F54EB text-xs font-regular d-flex align-center'>
                  {items.code}
                </div>
                <div
                  className={`h-30-px px-10-px rounded-2 px-2 text-xs font-regular d-flex align-center
                  ${(dataOption[items.targetId - 1].title === 'Khuyến mãi' && 'border-FA541C bg-FFF2E8 text-FA541C')
                    || (dataOption[items.targetId - 1].title === 'Thông báo' && 'border-40A9FF bg-E6F7FF text-1890FF')
                    || (dataOption[items.targetId - 1].title === 'Tiếp thị' && 'border-722ED1 bg-F9F0FF text-722ED1')
                    || (dataOption[items.targetId - 1].title === 'CSKH' && 'border-EB2F96 bg-FFF0F6 text-EB2F96')
                    }`}
                >
                  {dataOption[items.targetId - 1].title === 'Khuyến mãi' && (
                    <div className='d-flex align-center gap-2'>
                      <TagOutlined className='text-18-px text-FA541C' />
                      Khuyến mãi
                    </div>
                  )}
                  {dataOption[items.targetId - 1].title === 'Thông báo' && (
                    <div className='d-flex align-center gap-2'>
                      <BellOutlined className='text-18-px text-1890FF' />
                      Thông báo
                    </div>
                  )}
                  {dataOption[items.targetId - 1].title === 'Tiếp thị' && (
                    <div className='d-flex align-center gap-2'>
                      <ShoppingCartOutlined className='text-18-px text-722ED1' />
                      Tiếp thị
                    </div>
                  )}
                  {dataOption[items.targetId - 1].title === 'CSKH' && (
                    <div className='d-flex align-center gap-2'>
                      <img src="/images/exportImg/Icon-CSKH.svg" alt="CSKH" className='w-18-px h-18-px' />
                      CSKH
                    </div>
                  )}
                </div>

              </div>
              <p className='font-medium fw-500 text-344054 text-2-row'>{items.title}</p>
              <p className='d-flex align-center text-667085 text-sm font-regular gap-2'>
                <img src="/images/exportImg/dot-blue.svg" alt="dot" className='w-6-px h-6-px' />
                {dataOptionStatus[items.status].title}
              </p>
            </div>
            <div className='d-flex justify-end gap-3 pb-6 bg-FCFCFD pr-6'>
              <InfoOutlined className='text-base text-344054 p-2 rounded-2 shadow-btn-zns bg-white' />
              <EditOutlined
                className='text-base text-344054 p-2 rounded-2 shadow-btn-zns bg-white cursor-pointer'
                onClick={() => navigate('/marketing/notification-templates-management/editzns')}
              />
              <DeleteOutlined className='text-base text-F5222D p-2 rounded-2 shadow-btn-zns bg-white' />
            </div>
          </div>
        ))
      }

    </div>
  )
}

export default ZNS
