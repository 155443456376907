import { Checkbox, Drawer } from "antd"
import './style.scss'
interface DrawerProps {
  isOpen: boolean
  closeDrawer: () => void
}
const DrawerAddvance: React.FC<DrawerProps> = ({
  isOpen,
  closeDrawer
}) => {
  return (
    <Drawer
      title={<p className="font-medium fw-500 text-344054 py-3 px-6 bg-FCFCFD">Nâng cao</p>}
      placement="right"
      rootClassName="custom-drawer"
      closable={false}
      onClose={closeDrawer}
      open={isOpen}
    >
      <div className=" d-flex flex-column gap-18-px">
        <p className="font-medium fw-500 text-344054">Tùy chỉnh trường hiển thị trên bảng</p>
        <div className="d-flex flex-column gap-3 pl-3">
          <Checkbox className="size-check-box check-box-inner p-checkbox icon-check-box checked-active"><span className="text-sm font-regular fw-400 text-667085">Xuất xứ</span></Checkbox>
          <Checkbox className="size-check-box check-box-inner p-checkbox icon-check-box checked-active"><span className="text-sm font-regular fw-400 text-667085">Mã xe</span></Checkbox>
          <Checkbox className="size-check-box check-box-inner p-checkbox icon-check-box checked-active"><span className="text-sm font-regular fw-400 text-667085">Thương hiệu</span></Checkbox>
          <Checkbox className="size-check-box check-box-inner p-checkbox icon-check-box checked-active"><span className="text-sm font-regular fw-400 text-667085">Kiểu dáng</span></Checkbox>
          <Checkbox className="size-check-box check-box-inner p-checkbox icon-check-box checked-active"><span className="text-sm font-regular fw-400 text-667085">Phân khúc</span></Checkbox>
          <Checkbox className="size-check-box check-box-inner p-checkbox icon-check-box checked-active"><span className="text-sm font-regular fw-400 text-667085">Dòng xe</span></Checkbox>
          <Checkbox className="size-check-box check-box-inner p-checkbox icon-check-box checked-active"><span className="text-sm font-regular fw-400 text-667085">Phiên bản xe</span></Checkbox>
          <Checkbox className="size-check-box check-box-inner p-checkbox icon-check-box checked-active"><span className="text-sm font-regular fw-400 text-667085">Số chỗ ngồi</span></Checkbox>
          <Checkbox className="size-check-box check-box-inner p-checkbox icon-check-box checked-active"><span className="text-sm font-regular fw-400 text-667085">Màu xe</span></Checkbox>
          <Checkbox className="size-check-box check-box-inner p-checkbox icon-check-box checked-active"><span className="text-sm font-regular fw-400 text-667085">Nhiên liệu</span></Checkbox>
          <Checkbox className="size-check-box check-box-inner p-checkbox icon-check-box checked-active"><span className="text-sm font-regular fw-400 text-667085">Hệ thùng</span></Checkbox>
        </div>
      </div>
    </Drawer>
  )
}
export default DrawerAddvance
