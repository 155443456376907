import { Tag } from 'antd'
import React from 'react'
import './FeedbackItem.scss'
import { ICareCustomer } from '../../api/customerCare/customerCareApi'
import { ICON_SATISFACTION_LEVEL, ICON_SUPPORT_CHANEL } from '../../env'

interface Props {
  item: ICareCustomer

}

const FeedbackItem: React.FC<Props> = ({ item }) => {
  return (
    <div className="feedback-item">
      <div className="img">
        <img src={ICON_SATISFACTION_LEVEL + item.satisfactionLevel.icon} alt="" />
        <span className="neutral-gray-400">{item.satisfactionLevel.title}</span>
      </div>

      <div className="content">
        <div className="content__head">
          <span className="icon">
            <span className='anticon anticon-mail'>
              <img
                alt=''
                src={ICON_SUPPORT_CHANEL + item.iconSupportChanel}
              />
            </span>
          </span>
          <span className='title font-medium'>{item.title}</span>
        </div>
        <div className="content__body font-light"><i>“{item.description}”</i></div>
        <div className="content__foot">
          <Tag className={`rs-tag ${item.titletypeSupport === 'Hỗ trợ' ? 'sunset-orange' : 'success'}`}>{item.titletypeSupport}</Tag>
          <span className="neutral-gray-400">{item.timestamp}</span>
          <span className="primary-6">{`${item.admin.username} (${item.admin.code})`}</span>
        </div>
      </div>
    </div>
  )
}

export default FeedbackItem
