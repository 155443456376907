import React, { useEffect, useRef, useState } from "react";
import { Checkbox, GetProp, Radio, RadioChangeEvent } from "antd";
import * as RankApi from '../../../api/rank/rankApi';
import useToast from "../../../hook/useToast";

interface Props {
  className?: string;
  onClose?: () => void;
  setRankId: (value: number | undefined) => void
  rankId: number | undefined
  dataRank: RankApi.IRank[]
}

const SelectFilterTypeRank: React.FC<Props> = ({ className, rankId, onClose, setRankId, dataRank }) => {
  // const popupRef = useRef<HTMLDivElement>(null);

  const pushToast = useToast();

  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(e.target as Node)) {
        if (onClose) {
          onClose();
        }
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [onClose]);
  const onChange = (e: RadioChangeEvent) => {
    setRankId(e.target.value);
  };



  return (
    <div className='list-filter active' ref={popupRef}>
      <div className="list-add-filter-content">
        <Radio.Group value={rankId} onChange={onChange}>
          {dataRank.map((item) => (
            <Radio key={item.id} className="one-add-filter-content" value={item.id}>{item.title}</Radio>
          ))}
        </Radio.Group>
      </div>
    </div>
  )
}

export default SelectFilterTypeRank
