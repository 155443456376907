
import { IconType } from 'antd/es/notification/interface';
import { notification } from 'antd';

const useToast = () => {
    const pushToast = (message: string = "", icon: IconType, duration: number = 2500) => {
        notification.open({
            message: message,
            type: icon,
            duration: duration / 1000,
            placement: 'bottomRight',
            className: 'single-message',
            closeIcon: true
        });
    }
    return pushToast;
}
export default useToast;
