import { paserParams } from "../../until";
import authuClient from "../authuClient";

export interface ILogin {
    status: boolean
    message: string
    data: IUser
    token: string
}

export interface IUser {
    id: number
    username: string
    image: string
}

export const login = async (username: string, password: string) => {
    const path = "auth/login"
    const params = {
        username,
        password,
    }
    const result: ILogin = await authuClient.post(path,
        paserParams(params, undefined)
    );
    return result;
};
