import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons'
import React, { ReactNode } from 'react'
import './ARCCStatisticalCard.scss'

interface Props {
  className?: string;
  title: string,
  icon: ReactNode,
  items?: Array<any>
}

const numberFormat = (number: any) => {
  let result
  if (typeof number === 'number') {
    result = new Intl.NumberFormat().format(number)
  } else {
    result = number
  }
  return result
}

const ARCCStatisticalCard: React.FC<Props> = (props) => {
  const {className, title, icon, items} = props

  return (
    <div className={`arcc-statistical-card ${className}`}>
      <div className="title">
        <span>{ title }</span>
        { icon }
      </div>
      <div className="items">
        {
          items?.map((item, index) => (
            <div
              key={index}
              className="item"
            >
              <span>{ item?.title }</span>
              <div className="number">{ numberFormat(item?.number) }</div>
              <div className="percent">
                <span>{ item?.type === 'increase' ? 'Tăng' : 'Giảm'}&nbsp;</span>
                <span>{ item?.percent }%</span>
                {
                  item?.type === 'increase'
                    ? <CaretUpOutlined />
                    : <CaretDownOutlined />
                }
              </div>
            </div>
          ))
        }
      </div>
      <img src="/images/brand-icon--blue-24p.svg" alt="" className="bg-img" />
    </div>
  )
}

export default ARCCStatisticalCard