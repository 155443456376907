import React, { useEffect, useState } from 'react';
import Layout from '../../Layout';
import {
  Breadcrumb,
  Flex,
  Progress,
  Steps
} from 'antd';
import './PoolingData.scss'
import { Link } from 'react-router-dom';
import Step1 from './ComponentPoolingData/Step1';
import Step2 from './ComponentPoolingData/Step2';
import Lottie from 'react-lottie';
import SearchLoading from '../../assets/json/search-loading.json'
import Step3 from './ComponentPoolingData/Step3';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: SearchLoading,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const PoolingDataDetail: React.FC = () => {

  const [isLoadShow, setLoadShow] = useState(false);
  const [isLoadPooling, setLoadPooling] = useState(false);

  const setActiveStep = () => {
    setLoadShow(true)
    setTimeout(() => {
      setLoadShow(false)
    }, 2000)
    setCurrent(1)
  };

  const setGoBack = () => {
    setCurrent(0)
  };

  const setGoNext = () => {
    setLoadPooling(true)
    setTimeout(() => {
      setLoadPooling(false)
    }, 2000)
    setCurrent(2)
  };

  useEffect(() => {
    document.title = 'THACO AUTO CRM';
  }, []);

  const [current, setCurrent] = useState(0);

  const onChange = (value: number) => {
    console.log('onChange:', value);
    setCurrent(value);
  };

  return (
    <div className='pooling-data-detail-page'>
      <Layout>
        <Breadcrumb
          items={[
            {
              title: 'THACO AUTO CRM',
            },
            {
              title: <a href="/">Khách hàng</a>,
            },
            {
              title: <a href="/">Gộp dữ liệu khách hàng</a>,
            },
            {
              title: 'Gộp dữ liệu thủ công',
            },
          ]}
        />

        <div className='title-page title-btn-back'>
          <Link to="/customer/pooling-data" className="icon-svg">
            <svg width="1em" height="1em" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.6564 7.80321H4.04305L10.3955 2.37463C10.4971 2.28713 10.4354 2.12463 10.3012 2.12463H8.69582C8.62508 2.12463 8.55796 2.14963 8.50536 2.19428L1.65045 8.04963C1.58767 8.10321 1.53732 8.16944 1.50281 8.24385C1.4683 8.31825 1.45044 8.39909 1.45044 8.48088C1.45044 8.56268 1.4683 8.64352 1.50281 8.71792C1.53732 8.79233 1.58767 8.85856 1.65045 8.91213L8.54526 14.8032C8.57247 14.8264 8.60512 14.8389 8.63959 14.8389H10.2993C10.4336 14.8389 10.4953 14.6746 10.3937 14.5889L4.04305 9.16035H14.6564C14.7363 9.16035 14.8016 9.09606 14.8016 9.01749V7.94606C14.8016 7.86749 14.7363 7.80321 14.6564 7.80321Z" fill="currentColor" fillOpacity="0.88" />
            </svg>
          </Link>
          Gộp dữ liệu thủ công
        </div>

        <div className="pooling-data-detail-content">
          <Steps
            type="navigation"
            size="small"
            current={current}
            onChange={onChange}
            className="site-navigation-steps t-step-pooling-detail"
            items={[
              {
                title: 'Chọn nguồn',
                status: 'finish',
                description: 'Nguồn dữ liệu',
              },
              {
                title: 'Kiểm tra',
                description: 'Xem trước dữ liệu',
              },
              {
                title: 'Xác nhận',
                description: 'Gộp dữ liệu & lưu',
              },
            ]}
          />

          {
            isLoadShow && (
              <div className="load-data-pooling-data">
                <div className="img-box">
                  <Lottie
                    options={defaultOptions}
                  />
                </div>
                <Flex gap="small" vertical>
                  <div className="one-progress">
                    <div className="text">Phân tích dữ liệu từ nguồn</div>
                    <Progress percent={100} />
                  </div>
                  <div className="one-progress">
                    <div className="text">Phân tích dữ liệu trùng lặp</div>
                    <Progress percent={40} />
                  </div>
                  <div className="one-progress">
                    <div className="text">Nạp dữ liệu vào bảng</div>
                    <Progress percent={40} />
                  </div>
                </Flex>
              </div>
            )
          }

          {
            isLoadPooling && (
              <div className="load-data-pooling-data">
                <div className="img-box">
                  <Lottie
                    options={defaultOptions}
                  />
                </div>
                <Flex gap="small" vertical>
                  <div className="one-progress">
                    <div className="text">Đang gộp dữ liệu</div>
                    <Progress percent={40} />
                  </div>
                </Flex>
              </div>
            )
          }

          {
            current === 0 && (
              <Step1
                onClose={() => { setActiveStep() }}
              />
            )
          }

          {
            current === 1 && isLoadShow === false && (
              <Step2
                goBack={() => { setGoBack() }}
                goNext={() => { setGoNext() }}
              />
            )
          }

          {
            current === 2 && isLoadPooling === false && (
              <Step3
                goBack={() => { setGoBack() }}
                goNext={() => { setGoNext() }}
              />
            )
          }

        </div>

      </Layout>
    </div>
  );
}

export default PoolingDataDetail;
