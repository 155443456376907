import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Form, Modal, Select, Switch } from "antd"
import { useForm } from "antd/es/form/Form";
import { FormInstance, FormProps } from "antd/lib";
import { useEffect, useRef, useState } from "react";
import * as selectOptionsApi from "../../../../../api/selectOptions/selectOptionsApi";
import useToast from "../../../../../hook/useToast";
import { fommatSelectOption } from "../../../../../until";
import useThrottle from "../../../../../hook/useThrottle";
import * as groupCustomerApis from "../../../../../api/groupCustomer/groupCustomerApi";
interface ModalProps {
    isOpen: boolean
    listCustomer: React.Key[]

    onSubmitFormModalAdd?: (iSsuccess: boolean, errorMessage?: string) => void;
    openAddGroupCustomer?: () => void
    closeModal: () => void
}
const ModalGroupCustomerList: React.FC<ModalProps> = ({
    isOpen, listCustomer,
    onSubmitFormModalAdd, openAddGroupCustomer, closeModal
}) => {
    const pushToast = useToast();
    const [form] = useForm();
    const inputRef = useRef<FormInstance>(null);


    const [sLGroup, setSLGroup] = useState<selectOptionsApi.IGroup[]>([])

    const gSLGroup = async () => {
        const result = await selectOptionsApi.getListGroup()
        if (result?.status) {
            setSLGroup(result.data)
        } else {
            pushToast(result?.message, "warning")
        }
    }

    const submitForm = () => {
        if (inputRef.current) {
            inputRef.current.submit();
        }
    };

    const onFinish: FormProps["onFinish"] = useThrottle(async (values: any) => {
        const data = listCustomer.map(item => Number(item))
        const result = await groupCustomerApis.addCustomer(values.idGroup, data)
        if (result?.status) {
            onSubmitFormModalAdd?.(true, result.message)
        } else {
            onSubmitFormModalAdd?.(false, result.message)
        }

    }, 1000)

    useEffect(() => {
        if (isOpen) {
            if (!sLGroup.length)
                gSLGroup()
        } else {
            form.setFieldValue('idGroup', null)
        }

    }, [isOpen])
    return (
        <Form
            form={form}
            ref={inputRef}
            onFinish={onFinish}
            initialValues={{ remember: true }}
        >
            <Modal
                title="Nhóm khách hàng"
                open={isOpen}
                centered
                // confirmLoading={confirmLoading}
                footer={[
                    <span key="cancel">&nbsp;</span>,
                    <Button
                        key="submit"
                        type="primary"
                        // loading={confirmLoading}
                        onClick={submitForm}
                        className="rs-button"
                    >
                        Lưu
                    </Button>,
                ]}
                className="rs-modal w-400"
                onCancel={closeModal}
            >
                <div className="flex flex-column gap-18px">
                    <span className="font-bold">Thêm vào nhóm có sẵn</span>
                    <Form.Item
                        name="idGroup"
                        rules={[{ required: true, message: 'Chọn nhóm khách hàng' }]}
                        className="m-none-form-item text-explan"
                    >
                        <Select
                            placeholder="Chọn nhóm khách hàng"
                            className='rs-select'
                            popupClassName='rs-select-dropdown'
                            options={fommatSelectOption(sLGroup)}
                        />
                    </Form.Item>


                    <div
                        className="link-block cursor-pointer flex gap-10px align-center primary-6"
                        onClick={openAddGroupCustomer}
                    >
                        <div className="icon-svg"><PlusCircleOutlined /></div>
                        <div className="title">Hoặc tạo mới nhóm khách hàng</div>
                    </div>

                    {/* <div className="t-switch-block flex justify-between align-center gap-10px">
                        <div className="title">Tự động thêm khách hàng có cùng điều kiện</div>
                        <Switch
                            defaultChecked
                        // onChange={onChangeSW} 
                        />
                    </div> */}
                </div>
            </Modal>
        </Form>

    )
}
export default ModalGroupCustomerList