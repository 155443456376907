import { ArrowLeftOutlined, CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Breadcrumb, Checkbox, Input, Select, Table, TableColumnsType } from "antd"
import { Link } from "react-router-dom"
import Layout from "../../../../Layout"
interface DataType {
  key: React.Key;
  stt: string;
  name: string;
  level: string;
  children?: DataType[];
}
interface DataType2 {
  key: React.Key;
  stt: string;
  nationWide: string;
  region: string;
  major: string;
  units: string;
  department: string;
  nameAccount: string;
  name: string
}
const data: DataType[] = [
  {
    key: 1,
    stt: '1',
    level: '1',
    name: 'Thống kê',
  },

  {
    key: 2,
    stt: '2',
    level: '1',
    name: 'Quản trị danh mục',
    children: [
      {
        key: 21,
        name: 'Danh mục đơn vị',
        level: '7',
        stt: '3',
        children: [
          {
            key: 211,
            level: '7',
            name: 'Toàn quốc ',
            stt: '4',
          },
          {
            key: 212,
            level: '7',
            name: 'Vùng miền ',
            stt: '5',
          },
          {
            key: 213,
            level: '7',
            name: 'Đơn vị ',
            stt: '6',
          }
        ]
      }
    ]
  },
];
const data2: DataType2[] = [
  {
    stt: '1',
    key: 1,
    nationWide: 'Văn phòng điều hành THACO AUTO',
    region: 'Miền Nam',
    major: 'Nghiệp vụ quản trị',
    units: 'Cty TNHH ABC',
    department: 'Ban giám đốc',
    nameAccount: 'A.nguyen@email.com',
    name: 'Nguyễn Văn A'
  },
  {
    stt: '2',
    key: 2,
    nationWide: 'Văn phòng điều hành THACO AUTO',
    region: 'Miền Nam',
    major: 'Nghiệp vụ quản trị',
    units: 'Cty TNHH ABC',
    department: 'Ban giám đốc',
    nameAccount: 'A.nguyen@email.com',
    name: 'Nguyễn Văn A'
  },
  {
    stt: '3',
    key: 3,
    nationWide: 'Văn phòng điều hành THACO AUTO',
    region: 'Miền Nam',
    major: 'Nghiệp vụ quản trị',
    units: 'Cty TNHH ABC',
    department: 'Ban giám đốc',
    nameAccount: 'A.nguyen@email.com',
    name: 'Nguyễn Văn A'
  },
  {
    stt: '4',
    key: 4,
    nationWide: 'Văn phòng điều hành THACO AUTO',
    region: 'Miền Nam',
    major: 'Nghiệp vụ quản trị',
    units: 'Cty TNHH ABC',
    department: 'Ban giám đốc',
    nameAccount: 'A.nguyen@email.com',
    name: 'Nguyễn Văn A'
  },
  {
    stt: '5',
    key: 5,
    nationWide: 'Văn phòng điều hành THACO AUTO',
    region: 'Miền Nam',
    major: 'Nghiệp vụ quản trị',
    units: 'Cty TNHH ABC',
    department: 'Ban giám đốc',
    nameAccount: 'A.nguyen@email.com',
    name: 'Nguyễn Văn A'
  }
]
const InfoGroupRole: React.FC = () => {
  const columns: TableColumnsType<DataType> = [
    {
      title: 'STT',
      key: 'stt',
      dataIndex: 'stt',
      className: "w-71-px",
      align: 'center'
    },
    {
      title: 'Quyền',
      key: 'name',
      dataIndex: 'name',
      className: 'w-386-px ',
    },
    {
      title: 'Truy cập',
      render: () =>
        <Checkbox className="size-check-box size-check-box-inner p-checkbox icon-check-box" />,
      className: 'w-100-px',
      align: 'center',
      sorter: (a, b) => a.stt.length - b.stt.length
    },
    {
      title: 'Thêm mới',
      render: () =>
        <Checkbox className="size-check-box size-check-box-inner p-checkbox icon-check-box" />,
      className: 'w-108-px',
      align: 'center',
      sorter: (a, b) => a.stt.length - b.stt.length
    },
    {
      title: 'Sửa',
      render: () =>
        <Checkbox className="size-check-box size-check-box-inner p-checkbox icon-check-box" />,
      className: 'w-100-px',
      align: 'center',
      sorter: (a, b) => a.stt.length - b.stt.length
    },
    {
      title: 'Xoá',
      render: () =>
        <Checkbox className="size-check-box size-check-box-inner p-checkbox icon-check-box" />,
      className: 'w-100-px',
      align: 'center',
      sorter: (a, b) => a.stt.length - b.stt.length
    },
    {
      title: 'Tải lên',
      render: () =>
        <Checkbox className="size-check-box size-check-box-inner p-checkbox icon-check-box" />,
      className: 'w-100-px',
      align: 'center',
      sorter: (a, b) => a.stt.length - b.stt.length
    },
    {
      title: 'Tải về',
      render: () =>
        <Checkbox className="size-check-box size-check-box-inner p-checkbox icon-check-box" />,
      className: 'w-100-px',
      align: 'center',
      sorter: (a, b) => a.stt.length - b.stt.length
    },
    {
      title: 'Chia sẻ',
      render: () =>
        <Checkbox className="size-check-box size-check-box-inner p-checkbox icon-check-box" />,
      className: 'w-100-px',
      align: 'center',
      sorter: (a, b) => a.stt.length - b.stt.length
    },
  ];
  const columns2: TableColumnsType<DataType2> = [
    {
      title: 'STT',
      key: 'stt',
      dataIndex: 'stt',
      className: "w-74-px",
      align: 'center',
      sorter: (a, b) => a.stt.length - b.stt.length
    },
    {
      title: 'Toàn quốc',
      key: 'nationWide',
      dataIndex: 'nationWide',
      className: 'w-283-px ',
      sorter: (a, b) => a.nationWide.length - b.nationWide.length
    },
    {
      title: 'Vùng miền',
      key: 'region',
      dataIndex: 'region',
      className: 'w-112-px',
      sorter: (a, b) => a.region.length - b.region.length
    },
    {
      title: 'Khối/nghiệp vụ',
      key: 'major',
      dataIndex: 'major',
      className: 'w-156-px',
      align: 'center',
      sorter: (a, b) => a.major.length - b.major.length
    },
    {
      title: 'Đơn vị ',
      key: 'units',
      dataIndex: 'units',
      className: 'w-139-px',
      sorter: (a, b) => a.units.length - b.units.length
    },
    {
      title: 'Phòng ban',
      key: 'department',
      dataIndex: 'department',
      className: 'w-116-px',
      align: 'center',
      sorter: (a, b) => a.department.length - b.department.length
    },
    {
      title: 'Tên tài khoản',
      key: 'nameAccount',
      dataIndex: 'nameAccount',
      className: 'w-168-px',
    },
    {
      title: 'Họ và tên',
      key: 'name',
      dataIndex: 'name',
      className: 'w-128-px',
    },

  ];
  return (
    <Layout>
      <div className="app-breadcrumb-block">
        <Breadcrumb
          items={[
            { title: <Link to="/">THACO AUTO CRM</Link>, },
            { title: <Link to="/system-configuration/account-role">Cấu hình</Link>, },
            { title: <Link to="/system-configuration/account-role">Phân quyền</Link>, },
            { title: 'Thông tin nhóm', },
          ]}
        />
        <div className="d-flex justify-between">
          <div className="app-breadcrumb-title">
            <Link to='/system-configuration/account-role'><ArrowLeftOutlined /></Link>
            <div className="title">Thông tin nhóm </div>
          </div>
          <button className="h-8 px-4 border-none text-white bg-096DD9 rounded-6-px text-sm font-regular">
            Lưu thông tin
          </button>
        </div>

      </div>
      <div className=" w-full pb-18-px">
        <div className="d-flex gap-3 rounded-1   py-2 ">
          <div className='d-flex align-center gap-10-px w-half'>
            <p className='text-base fw-500 font-medium white-space-nowrap'>Tên nhóm</p>
            <Input
              placeholder="Ví dụ: Nhóm cấp cao"
              className="bg-FCFCFD border-F2F4F7 text-sm rounded-2  font-regular  input-custom  h-8"
            />
          </div>
          <div className='d-flex align-center gap-10-px w-half'>
            <p className='text-base fw-500 font-medium white-space-nowrap'>Mô tả ngắn</p>
            <Input
              placeholder="Ví dụ: Nhóm cấp cao"
              className="bg-FCFCFD border-F2F4F7 text-sm rounded-2  font-regular  input-custom  h-8"
            />
          </div>
        </div>
      </div>
      <div className="pb-18-px">
        <div className="t-table-block ">
          <Table
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
            columns={columns}
            dataSource={data}
            pagination={false}
            showSorterTooltip={false}
            className=" border-end-table  "
            expandIconColumnIndex={1}
            defaultExpandAllRows={true}
            defaultExpandedRowKeys={['key', 'stt', 'name']}
            expandable={{
              expandIcon: ({ expanded, onExpand, record }) =>
                record.children?.length ? (
                  expanded ? (
                    <CaretDownOutlined className={`pl-${record.level} pr-1 text-8E8E8E`} onClick={e => onExpand(record, e)} />
                  ) : (
                    <CaretRightOutlined className={`pl-${record.level} pr-1 text-8E8E8E`} onClick={e => onExpand(record, e)} />
                  )
                ) : (
                  <CaretDownOutlined className={`px-${record.level} `} style={{ opacity: 0 }} />
                ),
              defaultExpandAllRows: true
            }}
          />
        </div>
      </div>
      <div className="d-flex flex-column gap-18-px">
        <div className="d-flex gap-10-px">
          <div className='w-166-px'>
            <Select
              placeholder="Lọc theo đơn vị"
              options={[
                { value: 1, label: 'Cty TNHH ABC' },
                { value: 2, label: 'Cty TNHH DEF' },
              ]}
              className="custome-select-t "
              popupClassName='popup-select'
            />
          </div>
          <div className='w-166-px'>
            <Select
              placeholder="Khối/Nghiệp vụ"
              options={[
                { value: 1, label: 'Nghiệp vụ quản trị' },
                { value: 2, label: 'Nghiệp vụ thông tin' },
              ]}
              className="custome-select-t "
              popupClassName='popup-select'
            />
          </div>
          <div className="w-730-px">
            <Input
              placeholder="Tìm tài khoản để phân quyền"
              className="bg-FCFCFD border-F2F4F7 text-sm rounded-2  font-regular  input-custom  h-8"
            />
          </div>
          <button className="border-096DD9 text-096DD9 bg-white w-100-px text-center rounded-2 text-base fw-600 font-regular ">
            Tìm kiếm
          </button>
        </div>
        <div className="t-table-block ">
          <Table
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
            columns={columns2}
            dataSource={data2}
            pagination={false}
            showSorterTooltip={false}
            className="border-end-table w-fit-table zise-filter"
          />
        </div>
      </div>
    </Layout>
  )
}
export default InfoGroupRole
