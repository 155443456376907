import { Drawer } from "antd"
import './style.scss'
interface DrawerProps {
  isOpen: boolean
  closeDrawer: () => void
}
const DrawerInfoCar: React.FC<DrawerProps> = ({
  isOpen,
  closeDrawer
}) => {
  return (
    <Drawer
      title={<p className="font-medium fw-500 text-344054 py-3 px-6 bg-FCFCFD">Thông tin xe</p>}
      placement="right"
      closable={false}
      onClose={closeDrawer}
      open={isOpen}
      footer={<div className="py-2 px-6 bg-FCFCFD">
        <button onClick={closeDrawer} className="border-F2F4F7 bg-FCFCFD rounded-2 shadow-active h-8 w-full text-sm font-regular text-344054">Đóng</button>
      </div>}
      rootClassName="custom-drawer"
    >
      <div className=" d-flex flex-column gap-18-px">
        <div className="w-full h-214-px rounded-3 shadow-img">
          <img src='/images/exportImg/img_car_detail.svg' alt="img-car" className="w-full h-full object-fit-cover" />
        </div>
        <div className="d-flex flex-column gap-18-px px-4">
          <div className="d-flex justify-between align-center">
            <p className="text-sm font-regular  text-344054 ">Xuất xứ</p>
            <div className="d-flex  align-center gap-3" >
              <p className="text-sm font-regular  text-98A2B3 ">Việt Nam</p>
              <img src='/images/exportImg/Flags.svg' alt="" className="w-21-px h-15-px rounded-3-px" />
            </div>
          </div>
          <div className="d-flex justify-between align-center">
            <p className="text-sm font-regular  text-344054 ">Mã xe</p>
            <p className="text-sm font-regular  text-98A2B3 ">B001</p>
          </div>
          <div className="d-flex justify-between align-center">
            <p className="text-sm font-regular  text-344054 ">Thương hiệu</p>
            <div className="d-flex  align-center gap-3" >
              <p className="text-sm font-regular  text-98A2B3 ">BMW</p>
              <img src='/images/exportImg/BMW.svg' alt="" className="w-6 h-6" />
            </div>
          </div>
          <div className="d-flex justify-between align-center">
            <p className="text-sm font-regular  text-344054 ">Phân khúc</p>
            <p className="text-sm font-regular  text-98A2B3 ">Xe cỡ nhỏ</p>
          </div>
          <div className="d-flex justify-between align-center">
            <p className="text-sm font-regular  text-344054 ">Dòng xe</p>
            <p className="text-sm font-regular  text-98A2B3 ">Hatchback</p>
          </div>
          <div className="d-flex justify-between align-center">
            <p className="text-sm font-regular  text-344054 ">Phiên bản xe</p>
            <p className="text-sm font-regular  text-98A2B3 ">xDrive</p>
          </div>
          <div className="d-flex justify-between align-center">
            <p className="text-sm font-regular  text-344054 ">Số chỗ</p>
            <p className="text-sm font-regular  text-98A2B3 ">4</p>
          </div>
          <div className="d-flex justify-between align-center">
            <p className="text-sm font-regular  text-344054 ">Màu xe</p>
            <p className="text-sm font-regular  text-98A2B3 ">Xanh Đen</p>
          </div>
          <div className="d-flex justify-between align-center">
            <p className="text-sm font-regular  text-344054 ">Nhiên liệu</p>
            <p className="text-sm font-regular  text-98A2B3 ">Xăng</p>
          </div>
          <div className="d-flex justify-between align-center">
            <p className="text-sm font-regular  text-344054 ">Hệ thùng</p>
            <p className="text-sm font-regular  text-98A2B3 ">-</p>
          </div>
        </div>
      </div>
    </Drawer>
  )
}
export default DrawerInfoCar
