import React from "react";
import { Tag } from "antd";
import './VehicleItem.scss'
import { CarCare } from "../../api/customer/customerApi";
import { carDefault, logoCarDefault } from "../ExportImgFigma";
import { URL_CAR, URL_CAR_CATEGORY } from "../../env";

interface Props {
  infoCarCare?: CarCare;
}
const InterestedVehicle: React.FC<Props> = ({
  infoCarCare
}) => {
  return (
    <div className="vehicle-item vehicle-item--interested">
      <div className="vehicle-item__info">
        <img
          src={URL_CAR + infoCarCare?.imageCar}
          alt="BMW-Series-7"
          className="img"
          onError={(e) => {
            e.currentTarget.src = carDefault;
          }}
        />
        <div className="content">
          <div className="top">
            <img
              src={URL_CAR_CATEGORY + infoCarCare?.imageCategoryCar}
              alt="BMW" className="logo"
              onError={(e) => {
                e.currentTarget.src = logoCarDefault;
              }}
            />
            <div className="item">{infoCarCare?.car}</div>
            <div className="item">{`Phiên bản ${infoCarCare?.version}`}</div>
          </div>
          <div className="bottom">
            <Tag className='rs-tag neutral-gray'>{`${infoCarCare?.carLine} - ${infoCarCare?.segmentsVehicle} Chỗ`}</Tag>
          </div>
        </div>
      </div>
      <img src="/images/brand-icon--purple.svg" alt="THACO AUTO" className="vehicle-item__brand-icon" />
    </div>
  )
}

export default InterestedVehicle