import { PlusCircleOutlined } from '@ant-design/icons';
import { ConfigProvider, DatePicker, Pagination, PaginationProps, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Layout from '../../../Layout';
import ModalUpdateInfoGroup from './Component/Modal/ModalUpdateInfoGroup';
import './style.scss'
import BreadcrumbBlock from '../../../components/BreadcrumbBlock/BreadcrumbBlock';
import useToast from '../../../hook/useToast';
import { dataTypeGroup } from '../../../services/data';
import ItemsGroup from './Component/Content/ItemsGroup';
import viVN from 'antd/locale/vi_VN';
import { dataPagination } from '../../../until';
import *as  groupCustomerApi from '../../../api/groupCustomer/groupCustomerApi';
import useDebounce from '../../../hook/useDebounce';
import { IStaff, getCLStaff } from '../../../api/selectOption/selectOptionsApi';
import ModalDeleteGroup from './Component/Modal/ModalDeleteGroupV2';
const { RangePicker } = DatePicker;

export const arrayGroup = [
  {
    id: '1',
    title: 'KH lớn tuổi',
    listJob: [{
      id: '1',
      titleJob: 'Quảng cáo'
    },
    {
      id: '2',
      titleJob: 'Chăm sóc'
    }],
    content: 'Tập khách hàng từ 50 tuổi',
    number: '10.000',
    shareWith: 'Chỉ mình tôi',
    dateCrerate: '08/08/2023',
    peopleCreate: 'Nguyễn Hoàng'
  },
  {
    id: '2',
    title: 'KH nữ',
    listJob: [{
      id: '1',
      titleJob: 'Quảng cáo'
    },
    {
      id: '2',
      titleJob: 'Chăm sóc'
    }],
    content: 'Tập khách hàng giới tính nữ',
    number: '10.000',
    shareWith: 'Chỉ mình tôi',
    dateCrerate: '08/08/2023',
    peopleCreate: 'Nguyễn Hoàng'
  },
  {
    id: '3',
    title: 'KH nam',
    listJob: [{
      id: '1',
      titleJob: 'Quảng cáo'
    },
    {
      id: '2',
      titleJob: 'Chăm sóc'
    }],
    content: 'Tập khách hàng giới tính nữ',
    number: '10.000',
    shareWith: 'Chỉ mình tôi',
    dateCrerate: '08/08/2023',
    peopleCreate: 'Nguyễn Hoàng'
  },
  {
    id: '4',
    title: 'KH BMW',
    listJob: [{
      id: '1',
      titleJob: 'Quảng cáo'
    },
    {
      id: '2',
      titleJob: 'Chăm sóc'
    }],
    content: 'Tập khách hàng từ 50 tuổi',
    number: '10.000',
    shareWith: 'Chỉ mình tôi',
    dateCrerate: '08/08/2023',
    peopleCreate: 'Nguyễn Hoàng'
  }

]


const CustomerGroup: React.FC = () => {

  useEffect(() => {
    document.title = 'THACO AUTO CRM';
    gSLStaff()
    return () => {
      setDataFilter(undefined)

      setPagination({ current: 1, pageSize: 12, total: 0 })

      setListGroup([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const pushToast = useToast();
  const navigate = useNavigate()

  const [openModalCreateGroup, setOpenModalCreateGroup] = useState(false)
  const [isOpenModalDeleteGroup, setIsOpenModalDeleteGroup] = useState(false)

  const [dataFilter, setDataFilter] = useState<groupCustomerApi.IFilter>()

  const [pagination, setPagination] = useState({ current: 1, pageSize: 12, total: 0 });

  const [sLStaff, setSLStaff] = useState<IStaff[]>([])

  const [idGroup, setIdGroup] = useState<number>()

  const [listGroup, setListGroup] = useState<groupCustomerApi.IGroup[]>([])

  const gSLStaff = async () => {
    const result = await getCLStaff()
    if (result?.status) {
      setSLStaff(result.data)
    } else {
      pushToast(result?.message, "warning")
    }
  }


  const gListGroup = useDebounce(async (pageCurent: number = 1, pageSize: number = 12) => {

    const dateStart = dataFilter?.date ? dataFilter?.date[0] : undefined
    const dateEnd = dataFilter?.date ? dataFilter?.date[1] : undefined

    const result = await groupCustomerApi.getListGroup(pageSize, pageCurent, dataFilter?.idCreator, dataFilter?.share, dateStart, dateEnd)
    if (result?.status) {
      setListGroup(result.data)
      setPagination({ current: pageCurent, total: result.total, pageSize })
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const onPaginationChange: PaginationProps['onChange'] = (pageNumber, pageSize) => {
    gListGroup(pageNumber, pageSize)
  }

  const onSubmitFormModalAdd = (condition: boolean, iSsuccess: boolean, errorMessage?: string, id?: number) => {
    if (iSsuccess) {
      gListGroup()
      setOpenModalCreateGroup(false);
      pushToast("Thêm nhóm khách hàng thành công", "success")

      if (condition) {
        navigate('/customer/create-group', { state: { id: id } })
      }
    }
    else {
      pushToast(errorMessage, "warning")
    }
  }

  const openModalDeleteGroup = (id: number) => {
    setIdGroup(id)
    setIsOpenModalDeleteGroup(true)
  }

  const closeModalDeleteGroup = () => {
    setIdGroup(undefined)
    setIsOpenModalDeleteGroup(false)
  }

  const onConfirmDelete = async () => {
    const result = await groupCustomerApi.deleteGroup(Number(idGroup))
    if (result?.status) {
      pushToast("Xóa nhóm khách hàng thành công", "success")
      closeModalDeleteGroup()
      gListGroup()
    } else {
      pushToast(result?.message, "warning")
    }
  }

  const onDateChange = (date: any, dateString: string[], info: any) => {
    const isNotEmpty = dateString.some(item => item !== "");
    if (isNotEmpty) {
      setDataFilter({ ...dataFilter, date: dateString })
    } else {
      setDataFilter({ ...dataFilter, date: ["", ""] })
    }
  }

  const fommatSelectOption = (arr: IStaff[]) => {
    const newData = arr?.map(item => ({
      value: item.id,
      label: item.username
    }));
    return newData
  }

  useEffect(() => {
    gListGroup()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFilter])
  return (
    <Layout>
      <ModalUpdateInfoGroup
        isOpen={openModalCreateGroup}
        closeModal={() => setOpenModalCreateGroup(!openModalCreateGroup)}
        onSubmitFormModalAdd={onSubmitFormModalAdd}

      />
      <ModalDeleteGroup
        isOpen={isOpenModalDeleteGroup}
        onConfirm={onConfirmDelete}
        closeModal={closeModalDeleteGroup}
      />

      <BreadcrumbBlock
        items={[
          { title: <Link to="/">THACO AUTO CRM</Link>, },
          { title: <Link to="/customer/list">Khách hàng</Link>, },
          { title: 'Quản lý nhóm khách hàng', },
        ]}
        title='Quản lý nhóm khách hàng'
      />
      <div className="d-flex align-center justify-between pt-6 w-full">
        <div className="d-flex align-center gap-3 ">
          <span className="text-base font-base whitespace-nowrap font-medium">Danh sách nhóm</span>
          {/* {
            listGroup.length > 0 && ( */}
          <div className="d-flex gap-2">
            <div className="t-ctrl-date-picker-bg w-256-px">
              <RangePicker
                popupClassName="t-ctrl-date-picker"
                placeholder={["Từ ngày", "Đến ngày"]}
                onCalendarChange={onDateChange}
              />
            </div>
            <div className='w-107-px'>
              <Select
                placeholder="Người tạo"
                options={fommatSelectOption(sLStaff)}
                className="custome-select"
                popupClassName='popup-select'
                value={dataFilter?.idCreator}
                onChange={(e) => setDataFilter({ ...dataFilter, idCreator: Number(e) })}
              />
            </div>
            <div className='w-122-px'>
              <Select
                placeholder="Loại nhóm"
                options={dataTypeGroup}
                className="custome-select"
                popupClassName='popup-select'
                value={dataFilter?.share}
                onChange={(e) => setDataFilter({ ...dataFilter, share: Number(e) })}
              />
            </div>
          </div>
          {/* )
          } */}
        </div>
        <div className="d-flex align-center justify-end gap-3  ">
          <button
            className=" d-flex align-center justify-center rounded-6-px w-121-px h-8  gap-10-px  border-096DD9  bg-white "
            onClick={() => navigate('/customer/grouping')}
          >
            <PlusCircleOutlined className='text-096DD9 text-sm' />
            <span className='text-096DD9 text-sm font-regular'>Gộp nhóm</span>
          </button>
          <button
            onClick={() => setOpenModalCreateGroup(true)}

            className=" d-flex align-center justify-center rounded-6-px w-107-px h-8  gap-10-px  bg-096DD9 border-none">
            <PlusCircleOutlined className=' text-sm text-white' />
            <span className='text-white text-sm font-regular'>Tạo mới</span>
          </button>
        </div>
      </div>
      <div className='gap-14-px py-6 fix-list-customer-group'>
        {
          listGroup && listGroup.map((item, index) => (
            <ItemsGroup
              key={index}
              item={item}
              ctrDelete={openModalDeleteGroup}
            />
          ))
        }

      </div>
      {
        listGroup.length === 0 && (
          <div className='d-flex justify-center w-full pt-14 pb-4'>
            <div className='d-flex flex-column gap-2'>
              <img src='/images/exportImg/empty-image.svg' alt="emtyGroup" className='w-326-px h-200-px' />
              <p className='text-sm text-667085 font-regular'>Hiện chưa có nhóm khách hàng nào, thử
                <Link to='' className='text-1890FF text-underline'>tạo mới ngay</Link>
              </p>
            </div>
          </div>
        )
      }
      <ConfigProvider locale={viVN}>
        <div className="pagination-block">
          <Pagination
            showSizeChanger
            current={pagination.current}
            pageSize={pagination.pageSize}
            pageSizeOptions={['12', '48', '84', '120']}
            total={pagination.total}
            showTotal={(total) => `Tổng cộng ${dataPagination(pagination.total, pagination.pageSize, pagination.current).totalPage} trang`}
            className='rs-pagination'
            onChange={onPaginationChange}

          />
        </div>
      </ConfigProvider>
    </Layout>
  );
}

export default CustomerGroup 
