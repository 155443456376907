import React, { useEffect, useRef } from "react";
import { Checkbox, GetProp, Steps } from "antd";
import ItemNotificationShow from '../../../components/NotificationTemplatesManagement/ItemNotificationShow';

import './styles.scss';
import { Link } from "react-router-dom";

interface Props {
  className?: string;
  onClose?: () => void;
  onShowHistory?: () => void;
}

const ShowNotification: React.FC<Props> = ({ className, onClose, onShowHistory }) => {
  const handleOnClose = () => {
    if (onClose) {
      onClose()
    }
  }

  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (e: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(e.target as Node)) {
        if (onClose) {
          onClose();
        }
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [onClose]);

  const onChange: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues: any) => {
    console.log('checked = ', checkedValues);
  };

  return (
    <div
      ref={popupRef}
      className="filter-setting show-noti"
    >
      <div className="title-add-filter">
        <span>Mẫu nội dung</span>
      </div>
      <div className="t-step-block">
        <Steps
          progressDot
          current={1}
          items={[
            {
              title: 'Gửi mẫu',
            },
            {
              title: 'Quản lý duyệt',
            },
            {
              title: 'Nhà mạng duyệt',
            },
          ]}
        />
      </div>
      <div className="content-filter-setting t-pl-24 t-scrollbar">
        <div className="title-content">Tóm tắt thông tin</div>
        <div className="inner-block">
          <ItemNotificationShow
            key={1}
            type={1}
            typestatus={'Chờ quản lý duyệt'}
            typecontent={'Tạo bởi NV Hoàng Kim Quốc (TC54847) lúc 10:36 01/04/2024'}
            title={'Chương trình khuyến mãi mới'}
            link={'/marketing/notification-templates-management/add'}
          />
        </div>

        <div className="title-content t-mt-18">Xem trước mẫu gửi đi</div>
        <div className="inner-block">
          <div className="inner-mod">
            <div className="img-block">
              <img src="/avatar-pr.svg" alt="" />
              <div className="title">THACO AUTO</div>
            </div>
            <div className="t-px-24">
              <div className="content-block">
                <span>THACO AUTO ra mat chuong trinh Sam Tet Cung Thaco Auto. Tim hieu ngay </span>
                <Link to="/">thacoauto.vn/campaign1</Link>
              </div>
              <div className="time">3:39PM</div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-filter-block">
        <div className="btn-block">
          <div className="btn btn-delete" onClick={handleOnClose}>
            <div className="text">
              Từ chối
            </div>
          </div>
          <div className="btn btn-primary" onClick={handleOnClose}>
            <div className="text">
              Duyệt mẫu
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShowNotification
