import './Step.scss'
interface Props {
  className?: string;
  goBack?: () => void;
  goNext?: () => void;
}

const Step3: React.FC<Props> = ({ className, goBack, goNext }) => {
  // const handleChange = (value: string) => {
  //   console.log(`selected ${value}`);
  // };

  const handleOnBack = () => {
    if (goBack) {
      goBack()
    }
  }

  const handleOnNext = () => {
    if (goNext) {
      goNext()
    }
  }

  return (
    <>
      {/* pooling-success */}
      <div className='step3-pooling-data'>
        <div className="load-data-pooling-data message-pooling-success">
          <div className="img-box">
            <img src="/images/pooling-success.svg" alt="success" />
          </div>
          <div className="title-block">
            <div className="title">Thành công</div>
            <div className="text">Đã gộp thành công và lưu dữ liệu vào danh sách khách hàng</div>
          </div>
        </div>
        <div className="btn-pooling-data-block">
          <div className="ctrl-btn-pooling-data ctrl-2btn">
            <div className="btn btn-df" onClick={handleOnBack}>Xem thông tin khách hàng</div>
            <div className="btn" onClick={handleOnNext}>Gộp thêm dữ liệu khác</div>
          </div>
        </div>
      </div>

      {/* pooling-fail */}
      {/* <div className='step3-pooling-data'>
        <div className="load-data-pooling-data message-pooling-success">
          <div className="img-box">
            <img src="/images/pooling-fail.svg" alt="fail" />
          </div>
          <div className="title-block t-mw-300">
            <div className="title">Thất bại</div>
            <div className="text">Gộp dữ liệu thất bại do lỗi không xác định</div>
          </div>
        </div>
        <div className="btn-pooling-data-block">
          <div className="ctrl-btn-pooling-data ctrl-2btn">
            <div className="btn btn-df" onClick={handleOnBack}>Bước trước</div>
            <div className="btn" onClick={handleOnNext}>Gộp lại</div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default Step3;
