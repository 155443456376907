import { EyeOutlined } from '@ant-design/icons';
import { Button, Switch, Table, TableColumnsType } from 'antd';
import React from 'react';
import './ManualCampaignTable.scss'
import { ConfigProvider, Pagination, PaginationProps } from 'antd/lib';
import { dataPagination } from '../../../until';
import viVN from 'antd/locale/vi_VN';
import { StatusSendCampaign } from '../../../services/data';
import useThrottle from '../../../hook/useThrottle';
import useToast from '../../../hook/useToast';
import { activeCampaign } from '../../../api/campaign/campaignApi';

interface Props {
  pagination: { current: number, pageSize: number, total: number }
  tableData: Array<any>
  gListCampaignManual: (pageCurent: number, pageSize: number) => void
  onShowDetail?: (id: number) => void
}

// TABLE
export interface DataTypeCampaignManual {
  key: React.Key;
  id: number;
  muc_tieu: string;
  ten_chien_dich: string;
  trang_thai: number;
  view: number;
  kenh_gui: {
    text: string,
    img: string
  }[];
  total: string;
  thoi_gian_gui: string;
  da_chi_tieu: number;
}

const currencyFormat = (number: any) => {
  try {
    number = parseInt(number);
    const formattedNumber = new Intl.NumberFormat('vi-VN', {
      style: 'currency',
      currency: 'VND',
    }).format(number);
    return formattedNumber;
  } catch (error) {
    return number;
  }
};

const numberFormat = (number: any) => {
  let result
  if (typeof number === 'number') {
    result = new Intl.NumberFormat().format(number)
  } else {
    result = number
  }
  return result
}

const ManualCampaignTable: React.FC<Props> = ({
  pagination, tableData,
  gListCampaignManual, onShowDetail
}) => {

  const pushToast = useToast();


  const columns: TableColumnsType<DataTypeCampaignManual> = [
    {
      title: 'Hành động',
      dataIndex: '',
      key: 'action',
      render: (text, record, index) => (
        <div className="d-flex align-center action">
          <Button
            className="rs-button rs-button--icon-only"
            onClick={() => onShowDetail?.(record.id)}
          ><EyeOutlined />
          </Button>
          <Switch
            className="rs-switch rs-switch--sm"
            checked={record.view ? true : false}
            onChange={() => onChangeActive(record.id)}
          />
        </div>
      ),
    },
    {
      title: 'Mục tiêu',
      dataIndex: 'muc_tieu',
      key: 'muc_tieu',
      sorter: true,
      render: (text) => (<div className='text-2-row'>{text}</div>)
    },
    {
      title: 'Tên chiến dịch',
      dataIndex: 'ten_chien_dich',
      key: 'ten_chien_dich',
      render: (text) => (<div className='text-2-row'>{text}</div>)
    },
    {
      title: 'Trạng thái',
      dataIndex: 'trang_thai',
      key: 'trang_thai',
      sorter: true,
      render: (text, record, index) => (
        // status: 0: 'Chờ gửi' | 1: 'Đang gửi' | 2: 'Đã gửi'
        <div className={`status ${record.trang_thai === 2 ? 'status-sent' : record.trang_thai === 1 ? 'status-sending' : 'status-pending'}`}>
          <span>{StatusSendCampaign[record.trang_thai].label}</span>
        </div>
      ),
    },
    {
      title: 'Kênh gửi',
      dataIndex: 'kenh_gui',
      key: 'kenh_gui',
      align: 'center',
      render: (text, record, index) => (
        <div className="d-flex align-center gap-1">
          {
            text.map((item: any) => (
              <img src={item.img} alt={item.text} className="channel-img" key={item.text} title={item.text} />
            ))
          }
        </div>
      )
    },
    {
      title: 'Khách hàng nhận',
      dataIndex: 'nhom_khach_hang_nhan',
      key: '',
      render: (text, record) => (
        <a href={"/marketing/campaign-sends-notifications/" + record.id} className="d-flex justify-between">
          <span>{text}</span>
          <span className="primary-7 cursor-pointer">Xem DS</span>
        </a>
      )
    },
    {
      title: 'Đã gửi',
      dataIndex: 'da_gui',
      sorter: true,
      align: 'right',
      render: (text, record, index) => (
        <>
          <span>{numberFormat(text)}</span><span className="neutral-gray-400">{record.total}</span>
        </>
      )
    },
    {
      title: 'Thời gian gửi',
      dataIndex: 'thoi_gian_gui',
      key: 'thoi_gian_gui',
      sorter: true,
    },
    {
      title: 'Đã chi tiêu',
      dataIndex: 'da_chi_tieu',
      key: 'da_chi_tieu',
      sorter: true,
      render: (value) => (
        <span>{currencyFormat(value)}</span>
      )
    },
  ];

  const onChangeActive = useThrottle(async (value: number) => {
    const result = await activeCampaign(value)
    if (result?.status) {
      gListCampaignManual(pagination.current, pagination.pageSize)
    } else {
      pushToast(result?.message, "warning")
    }
  }, 2000)

  const onPaginationChange: PaginationProps['onChange'] = (pageNumber, pageSize) => {
    gListCampaignManual(pageNumber, pageSize)
  }

  return (

    <>
      <div className="rs-table manual-campaign-table">
        <Table
          rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
          // rowSelection={rowSelection}
          columns={columns}
          dataSource={tableData}
          // onChange={onChangeTB}
          pagination={false}
          showSorterTooltip={false}
          className="w-fit-table border-end-table zise-filter"
          scroll={{ x: true }}
        />
        <ConfigProvider locale={viVN}>
          <div className="pagination-block">
            <Pagination
              showSizeChanger
              current={pagination.current}
              pageSize={pagination.pageSize}
              total={pagination.total}
              showTotal={(total) => `Tổng cộng ${dataPagination(pagination.total, pagination.pageSize, pagination.current).totalPage} trang`}
              className='rs-pagination'
              onChange={onPaginationChange}

            />
          </div>
        </ConfigProvider>
      </div>
    </>
    // <Table
    //   columns={columns}
    //   dataSource={tableData}
    //   showSorterTooltip={false}
    //   className="rs-table manual-campaign-table"
    //   loading={isLoading}
    //   pagination={{
    //     position: ['bottomCenter'],
    //     defaultPageSize: 15,
    //     className: 'rs-pagination',
    //     showQuickJumper: true,
    //     // hideOnSinglePage: true,
    //   }}
    // />
  )
}

export default ManualCampaignTable
