import axiosClient, { ResponseSuccess, ResponseSuccessTotal } from "../axiosClient";
export interface DataTypeBenefit {
    key: number
    stt: number
    title: string
    description: string
    status: number
}
export interface IBenefit {
    id: number
    title: string
    description: string
    status: number
}
export interface IBenefitRank {
    rank: RankBenefit;
    listBenefit: ListBenefit[];
}
export interface ListBenefit {
    id: number;
    title: string;
    description: string;
}
export interface RankBenefit {
    title: string;
    icon: string;
}

export interface IInfoBenefit {
    id: number;
    title: string;
    description: string;
}
export const infoBenefit = async (
    id: number
) => {
    const path = "rank/infoBenefit"
    const params = {
        id
    }
    const result: ResponseSuccess<IInfoBenefit> = await axiosClient.get(
        path, { params }
    );
    return result;
};

export const getListBenefitRank = async (
    id: number
) => {
    const path = "rank/listBenefitRank"
    const params = {
        id
    }
    const result: ResponseSuccessTotal<IBenefitRank> = await axiosClient.get(
        path, { params }
    );
    return result;
};

export const getListBenefit = async (
    limit?: number, pageNumber?: number, search?: string, sort?: number, type?: number
) => {
    const path = "rank/listBenefit"
    const params = {
        limit, pageNumber, search, sort, type
    }
    const result: ResponseSuccessTotal<IBenefit[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};
export const addBenefit = async (
    title: string, description: string
) => {
    const path = "rank/addBenefit"
    const params = {
        title, description
    }
    const result: ResponseSuccess<[]> = await axiosClient.post(
        path, params
    );
    return result;
};

export const updateActiveBenefit = async (
    id: number) => {
    const path = "rank/activeBenefit"
    const params = {
        id
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        path, params
    );
    return result;
};
export const editBenefit = async (
    id: number, title: string, description: string) => {
    const path = "rank/editBenefit"
    const params = {
        id, title, description
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        path, params
    );
    return result;
};
export const deleteBenefit = async (
    id: number
) => {
    const path = "rank/deleteBenefit"
    const params = {
        id
    }
    const result: ResponseSuccess<[]> = await axiosClient.delete(
        path, { params }
    );
    return result;
};