import { CloseOutlined, DeleteOutlined, SearchOutlined } from "@ant-design/icons"
import { Checkbox, Divider, Modal, notification, Select } from "antd"
import { useEffect, useState } from "react"
import './style.scss'
import useDebounce from "../../../../../hook/useDebounce"
import * as groupCustomerApi from "../../../../../api/groupCustomer/groupCustomerApi"
import useToast from "../../../../../hook/useToast"
import useThrottle from "../../../../../hook/useThrottle"
interface ModalProps {
  isOpen: boolean
  idGroup: number
  onSubmitFormModal?: (iSsuccess: boolean, errorMessage?: string) => void;
  closeModal: () => void

}
interface listTest {
  value: number
  label: string
  checked: boolean
}
const ModalAddCustomer: React.FC<ModalProps> = ({
  isOpen, idGroup,
  onSubmitFormModal, closeModal
}) => {

  const pushToast = useToast();


  const [openSelectAdd, setOpenSelectAdd] = useState(false)

  const [filterName, setFilterName] = useState<string>('')

  const [listCustomer, setListCustomer] = useState<groupCustomerApi.ICustomer[]>([])
  const [listCustomerSL, setListCustomerSL] = useState<groupCustomerApi.ICustomer[]>([]);
  const [listCustomerCF, setListCustomerCF] = useState<groupCustomerApi.ICustomer[]>([]);
  const [searchActive, setSearchActive] = useState('' as string)

  const gListCustomer = useDebounce(async (pageCurent: number = 1, pageSize: number = 10) => {
    const result = await groupCustomerApi.getListCustomer(pageSize, pageCurent, filterName)
    if (result?.status) {
      setListCustomer(result.data);
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const handleChangeSL = (infoCustomer: groupCustomerApi.ICustomer) => {
    const isIdExist = listCustomerSL.some(item => item.id === infoCustomer.id);

    if (isIdExist) {
      const updatedList = listCustomerSL.filter(item => item.id !== infoCustomer.id);
      setListCustomerSL(updatedList);
    } else {
      setListCustomerSL([...listCustomerSL, infoCustomer])
    }
  };

  const handleCustomerCF = (evnet: any) => {
    evnet.stopPropagation()
    setSearchActive('')
    setListCustomerCF(listCustomerSL)
    setOpenSelectAdd(false)
  };

  const handleDeleteCF = (id: number) => {
    const updatedList = listCustomerSL.filter(item => item.id !== id);
    setListCustomerSL(updatedList);
    setListCustomerCF(updatedList);

  }
  const handleDeleteAll = () => {
    setListCustomerSL([])
    setListCustomerCF([])
  }

  const handleChangeUpdate = useThrottle(async () => {
    const listCustomer = listCustomerCF.map(item => Number(item.id))
    const result = await groupCustomerApi.addCustomer(idGroup, listCustomer)
    if (result?.status) {
      onSubmitFormModal?.(true)
    } else {
      onSubmitFormModal?.(false, result.message)
    }
  }, 1000)

  const disabledAdd = listCustomerCF.length > 0

  useEffect(() => {
    if (isOpen) {
      gListCustomer()
    } else {
      handleDeleteAll()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, filterName])

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (event.target.matches('.custom-search-auto, .custom-search-auto *') === false) {
        setSearchActive('')
      } else {
        setSearchActive('active')
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <Modal
      title={<div className="bg-FCFCFD d-flex justify-betweeen align-center py-4 px-6 w-full border-b-F0F0F0">
        <span className="text-base text-344054 fw-500 font-medium">Thêm khách hàng</span>
        <CloseOutlined className="text-base text-8B8B8B" onClick={closeModal} />
      </div>}
      open={isOpen}
      onCancel={closeModal}
      centered
      className="w-modal modal-content-addCustomer ant-modal-size"
      closeIcon={false}
      footer={
        <div className={`${listCustomerCF.length !== 0 ? 'justify-between align-center' : 'justify-end'} bg-FCFCFD d-flex  py-2 px-6 w-full border-t-F0F0F0`}>
          <span
            className={`${listCustomerCF.length !== 0 ? 'text-sm text-F5222D font-regular cursor-pointer' : 'none'}`}
            onClick={handleDeleteAll}
          >
            Xóa toàn bộ
          </span>
          <button
            disabled={!disabledAdd}
            className={`${listCustomerCF.length !== 0 ? "bg-096DD9 text-white border-none" : 'text-BBBBBC bg-F9FAFB border-F2F4F7'} text-sm   rounded-2 h-8 px-15-px gap-10-px d-flex align-center`}
            onClick={handleChangeUpdate}
          >
            Thêm {listCustomerCF.length !== 0 ? (listCustomerCF.length) : ('')} khách hàng vào nhóm
          </button>
        </div>
      }
    >
      <div className="p-6 d-flex flex-column justify-center align-center gap-18-px">
        <div className="d-flex flex-column w-full gap-18-px">
          <div className="d-flex flex-column gap-1">
            <p className="text-sm  font-regular text-344054">Tìm kiếm khách hàng theo SĐT</p>
            <div className={`custom-search-auto ${searchActive}`}>
              <div className="input-search">
                <input
                  type="text"
                  className="custom-input-search-auto"
                  placeholder="Tìm kiếm khách hàng theo SĐT"
                  onChange={(e) => setFilterName(e.target.value)}
                />
                <SearchOutlined className="icon-search-auto" />
              </div>
              <div className="search-res">
                <div className="search-res-main">
                  <div className="search-res-list">
                    {
                      listCustomer.map((item, index) => (
                        <div key={index} className={`search-res-item ${listCustomerSL.some(items => items.id === item.id) ? 'active' : ''}`}>
                          <Checkbox
                            className="size-check-box size-check-box-inner p-checkbox icon-check-box"
                            checked={listCustomerSL.some(items => items.id === item.id)}
                            onClick={() => handleChangeSL(item)}
                          >
                            <span className="font-regular fw-400 text-344054">{`${item.name} (${item.phone})`}</span>
                          </Checkbox>
                          <span className="ant-select-item-option-state" unselectable="on" aria-hidden="true">
                            {listCustomerSL.some(items => items.id === item.id) &&
                              <span role="img" aria-label="check" className="anticon anticon-check">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                  <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path>
                                </svg>
                              </span>
                            }
                          </span>
                        </div>
                      ))
                    }
                  </div>
                  <div className="foot-search">
                    <div className="d-flex justify-between align-center px-3 py-2">
                      <span
                        className="text-096DD9 text-sm cursor-pointer font-regular"
                        onClick={handleDeleteAll}
                      >
                        Làm mới
                      </span>
                      <button
                        className="bg-096DD9 text-white text-sm rounded-1 border-none px-7-px h-22-px font-regular"
                        onClick={(e) => handleCustomerCF(e)}
                      >
                        Xác nhận
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <Select
            placeholder="Tìm kiếm khách hàng theo SĐT"
            mode="multiple"
            className="custom-select-mutiple-dv d-none-span  placehoder-667085 d-none-checkbox p-none-checkbox"
            open={openSelectAdd}
            popupClassName='popup-select '
            filterOption={false}
            value={listCustomerSL.map((item) => Number(item.id))}
            options={
              listCustomer.map((item) => ({
                value: Number(item.id),
                label: <Checkbox
                  className="size-check-box size-check-box-inner p-checkbox icon-check-box"
                  checked={listCustomerSL.some(items => items.id === item.id)}
                  onClick={() => handleChangeSL(item)}
                >
                  <span className="text-sm font-regular fw-400 text-344054">{`${item.name} (${item.phone})`}</span>
                </Checkbox>
              }))
            }
            onSearch={(e) => setFilterName(e)}
            suffixIcon={<SearchOutlined />}
            onDropdownVisibleChange={setOpenSelectAdd}
            dropdownRender={(menu) => (
              <div>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                <div className="d-flex justify-between align-center px-3 py-2">
                  <span
                    className="text-096DD9 text-sm cursor-pointer font-regular"
                    onClick={handleDeleteAll}
                  >
                    Làm mới
                  </span>
                  <button
                    className="bg-096DD9 text-white text-sm rounded-1 border-none px-7-px h-22-px font-regular"
                    onClick={handleCustomerCF}
                  >
                    Xác nhận
                  </button>
                </div>
              </div>
            )}

          /> */}
          <div className={`${listCustomerCF.length !== 0 ? 'd-flex flex-column gap-3 pl-3' : 'none'}`}>
            {
              listCustomerCF.map((item, index) =>
                <div
                  key={index}
                  className="d-flex align-center gap-1"
                >
                  <DeleteOutlined className="text-F5222D fix-size-icon-del cursor-pointer" onClick={() => handleDeleteCF(item.id)} />
                  <p className="text-sm text-344054 font-regular">{`${item.name} (${item.phone})`}</p>
                </div>

              )
            }

          </div>
        </div>
      </div>
    </Modal>
  )
}
export default ModalAddCustomer
