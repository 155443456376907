import axiosClient, { ResponseSuccess, ResponseSuccessTotal } from "../axiosClient";

export interface DataTypeObject {
    id: undefined | number,
    access: boolean,
    add: boolean,
    edit: boolean,
    delete: boolean,
    upload: boolean,
    download: boolean,
    share: boolean

}

export interface DataTypeAdmin {
    key: number
    id: number
    ho_ten: string
    ten_tai_khoan: string
    phong_ban: string
    khoi_nghiep_vu: string
    don_vi: string
    vung_mien: string
    toan_quoc: string
    nhom_quyen: string
}


export interface IDecentralization {
    id: number
    name: string
    access: boolean
    add: boolean
    edit: boolean
    delete: boolean
    upload: boolean
    download: boolean
    share: boolean
    children: Children[]
}

export interface Children {
    id: number
    name: string
    access: boolean
    add: boolean
    edit: boolean
    delete: boolean
    upload: boolean
    download: boolean
    share: boolean
}
export interface IAdmin {
    id: number
    username: string
    fullname: string
    businessUnit: string
    unit: string
    region: string
    department: string
    nationwide: string
    groupPermission: string
}

export const getListmenu = async () => {
    const path = "groupPermission/listmenu"
    const result: ResponseSuccess<IDecentralization[]> = await axiosClient.get(
        path, {}
    );
    return result;
};

export const getListAdmin = async (limit?: number, pageNumber?: number, unitId?: number, businessUnitId?: number, search?: string
) => {
    const path = "auth/allAdmin"
    const params = {
        limit, pageNumber, search, unitId, businessUnitId
    }
    const result: ResponseSuccessTotal<IAdmin[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};