import { ArrowLeftOutlined, BellOutlined, CheckCircleFilled, EyeOutlined, InboxOutlined, SaveOutlined, ShoppingCartOutlined, TagOutlined } from "@ant-design/icons"
import { Breadcrumb, Input, message, Upload, UploadProps } from "antd"
import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import Layout from "../../Layout"
import './NotificationEmailCreate.scss'
import Ckeditor from "./FroalaEditor"
const { Dragger } = Upload;
const props: UploadProps = {
  name: "file",
  multiple: false,
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
};
const NotificationEmailCreate: React.FC = () => {
  const [isActiveLabel, setActiveLabel] = useState(1);
  const [inputChange, setInputChange] = useState<String>("");
  const navigate = useNavigate()
  return (
    <Layout>
      <div className="app-breadcrumb-block">
        <Breadcrumb
          items={[
            { title: <Link to="/">THACO AUTO CRM</Link>, },
            { title: <Link to="/marketing/notification-templates-management">Marketing</Link>, },
            { title: <Link to="/marketing/notification-templates-management">Quản lý mẫu thông báo</Link>, },
            { title: 'Tạo nội dung mẫu Email', },
          ]}
        />
        <div className="d-flex align-baseline gap-3">
          <div className="app-breadcrumb-title">
            <Link to='/marketing/notification-templates-management'><ArrowLeftOutlined /></Link>
            <div className="title">Nhập mẫu mới </div>
          </div>
          <p className="text-sm font-regular text-98A2B3">
            Tạo nội dung mẫu Email
          </p>
        </div>
        <div className="d-flex justify-center">
          <div className="w-769-px d-flex flex-column gap-6">
            <div className="d-flex gap-6 pt-6">
              <div className="w-full">
                <Input
                  placeholder="Nhập tên mẫu, ví dụ: Mẫu chào sản phẩm mới"
                  className="bg-white border-E1E1E1 text-base rounded-2  font-regular  input-custom  h-10 "
                />
              </div>
              <button className="d-flex align-center justify-center bg-096DD9 rounded-2 gap-10-px h-10 border-1890FF shadow-btn-save px-15-px white-space-nowrap">
                <SaveOutlined className="text-sm text-white" />
                <p className="text-base font-regular text-white">Lưu mẫu </p>
              </button>
            </div>
            <div className="d-flex flex-column gap-4">
              <p className="text-base fw-600 font-bold text-344054">Mục tiêu</p>
              <div className="d-flex gap-3">
                <button
                  className={`d-flex justify-center align-center rounded-2 px-10-px h-30-px text-sm gap-2 ${isActiveLabel === 1 ? 'border-FA541C bg-FFF2E8 text-FA541C' : 'border-EAECF0 bg-white text-344054'} `}
                  onClick={() => setActiveLabel(1)}
                >
                  <TagOutlined className={`text-18-px${isActiveLabel === 1 ? ' text-FA541C' : ' text-344054'} `}
                  />
                  Khuyến mãi
                </button>
                <button
                  className={`d-flex justify-center align-center rounded-2 px-10-px h-30-px text-sm gap-2  ${isActiveLabel === 2 ? 'border-40A9FF bg-E6F7FF text-1890FF' : 'border-EAECF0 bg-white text-344054'}`}
                  onClick={() => setActiveLabel(2)}
                >
                  <BellOutlined className={`text-18-px ${isActiveLabel === 2 ? 'text-1890FF' : 'text-344054'} `} />
                  Thông báo
                </button>
                <button
                  className={`d-flex justify-center align-center rounded-2 px-10-px h-30-px text-sm gap-2 ${isActiveLabel === 3 ? 'border-722ED1 bg-F9F0FF text-722ED1' : 'border-EAECF0 bg-white text-344054'}`}
                  onClick={() => setActiveLabel(3)}
                >
                  <ShoppingCartOutlined className={`text-18-px ${isActiveLabel === 3 ? 'text-722ED1' : 'text-344054'} `} />
                  Tiếp thị
                </button>
                <button
                  className={`d-flex justify-center align-center rounded-2 px-10-px h-30-px text-sm gap-2 ${isActiveLabel === 4 ? 'border-EB2F96 bg-FFF0F6 text-EB2F96' : 'border-EAECF0 bg-white text-344054'}`}
                  onClick={() => setActiveLabel(4)}
                >
                  <img src={`${isActiveLabel === 4 ? '/images/exportImg/Icon-CSKH.svg' : '/images/exportImg/Icon-CSKH-2.svg'}`} alt="CSKH" className='w-18-px h-18-px' />
                  CSKH
                </button>
              </div>
            </div>
            <div className="d-flex flex-column gap-4">
              <p className="text-base fw-600 font-bold text-344054">Tiêu đề Email</p>
              <div className="w-full pr-5">
                <Input
                  placeholder="Nhập tiêu đề Email, ví dụ: Bất ngờ chưa? THACO AUTO đang có chương trình..."
                  className="bg-white border-E1E1E1 text-base rounded-2  font-regular  input-custom  h-10 "
                  onChange={(e) => setInputChange(e.target.value)}
                />
              </div>
              <div
                className={`${inputChange ? `w-full rounded-2 d-flex align-center h-10 gap-10-px px-10-px ${inputChange.length >= 56 ? ' bg-F6FEF9 border-039855 ' : 'border-FA541C bg-FFF0F6'}` : 'none'}`}
              >
                <CheckCircleFilled className={`text-sm ${inputChange.length >= 56 ? ' text-039855' : 'text-FA541C'}`} />
                <p className="text-sm font-regular text-344054">Độ dài tiêu đề {inputChange.length} ký tự {inputChange.length >= 56 ? "rất phù hợp" : "không phù hợp"}
                  <span className="text-sm font-regular text-98A2B3">(trong khoảng 50 - 60 ký tự)</span></p>
              </div>
            </div>
            <div className="pr-5">

              <Ckeditor />
            </div>
            <div className="d-flex flex-column gap-4 pr-5">
              <p className="text-base fw-600 font-bold text-344054">Hoặc tải lên mẫu Email</p>
              <Dragger {...props} className="custom-upload-ant">
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="text-sm text-344054 font-regular">
                  Click hoặc kéo file và thả vào vùng này
                </p>
                <div className="flex items-center justify-center">
                  <span className="text-sm font-regular text-98A2B3">
                    Chỉ được tải lên 01 file duy nhất<br />
                    <span className="text-sm font-regular text-1890FF">
                      (Yêu cầu định dạng html, vd: emailchaomung.html)
                    </span>
                  </span>
                </div>
              </Dragger>
            </div>
            <div className="pb-4 pt-3 d-flex justify-center">
              <button className="border-EAECF0 bg-F5F5F5 text-98A2B3 text-base fw-600 font-bold rounded-2 h-38-px d-flex align-center gap-10-px px-4" onClick={() => navigate('/marketing/notification-templates-management/previewemail')}>
                <EyeOutlined className="text-sm text-B8B8B8" />
                Xem thử nội dung gửi đi
              </button>
            </div>
          </div>
        </div>
      </div>

    </Layout>
  )
}
export default NotificationEmailCreate
