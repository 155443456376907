import { CloseOutlined, PlusOutlined } from "@ant-design/icons"
import { Input, Modal } from "antd"
import { ChangeEvent, useEffect, useState } from "react"
import './style.scss'
import { ICategoryCarEdit, editCategoryCar } from "../../../../../api/categoryCar/categoryCarApi"
import { URL_CAR_CATEGORY } from "../../../../../env"
import useThrottle from "../../../../../hook/useThrottle"
interface ModalProps {
  isOpen: boolean
  infoCategoryCar?: ICategoryCarEdit
  onSubmitFormModal?: (iSsuccess: boolean, errorMessage?: string) => void;
  closeModal: () => void
}
const ModalEditBrand: React.FC<ModalProps> = ({
  isOpen, infoCategoryCar,
  onSubmitFormModal, closeModal
}) => {
  let errorsObj = { title: "", uploadedFile: "", result: "" };
  const [errors, setErrors] = useState(errorsObj);

  const [title, setTitle] = useState("")
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const checkVariables = useThrottle(() => {
    let error = false;
    const errorObj = { ...errorsObj };
    if (!title) {
      errorObj.title = "Vui lòng nhập tên thương hiệu!"
      error = true;
    }
    if (!uploadedFile) {
      errorObj.uploadedFile = "Vui lòng chọn ảnh thương hiệu xe!"
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    } else {
      eCategoryCar()
    }
  }, 1000)

  const eCategoryCar = async () => {
    const result = await editCategoryCar(Number(infoCategoryCar?.id), title, uploadedFile)
    if (result?.status) {
      onSubmitFormModal?.(true)
    } else {
      onSubmitFormModal?.(false, result.message)
    }
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    setUploadedFile(file);
  };

  const handleUploadClick = (event: React.MouseEvent) => {
    event.preventDefault();
    const inputElement = document.querySelector('input[type="file"]') as HTMLInputElement | null;
    if (inputElement) {
      inputElement.click();
    }
  };

  const handleSetFileFromUrl = (url: string) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const file = new File([blob], 'image.png', { type: blob.type });
        setUploadedFile(file);
      });
  };

  useEffect(() => {
    if (isOpen) {
      setTitle(infoCategoryCar?.title ?? "")
      handleSetFileFromUrl(URL_CAR_CATEGORY + infoCategoryCar?.image)
    } else {
      setTitle("")
      setUploadedFile(null)

      setErrors(errorsObj)
    }

  }, [isOpen, infoCategoryCar])
  return (
    <Modal
      title={<div className="bg-FCFCFD d-flex justify-betweeen align-center py-4 px-6 w-full border-b-F0F0F0">
        <span className="text-base text-344054 fw-500 font-medium">Chỉnh sửa thương hiệu xe</span>
        <CloseOutlined className="text-base text-8B8B8B" onClick={closeModal} />
      </div>}
      open={isOpen}
      onCancel={closeModal}
      centered
      className="w-modal modal-content ant-modal-size"
      closeIcon={false}
      footer={<div className="bg-FCFCFD d-flex justify-betweeen align-center py-2 px-6 w-full border-t-F0F0F0">
        <span className="text-sm text-98A2B3 cursor-pointer" onClick={closeModal}>Huỷ</span>
        <button className="text-white text-sm bg-096DD9 border-none shadow-btn-save rounded-2 h-8 px-15-px"
          onClick={checkVariables}>
          Lưu chỉnh sửa
        </button>
      </div>}
    >
      <div className="p-6 d-flex flex-column gap-18-px">
        <div className="d-flex flex-column gap-1">
          <p className="text-sm  font-regular text-344054">Tên thương hiệu xe</p>
          <div className="d-flex flex-column">
            <div className="w-full border-F2F4F7 bg-FCFCFD rounded-2 pl-3  py-1 h-8  overflow-hidden">
              <Input
                placeholder="Tên thương hiệu xe"
                className="bg-FCFCFD text-sm border-none custom-input-focus none-focus-erro font-regular p-none-input  hover-none"
                maxLength={50}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            {
              errors.title &&
              <span className="text-F5222D text-base font-regular pl-2">{errors.title}</span>
            }
          </div>
        </div>

        <div className="d-flex flex-column">
          <div className="border-F2F4F7 rounded-2-px w-104-px h-104-px bg-F9FAFB d-flex align-center justify-center">
            {
              uploadedFile
                ? (
                  <div>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                    />
                    <img
                      alt="Uploaded"
                      className="w-64-px h-64-px rounded-100-px cursor-pointer"
                      src={URL.createObjectURL(uploadedFile)}
                      onClick={handleUploadClick}
                    />
                  </div>

                ) : (
                  <div>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                    />
                    {
                      infoCategoryCar?.image ?
                        <img src={URL_CAR_CATEGORY + infoCategoryCar?.image}
                          alt="Uploaded" className="w-64-px h-64-px rounded-100-px cursor-pointer"
                          onClick={handleUploadClick} /> :
                        <PlusOutlined className="text-sm text-252626" />
                    }
                  </div>
                )
            }

          </div>
          {
            errors.uploadedFile &&
            <span className="text-F5222D text-base font-regular pl-2">{errors.uploadedFile}</span>
          }
        </div>


      </div>
    </Modal>
  )
}
export default ModalEditBrand
