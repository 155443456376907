import { useEffect, useState } from "react"
import { CalendarOutlined, DeleteOutlined, DownloadOutlined, EditOutlined, ShareAltOutlined, UserAddOutlined, UserOutlined } from "@ant-design/icons"
import { IGroup, List, getInfoGroup } from "../../../../../api/groupCustomer/groupCustomerApi"
import { formatPrice } from "../../../../../until"
import { dataGender, dataTypeGroup } from "../../../../../services/data"
import { Value } from "sass"
import { useNavigate } from "react-router-dom"
import useToast from "../../../../../hook/useToast"
import * as XLSX from 'xlsx';

import ModalDeleteGroup from "../Modal/ModalDeleteGroupV2"
import useDebounce from "../../../../../hook/useDebounce"

interface Props {
  item: IGroup
  ctrDelete?: (id: number) => void
}
const ItemsGroup: React.FC<Props> = ({
  item,
  ctrDelete
}) => {
  const pushToast = useToast();
  const navigate = useNavigate()

  const gInfoGroup = useDebounce(async () => {
    const result = await getInfoGroup(Number(item.id), 100000000, 1)
    if (result?.status) {
      formattedDataExcel(result.data.listCustomer.list)
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const formattedDataExcel = (data: List[]) => {
    const formattedData = data.map((item) => {
      return {
        "Loại thẻ": item.rankTitle.title,
        "Họ tên": item.name,
        "CCCD/CMND": item.CCCD,
        "Ngày sinh": item.birthday,
        "Giới tính": dataGender[item.gender].title,
        "Số điện thoại": item.phone,
        "Email": item.email
      }
    }
    );
    exportExcel(formattedData)
    // const formattedData: [string, string, string, string, string, string, string][] = data.map((item) => {
    //     return {
    //         "Loại thẻ": item.rankTitle.title,
    //         "Họ tên": item.name,
    //         "CCCD/CMND": item.CCCD,
    //         "Ngày sinh": item.birthday,
    //         "Giới tính": dataGender[item.gender].title,
    //         "Số điện thoại": item.phone,
    //         "Email": item.email
    //     }
    // }
    // );
    // handleDownload(formattedData)
  }
  function exportExcel(convertData: any): boolean {
    try {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(convertData);
      const workbook: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      const excelBuffer: ArrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });

      const saveAsExcel = (buffer: ArrayBuffer, fileName: string): void => {
        const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
        const link: HTMLAnchorElement = document.createElement('a');
        link.href = window.URL.createObjectURL(data);
        link.download = fileName;
        link.click();
      };

      saveAsExcel(excelBuffer, `${item.title}.xlsx`);
      return true; // Export successful
    } catch (error) {
      console.error('Error exporting Excel:', error);
      return false; // Export failed
    }
  }

  return (
    <div
      className=' p-6 bg-linear-group-customer rounded-3 border-DFF1FC'
    >
      <div className='d-flex flex-column gap-3 '>
        <div
          className='d-flex align-center gap-3 cursor-pointer'
          onClick={() => navigate(`/customer/detail-group/${item.id}`)}
        >
          <UserOutlined className='text-18-px text-1890FF p-2 rounded-100-px d-flex justify-center bg-E6F7FF' />
          <span className='text-base font-medium fw-500 text-2-row'>{item.title}</span>
        </div>
        <div className='d-flex align-center gap-2'>
          {
            item.tags.map((value, indexJob) => (
              <p key={indexJob} className={` rounded-2  text-xs px-2 d-flex align-center gap-3-px h-22-px font-regular ${value.title === 'Quảng cáo' ? 'bg-E6F7FF  border-91D5FF text-1890FF' : 'bg-FFFBE6  border-FFE58F text-FAAD14'}`}>{value.title}</p>
            ))
          }
        </div>
        <p className='text-sm font-regular text-344054 cursor-pointer text-3-row' onClick={() => navigate(`/customer/detail-group/${item.id}`)}>{item.description}</p>
        <div className='d-flex align-center justify-between'>
          <div className='d-flex align-center gap-2'>
            <img src='/images/exportImg/UserGroup.svg' alt="số lượng" className='w-4 h-4' />
            <p className='text-xs text-344054 font-regular'>Số lượng</p>
          </div>
          <p className='text-xs text-667085 font-regular'>{formatPrice(item.countCustomer)}</p>
        </div>
        <div className='d-flex align-center justify-between'>
          <div className='d-flex align-center gap-2'>
            <ShareAltOutlined className='text-base text-096DD9' />
            <p className='text-xs text-344054 font-regular'>Chia sẻ với</p>
          </div>
          <p className='text-xs text-667085 font-regular'>{dataTypeGroup.filter((items) => items.value === item.status)[0].label}</p>
        </div>
        <div className='d-flex align-center justify-between'>
          <div className='d-flex align-center gap-2'>
            <CalendarOutlined className='text-base text-096DD9' />
            <p className='text-xs text-344054 font-regular'>Tạo ngày</p>
          </div>
          <p className='text-xs text-667085 font-regular'>{item.dateCreate}</p>
        </div>
        <div className='d-flex align-center justify-between'>
          <div className='d-flex align-center gap-2'>
            <UserAddOutlined className='text-base text-096DD9' />
            <p className='text-xs text-344054 font-regular'>Người tạo nhóm</p>
          </div>
          <p className='text-xs text-667085 font-regular'>{item.nameAdmin}</p>
        </div>
        <div className='d-flex align-center justify-between'>
          <div
            className='d-flex align-center gap-2 cursor-pointer'
            onClick={gInfoGroup}
          >
            <p className='text-sm font-regular text-40A9FF white-space-nowrap'>Tải danh sách</p>
            <DownloadOutlined className='text-base text-1890FF cursor-pointer' />
          </div>
          <div className='d-flex align-center gap-3'>
            <EditOutlined
              className='bg-white p-2 text-base rounded-2 text-344054 cursor-pointer'
              onClick={() => navigate(`/customer/detail-group/${item.id}`)}
            />
            <DeleteOutlined
              className='bg-white p-2 text-base rounded-2 text-F5222D cursor-pointer'
              onClick={() => ctrDelete?.(item.id)}
            />
          </div>
        </div>
      </div>
    </div>

  )

}
export default ItemsGroup
