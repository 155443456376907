import React, { useState } from 'react';
import './VoucherCard.scss'
import { CopyOutlined, GlobalOutlined, SwapRightOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Tag, message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { IVoucher } from '../../api/voucher/voucherApi';
import { statusVoucher } from '../../services/data';
import ModalDeleteManager from '../../components/ModalDeleteManager';
import useToast from '../../hook/useToast';
interface Props {
  item: IVoucher
  openModalDelete?: (value: number) => void
}

const VoucherCard: React.FC<Props> = ({
  item,
  openModalDelete
}) => {


  async function copyTextToClipboard(text: any) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleCopyClick = (copyText: any) => {
    copyTextToClipboard(copyText)
      .then(() => {
        message.success({
          content: 'Copied',
          className: 'rs-message'
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const pushToast = useToast();
  const navigatorLink = useNavigate()


  return (
    <>
      <div className={`voucher-card `}>
        <div className="top">
          <div className="top-item">
            <GlobalOutlined className="neutral-gray-400" />
            <span className="neutral-gray-400">Mã chương trình Voucher</span>
            <span className="neutral-gray-400">{item.code}</span>
            <CopyOutlined
              className="primary-7"
              onClick={() => handleCopyClick(item.code)}
            />
            <EditOutlined
              className="primary-7"
              onClick={() => navigatorLink(`/marketing/vouchers-management/edit/${item.id}`)}
            />
            <DeleteOutlined
              className="text-F5222D"
              onClick={() => openModalDelete?.(item.id)}
            />
          </div>
          <div className="top-item">
            <Tag className='rs-tag daybreak-blue'>{statusVoucher.find(items => items.Value === item.status)?.label}</Tag>
          </div>
        </div>
        <Link to={`/marketing/vouchers-management/${item.id}`} className="title text-2-row">{item.title}</Link>
        <div className="bottom">
          <div className="bottom-item">
            <span className="neutral-gray-400">{item.dateStart}</span>
          </div>
          <div className="bottom-item">
            <SwapRightOutlined className="neutral-gray-400" />
          </div>
          <div className="bottom-item">
            <span className="neutral-gray-400">{item.dateEnd}</span>
          </div>
        </div>
        <img src="/images/brand-icon--180x120.svg" alt="" className="bg-img" />
      </div>

    </>

  )
}

export default VoucherCard
