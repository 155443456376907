import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const data = [
  {
    name: 'T5/2023',
    htkh: 1400,
    kn: 1180,
  },
  {
    name: 'T6/2023',
    htkh: 1450,
    kn: 1210,
  },
  {
    name: 'T7/2023',
    htkh: 1435,
    kn: 1218,
  },
  {
    name: 'T8/2023',
    htkh: 1505,
    kn: 1255,
  },
  {
    name: 'T9/2023',
    htkh: 1495,
    kn: 1248,
  },
  {
    name: 'T10/2023',
    htkh: 1615,
    kn: 1287,
  },
  {
    name: 'T11/2023',
    htkh: 1701,
    kn: 1291,
  },
  {
    name: 'T12/2023',
    htkh: 1712,
    kn: 1299,
  },
  {
    name: 'T1/2024',
    htkh: 1800,
    kn: 1300,
  },
];

const totalData = {
  htkh: 28000,
  kn: 1000,
}

function getTotalValue(value: string): number {
  if (value === "htkh" || value === "kn") {
    return totalData[value];
  } else {
    return 0;
  }
}

const numberInShort = (number: number) => {
  if(number > 1000000000){
    return (number/1000000000).toString() + 'B';
  }else if(number > 1000000){
    return (number/1000000).toString() + 'M';
  }else if(number > 1000){
    return (number/1000).toString() + 'K';
  }else{
    return number.toString();
  }
}

const numberFormat = (number: any) => {
  let result
  if (typeof number === 'number') {
    result = new Intl.NumberFormat().format(number)
  } else {
    result = number
  }
  return result
}

const ARCCQuantityRequested: React.FC = () => {
  const renderLegendText = (value: string, entry: any, index: number) => {
    const key = entry.dataKey
    return <span>{value} <span className="neutral-gray-400">({ numberFormat(getTotalValue(key)) })</span></span>
  };

  return (
    <div className="arc-card">
      <div className="arc-card__head">
        <span className="title">Số lượng yêu cầu theo thời gian</span>
        <div className="control">
          <Button className="rs-button">
            <DownloadOutlined />
            <span>Xuất báo cáo</span>
          </Button>
        </div>
      </div>

      <div className="arc-card__content">
        <ResponsiveContainer aspect={769 / 342}>
          <LineChart
            width={769}
            height={308}
            data={data}
            className="quantity-request-chart rs-recharts-legend rs-recharts-tooltip "
          >
            <CartesianGrid
              strokeDasharray="4 4"
              vertical={false}
            />

            <XAxis dataKey="name" />

            <YAxis
              tickFormatter={(entry) => numberInShort(entry)} 
            />

            <Tooltip
              formatter={(value) => numberFormat(value)}
            />

            <Legend
              iconType="circle"
              formatter={renderLegendText}
            />

            <Line
              type="linear"
              name="Hỗ trợ khách hàng"
              dataKey="htkh"
              stroke="#1890FF"
              strokeWidth={2}
              dot= {{
                stroke: "#1890FF",
                strokeWidth: 4,
                r: 2
              }}
            />
            
            <Line
              type="monotone"
              name="Khiếu nại"
              dataKey="kn"
              stroke="#F05871"
              strokeWidth={2}
              dot= {{
                stroke: "#F05871",
                strokeWidth: 4,
                r: 2
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ARCCQuantityRequested;
