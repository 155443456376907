export const API_URL = "http://api.thacocrm.bdata.link:5004/";

export const URL_CAR = "http://api.thacocrm.bdata.link:5004/uploads/car/"
export const URL_TEMPLATE = "http://api.thacocrm.bdata.link:5004/uploads/typeZNS/"
export const URL_CAR_CATEGORY = "http://api.thacocrm.bdata.link:5004/uploads/categoryCar/"
export const URL_CUSTOMER = "http://api.thacocrm.bdata.link:5004/uploads/customer/"
export const URL_RANK = "http://api.thacocrm.bdata.link:5004/uploads/rank/"

export const ICON_SUPPORT_CHANEL = "http://api.thacocrm.bdata.link:5004/uploads/iconSupportChanel/"//NGUÔN
export const ICON_SUPPORT_TYPE = "http://api.thacocrm.bdata.link:5004/uploads/iconSupport/"//loại
export const ICON_SATISFACTION_LEVEL = "http://api.thacocrm.bdata.link:5004/uploads/iconSatisfactionLevel/"//mức độ

export const URL_COUNTRY = "http://api.thacocrm.bdata.link:5004/uploads/country/"

export const URL_NETWORK = "http://api.thacocrm.bdata.link:5004/uploads/network/"
export const URL_CHANNEL = "http://api.thacocrm.bdata.link:5004/uploads/channel/"

export const LIMIT_EXPORT_EXCEL = 10000