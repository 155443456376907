import { CalendarOutlined, DeleteOutlined, EditOutlined, UserAddOutlined } from '@ant-design/icons'
import React from 'react'
import './AccountRoleItem.scss'
import { IGroupPermission } from '../../../../api/groupPermission/groupPermissionApi'
import { formatPrice } from '../../../../until'

interface Props {
  item: IGroupPermission

  onEdit?: (id: number) => void,
  onDelete?: (id: number) => void,
  onShowInfo?: (id: number) => void,
}

const AccountRoleItem: React.FC<Props> = (props) => {
  const { item, onEdit, onDelete, onShowInfo } = props
  const { createdAt, description, id, so_luong, title } = item
  return (
    <div
      className="account-role-item"
    >
      <img src="/images/brand-icon--180x120.svg" alt="THACO AUTO" className="bg-img" />
      <div className="account-role-item__title">
        <div
          className="title fsz-16 lh-24 font-medium"
          onClick={() => onShowInfo?.(id)}
        >{title}</div>
        <div className="actions">
          <div
            className="action action--delete"
            title='Delete'
            onClick={() => onDelete?.(id)}
          ><DeleteOutlined /></div>
          <div
            className="action action--edit"
            title='Edit'
            onClick={() => onEdit?.(id)}
          ><EditOutlined /></div>
        </div>
      </div>
      <div className="item">
        <span className="neutral-gray-400 font-medium">{description}</span>
      </div>
      <div className="item">
        <CalendarOutlined />
        <span className="neutral-gray-400 font-medium">Chỉnh sửa lần cuối {createdAt}</span>
      </div>
      <div className="item">
        <UserAddOutlined />
        <span className="neutral-gray-400 font-medium">Có {formatPrice(so_luong || 0)} tài khoản đang thuộc nhóm</span>
      </div>
    </div>
  )
}

export default AccountRoleItem
