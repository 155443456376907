import { DownloadOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import type { TableColumnsType } from 'antd';
import React from "react";
import fakeData from './fake-data';

interface DataType {
  code: string;
  name: string;
  customers: number;
  cars: number;
}

const numberFormat = (number: any) => {
  const result = new Intl.NumberFormat().format(number)
  return result
}

const columns: TableColumnsType<DataType> = [
  {
    title: 'Mã đại lý/Công ty',
    dataIndex: 'code',
    key: 'code',
    render: (text) => <span className="font-medium">{ text }</span>,
    sorter: (a, b) => a.code.length - b.code.length,
  },
  {
    title: 'Tên Đại lý/Công ty',
    dataIndex: 'name',
    key: 'name',
    filters: [],
    filterDropdown: <></>,
    // filters: [
    //   {
    //     text: 'THACO AUTO PHẠM VĂN ĐỒNG - HN (DL)',
    //     value: 'TCAPVDHNDL',
    //   },
    //   {
    //     text: 'THACO AUTO BẠCH ĐẰNG - HN (DL)',
    //     value: 'TCABDHNDL',
    //   },
    //   {
    //     text: 'THACO AUTO LONG BIÊN - HN (DL)',
    //     value: 'TCALBHNDL',
    //   },
    // ],
    // onFilter: (value: string, record: DataType) => record.name.includes(value),
  },
  {
    title: 'Số lượng khách hàng',
    dataIndex: 'customers',
    key: 'customers',
    render: (text) => <span>{ numberFormat(text) }</span>,
    sorter: (a, b) => a.customers - b.customers,
  },
  {
    title: 'Số lượng xe',
    dataIndex: 'cars',
    key: 'cars',
    render: (text) => <span>{ numberFormat(text) }</span>,
    sorter: (a, b) => a.cars - b.cars,
  },
];

const ARCTopCustomerByUnit: React.FC = () => {
  return (
    <div className="arc-card">
      <div className="arc-card__head">
        <span className="title">Top khách hàng theo đơn vị</span>
        <div className="control">
          <Button
            className="rs-button"
          >
            <DownloadOutlined />
            <span>Xuất báo cáo</span>
          </Button>
        </div>
      </div>

      <div className="arc-card__content">
        <Table
          columns={columns}
          dataSource={fakeData}
          showSorterTooltip={false}
          className="rs-table"
          pagination={{
            position: ['bottomCenter'],
            defaultPageSize: 15,
            className: 'rs-pagination',
            showQuickJumper: true,
          }}
        />
      </div>
    </div>
  )
}

export default ARCTopCustomerByUnit