import React, { useEffect } from 'react';
import Layout from '../../Layout';
// import EmptyComponentLink from '../../components/EmptyComponent/EmptyComponentLink';
import { Breadcrumb } from 'antd';
import './PoolingData.scss'
import { CheckCircleOutlined, SettingOutlined, UserAddOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const PoolingData: React.FC = () => {
  useEffect(() => {
    document.title = 'THACO AUTO CRM';
  }, []);

  return (
    <div className='pooling-data-page'>
      <Layout>
        <Breadcrumb
          items={[
            {
              title: 'THACO AUTO CRM',
            },
            {
              title: <a href="/">Khách hàng</a>,
            },
            {
              title: 'Gộp dữ liệu khách hàng',
            },
          ]}
        />
        <div className='title-page'>Gộp dữ liệu khách hàng</div>

        <div className="list-item">
          <Link to="/customer/pooling-data/merge-manually" className="one-item">
            <div className="icon-svg">
              <UserAddOutlined />
              {/* <div className="label-smal">99</div> */}
            </div>
            <div className="name-content">
              <div className="name">Gộp thủ công</div>
              <div className="content">Chọn khách hàng từ dữ liệu, xác nhận gộp và lưu vào danh sách khách hàng</div>
            </div>
          </Link>

          <Link to="/customer/pooling-data/pooling-data-auto" className="one-item">
            <div className="icon-svg">
              <CheckCircleOutlined />
              <div className="label-smal">99</div>
            </div>
            <div className="name-content">
              <div className="name">Xác nhận gộp tự động</div>
              <div className="content">Xác nhận và lưu khách hàng đã gộp tự động vào danh sách khách hàng</div>
            </div>
          </Link>

          <Link to="#" className="one-item">
            <div className="icon-svg">
              <SettingOutlined />
              {/* <div className="label-smal">99</div> */}
            </div>
            <div className="name-content">
              <div className="name">Thiết lập điều kiện gộp</div>
              <div className="content">Điều kiện gộp dữ liệu từ các hệ thống và định nghĩa dữ liệu chuẩn lên CRM</div>
            </div>
          </Link>

          <Link to="#" className="one-item">
            <div className="icon-svg">
              <svg width="1em" height="1em" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.3599 7.46212H15.642C15.4849 7.46212 15.3563 7.59069 15.3563 7.74783V17.58C15.3563 17.6728 15.3991 17.7585 15.4741 17.8121L21.3777 22.1228C21.5063 22.2157 21.6849 22.1907 21.7777 22.0621L22.7992 20.6693C22.8956 20.5371 22.867 20.3585 22.7384 20.2693L17.6456 16.5871V7.74783C17.6456 7.59069 17.517 7.46212 17.3599 7.46212ZM25.2099 10.1478L30.8099 11.5157C30.9884 11.5585 31.1634 11.4228 31.1634 11.2407L31.192 5.47283C31.192 5.23355 30.917 5.09783 30.7313 5.24783L25.1027 9.64426C25.0602 9.67717 25.0278 9.72142 25.0093 9.77191C24.9908 9.82241 24.9869 9.8771 24.9981 9.92971C25.0093 9.98231 25.0351 10.0307 25.0726 10.0693C25.11 10.1079 25.1576 10.1351 25.2099 10.1478ZM31.1992 20.9014L29.1742 20.205C29.1035 20.1808 29.0263 20.1852 28.9589 20.2172C28.8915 20.2492 28.8393 20.3064 28.8134 20.3764C28.7456 20.5585 28.6742 20.7371 28.5991 20.9157C27.9634 22.4193 27.0527 23.7728 25.8884 24.9335C24.7371 26.0885 23.3726 27.0091 21.8706 27.6443C20.3146 28.3021 18.642 28.6398 16.9527 28.6371C15.2456 28.6371 13.592 28.305 12.0349 27.6443C10.5329 27.0091 9.16837 26.0885 8.01701 24.9335C6.85629 23.7728 5.94558 22.4193 5.30629 20.9157C4.65207 19.3588 4.31688 17.6866 4.32058 15.9978C4.32058 14.2907 4.65272 12.6335 5.31344 11.0764C5.94915 9.57283 6.85986 8.21926 8.02415 7.05855C9.17551 5.90362 10.54 4.98304 12.042 4.34783C13.592 3.68712 15.2492 3.35498 16.9563 3.35498C18.6634 3.35498 20.317 3.68712 21.8742 4.34783C23.3762 4.98304 24.7407 5.90362 25.892 7.05855C26.2563 7.42641 26.5991 7.80855 26.9134 8.21212L29.0492 6.54069C26.2384 2.94783 21.8634 0.637119 16.9492 0.64069C8.39201 0.644262 1.52058 7.59426 1.60629 16.155C1.69201 24.5657 8.53129 31.355 16.9563 31.355C23.5813 31.355 29.2242 27.155 31.3742 21.2728C31.4277 21.1228 31.3491 20.955 31.1992 20.9014Z" fill="#344054" />
              </svg>
              {/* <div className="label-smal">99</div> */}
            </div>
            <div className="name-content">
              <div className="name">Lịch sử</div>
              <div className="content">Tra cứu lịch sử gộp khách hàng và lưu vào danh sách khách hàng</div>
            </div>
          </Link>
        </div>
      </Layout >
    </div >
  );
}

export default PoolingData;
