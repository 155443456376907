import { CloseOutlined, DeleteOutlined, EditOutlined, PlusOutlined, RightOutlined } from "@ant-design/icons"
import { Modal } from "antd"
import { useEffect, useState } from "react"
import * as AttributeVehicleApi from "../../../../../api/attributeVehicle/attributeVehicleApi"
import ModalDeleteManager from "../../../../../components/ModalDeleteManager"
import useDebounce from "../../../../../hook/useDebounce"
import useToast from "../../../../../hook/useToast"
import ModalAddSegment from "./ModalAddSegment"
import ModalEditSegment from "./ModalEditSegment"
import './style.scss'
interface ModalProps {
  isOpen: boolean
  closeModal: () => void
}

const ModalManagerSegment: React.FC<ModalProps> = ({
  isOpen,
  closeModal
}) => {

  useEffect(() => {

    return (() => {

    })
  }, [])

  const pushToast = useToast();

  const [isOpenAddSegment, setIsOpenAddSegment] = useState(false)
  const [opentEditSegment, setOpenEditSegment] = useState(false)
  const [opentDeleteSegment, setOpenDeleteSegment] = useState(false)

  const [infoTypeAttributeVehicle, setInfoTypeAttributeVehicle] = useState<AttributeVehicleApi.ITypeAttributeVehicle>({
    id: 1,
    title: ''
  })
  const [infoAttributeVehicle, setInfoAttributeVehicle] = useState<AttributeVehicleApi.IAttributeVehicle>()

  const [listTypeAttributeVehicle, setListTypeAttributeVehicle] = useState<AttributeVehicleApi.ITypeAttributeVehicle[]>([])
  const [listAttributeVehicle, setListAttributeVehicle] = useState<AttributeVehicleApi.IAttributeVehicle[]>([])

  const gListTypeAttributeVehicle = async () => {
    const result = await AttributeVehicleApi.getListTypeAttributeVehicle();
    if (result?.status) {
      setListTypeAttributeVehicle(result.data)
    } else {
      pushToast(result?.message, "warning")
    }
  }

  const gListAttributeVehicle = useDebounce(async () => {
    const result = await AttributeVehicleApi.getListAttributeVehicle(Number(infoTypeAttributeVehicle?.id));
    if (result?.status) {
      setListAttributeVehicle(result.data)
    } else {
      pushToast(result?.message, "warning")
    }
  }, 300)


  const handleOpenAddSegment = () => {
    if (!infoTypeAttributeVehicle?.id) {
      pushToast("Vui lòng chọn thuộc tính!", "warning")
    } else {
      setIsOpenAddSegment(true)

    }
  }

  const onSubmitFormModalAdd = (iSsuccess: boolean, errorMessage?: string) => {
    if (iSsuccess) {
      setIsOpenAddSegment(false);
      pushToast("Thêm thuộc tính thành công", "success")
      gListAttributeVehicle()
    }
    else {
      pushToast(errorMessage, "warning")
    }
  }

  const handleOpenEditSegment = async (id: number) => {
    const result = await AttributeVehicleApi.getInfoAttributeVehicle(Number(infoTypeAttributeVehicle?.id), id);
    if (result?.status) {
      setInfoAttributeVehicle(result.data)
      setOpenEditSegment(true)
    } else {
      pushToast(result?.message, "warning")
    }
  }

  const onSubmitFormModalEdit = (iSsuccess: boolean, errorMessage?: string) => {
    if (iSsuccess) {
      setOpenEditSegment(false);
      pushToast("Sửa thuộc tính thành công", "success")
      gListAttributeVehicle()
    }
    else {
      pushToast(errorMessage, "warning")
    }
  }
  const handleOpenDeleteSegment = (item: AttributeVehicleApi.IAttributeVehicle) => {
    setInfoAttributeVehicle(item)
    setOpenDeleteSegment(true)
  }

  const closeModalDeleteSegment = () => {
    setInfoAttributeVehicle(undefined)
    setOpenDeleteSegment(false)
  }

  const onConfirmDelete = async () => {
    const result = await AttributeVehicleApi.deleteAttributeVehicle(Number(infoTypeAttributeVehicle?.id), Number(infoAttributeVehicle?.id))
    if (result?.status) {
      setOpenDeleteSegment(false)
      setInfoAttributeVehicle(undefined)
      pushToast("Xóa thuộc tính thành công", "success")

      gListAttributeVehicle()
    } else {
      pushToast(result?.message, "warning")
    }
  }

  const resetData = () => {
    setInfoTypeAttributeVehicle({
      id: 1,
      title: ''
    })

    setListTypeAttributeVehicle([])
    setListAttributeVehicle([])
  }

  useEffect(() => {
    if (isOpen) {
      gListTypeAttributeVehicle()
    }
    else {
      resetData()
    }
  }, [isOpen])

  useEffect(() => {
    if (infoTypeAttributeVehicle?.id) {
      gListAttributeVehicle()
    }
  }, [infoTypeAttributeVehicle])

  return (
    <div>
      <ModalAddSegment
        isOpen={isOpenAddSegment}
        infoTypeAttributeVehicle={infoTypeAttributeVehicle}
        onSubmitFormModal={onSubmitFormModalAdd}
        closeModal={setIsOpenAddSegment}
      />

      <ModalEditSegment
        isOpen={opentEditSegment}
        infoTypeAttributeVehicle={infoTypeAttributeVehicle}
        infoAttributeVehicle={infoAttributeVehicle}
        onSubmitFormModal={onSubmitFormModalEdit}
        closeModal={setOpenEditSegment}
      />

      <ModalDeleteManager
        isOpen={opentDeleteSegment}
        closeModal={closeModalDeleteSegment}
        title='Bạn có chắc chắn xóa thuộc tính này'
        titleDelete='Xóa thuộc tính'
        content='Thuộc tính và dữ liệu liên quan sẽ bị xóa vĩnh viễn không thể khôi phục'
        onConfirm={onConfirmDelete}
      />

      <Modal
        title={<div className="bg-FCFCFD d-flex justify-betweeen align-center py-4 px-6 w-full border-b-F0F0F0">
          <span className="text-base text-344054 fw-500 font-medium">Quản lý thuộc tính</span>
          <CloseOutlined className="text-base text-8B8B8B"
            onClick={closeModal}
          />
        </div>}
        onCancel={closeModal}
        open={isOpen}
        centered
        className="w-modal modal-content ant-modal-size m-none-header"
        closeIcon={false}
        footer={[]}
      >
        <div className="d-flex align-center gap-18-px pl-6 py-3 bg-FAFAFA">
          <p className="text-base text-4B4B4B fw-500 font-medium">Thuộc tính</p>
          <div className="d-flex align-center rounded-2 h-26-px w-62-px gap-1 border-096DD9 justify-center cursor-pointer" onClick={handleOpenAddSegment}>
            <p className="text-xs text-096DD9 font-regular">Thêm</p>
            <PlusOutlined className="text-xs text-096DD9 " />
          </div>
        </div>
        <div className="d-flex">
          <div className="d-flex flex-column">
            {
              listTypeAttributeVehicle && listTypeAttributeVehicle.map((items, index) => (
                <div className="d-flex flex-column " key={index}>
                  <div
                    className={`cursor-pointer d-flex align-center justify-between pl-6 pr-15-px py-18-px gap-2 border-top-EEEEEE border-right-EEEEEE   h-fit-content w-147-px
                  ${infoTypeAttributeVehicle?.id === items.id ? 'bg-E6F7FF' : 'bg-white'}`}
                    onClick={() => setInfoTypeAttributeVehicle(items)}
                  >
                    <p className={`text-base font-regular   ${infoTypeAttributeVehicle?.id === items.id ? 'text-1890FF' : 'text-344054'}`}>{items.title}</p>
                    <RightOutlined className={`text-10-px   ${infoTypeAttributeVehicle?.id === items.id ? 'text-1890FF' : 'text-344054'}`} />
                  </div>
                </div>

              ))
            }
          </div>
          <div className="d-flex flex-column w-full h-300-px overflow-y-scroll">
            <div>

              {
                listAttributeVehicle && listAttributeVehicle.map((item, i) => (
                  <div
                    key={i}
                    className={`px-6 py-18-px border-top-EEEEEE d-flex align-center justify-between ${i === listAttributeVehicle.length - 1 ? 'border-bottom-EEEEEE' : ''}`}
                  >
                    <p className="text-base font-regular text-344054">{item.title}</p>
                    <div className="d-flex align-center gap-6">
                      <EditOutlined
                        className="text-base text-096DD9 cursor-pointer"
                        onClick={() => handleOpenEditSegment(item.id)}
                      />
                      <DeleteOutlined
                        className="text-base text-F5222D cursor-pointer"
                        onClick={() => handleOpenDeleteSegment(item)}
                      />
                    </div>
                  </div>
                ))
              }

            </div>
          </div>
        </div>
      </Modal>
    </div>

  )
}
export default ModalManagerSegment

