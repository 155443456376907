import React, { useEffect } from 'react';
import Layout from '../../Layout';
import './MarketingCampaigns.scss';
import { Table, TableProps, TableColumnsType, ConfigProvider, Pagination, Breadcrumb, Switch } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import viVN from 'antd/locale/vi_VN';

interface DataType {
  key: React.Key;
  stt: number;
  hd: string;
  mavc: string;
  kh: string;
  dt: string;
  date: string;
  idch: string;
  dateUse: string;
}

const columns: TableColumnsType<DataType> = [
  {
    title: 'STT',
    dataIndex: 'stt',
    align: 'center',
    sorter: {
      compare: (a, b) => a.stt - b.stt,
      multiple: 1,
    },
  },
  {
    title: 'Hành động',
    dataIndex: 'hd',
    align: 'center',
    render: (text) => (
      <div className="t-align-center">
        <Switch className="rs-switch rs-switch--sm" defaultChecked onChange={onChangeSW} />
      </div>
    ),
  },
  {
    title: 'Mã Voucher',
    dataIndex: 'mavc',
  },
  {
    title: 'Khách hàng đổi',
    dataIndex: 'kh',
    sorter: {
      compare: (a, b) => a.stt - b.stt,
      multiple: 1,
    },
  },
  {
    title: 'Số điện thoại',
    dataIndex: 'dt',
    sorter: {
      compare: (a, b) => a.stt - b.stt,
      multiple: 1,
    },
  },
  {
    title: 'Thời gian đổi',
    dataIndex: 'date',
    sorter: {
      compare: (a, b) => a.stt - b.stt,
      multiple: 1,
    },
  },
  {
    title: 'ID cửa hàng dùng',
    dataIndex: 'idch',
    sorter: {
      compare: (a, b) => a.stt - b.stt,
      multiple: 1,
    },
  },
  {
    title: 'Ngày dùng',
    dataIndex: 'dateUse',
    sorter: {
      compare: (a, b) => a.stt - b.stt,
      multiple: 1,
    },
  },
];

const data: DataType[] = [
  {
    key: '1',
    stt: 1,
    hd: '-',
    mavc: '8121BXV',
    kh: '-',
    dt: '-',
    date: '-',
    idch: '-',
    dateUse: '-',
  },
  {
    key: '2',
    stt: 2,
    hd: '-',
    mavc: '8121BXV',
    kh: '-',
    dt: '-',
    date: '-',
    idch: '-',
    dateUse: '-',
  },
  {
    key: '3',
    stt: 3,
    hd: '-',
    mavc: '8121BXV',
    kh: '-',
    dt: '-',
    date: '-',
    idch: '-',
    dateUse: '-',
  },
  {
    key: '4',
    stt: 4,
    hd: '-',
    mavc: '8121BXV',
    kh: '-',
    dt: '-',
    date: '-',
    idch: '-',
    dateUse: '-',
  },
  {
    key: '5',
    stt: 5,
    hd: '-',
    mavc: '8121BXV',
    kh: '-',
    dt: '-',
    date: '-',
    idch: '-',
    dateUse: '-',
  },
  {
    key: '6',
    stt: 6,
    hd: '-',
    mavc: '8121BXV',
    kh: '-',
    dt: '-',
    date: '-',
    idch: '-',
    dateUse: '-',
  },
  {
    key: '7',
    stt: 7,
    hd: '-',
    mavc: '8121BXV',
    kh: '-',
    dt: '-',
    date: '-',
    idch: '-',
    dateUse: '-',
  },
  {
    key: '8',
    stt: 8,
    hd: '-',
    mavc: '8121BXV',
    kh: '-',
    dt: '-',
    date: '-',
    idch: '-',
    dateUse: '-',
  },
  {
    key: '9',
    stt: 9,
    hd: '-',
    mavc: '8121BXV',
    kh: '-',
    dt: '-',
    date: '-',
    idch: '-',
    dateUse: '-',
  },
  {
    key: '10',
    stt: 10,
    hd: '-',
    mavc: '8121BXV',
    kh: '-',
    dt: '-',
    date: '-',
    idch: '-',
    dateUse: '-',
  },
  {
    key: '11',
    stt: 11,
    hd: '-',
    mavc: '8121BXV',
    kh: '-',
    dt: '-',
    date: '-',
    idch: '-',
    dateUse: '-',
  },
  {
    key: '12',
    stt: 12,
    hd: '-',
    mavc: '8121BXV',
    kh: '-',
    dt: '-',
    date: '-',
    idch: '-',
    dateUse: '-',
  },
  {
    key: '13',
    stt: 13,
    hd: '-',
    mavc: '8121BXV',
    kh: '-',
    dt: '-',
    date: '-',
    idch: '-',
    dateUse: '-',
  },
  {
    key: '14',
    stt: 14,
    hd: '-',
    mavc: '8121BXV',
    kh: '-',
    dt: '-',
    date: '-',
    idch: '-',
    dateUse: '-',
  },
  {
    key: '15',
    stt: 15,
    hd: '-',
    mavc: '8121BXV',
    kh: '-',
    dt: '-',
    date: '-',
    idch: '-',
    dateUse: '-',
  },
  {
    key: '16',
    stt: 16,
    hd: '-',
    mavc: '8121BXV',
    kh: '-',
    dt: '-',
    date: '-',
    idch: '-',
    dateUse: '-',
  }
];

const onChangeTB: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
  console.log('params', pagination, filters, sorter, extra);
};

const onChangeSW = (checked: boolean) => {
  console.log(`switch to ${checked}`);
};

const MarketingCampaigns: React.FC = () => {
  useEffect(() => {
    document.title = 'THACO AUTO CRM';
  }, []);

  return (
    <div className="marketing-campaigns marketing-campaigns-detail">
      <Layout>
        <Breadcrumb
          items={[
            {
              title: 'THACO AUTO CRM',
            },
            {
              title: <a href="/">Báo cáo phân tích</a>,
            },
            {
              title: <a href="/">CSKH</a>,
            },
            {
              title: 'Chi tiết',
            },
          ]}
        />

        <div className='title-page title-btn-back ctrl-title-vouche'>
          <Link to="/analysis-report/voucher" className="icon-svg">
            <svg width="1em" height="1em" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.6564 7.80321H4.04305L10.3955 2.37463C10.4971 2.28713 10.4354 2.12463 10.3012 2.12463H8.69582C8.62508 2.12463 8.55796 2.14963 8.50536 2.19428L1.65045 8.04963C1.58767 8.10321 1.53732 8.16944 1.50281 8.24385C1.4683 8.31825 1.45044 8.39909 1.45044 8.48088C1.45044 8.56268 1.4683 8.64352 1.50281 8.71792C1.53732 8.79233 1.58767 8.85856 1.65045 8.91213L8.54526 14.8032C8.57247 14.8264 8.60512 14.8389 8.63959 14.8389H10.2993C10.4336 14.8389 10.4953 14.6746 10.3937 14.5889L4.04305 9.16035H14.6564C14.7363 9.16035 14.8016 9.09606 14.8016 9.01749V7.94606C14.8016 7.86749 14.7363 7.80321 14.6564 7.80321Z" fill="currentColor" fillOpacity="0.88" />
            </svg>
          </Link>
          Chi tiết
        </div>
        <div className="filter-block">
          <div className="left">
            <div className="title">28/10/2023</div>
            <span className="line-center">|</span>
            <div className="title">Chương trình Voucher giới thiêu BMW</div>
          </div>
          <div className="right">
            <div className="t-btn-df">
              <div className="icon-svg"><DownloadOutlined /></div>
              <div className="text">Xuất báo cáo</div>
            </div>
          </div>
        </div>

        <div className="detail-block t-mt-18">
          <div className="t-table-block t-table-vouche-detail">
            <Table
              rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
              columns={columns}
              dataSource={data}
              onChange={onChangeTB}
              pagination={false}
              showSorterTooltip={false}
            />
            <ConfigProvider locale={viVN}>
              <div className="pagination-block">
                <Pagination
                  total={85}
                  showSizeChanger
                  showQuickJumper
                  showTotal={(total) => `Tổng cộng ${total} trang`}
                  locale={{ items_per_page: 'trang' }}
                />
              </div>
            </ConfigProvider>
          </div>
        </div>

      </Layout>
    </div>
  );
}

export default MarketingCampaigns;
