import { CloseOutlined } from "@ant-design/icons";
import { Form, FormInstance, FormProps, Input, Modal } from "antd";
import { useEffect, useRef } from "react";
import * as SelectOptionApi from '../../../../api/selectOptions/selectOptionsApi';
import useToast from "../../../../hook/useToast";
import { useForm } from "antd/lib/form/Form";
interface ModalProps {
  isOpen: boolean
  closeModal: () => void
  getListBusinessUnit: () => void
}
const ModalCreateMajor: React.FC<ModalProps> = ({
  isOpen,
  closeModal,
  getListBusinessUnit
}) => {
  const pushToast = useToast();
  const [form] = useForm()
  const inputRef = useRef<FormInstance>(null);
  const submitForm = () => {
    if (inputRef.current) {
      inputRef.current.submit();
    }
  };

  const onFinish: FormProps["onFinish"] = async (values) => {
    const result = await SelectOptionApi.addBusinessUnit(values.codemajor, values.namemajor);
    if (result?.status) {
      pushToast(result.message, "success", 2000)
      closeModal();
      getListBusinessUnit()
    } else {
      pushToast(result.message, "error", 1000)
    }
  };
  useEffect(() => {
    if (isOpen) {
      form.resetFields();

    }
  }, [isOpen])
  return (
    <Modal
      title={<div className="bg-FCFCFD d-flex justify-betweeen align-center py-4 px-6 w-full border-b-F0F0F0">
        <span className="text-base text-344054 fw-500 font-medium">Tạo mới khối nghiệp vụ</span>
        <CloseOutlined className="text-base text-8B8B8B" onClick={closeModal} />
      </div>}
      open={isOpen}
      onCancel={closeModal}
      centered
      className="w-modal modal-content ant-modal-size"
      closeIcon={false}
      footer={<div className="bg-FCFCFD d-flex justify-betweeen align-center py-2 px-6 w-full border-t-F0F0F0">
        <span className="text-sm text-98A2B3 cursor-pointer" onClick={closeModal}>Huỷ</span>
        <button className="text-white text-sm bg-096DD9 border-none shadow-btn-save rounded-2 h-8 px-15-px"
          onClick={submitForm}>
          Xác nhận
        </button>
      </div>}
    >
      <div className="p-6 ">
        <Form
          ref={inputRef}
          form={form}
          onFinish={onFinish}
          initialValues={{ remember: true }}
        >
          <div className="d-flex flex-column gap-18-px">
            <div className="d-flex flex-column gap-1">
              <p className="text-sm  font-regular text-344054">Mã khối/nghiệp vụ</p>
              <Form.Item
                name="codemajor"
                rules={[{ required: true, message: 'Nhập mã khối/nghiệp vụ!' }]}
                className="m-none-form-item text-explan"
              >
                <Input
                  placeholder="Mã khối/nghiệp vụ"
                  className="bg-FCFCFD border-F2F4F7 text-sm rounded-2  font-regular  input-custom  h-8"
                />
              </Form.Item>
            </div>
            <div className="d-flex flex-column gap-1">
              <p className="text-sm  font-regular text-344054">Tên khối/nghiệp vụ</p>
              <Form.Item
                name="namemajor"
                rules={[{ required: true, message: 'Nhập tên khối/nghiệp vụ!' }]}
                className="m-none-form-item text-explan"
              >
                <Input
                  placeholder="Tên khối/nghiệp vụ"
                  className="bg-FCFCFD border-F2F4F7 text-sm rounded-2  font-regular  input-custom  h-8"
                />
              </Form.Item>
            </div>

          </div>

        </Form>

      </div>
    </Modal>
  )
}
export default ModalCreateMajor
