import { useRef, useEffect } from 'react';

export default function useThrottle(fn: any, ms: number) {
    const lastCall = useRef(0);

    const throttledFn = (...args: any) => {
        const now = Date.now();
        if (now - lastCall.current < ms) return;
        lastCall.current = now;
        return fn(...args);
    };

    useEffect(() => {
        // Cleanup function (optional)
        return () => { };
    }, [fn, ms]); // Add dependencies if needed

    return throttledFn;
}