import React, { useEffect, useState } from 'react';
import './Information.scss';
import { Button, Switch, Tag } from 'antd';
import { EditFilled, MinusCircleFilled, PlusCircleFilled } from '@ant-design/icons';
import RelativeList from '../../../components/Relative/RelativeList';
import OwnedVehicle from '../../../components/Vehicle/OwnedVehicle';
import InterestedVehicleList from '../../../components/Vehicle/InterestedVehicleList';
import VehicleInfoPopup from './VehicleInfoPopup';
import RankBenefitPopup from './RankBenefitPopup';
import WarrantyHistoryPopup from './WarrantyHistoryPopup';
import { ICustomerDetail } from '../../../api/customer/customerApi';
import { dataGender, dataStatusMarital } from '../../../services/data';
import { avatarDefault, rankGoldenDefault } from '../../../components/ExportImgFigma';
import { URL_CUSTOMER, URL_RANK } from '../../../env';
import * as historyApi from '../../../api/history/historyApi';
import { useNavigate } from 'react-router-dom';
import useToast from '../../../hook/useToast';
import { ICardCustomer, getInfoCardCustomer } from '../../../api/rank/rankApi';
import useThrottle from '../../../hook/useThrottle';

const onVIPChange = (checked: boolean) => {
  console.log('vip change to', checked)
}
interface Props {
  infoCustomer?: ICustomerDetail;
}
const InformationTab: React.FC<Props> = ({
  infoCustomer
}) => {

  const navigator = useNavigate()
  const pushToast = useToast()

  const [isShowAllPhoneNumber, setShowAllPhoneNumber] = useState(false)
  const [isShowAllSource, setShowAllSource] = useState(false)
  const [isBenefitPopupShow, setBenefitPopupShow] = useState(false)
  const [isWarrantyHistoryShow, setWarrantyHistoryShow] = useState(false)
  const [isInfoPopupShow, setInfoPopupShow] = useState(false)

  const [pagination, setPagination] = useState({ current: 1, pageSize: 2, total: 0 });

  const [dataHistoryMaintenance, setDataHistoryMaintenance] = useState<historyApi.IHistoryMaintenance[]>()
  const [data, setData] = useState<historyApi.IHistoryCarDetail>()
  const [infoCardCustomer, setInfoCardCustomer] = useState<ICardCustomer>()


  const getListHistoryMaintenance = async (limit: number = 2, pageNumber: number = 1, customerId: string = '', carId: string = '') => {
    const result = await historyApi.getListHistoryMaintenance(limit, pageNumber, customerId, carId)
    if (result?.status) {
      setDataHistoryMaintenance(result.data);
      setPagination({ current: pageNumber, total: result.total, pageSize: limit })
    } else {
      pushToast(result.message, 'error', 1000)
    }
  }

  const gInfoCardCustomer = useThrottle(async () => {
    const result = await getInfoCardCustomer(Number(infoCustomer?.id))
    if (result?.status) {
      setInfoCardCustomer(result.data)
      setBenefitPopupShow(true)
    } else {
      pushToast(result.message, 'error', 1000)
    }
  }, 1000)

  const showAllPhoneNumber = () => {
    setShowAllPhoneNumber(!isShowAllPhoneNumber)
  }

  const showAllSource = () => {
    setShowAllSource(!isShowAllSource)
  }

  const handleButtonInfoClick = (info: boolean) => {
    setInfoPopupShow(info)
  };



  useEffect(() => {
    if (data && infoCustomer) {
      getListHistoryMaintenance(pagination.pageSize, pagination.current, infoCustomer?.id.toString(), data?.carId.toString())
    }
  }, [data])

  return (
    <>
      <div className="information-tab">
        <div className="information-tab__head">
          <div className="avatar-block">
            <img
              src={URL_CUSTOMER + infoCustomer?.image}
              alt="NVHH"
              className="avatar-block__avatar ob-cover"
              onError={(e) => {
                e.currentTarget.src = avatarDefault;
              }}
            />

            <div className="avatar-block__content">
              <div className="name">{infoCustomer?.userName}</div>

              <div
                className={`rank-block ${!infoCustomer?.rank?.title?.length ? "hidden" : ""}`}
              >
                <div
                  className={`item `}
                >
                  <div className="rank">
                    <img
                      src={URL_RANK + infoCustomer?.rank?.icon}
                      alt="Vàng"
                      className='rank-img'
                    />
                    <span className='rank-name'>{infoCustomer?.rank?.title}</span>
                    {/* <span className='rank-point primary-7'>(3000 Điểm)</span> */}
                  </div>
                </div>

                <div className="item">
                  <span
                    className="font-medium primary-7 cursor-pointer"
                    onClick={gInfoCardCustomer}
                  >Quyền lợi</span>
                </div>
              </div>
            </div>
          </div>

          <Button
            type="primary"
            className='rs-button'
            onClick={() => navigator(`/customer/update-customer/${infoCustomer?.id}`)}
          >
            Cập nhật
            <EditFilled />
          </Button>
        </div>

        <div className="information-tab__content">
          <div className="information-tab__content-left">
            <div className="block vip-block">
              <img src="/images/medal/vip-diamond.svg" alt="VIP" className='vip-block__img' />
              <span className="font-bold">VIP cần theo dõi chăm sóc định kỳ</span>
              <Switch defaultChecked onChange={onVIPChange} className='rs-switch' />
            </div>

            <div className="block">
              <span className="font-bold">Thông tin cá nhân</span>
            </div>

            <div className="information-table-show">
              <div className="item">
                <span className="item-left">Tình trạng hôn nhân</span>
                <div className="item-right">
                  <span className='neutral-gray-400'>{dataStatusMarital[Number(infoCustomer?.statusMarital || 0)].title}</span>
                </div>
              </div>
              <div className="item">
                <span className="item-left">Nguồn</span>
                <div className="item-right flex-column">
                  {
                    !isShowAllSource
                      ? <div className="first-item-of-list">
                        <span className='neutral-gray-400'>DDMS - Sales</span>
                        <Tag
                          className='rs-tag daybreak-blue no-border cursor-pointer'
                          onClick={showAllSource}
                        >
                          <PlusCircleFilled />
                          <span>2 nguồn</span>
                        </Tag>
                      </div>
                      : <>
                        <span className='neutral-gray-400'>DDMS - Sales</span>
                        <span className='neutral-gray-400'>IPCC</span>
                        <Tag
                          className='rs-tag daybreak-blue no-border cursor-pointer'
                          onClick={showAllSource}
                        >
                          <MinusCircleFilled />
                          <span>Thu gọn</span>
                        </Tag>
                      </>
                  }
                </div>
              </div>
              <div className="item">
                <span className="item-left">Thuộc</span>
                <div className="item-right">
                  <span className='neutral-gray-400'>Đại lý bán hàng ABC</span>
                </div>
              </div>
              <div className="item">
                <span className="item-left">Nghề nghiệp</span>
                <div className="item-right">
                  <span className='neutral-gray-400'>{infoCustomer?.profession}</span>
                </div>
              </div>
              <div className="item">
                <span className="item-left">Ngày sinh</span>
                <div className="item-right">
                  <span className='neutral-gray-400'>{infoCustomer?.birthday}</span>
                </div>
              </div>
              <div className="item">
                <span className="item-left">Giới tính</span>
                <div className="item-right">
                  <span className='neutral-gray-400'>{dataGender[Number(infoCustomer?.gender || 0)].title}</span>
                </div>
              </div>
              <div className="item">
                <span className="item-left">Số điện thoại</span>
                <div className="item-right flex-column">
                  {
                    !isShowAllPhoneNumber
                      ? <div className="first-item-of-list">
                        {/* <span className='neutral-gray-400'>{infoCustomer?.phone}</span> */}
                        <span className='neutral-gray-400'>{infoCustomer?.phone?.filter((item) => item.type === 1)[0].phone}</span>

                        <Tag
                          className='rs-tag daybreak-blue no-border cursor-pointer'
                          onClick={showAllPhoneNumber}
                        >
                          <PlusCircleFilled />
                          <span>{infoCustomer?.phone?.filter((item) => item.type === 0).length} số phụ</span>
                        </Tag>
                      </div>
                      : <>
                        {
                          infoCustomer?.phone?.filter((item) => item.type === 0)?.map((item, i) =>
                            <span className='neutral-gray-400'>{item.phone}</span>
                          )
                        }

                        {/* <span className='neutral-gray-400'>0977721241</span>
                        <span className='neutral-gray-400'>0977721240</span> */}
                        <Tag
                          className='rs-tag daybreak-blue no-border cursor-pointer'
                          onClick={showAllPhoneNumber}
                        >
                          <MinusCircleFilled />
                          <span>Thu gọn</span>
                        </Tag>
                      </>
                  }
                </div>
              </div>
              <div className="item">
                <span className="item-left">Email</span>
                <div className="item-right">
                  <span className='neutral-gray-400'>{infoCustomer?.email}</span>
                </div>
              </div>
              <div className="item">
                <span className="item-left">Địa chỉ</span>
                <div className="item-right">
                  <span className='neutral-gray-400'>{infoCustomer?.address}</span>
                </div>
              </div>
              <div className="item">
                <span className="item-left">Nhóm</span>
                <div className="item-right">
                  <div className="ant-tags">
                    <Tag className='rs-tag golden-purple'>Âm nhạc</Tag>
                    <Tag className='rs-tag golden-purple'>Thể thao</Tag>
                  </div>

                  {/* If empty */}
                  {/* <span className='neutral-gray-400'>Chưa xác định</span> */}
                </div>
              </div>
            </div>
          </div>

          <div className="information-tab__content-right">
            <div className="card-block">
              <div className="card-block__title">
                <span className="font-bold">Phân loại</span>
              </div>
              <div className="card-block__content">
                <div className="ant-tags">
                  {
                    infoCustomer?.classifys && infoCustomer.classifys.map((item, i) =>
                      <Tag
                        className='rs-tag geek-blue'
                        key={i}
                      >{item.title}
                      </Tag>

                    )
                  }
                  {/* <Tag className='rs-tag geek-blue'>Doanh nghiệp</Tag>
                  <Tag className='rs-tag geek-blue'>Khó tính</Tag>
                  <Tag className='rs-tag geek-blue'>Giàu có</Tag> */}
                </div>

                {/* If empty */}
                {/* <Tag className='rs-tag neutral-gray'>Chưa xác định</Tag> */}
              </div>
            </div>

            <div className="card-block">
              <div className="card-block__title">
                <span className="font-bold">Sở thích</span>
              </div>
              <div className="card-block__content">
                <div className="ant-tags">
                  {
                    infoCustomer?.hobby && infoCustomer.hobby.map((item, i) =>
                      <Tag
                        className='rs-tag golden-purple'
                        key={i}
                      >{item.title}</Tag>
                    )
                  }
                  {/* <Tag className='rs-tag golden-purple'>Âm nhạc</Tag>
                  <Tag className='rs-tag golden-purple'>Thể thao</Tag> */}
                </div>

                {/* If empty */}
                {/* <Tag className='rs-tag neutral-gray'>Chưa xác định</Tag> */}
              </div>
            </div>

            <div className="card-block">
              <div className="card-block__title">
                <span className="font-bold">Xe quan tâm</span>
              </div>
              <div className="card-block__content">
                <InterestedVehicleList
                  listCarCare={infoCustomer?.carCare}
                />

                {/* If Empty */}
                {/* <EmptyComponent /> */}
              </div>
            </div>

            <div className="card-block">
              <div className="card-block__title">
                <span className="font-bold">Xe sở hữu</span>
              </div>
              <div className="card-block__content">
                <OwnedVehicle
                  setData={setData}
                  listOwnedVehicle={infoCustomer?.history}
                  onButtonClick={handleButtonInfoClick}
                />

                {/* <OwnedVehicle
                  onButtonClick={handleButtonInfoClick}
                /> */}

                {/* If Empty */}
                {/* <EmptyComponent /> */}
              </div>
            </div>

            <div className="card-block">
              <div className="card-block__title">
                <span className="font-bold">Danh sách người thân</span>
              </div>
              <div className="card-block__content">
                <RelativeList
                  listRelatives={infoCustomer?.relatives}
                />
              </div>
            </div>
          </div>
        </div>
      </div >

      {
        isInfoPopupShow
          ? <VehicleInfoPopup
            className="show"
            data={data}
            onClose={() => { setInfoPopupShow(false) }}
            dataHistoryMaintenance={dataHistoryMaintenance ?? []
            }
            onShowHistory={() => { setWarrantyHistoryShow(true) }}
          />
          : null
      }
      {
        isWarrantyHistoryShow
          ? <WarrantyHistoryPopup
            className="show"
            onClose={() => { setWarrantyHistoryShow(false) }}
            // setPagination={setPagination}
            customerId={infoCustomer?.id.toString()}
            cardId={data?.carId.toString()}
          />
          : null
      }
      {
        isBenefitPopupShow
          ? <RankBenefitPopup
            className="show"
            infoCardCustomer={infoCardCustomer}
            onClose={() => { setBenefitPopupShow(false) }}
          />
          : null
      }
    </>
  );
}

export default InformationTab;
