
import axiosClient, { ResponseSuccess } from "../axiosClient";


export const activeArray = [
    "Chưa kích hoạt", "Đang sử dụng"
]
export interface IListCustomer {
    id: number;
    name: string;
    CCCD?: any;
    phone: string;
    birthday: string;
    gender: number;
    profession: string;
    statusMarital: number;
    address: string;
    email: string;
    province: string;
    interestedCar: string;
    rankTitle: RankTitle;
}
export interface IListCustomerBenefit {
    id: number;
    name: string;
    phone: string;
}
export interface RankTitle {
    id?: any;
    title: string;
    icon?: any;
}
export interface IPhone {
    id: number
    phone: string
}
export interface dataConvert {
    id: number,
    title: string,
    level: number,
    parentId: number | null
}
export interface UnitLevel {
    id: number
    code: string
    title: string
    view: number
    level: number
    parentId: any
    children: Children[]
}

export interface Children {
    id: number
    code: string
    title: string
    view: number
    level: number
    parentId: number
    children: Children[]
}
export interface relationshipProp {
    name: string,
    hobby: number[],
    relationship: number | null
}
export interface ISelectOption {
    value: number
    label: string
}

export interface Phone {
    type: number,
    phone: string,
}
export interface IProvince {
    id: number
    areaId: number
    title: string
}

export interface IHobby {
    id: number
    title: string
}

export interface ITag {
    id: number
    title: string
}
export interface IGroup {
    title: string
    id: number
}

export interface ICarCompany {
    id: number
    title: string
    image: string
}
export interface ICar {
    id: number
    title: string
    image: string
    seatingCapacity: number
    carLinesTitle: string
    categoryCar: string
}

export interface IRelative {
    id: number
    title: string
}
export interface IRank {
    id: number
    description: any
    title: string
}
export interface IArea {
    id: number
    title: string
    countCustomer: number
    province: Province[]
}

export interface Province {
    id: number
    title: string
    customerCount: number
}
export interface ICategoryCar {
    id: number
    title: string
    image: string
    historyCount: number
}
export interface IUnitOption {
    id: number
    code: string
    title: string
    parentId: number
    view: number
    level: number
}
// Khối nghiệp vụ
export interface IBusinessUnit {
    id: number
    code: string
    title: string
    status: number
}
export interface IListAgent {
    id: number;
    code: string;
    title: string;
}

export interface ITemplate {
    id: number
    title: string
    status: number
    description: string
    code: string
    targetId: number
    iamgeTypeZNS: string
}

export interface IUnitChild {
    id: number
    code: string
    title: string
    parentId: number
    view: number
    level: number
}

export const deleteBusinessUnit = async (
    id: number
) => {
    const path = "businessUnit/deleteBusinessUnit"
    const params = {
        id
    }
    const result: ResponseSuccess<[]> = await axiosClient.delete(
        path, { params }
    );
    return result;
};
export const addBusinessUnit = async (
    code: string, title: string
) => {
    const path = "businessUnit/addBusinessUnit"
    const params = {
        code, title
    }
    const result: ResponseSuccess<[]> = await axiosClient.post(
        path, params
    );
    return result;
};
export const getListBusinessUnit = async () => {
    const path = "businessUnit/listBusinessUnit"
    const result: ResponseSuccess<IBusinessUnit[]> = await axiosClient.get(
        path, {}
    );
    return result;
};
// end khối nghiệp vụ

export const getListUnitOption = async () => {
    const path = "apiChild/listUnit"
    const result: ResponseSuccess<IUnitOption[]> = await axiosClient.get(
        path, {}
    );
    return result;
};
// option khách hàng cho Phát hành thẻ
export const getListUnreleased = async () => {
    const path = "rank/listUnreleased"
    const result: ResponseSuccess<IListCustomerBenefit[]> = await axiosClient.get(
        path, {}
    );
    return result;
};

export const getlistCustomer = async () => {
    const path = "customer/listCustomer"
    const result: ResponseSuccess<IListCustomer[]> = await axiosClient.get(
        path, {}
    );
    return result;
};

export const getListAgent = async () => {
    const path = "apiChild/listAgent"
    const result: ResponseSuccess<IListAgent[]> = await axiosClient.get(
        path, {}
    );
    return result;
};

export const getValidatePhone = async (phone?: string[]) => {
    const path = "apiChild/validatePhone"
    const params = { phone }
    const result: ResponseSuccess<IPhone[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};

export const getValidateMail = async (email: string) => {
    const path = "apiChild/validateEmail"
    const params = { email }
    const result: ResponseSuccess<[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};

export const getListNationwide = async () => {
    const path = "unit/listNationwide"
    const result: ResponseSuccess<UnitLevel[]> = await axiosClient.get(
        path, {}
    );
    return result;
};

export const getListCategoryCar = async () => {
    const path = "filter/categoryCar"
    const result: ResponseSuccess<ICategoryCar[]> = await axiosClient.get(
        path, {}
    );
    return result;
};

export const getListArea = async () => {
    const path = "filter/area"
    const result: ResponseSuccess<IArea[]> = await axiosClient.get(
        path, {}
    );
    return result;
};

export const getListRank = async () => {
    const path = "rank/listRank"
    const result: ResponseSuccess<IRank[]> = await axiosClient.get(
        path, {}
    );
    return result;
};

export const getListProvince = async () => {
    const path = "province/listProvince"
    const result: ResponseSuccess<IProvince[]> = await axiosClient.get(
        path, {}
    );
    return result;
};

export const getListHobby = async () => {
    const path = "hobby/listHobby"
    const result: ResponseSuccess<IHobby[]> = await axiosClient.get(
        path, {}
    );
    return result;
};

export const getListTag = async () => {
    const path = "tags/listTags"
    const result: ResponseSuccess<ITag[]> = await axiosClient.get(
        path, {}
    );
    return result;
};

export const getListGroup = async (search?: string) => {
    const params = { search }
    const path = "apiChild/listGroupCustomer"
    const result: ResponseSuccess<IGroup[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};


export const getListCarCompany = async () => {
    const path = "apiChild/listCategoryCar"
    const result: ResponseSuccess<ICarCompany[]> = await axiosClient.get(
        path, {}
    );
    return result;
};


export const getListCar = async (categoryCarId?: number | null, search?: string) => {
    const params = { categoryCarId, search }
    const path = "apiChild/listCar"
    const result: ResponseSuccess<ICar[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};

export const getListRelatives = async () => {
    const path = "relatives/listRelatives"
    const result: ResponseSuccess<IRelative[]> = await axiosClient.get(
        path, {}
    );
    return result;
};


//Danh sách mục tiêu
export const getListTarget = async () => {
    const path = "apiChild/listTarget"
    const result: ResponseSuccess<IRelative[]> = await axiosClient.get(
        path, {}
    );
    return result;
};


//Danh sách mẫu thông báo
export const getListTemplate = async (channelId: Number) => {
    const path = "apiChild/listTemplate"
    const params = { channelId }
    const result: ResponseSuccess<ITemplate[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};


//Danh sách đơn vị
export const getListUnitChild = async () => {
    const path = "apiChild/listUnit"
    const params = {}
    const result: ResponseSuccess<IUnitChild[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};