import { CloseOutlined, GlobalOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons"
import { Form, FormInstance, FormProps, Input, Modal, notification, Radio, Select } from "antd"
import TextArea from "antd/es/input/TextArea"
import { useEffect, useRef, useState } from "react"
import { useForm } from 'antd/lib/form/Form';
import './style.scss'
import { IDuplicate, addGroup, addGroupCombination } from "../../../../../api/groupCustomer/groupCustomerApi";
import * as selectOptionsApi from '../../../../../api/selectOptions/selectOptionsApi';
import *as unitApi from "../../../../../api/unit/unitApi";
import useToast from "../../../../../hook/useToast";
import { fommatSelectOption } from "../../../../../until";
import useThrottle from "../../../../../hook/useThrottle";
import { useNavigate } from "react-router-dom";
interface ModalProps {
  isOpen: boolean
  infoDuplicate?: IDuplicate
  closeModal: () => void
}
const selectTagGroup = [
  { value: 1, label: 'Quảng cáo' },
  { value: 2, label: 'Chăm sóc' },
]
const ModalCreateGroupCustomer: React.FC<ModalProps> = ({
  isOpen, infoDuplicate,
  closeModal,
}) => {

  const pushToast = useToast();
  const navigate = useNavigate()

  const [form] = useForm();
  const inputRef = useRef<FormInstance>(null);
  const [valuesTagGroup, setValuesTagGroup] = useState<number[]>([]);
  const [openTagGroup, setOpenTagGroup] = useState(false);
  const [unit, setUnit] = useState<number | undefined>(undefined)
  const [condition, setcondition] = useState(false)

  const [isShare, setIsShare] = useState(0)

  const [sLTag, setSLTag] = useState<selectOptionsApi.ITag[]>([])
  const [sLUnit, setSLUnit] = useState<unitApi.IUnit[]>([])

  const gSLTag = async () => {
    const result = await selectOptionsApi.getListTag()
    if (result?.status) {
      setSLTag(result.data)
    } else {
      pushToast(result?.message, "warning")
    }
  }

  const gSLUnit = async () => {
    const result = await unitApi.getListUnit()
    if (result?.status) {
      setSLUnit(result.data)
    } else {
      pushToast(result?.message, "warning")
    }
  }


  const handleChangeTagGroup = (selectedValues: number[]) => {
    setValuesTagGroup(selectedValues);
    setOpenTagGroup(false)
    form.setFieldsValue({ typetag: selectedValues });
    form.validateFields(['typetag']);

  };

  const handleChangeUnit = (value: number) => {
    setUnit(value);
    form.setFieldsValue({ unit: value });
  }

  const submitForm = () => {
    if (inputRef.current) {
      inputRef.current.submit();
    }
  };

  const onFinish: FormProps["onFinish"] = (values) => {
    onAddSubmitFormModal(values)
  };

  const onAddSubmitFormModal = useThrottle(async (values: any) => {
    const status = isShare ? 1 : 0
    const { unit, namegroup, introduce, typetag } = values
    const type = condition ? 1 : 0

    const result = await addGroupCombination(
      Number(infoDuplicate?.group1.id), Number(infoDuplicate?.group2.id), status, namegroup, introduce, typetag, type, unit
    )
    if (result?.status) {
      closeModal()
      navigate("/customer/group")
    } else {
      pushToast(result?.message, "warning")
    }

  }, 1000)

  useEffect(() => {
    if (isOpen && sLTag.length === 0) {
      gSLTag()
      gSLUnit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  useEffect(() => {
    if (isOpen) {

    } else {
      setIsShare(0)
      setValuesTagGroup([])
      setcondition(false)
      setUnit(undefined)
      setOpenTagGroup(false)

      form.setFieldValue('unit', undefined)
      form.setFieldValue('namegroup', undefined)
      form.setFieldValue('introduce', "")
      form.setFieldValue('typetag', undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])
  return (
    <Form
      form={form}
      ref={inputRef}
      onFinish={onFinish}
      initialValues={{ remember: true }}
    >
      <Modal
        title={<div className="bg-FCFCFD d-flex justify-betweeen align-center py-4 px-6 w-full border-b-F0F0F0">
          <span className="text-base text-344054 fw-500 font-medium">Tạo nhóm khách hàng</span>
          <CloseOutlined className="text-base text-8B8B8B" onClick={closeModal} />
        </div>}
        open={isOpen}
        onCancel={closeModal}
        centered
        className="w-modal modal-content-update-group ant-modal-size"
        closeIcon={false}
        footer={<div className="bg-FCFCFD d-flex justify-betweeen align-center py-2 px-6 w-full border-t-F0F0F0">
          <span className="text-sm text-98A2B3 cursor-pointer" onClick={closeModal}>Làm mới thông tin</span>
          <button className="text-white text-sm bg-096DD9 border-none shadow-btn-save rounded-2 h-8 px-15-px"
            onClick={submitForm}>
            Lưu
          </button>
        </div>}
      >
        <div className="p-6 d-flex flex-column gap-18-px">
          <div className="w-313-px d-flex flex-column">
            <Radio.Group value={isShare} onChange={(e) => setIsShare(e.target.value)} className="w-full ">
              <Radio.Button value={0} className="w-138-px h-8 border-start-radio p-none-wrapper" >
                <div className="d-flex align-center gap-2 h-8 px-3">
                  <UserOutlined className="text-sm" />
                  <p className="text-sm font-regular">Nhóm riêng tư</p>
                </div>
              </Radio.Button>
              <Radio.Button value={1} className=" h-8 border-end-radio p-none-wrapper">
                <div className="d-flex align-center gap-2 h-8  px-3">
                  <GlobalOutlined className="text-sm" />
                  <p className="text-sm font-regular white-space-nowrap">Nhóm có thể chia sẻ</p>
                </div>
              </Radio.Button>
            </Radio.Group>

          </div>

          <div className="d-flex flex-column gap-18-px">
            {
              isShare === 1 && (
                <Form.Item
                  name="unit"
                  rules={[{ required: isShare === 1, message: 'Chọn đơn vị!' }]}
                  className="m-none-form-item text-explan"
                >
                  <div className='w-full'>
                    <Select
                      placeholder="Chọn đơn vị"
                      value={unit}
                      onChange={handleChangeUnit}
                      options={fommatSelectOption(sLUnit)}
                      className="custome-select-t "
                      popupClassName='popup-select'
                    />
                  </div>
                </Form.Item>
              )
            }

            <Form.Item
              name="namegroup"
              rules={[{ required: true, message: 'Nhập tên nhóm!' }]}
              className="m-none-form-item text-explan"
            >
              <Input
                placeholder="Tên nhóm"
                className="bg-FCFCFD border-F2F4F7 text-sm rounded-2  font-regular  input-custom  h-8"
              />
            </Form.Item>

            <Form.Item
              name="introduce"
              rules={[{ required: true, message: 'Giới thiệu về nhóm!' }]}
              className="m-none-form-item text-explan"
            >
              {/* <div className="d-flex flex-column"> */}
              <TextArea placeholder='Giới thiệu về nhóm này' className="text-area-hover custom-text-area bg-FCFCFD text-area-input border-F2F4F7" />
              {/* </div> */}
            </Form.Item>

            <Form.Item
              name="typetag"
              rules={[{ required: true, message: 'Chọn nhóm khách hàng bằng thẻ tag' }]}
              className="m-none-form-item text-explan"
            >
              <div className="d-flex flex-column gap-3 w-full">
                <Select
                  placeholder="Phân loại nhóm khách hàng bằng thẻ tag"
                  mode="multiple"
                  value={valuesTagGroup}
                  options={fommatSelectOption(sLTag)}
                  onChange={handleChangeTagGroup}
                  onDropdownVisibleChange={setOpenTagGroup}
                  open={openTagGroup}
                  className="custom-select-mutiple d-none-span "
                  popupClassName='popup-select'
                  suffixIcon={<SearchOutlined />}
                />
                <div className={`${valuesTagGroup.length !== 0 ? ' d-flex align-center gap-2' : 'none'}`}>
                  {
                    valuesTagGroup.map((value, index) => {
                      const selectedOption = fommatSelectOption(sLTag).find(option => option.value === value);
                      if (!selectedOption) return null;
                      return (
                        <p key={index} className="bg-F0F5FF border-ADC6FF rounded-2 text-2F54EB text-xs px-2 d-flex align-center gap-3-px h-22-px font-regular">
                          {selectedOption.label}
                          <CloseOutlined className="text-84878C text-xs cursor-pointer" onClick={() => handleChangeTagGroup(valuesTagGroup.filter(v => v !== value))} />
                        </p>
                      );
                    })
                  }
                </div>
              </div>
            </Form.Item>
          </div>
        </div>
      </Modal>
    </Form >
  )
}
export default ModalCreateGroupCustomer
