import { Tag } from 'antd';
import React from 'react'
import './RelativeItem.scss'
import { Rela } from '../../api/customer/customerApi';

interface Props {
  item?: Rela
}

const RelativeItem: React.FC<Props> = ({
  item
}) => {
  return (
    <div className="relative-item">
      <div className="relative-item__top">
        <div className="tag">
          <Tag className='rs-tag daybreak-blue no-border'>{item?.relationship.title}</Tag>
        </div>
        <span className="name font-medium">{item?.name}</span>
      </div>

      <div className="relative-item__bottom">
        <span>Sở thích</span>
        <div className="ant-tags">
          {
            item?.hobby.map((item, index) => (
              <Tag
                key={index}
                className='rs-tag golden-purple'
              >{item.title}</Tag>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default RelativeItem;