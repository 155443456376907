import { paserParams } from "../../until";
import axiosClient, { ResponseSuccess, ResponseSuccessTotal } from "../axiosClient";

export type SortType = 0 | 1

export interface ICarLine {
    id: number
    carLines: string
    categoryCar: string
    status: number
}

export interface ICarLineEdit {
    id: number
    title: string
    categoryCarId: number
    carLinesId: number
}

export const getListCarLine = async (
    limit?: number, pageNumber?: number, searchNameCategoryCar?: string, sort?: SortType, searchIdCategoryCar?: number) => {
    const path = "carLines/listCarLines"
    const params = {
        limit, pageNumber, searchNameCategoryCar, sort, searchIdCategoryCar
    }
    const result: ResponseSuccessTotal<ICarLine[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Thông tin dòng xe
export const getInfoCarLine = async (
    id: number
) => {
    const path = "carLines/infoCarLines"
    const params = {
        id
    }
    const result: ResponseSuccessTotal<ICarLineEdit> = await axiosClient.get(
        path, { params }
    );
    return result;
};


//Thêm mới dòng xe
export const addCarLines = async (
    title: string, idCategoryCar: Number
) => {
    const url = `carLines/addCarLines`;
    const params = {
        title, idCategoryCar
    }
    const result: ResponseSuccess<[]> = await axiosClient.post(
        url, paserParams(params, undefined)
    );
    return result;
}

//Chỉnh sửa dòng xe
export const editCarLines = async (
    idCarlines: number, title: string, idCategoryCar: Number
) => {
    const url = `carLines/editCarLines`;
    const params = {
        idCarlines, title, idCategoryCar
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        url, paserParams(params, undefined)
    );
    return result;
}

//Xóa dòng xe
export const deleteCarLines = async (
    id: Number
) => {
    const url = `carLines/deleteCarLines`;
    const params = {
        id
    }
    const result: ResponseSuccess<[]> = await axiosClient.delete(
        url, { params }
    );
    return result;
}

export const updateActiveCarLine = async (
    id: number) => {
    const path = "carLines/activeCarLines"
    const params = {
        id
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        path, params
    );
    return result;
};