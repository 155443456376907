import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface InfoAccount {
    username: string
    password: string
}


const initialUserState: InfoAccount = {
    username: "",
    password: "",
}

const userSlice = createSlice({
    name: 'dataAcccount',
    initialState: initialUserState,
    reducers: {
        setDataAcccount: (state, action: PayloadAction<InfoAccount>) => action.payload,
        clearSaveAcccount: (state) => initialUserState
    }
})

export const selectorDataAcccount = (state: { dataAcccount: InfoAccount }) => state.dataAcccount;
export const { setDataAcccount, clearSaveAcccount } = userSlice.actions;
export default userSlice.reducer;