/* eslint-disable react-hooks/exhaustive-deps */
import { CalendarOutlined, CloseCircleOutlined, CloseOutlined, CompassOutlined, DownOutlined, FolderOpenOutlined, HomeOutlined, MailOutlined, PhoneOutlined, PlusCircleFilled, PlusSquareOutlined, SearchOutlined, UserOutlined } from "@ant-design/icons";
import { DatePicker, Form, Input, Radio, Select, Switch } from "antd";
import dayjs from "dayjs";
import { ChangeEvent, useEffect, useState } from "react";
import * as selectOptionsApi from '../../../../../api/selectOptions/selectOptionsApi';
import { URL_CUSTOMER } from "../../../../../env";
import { changePercent, changePercentDate, changePercentGroup } from "../../../../../until";
import './style.scss';

interface Props {
  errorObject: {
    email: string;
    profession: string;
    userName: string;
    image: string;
    phone: string[];
    birthday: string;
    province: string;
    address: string;
    group: string;
    tag: string;
    hobby: string;
    car: string;
  },
  error: boolean,
  setError: (e: boolean) => void,
  sLProvince: selectOptionsApi.ISelectOption[],
  sLHobby: selectOptionsApi.ISelectOption[],
  sLTag: selectOptionsApi.ISelectOption[],
  sLGroup: selectOptionsApi.ISelectOption[],
  setProvinceId: (value: number | null) => void,
  provinceId: number | null,
  setValuesGroup: (value: number[]) => void,
  valuesGroup: number[]
  setValuesTag: (value: number[]) => void,
  valuesTag: number[]
  setValuesInterest: (value: number[]) => void,
  valuesInterest: number[]
  setUserName: (e: string) => void,
  userName: string
  setEmail: (e: string) => void,
  email: string
  setProfession: (e: string) => void,
  profession: string
  setMarri: (e: number) => void,
  marri: number
  setSex: (e: number) => void,
  sex: number
  setDate: (e: string) => void,
  date: string
  setAddress: (e: string) => void,
  address: string
  setPhoneNumbers: (e: selectOptionsApi.Phone[]) => void,
  phoneNumbers: selectOptionsApi.Phone[]
  setValueCar: (e: number[]) => void,
  valueCar: number[],
  setUploadedFile: (e: File | null) => void,
  uploadedFile: File | null
  image?: string
  valueCategoryCar?: number
  percent: number
  setPercent: (e: number) => void
}


const InfoPersonal: React.FC<Props> = ({
  sLProvince, sLHobby, valuesInterest, sLTag, valuesTag, sLGroup, valuesGroup, userName, marri, sex, date, address, phoneNumbers, valueCar, errorObject, error, provinceId, uploadedFile, email, profession, image, valueCategoryCar, percent, setProvinceId, setValuesInterest, setValuesTag, setValuesGroup, setUserName, setMarri, setSex, setDate, setAddress, setPhoneNumbers, setValueCar, setError, setUploadedFile, setEmail, setProfession, setPercent
}) => {

  const [openlistCar, setOpenlistCar] = useState(false);
  const [openGroup, setOpenGroup] = useState(false);
  const [openTag, setOpenTag] = useState(false);
  const [openInterest, setOpenInterest] = useState(false);
  const [carCompany, setCarCompany] = useState<selectOptionsApi.ISelectOption[]>([])
  const [dataCar, setDataCar] = useState<selectOptionsApi.ISelectOption[]>([])
  const [percentChanged, setPercentChanged] = useState(false);

  const [placeholderCount, setPlaceholderCount] = useState<number>(1);
  const addPhoneNumber = () => {
    setPhoneNumbers([...phoneNumbers, { phone: '', type: 0 }]);
    setPlaceholderCount(placeholderCount + 1);

  };
  const removePhoneNumber = (indexToRemove: number) => {
    if (phoneNumbers[indexToRemove].type === 1) {
      const newPhoneNumbers = phoneNumbers.filter((_, index) => index !== indexToRemove);
      setPhoneNumbers(newPhoneNumbers);
      phoneNumbers[0].type = 1
    } else {
      const newPhoneNumbers = phoneNumbers.filter((_, index) => index !== indexToRemove);
      setPhoneNumbers(newPhoneNumbers);
    }

  };
  const handleSwitchChange = (index: number) => {
    const newPhoneNumbers = phoneNumbers.map(phoneNumber => ({ ...phoneNumber, type: 0 }));
    setPhoneNumbers(newPhoneNumbers);
    newPhoneNumbers[index].type = 1;
    setPhoneNumbers(newPhoneNumbers);
  };
  const handleChangeListCar = (selectedValues: number[]) => {
    setPercent(changePercentGroup(percent, 6, valueCar, selectedValues))
    setValueCar(selectedValues)
    setOpenlistCar(false)
  };

  const handleChangeGroup = (selectedValues: number[]) => {
    setValuesGroup(selectedValues)
    setOpenGroup(false)
    setPercent(changePercentGroup(percent, 6, valuesGroup, selectedValues))

  };
  const handleChangeTag = (selectedValues: number[]) => {
    setValuesTag(selectedValues)
    setPercent(changePercentGroup(percent, 6, valuesTag, selectedValues))
    setOpenTag(false)
  };
  const handleChangeInterest = (selectedValues: number[]) => {
    setPercent(changePercentGroup(percent, 6, valuesInterest, selectedValues))
    setValuesInterest(selectedValues);
    setOpenInterest(false)
  };

  const handleDateChange = (dateValue: any) => {
    if (dateValue && dateValue.isValid && dateValue.isValid()) {
      // Checking if dateValue exists and if it's a moment object and if it's valid
      const dateString = dateValue.format('YYYY-MM-DD'); // Formatting the date string
      setDate(dateString);
      setPercent(changePercentDate(percent, 6, date, dateString))

    } else {
      setDate('');
    }
  };
  const handleChangUserName = (e: string) => {
    setPercent(changePercent(percent, 6, userName, e))
    setUserName(e)
  }
  const handleChangProfession = (e: string) => {
    setPercent(changePercent(percent, 6, profession, e))
    setProfession(e)
  }

  const getListCarCompany = async () => {
    const result = await selectOptionsApi.getListCarCompany();
    if (result?.status) {
      setCarCompany(convertData(result.data))
    } else {
    }
  }

  const gListCar = async (Id: number | null) => {
    const result = await selectOptionsApi.getListCar(Id, undefined);
    if (result?.status) {
      setDataCar(convertData(result.data))
    } else {
    }
  }

  const convertData = (data: selectOptionsApi.IHobby[]) => {
    const convertData = data.map((item) => ({
      value: item.id,
      label: item.title,
    }))
    return convertData
  }

  useEffect(() => {
    if (!image) {
      setPercent(percent + 6)
    }
  }, [image])

  useEffect(() => {
    getListCarCompany()
    gListCar(null)
  }, [])

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    if (file) {
      setUploadedFile(file);
    }
  };

  const handleUploadClick = (event: React.MouseEvent) => {
    event.preventDefault();
    const inputElement = document.querySelector('input[type="file"]') as HTMLInputElement | null;
    if (inputElement) {
      inputElement.click();
    }
  };

  const handlePhoneNumberChange = (index: number, value: string) => {
    if (Number(value) < 0) {
      return;
    }
    const newPhoneNumbers = [...phoneNumbers];
    const arr = newPhoneNumbers.filter((item) => item.phone !== '')
    if (value === '') {
      if (arr.length > 1) {
      } else {
        setPercent(percent - 6)
      }
    } else if (!newPhoneNumbers.some((item) => item.phone !== '') && value !== '') {
      setPercent(percent + 6)
    }
    newPhoneNumbers[index].phone = value;
    setPhoneNumbers(newPhoneNumbers)
  };

  return (
    <div className="d-flex flex-column align-baseline gap-3 pt-29-px">
      <div className="d-flex flex-column w-full gap-10-px">
        <p className="text-344054 text-base fw-500 font-medium">Thông tin cá nhân</p>
        <div className="w-64-px h-64-px rounded-100-px bg-EAECF0 d-flex justify-center align-center relative">
          {
            uploadedFile
              ? (
                <div>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                  <img src={URL.createObjectURL(uploadedFile)} alt="Uploaded" className="w-64-px h-64-px ob-cover rounded-100-px cursor-pointer" onClick={handleUploadClick} />
                </div>

              ) : (
                <div>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                  {image ? <img src={URL_CUSTOMER + image} alt="Uploaded" className="w-64-px h-64-px ob-cover rounded-100-px cursor-pointer" onClick={handleUploadClick} /> : <UserOutlined className="text-32-px text-white" />}


                  <PlusCircleFilled className="text-24-px text-40A9FF absolute bottom-0 right_-5-px" onClick={handleUploadClick} />
                </div>
              )
          }

        </div>
        {errorObject.image &&
          <div className=" ant-form-item-explain-error " style={{ color: '#ff4d4f' }}>
            {errorObject.image}
          </div>}

        <Form.Item
          className="m-none-form-item text-explan"
        >

          <div className="w-full border-F2F4F7 bg-FCFCFD rounded-2 d-flex align-center gap-2 px-3 py-2-px h-10  overflow-hidden">
            <UserOutlined className="text-sm text-344054" />
            <Input
              value={userName}
              onChange={(e) => {
                handleChangUserName(e.target.value)
              }}
              placeholder="Họ và tên"
              className="bg-FCFCFD place-holder-input-98A2B3 text-sm border-none custom-input-focus none-focus-erro font-regular p-none-input hover-none"
            />
          </div>
        </Form.Item>
        {errorObject.userName &&
          <div className=" ant-form-item-explain-error " style={{ color: '#ff4d4f' }}>
            {errorObject.userName}
          </div>
        }
        <Form.Item
          className="m-none-form-item text-explan"
        >
          <Radio.Group value={sex} onChange={(e) => setSex(e.target.value)} className="w-full ">
            <Radio.Button value={1} className="w-half h-8 border-start-radio p-none-wrapper border-radio-checked" >
              <div className="d-flex align-center gap-2 h-8 px-3">
                <img src='/images/exportImg/Man_icon.svg' className="w-14-px h-14-px" alt="" />
                <p className="text-sm font-regular">Nam</p>
              </div>

            </Radio.Button>
            <Radio.Button value={0} className="w-half h-8 border-end-radio p-none-wrapper border-radio-checked">
              <div className="d-flex align-center gap-2 h-8  px-3">
                <img src='/images/exportImg/Woman_icon.svg' className="w-14-px h-14-px" alt="" />
                <p className="text-sm font-regular">Nữ</p>
              </div>
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: 'Chọn tình trạng hôn nhân!' }]}
          className="m-none-form-item text-explan"
          initialValue={marri}

        >
          <Radio.Group value={marri} onChange={(e) => setMarri(e.target.value)} className="w-full">
            <Radio.Button value={0} className="w-half h-8 border-start-radio p-none-wrapper border-radio-checked">
              <div className="d-flex justify-start text-sm h-8 align-center font-regular px-4">
                Độc thân
              </div>

            </Radio.Button>
            <Radio.Button value={1} className="w-half h-8 border-end-radio p-none-wrapper border-radio-checked">
              <div className="d-flex justify-start text-sm h-8 align-center font-regular px-4">
                Đã kết hôn
              </div>
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          className="m-none-form-item text-explan"
        >

          <div className="w-full border-F2F4F7 bg-FCFCFD rounded-2 d-flex align-center gap-2 px-3 py-2-px h-10  overflow-hidden">
            <FolderOpenOutlined className="text-sm text-344054" />
            <Input
              value={profession}
              onChange={(e) => {
                handleChangProfession(e.target.value);
              }}
              placeholder="Nghề nghiệp"
              className="bg-FCFCFD place-holder-input-98A2B3 text-sm border-none custom-input-focus none-focus-erro font-regular p-none-input hover-none"
            />
          </div>
        </Form.Item>
        {errorObject.profession &&
          <div className=" ant-form-item-explain-error " style={{ color: '#ff4d4f' }}>
            {errorObject.profession}
          </div>
        }
        <Form.Item
          className="m-none-form-item text-explan"
        >
          <div className="d-flex flex-column gap-10-px">
            {
              phoneNumbers.map((phoneNumber, index) => (
                <div key={index} className="d-flex flex-column gap-2">
                  <div className="d-flex align-center justify-between">
                    <div className={` border-F2F4F7 bg-FCFCFD rounded-2 d-flex align-center px-3 py-2-px h-10 gap-2  overflow-hidden ${phoneNumbers.length > 1 ? 'w-386-px' : 'w-full'}`}>
                      <PhoneOutlined className="text-sm text-344054" />
                      <Input
                        placeholder={`${index > 0 ? `Số điện thoại ${index + 1}` : 'Số điện thoại'}`}
                        value={phoneNumber.phone}
                        type="number"
                        onChange={(e) => handlePhoneNumberChange(index, e.target.value)}
                        className="bg-FCFCFD text-sm border-none custom-input-focus none-focus-erro font-regular p-none-input place-holder-input-98A2B3 hover-none"
                      />
                    </div>
                    <div className="d-flex align-center">
                      {
                        index > 0 && (
                          <CloseOutlined className="text-base text-98A2B3 cursor-pointer"
                            onClick={() => removePhoneNumber(index)}
                          />
                        )
                      }
                      <div className={` ${phoneNumbers.length > 1 ? "pl-10-px" : "none"}`}>
                        <Switch
                          checkedChildren="Số chính"
                          unCheckedChildren="Số chính"
                          checked={phoneNumber.type === 1 ? true : false}
                          onChange={() => handleSwitchChange(index)}
                          className="custom-switch switch-handle switch-checked switch-inner size-text-checked siz-text-unchecked switch-inset switch-before switch-m switch-mt m-none-switch"
                        />
                      </div>
                    </div>
                  </div>
                  {errorObject.phone[index] && errorObject.phone[index] !== '' ?
                    <div className="ant-form-item-explain-error">
                      {errorObject.phone[index]}
                    </div> : ''}
                </div>
              ))
            }
          </div>
        </Form.Item>

        <div className="d-flex align-center gap-10-px pl-7-px cursor-pointer" onClick={addPhoneNumber}>
          <PlusSquareOutlined className="text-sm text-1890FF" />
          <span className="text-sm text-1890FF font-regular fw-400">Thêm số điện thoại mới</span>
        </div>
        <div className="w-full border-F2F4F7 bg-FCFCFD rounded-2 d-flex align-center px-3 py-2-px gap-2 h-10">
          <CalendarOutlined className="text-sm text-344054" />
          <DatePicker
            placeholder="Ngày/Tháng/Năm sinh"
            variant="borderless"
            value={date ? dayjs(date, 'YYYY-MM-DD') : null}
            onChange={(e) => handleDateChange(e)}
            format='DD/MM/YYYY'
            className="w-full text-input font-regular fw-400 text-sm p-none-picker size-ant-picker"
            popupClassName="t-ctrl-date-picker"
            suffixIcon={false}
            allowClear={false}
          />

        </div>
        {errorObject.birthday &&
          <div className=" ant-form-item-explain-error " style={{ color: '#ff4d4f' }}>
            {errorObject.birthday}
          </div>}
        <Form.Item
          className="m-none-form-item text-explan"
        >
          <div className="w-full border-F2F4F7 bg-FCFCFD rounded-2 d-flex align-center gap-2 px-3 py-2-px h-10  overflow-hidden">
            <MailOutlined className="text-sm text-344054" />
            <Input
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setPercent(changePercent(percent, 6, email, e.target.value))
              }}
              placeholder="Mail"
              className="bg-FCFCFD place-holder-input-98A2B3 text-sm border-none custom-input-focus none-focus-erro font-regular p-none-input hover-none"
            />
          </div>
        </Form.Item>
        {errorObject.email &&
          <div className=" ant-form-item-explain-error " style={{ color: '#ff4d4f' }}>
            {errorObject.email}
          </div>
        }
        <Form.Item
          className="m-none-form-item text-explan"
        >
          <div className="w-full border-F2F4F7 bg-FCFCFD rounded-2 d-flex align-center pl-3  py-2-px h-10 justify-between overflow-hidden">
            <div className="d-flex align-center  w-full">
              <CompassOutlined className="text-sm pr-2 text-344054" />
              <Select
                placeholder="Tỉnh/Thành phố"
                options={sLProvince}
                className="custome-select-relative "
                value={provinceId}
                popupClassName='popup-select'
                variant="borderless"
                filterOption={(input, option) =>
                  typeof option?.label === 'string' && option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onSelect={(e) => {
                  setProvinceId(e);
                  // setPercent(changePercent(percent, 6, profession, e.toString()))
                  if (!percentChanged) {
                    setPercent(percent + 6);
                    setPercentChanged(true);
                  }
                }}
              />
            </div>
          </div>

          {errorObject.province &&
            <div className=" ant-form-item-explain-error " style={{ color: '#ff4d4f' }}>
              {errorObject.province}
            </div>}
        </Form.Item>

        <div className="w-full border-F2F4F7 bg-FCFCFD rounded-2 d-flex align-center px-3 py-2-px h-10 gap-2  overflow-hidden">
          <HomeOutlined className="text-sm text-344054" />
          <Input
            placeholder="Địa chỉ (Không bắt buộc)"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            className="bg-FCFCFD text-sm border-none custom-input-focus none-focus-erro font-regular p-none-input place-holder-input-98A2B3 hover-none"
          />
        </div>
      </div>
      <div className="d-flex flex-column gap-3 w-full ">
        <p className="text-344054 text-base fw-500 text-left font-medium">Nhóm </p>
        <div className="d-flex flex-column">
          <Select
            placeholder="Tìm và chọn nhóm"
            mode="multiple"
            value={valuesGroup}
            options={sLGroup}
            onChange={handleChangeGroup}
            onDropdownVisibleChange={setOpenGroup}
            open={openGroup}
            className="custom-select-mutiple-add d-none-span "
            popupClassName='popup-select'
            suffixIcon={<SearchOutlined />}
            filterOption={(input, option) =>
              typeof option?.label === 'string' && option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          />
        </div>

        {errorObject.group &&
          <div className=" ant-form-item-explain-error " style={{ color: '#ff4d4f' }}>
            {errorObject.group}
          </div>}
        <div className={`${sLGroup.length !== 0 ? ' d-flex align-center gap-2' : 'none'}`}>
          {valuesGroup.map((item, index) => {
            const selectedOption = sLGroup.find(option => option.value === item);
            if (!selectedOption) return null;
            return (
              <p key={index} className="bg-F0F5FF  border-ADC6FF rounded-2 text-2F54EB text-xs px-2 d-flex align-center gap-3-px py-1 font-regular">
                {selectedOption.label}
                <CloseOutlined className="text-84878C text-xs cursor-pointer" onClick={() => { setValuesGroup(valuesGroup.filter(v => v !== item)); if (valuesGroup.length === 1) { setPercent(percent - 6) } }} />
              </p>
            );
          })}

        </div>
      </div>

      <div className="d-flex flex-column gap-3 w-full ">
        <p className="text-344054 text-base fw-500 text-left font-medium">Phân loại theo thẻ tag</p>
        <div className="d-flex flex-column">
          <Select
            placeholder="Tìm thẻ tag và chọn"
            mode="multiple"
            value={valuesTag}
            options={sLTag}
            onChange={handleChangeTag}
            onDropdownVisibleChange={setOpenTag}
            open={openTag}
            className="custom-select-mutiple-add d-none-span "
            popupClassName='popup-select'
            suffixIcon={<SearchOutlined />}
            filterOption={(input, option) =>
              typeof option?.label === "string" && option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          />
        </div>

        {errorObject.tag &&
          <div className=" ant-form-item-explain-error " style={{ color: '#ff4d4f' }}>
            {errorObject.tag}
          </div>}
        <div className={`${valuesTag.length !== 0 ? ' d-flex align-center gap-2' : 'none'}`}>
          {valuesTag.map((value, index) => {
            const selectedOption = sLTag.find(option => option.value === value);
            if (!selectedOption) return null; // Bỏ qua nếu không tìm thấy
            return (
              <p key={index} className="bg-F0F5FF border-ADC6FF rounded-2 text-2F54EB text-xs px-2 d-flex align-center gap-3-px py-1 font-regular">
                {selectedOption.label} {/* Hiển thị nhãn thay vì giá trị */}
                <CloseOutlined className="text-84878C text-xs cursor-pointer" onClick={() => { setValuesTag(valuesTag.filter(v => v !== value)); if (valuesTag.length === 1) { setPercent(percent - 6) } }} />
              </p>
            );
          })}
        </div>
      </div>
      <div className="d-flex flex-column gap-3 w-full ">
        <p className="text-344054 text-base fw-500 text-left font-medium">Sở thích</p>
        <div className="d-flex flex-column">
          <Select
            placeholder="Tìm và chọn sở thích"
            mode="multiple"
            value={valuesInterest}
            options={sLHobby}
            onChange={handleChangeInterest}
            onDropdownVisibleChange={setOpenInterest}
            open={openInterest}
            className="custom-select-mutiple-add d-none-span "
            popupClassName='popup-select'
            filterOption={(input, option) =>
              typeof option?.label === 'string' && option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          />
        </div>

        {errorObject.hobby &&
          <div className=" ant-form-item-explain-error " style={{ color: '#ff4d4f' }}>
            {errorObject.hobby}
          </div>}
        <div className={` ${valuesInterest.length !== 0 ? 'd-flex align-center gap-2' : 'none'}`}>
          {valuesInterest.map((value, index) => {
            const selectedOption = sLHobby.find(option => option.value === value);
            if (!selectedOption) return null;
            return (
              <p key={index} className="bg-F0F5FF  border-ADC6FF rounded-2 text-2F54EB text-xs px-2 d-flex align-center gap-3-px py-1 font-regular">
                {selectedOption.label}
                <CloseOutlined className="text-84878C text-xs cursor-pointer" onClick={() => { setValuesInterest(valuesInterest.filter(v => v !== value)); if (valuesInterest.length === 1) { setPercent(percent - 6) } }} />
              </p>
            )
          })}
        </div>
      </div>
      <div className="d-flex flex-column gap-3 w-full">
        <p className=" text-344054 text-base fw-500 text-left font-medium">Loại xe quan tâm</p>
        <div>
          <div className="d-flex mb-3 align-center ">
            <div className="w-98-px border-F2F4F7 bg-FCFCFD rounded-tl-bl-2  d-flex align-center pl-2 pr-3  py-2-px h-10 justify-between overflow-hidden">
              <Select
                placeholder="Hãng xe"
                popupMatchSelectWidth={false}
                className="w-full  text-left text-span-select text-sm  bg-FCFCFD-ant-select font-regular p-none-select h-10 line-none line-none-item inline-none line-none-placeholder size-selector"
                popupClassName="height-popup select-item dropdown-ant w-dropdown-ant"
                value={valueCategoryCar}
                options={carCompany.map((item: selectOptionsApi.ISelectOption) => ({
                  value: item.value,
                  label: item.label,
                }))}
                onSelect={(e) => gListCar(e)}
                suffixIcon={null}
                variant="borderless"
              />
              <div className="  h-full d-flex align-center">
                <DownOutlined className="text-sm text-98A2B3 pl-3" />
              </div>
            </div>
            <div className="wfull border-F2F4F7 bg-FCFCFD rounded-tr-br-2 d-flex align-center pl-2 pr-3  py-2-px h-10 justify-between overflow-hidden">
              <Select
                mode="multiple"
                placeholder=" Tìm hoặc chọn xe"
                className="w-402-px d-none-span text-left text-span-select text-sm  bg-FCFCFD-ant-select font-regular p-none-select h-10 line-none line-none-item inline-none line-none-placeholder size-selector"
                popupClassName="height-popup select-item dropdown-ant "
                value={valueCar}
                onChange={handleChangeListCar}
                onDropdownVisibleChange={setOpenlistCar}
                open={openlistCar}
                options={dataCar.map(
                  (item: selectOptionsApi.ISelectOption) => ({
                    value: item.value,
                    label: item.label,
                  })
                )}
                suffixIcon={null}
                variant="borderless"

                filterOption={(input, option) =>
                  typeof option?.label === 'string' && option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              />
              <div className=" border-l-F2F4F7 h-full d-flex align-center">
                <SearchOutlined className="text-sm text-98A2B3 pl-3" />
              </div>
            </div>
          </div>
          <div className={`${dataCar.length !== 0 ? 'd-flex flex-column gap-4' : 'none'} `}>
            {
              valueCar.map((item, index) => {
                const selectedOption = dataCar.find(option => option.value === item);
                if (!selectedOption) return null;
                return (
                  <div key={index} className="border-F2F4F7 rounded-2 d-flex justify-between  px-4 mb-3">
                    <div className="d-flex align-center gap-3 py-4">
                      <img
                        src='/images/exportImg/Car.svg'
                        alt="car"
                        className="w-64-px h-64-px"
                      />
                      <div className="d-flex flex-column gap-2">
                        <span className="text-sm font-medium text-344054 ">
                          {selectedOption.label}
                        </span>
                        <span className=" text-xs text-98A2B3 font-regular">
                          {/* {selectedOption.description} */}
                          Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus nihil sunt ipsa
                        </span>
                      </div>
                    </div>
                    <CloseCircleOutlined
                      className="text-F5222D text-24-px pt-3 cursor-pointer d-flex align-baseline"
                      onClick={() => { setValueCar(valueCar.filter(v => v !== item)); if (valueCar.length === 1) { setPercent(percent - 6) } }}
                    />
                  </div>
                )
              })
            }
          </div>
        </div>

        {errorObject.car &&
          <div className=" ant-form-item-explain-error " style={{ color: '#ff4d4f' }}>
            {errorObject.car}
          </div>}
      </div>
    </div>
  )
}
export default InfoPersonal
