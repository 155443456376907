import React, { useEffect, useRef, useState } from 'react';
import './ManualCampaignPopup.scss'
import { Alert, Button, Checkbox, DatePicker, Drawer, Form, FormInstance, FormProps, GetProp, Input, Radio, Select, TimePicker, Tree, notification } from 'antd';
import { BellOutlined, ClearOutlined, MinusOutlined, PlusOutlined, ShoppingCartOutlined, TagOutlined, TeamOutlined } from '@ant-design/icons';
import './ManualCampaignPopup.scss'
import EmptyComponent from '../../../components/EmptyComponent/EmptyComponent';
import { Link } from 'react-router-dom';

interface Props {
  isShow?: boolean
  onPopupClose?: () => void
}

type FieldType = {
  title?: string;
};

const AutomatedCampaignPopup: React.FC<Props> = (props) => {
  const { isShow = false, onPopupClose } = props
  const [open, setOpen] = useState(isShow);

  useEffect(() => {
    setOpen(isShow)
  }, [isShow])

  const onClose = () => {
    setOpen(false);
    if (onPopupClose) {
      onPopupClose();
    }
  };

  // FORM SUBMIT
  const formRef = useRef<FormInstance<FieldType>>(null);
  const onSubmitForm = () => {
    if (formRef.current) {
      formRef.current.submit()
    }
  };

  // FORM SUBMIT SUCCESSFULLY
  const [confirmLoading, setConfirmLoading] = useState(false)
  const handleOk = () => {
    setConfirmLoading(true)
    setTimeout(() => {
      setConfirmLoading(false)
      onClose()
      openNotification()
    }, 1500)
  }
  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    handleOk()
  };

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();
  const openNotification = () => {
    api['success']({
      message: 'Tạo chiến dịch thành công!',
      placement: 'bottomRight',
      className: 'single-message'
    });
  };

  const [showFeeDetail, setShowFeeDetail] = useState(false)
  // POPUP FOOTER
  const Footer = (
    <div className="drawer-footer-vertical">
      <div className="top">
        <span className="font-medium lh-24 fsz-16">Dự tính chi phí</span>
        <div className="fee-list">
          <div className="fee-list-item-wrapper">
            <div className="fee-list-item">
              <span
                className="icon"
                onClick={() => setShowFeeDetail(!showFeeDetail)}
              >
                {
                  showFeeDetail
                    ? <MinusOutlined />
                    : <PlusOutlined />
                }
              </span>
              <span className="title">SMS</span>
              <span className="number">400đ/tin</span>
            </div>
            {
              showFeeDetail &&
                <div className="fee-list-detail">
                  <div className="item">
                    <img src="/images/network/viettel.svg" alt="viettel" />
                    <span className="title">Phí SMS Viettel cho 12.400 khách hàng</span>
                    <span className="number">400đ/tin</span>
                  </div>
                  <div className="item">
                    <img src="/images/network/vinaphone.svg" alt="vinaphone" />
                    <span className="title">Phí SMS Vinaphone cho 11.600 khách hàng</span>
                    <span className="number">400đ/tin</span>
                  </div>
                </div>
            }
          </div>

          <div className="fee-list-item-wrapper">
            <div className="fee-list-item">
              <span className="icon empty"></span>
              <span className="title">ZNS</span>
              <span className="number">400đ/tin</span>
            </div>
          </div>

          {/* <div className="fee-list-item-wrapper total">
            <div className="fee-list-item">
              <span className="icon empty"></span>
              <span className="title">Tạm tính</span>
              <span className="number">3.320.000đ</span>
            </div>
          </div> */}
        </div>
      </div>
      <div className="bottom">
        <span className="neutral-gray-400">Làm mới thông tin</span>
        <Button
          type="primary"
          className="rs-button rs-button--sm"
          icon={<PlusOutlined />}
          loading={confirmLoading}
          onClick={onSubmitForm}
        >Tạo mới chiến dịch</Button>
      </div>
    </div>
  );

  // CUSTOMER GROUPs CHANGE
  const onGroupChange: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues) => {
    // console.log('checked = ', checkedValues);
  };

  const [channels] = useState([
    {  key: 'sms', value: 'sms', title: 'SMS' },
    {  key: 'zns', value: 'zns', title: 'ZNS' },
    {  key: 'email', value: 'email', title: 'Email' },
  ])
  // CHANNEL CHANGE
  const onChannelChange: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues) => {
    const result: ({ key: string; value: string; title: string; } | undefined)[] = []
    if (checkedValues.length) {
      checkedValues.map(item => result.push(channels.find(channel => channel.value === item)))
    }
    setChannelSelected(result)
  };
  const [channelSelected, setChannelSelected] = useState([] as any)
  // // TREE SELECT DRAG & DROP
  const onDrop = (info: any) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]); // the drop position relative to the drop node, inside 0, top -1, bottom 1

    if (dropPosition === 0) return

    const loop = (data: any, key: any, callback: any) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
      }
    };
    const data = [...channelSelected];

    // Find dragObject
    let dragObj: any;
    loop(data, dragKey, (item: any, index: any, arr: any[]) => {
      arr.splice(index, 1);
      dragObj = item;
    });
    if (info.dropToGap) {
      let ar: any[] = [];
      let i: any;
      loop(data, dropKey, (_item: any, index: any, arr: any[]) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        // Drop on the top of the drop node
        ar.splice(i, 0, dragObj);
      } else {
        // Drop on the bottom of the drop node
        ar.splice(i + 1, 0, dragObj);
      }
    }
    setChannelSelected(data);
  };

  const [sendType, setSendType] = useState('uu-tien-gui-theo-kenh')
  const [sendTimeType, setSendTimeType] = useState('tuc-thi')
  const [isCustomerGroupEmpty] = useState(false)

  return (
    <>
      { contextHolder }

      <Drawer
        title="Tạo chiến dịch"
        rootClassName="rs-drawer w-580"
        className="manual-campaign-popup"
        closeIcon={false}
        open={open}
        footer={Footer}
        onClose={onClose}
      >
        <Form
          ref={formRef}
          onFinish={onFinish}
          autoComplete="off"
          className="rs-form voucher-add__content"
        >
          <div className="block">
            <span className="font-medium lh-24 fsz-16">Chiến dịch tự động</span>
            <Form.Item<FieldType>
              name="title"
              rules={[{ required: true, message: 'Vui lòng nhập tên chương trình!' }]}
            >
              <Input
                placeholder="Tên chương trình"
                className="rs-input"
              />
            </Form.Item>
          </div>

          <div className="block">
            <span className="font-medium lh-24 fsz-16">Chiến dịch thủ công</span>
            <Radio.Group
              defaultValue="khuyen-mai"
              className="rs-radio-group rs-radio-group--button"
            >
              <Radio.Button
                value="khuyen-mai"
                className="rs-radio rs-radio--button volcano"
              >
                <TagOutlined />
                <span>Khuyến mãi</span>
              </Radio.Button>
              <Radio.Button
                value="thong-bao"
                className="rs-radio rs-radio--button primary-5"
              >
                <BellOutlined />
                <span>Thông báo</span>
              </Radio.Button>
              <Radio.Button
                value="tiep-thi"
                className="rs-radio rs-radio--button golden-purple"
              >
                <ShoppingCartOutlined />
                <span>Tiếp thị</span>
              </Radio.Button>
              <Radio.Button
                value="cskh"
                className="rs-radio rs-radio--button magenta"
              >
                <TeamOutlined />
                <span>CSKH</span>
              </Radio.Button>
            </Radio.Group>
          </div>

          <div className="block">
            <span className="font-medium lh-24 fsz-16">Chọn khách hàng</span>
            <Checkbox className="rs-checkbox">
              <span>Tất cả</span>
              <span className="primary-7">(24.000)</span>
            </Checkbox>
          </div>

          <div className="block">
            <span className="font-medium lh-24 fsz-16">Nhóm khách hàng</span>

            <Checkbox.Group
              rootClassName="rs-checkbox-group vertical"
              onChange={onGroupChange}
            >
              <Checkbox value="khach-hang-moi" className="rs-checkbox">
                <span>Khách hàng mới</span>
                <span className="primary-7">&nbsp;(1.000)</span>
              </Checkbox>
              <Checkbox value="khach-hang-nu" className="rs-checkbox">
                <span>Khách hàng nữ</span>
                <span className="primary-7">&nbsp;(1.000)</span>
              </Checkbox>
              <Checkbox value="khach-hang-team-a" className="rs-checkbox">
                <span>Khách hàng team A</span>
                <span className="primary-7">&nbsp;(1.000)</span>
              </Checkbox>
            </Checkbox.Group>

            {
              isCustomerGroupEmpty &&
                <EmptyComponent
                  content=''
                >
                  <div>
                    <span className="neutral-gray-500">Bạn chưa tạo nhóm khách hàng nào,&nbsp;</span>
                    <Link to="/customer/group" className="primary-7">tạo ngay</Link>
                  </div>
                </EmptyComponent>
            }
          </div>

          <div className="block-flex">
            <span className="font-medium lh-24 fsz-16">Hành động</span>
            <Checkbox.Group
              rootClassName="rs-checkbox-group"
              onChange={onChannelChange}
            >
              {
                channels.length && channels.map((item, index) => (
                  <Checkbox
                    key={index}
                    value={item.value}
                    className="rs-checkbox"
                  >{ item.title }</Checkbox>
                ))
              }
            </Checkbox.Group>
          </div>

          <div className="block">
            <span className="font-medium lh-24 fsz-16">Điều kiện</span>
            <div className="flex flex-vertical gap-16px">
              <div className="condition-item align-center">
                <Select
                  defaultValue="mua-hang"
                  options={[
                    { value: 'mua-hang', label: 'Mua hàng' },
                  ]}
                  className="rs-select"
                  popupClassName="rs-select-dropdown"
                />
                <Select
                  defaultValue="bang"
                  options={[
                    { value: 'bang', label: 'Bằng' },
                  ]}
                  className="rs-select"
                  popupClassName="rs-select-dropdown"
                />
                <Input
                  addonBefore={
                    <Select
                      defaultValue="luot"
                      options={[
                        { value: 'luot', label: 'Lượt' },
                      ]}
                      className="rs-select"
                      popupClassName="rs-select-dropdown"
                    />
                  }
                  defaultValue={1}
                  className="rs-input-group rs-input-group--32"
                />
                <Button
                  type="primary"
                  icon={<ClearOutlined />}
                  className="rs-button dust-red"
                ></Button>
              </div>

              <div className="condition-item align-center">
                <Select
                  defaultValue="diem-tich-luy"
                  options={[
                    { value: 'diem-tich-luy', label: 'Điểm tích lũy' },
                  ]}
                  className="rs-select"
                  popupClassName="rs-select-dropdown"
                />
                <Select
                  defaultValue="bang"
                  options={[
                    { value: 'bang', label: 'Bằng' },
                  ]}
                  className="rs-select"
                  popupClassName="rs-select-dropdown"
                />
                <Input
                  addonBefore="Điểm"
                  defaultValue={2000}
                  className="rs-input-group rs-input-group--32"
                />
                <Button
                  type="primary"
                  icon={<ClearOutlined />}
                  className="rs-button dust-red"
                ></Button>
              </div>

              <div className="condition-item align-center">
                <Select
                  defaultValue="toi-han-bao-duong"
                  options={[
                    { value: 'toi-han-bao-duong', label: 'Tới hạn bảo dưỡng' },
                  ]}
                  className="rs-select"
                  popupClassName="rs-select-dropdown"
                />
                <Select
                  defaultValue="bang"
                  options={[
                    { value: 'bang', label: 'Bằng' },
                  ]}
                  className="rs-select"
                  popupClassName="rs-select-dropdown"
                />
                <Input
                  addonBefore={
                    <Select
                      defaultValue="ngay"
                      options={[
                        { value: 'ngay', label: 'Ngày' },
                      ]}
                      className="rs-select"
                      popupClassName="rs-select-dropdown"
                    />
                  }
                  defaultValue={7}
                  className="rs-input-group rs-input-group--32"
                />
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  className="rs-button"
                ></Button>
              </div>
            </div>
          </div>

          <div className="block-flex">
            <span className="font-medium lh-24 fsz-16">Hình thức</span>
            <Select
              defaultValue="uu-tien-gui-theo-kenh"
              options={[
                { value: 'uu-tien-gui-theo-kenh', label: 'Ưu tiên gửi theo kênh' },
                { value: 'dong-thoi-gui-nhieu-kenh', label: 'Đồng thời gửi nhiều kênh' },
              ]}
              onSelect={(val) => setSendType(val)}
              className="rs-select"
              popupClassName="rs-select-dropdown"
            />
          </div>

          {
            sendType === 'uu-tien-gui-theo-kenh' &&
              <Alert
                message="Khách đã nhận thông báo ở kênh này thì không gửi ở kênh khác"
                type="info"
                showIcon
                closable
                className="rs-alert"
              />
          }
          {
            sendType === 'dong-thoi-gui-nhieu-kenh' &&
              <Alert
                message="Một khách có thể nhận nhiều nội dung ở nhiều kênh"
                type="info"
                showIcon
                closable
                className="rs-alert"
              />
          }

          <div className="block">
            <span className="font-medium lh-24 fsz-16">Chọn mẫu nội dung cho kênh gửi</span>
            <Tree
              className="simple-draggable"
              draggable={channelSelected.length > 1 && sendType !== 'dong-thoi-gui-nhieu-kenh'}
              blockNode
              selectable={false}
              onDrop={onDrop}
              treeData={channelSelected}
              titleRender={(nodeData: any) => (
                <div className="simple-draggable-item">
                  <span className="title">{nodeData.title}</span>
                  <Select
                    className="rs-select"
                    popupClassName="rs-select-dropdown"
                    placeholder="Chọn mẫu nội dung"
                    options={[
                      { value: 'mau-1', label: 'Mẫu nội dung 1' },
                      { value: 'mau-2', label: 'Mẫu nội dung 2' },
                    ]}
                  />
                </div>
              )}
            />
          </div>

          <div className="block">
            <div className="block-flex">
              <span className="font-medium lh-24 fsz-16">Thời gian gửi</span>
              <Radio.Group
                defaultValue={sendTimeType}
                className="rs-radio-group"
                onChange={(e) => setSendTimeType(e.target.value)}
              >
                <Radio.Button value="tuc-thi" className="rs-radio">Tức thì</Radio.Button>
                <Radio.Button value="len-lich" className="rs-radio">Lên lịch</Radio.Button>
              </Radio.Group>
            </div>

            {
              sendTimeType === 'len-lich' &&
                <div className="grid-2-cols">
                  <DatePicker
                    placeholder="Chọn ngày"
                    className="rs-date-picker"
                    popupClassName="rs-date-picker-dropdown"
                  />
                  <TimePicker
                    placeholder="Chọn giờ"
                    className="rs-date-picker"
                    popupClassName="rs-date-picker-dropdown"
                    minuteStep={30}
                    format={'HH:mm'}
                  />
                </div>
            }
          </div>
        </Form>
      </Drawer>
    </>
  )
}

export default AutomatedCampaignPopup
