import { CloseOutlined, DownOutlined, PlusOutlined } from "@ant-design/icons"
import { DatePicker, Form, FormInstance, FormProps, Input, Modal, Select } from "antd"
import { ChangeEvent, useEffect, useRef, useState } from "react"
import { useForm } from 'antd/lib/form/Form';
import './style.scss'
import { ICarCompany, getCLCarLines, getCLCountry, getCLTypeVehicle, getCLVersion } from "../../../../../api/selectOption/selectOptionsApi";
import useToast from "../../../../../hook/useToast";
import { fommatSelectOption } from "../../../../../until";
import { IAttributeVehicle, getListAttributeVehicle } from "../../../../../api/attributeVehicle/attributeVehicleApi";
import useThrottle from "../../../../../hook/useThrottle";
import { IRelative, ITag } from "../../../../../api/selectOptions/selectOptionsApi";
import { URL_CAR, URL_COUNTRY } from "../../../../../env";
import { ICarEdit, editCar } from "../../../../../api/car/carApi";
import dayjs from 'dayjs';
interface ModalProps {
  isOpen: boolean
  infoCar?: ICarEdit
  sLCategoryCar: ICarCompany[]
  sLAttributeVehicle: IAttributeVehicle[]

  onSubmitFormModal?: (iSsuccess: boolean, errorMessage?: string) => void;
  closeModal: () => void
}
const ModalEditCar: React.FC<ModalProps> = ({
  isOpen, infoCar, sLCategoryCar, sLAttributeVehicle,
  onSubmitFormModal, closeModal
}) => {

  const pushToast = useToast();

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [advanced, setAdvanced] = useState(false)

  const [form] = useForm();
  const inputRef = useRef<FormInstance>(null);

  const [sLCarLines, setSLCarLines] = useState<IRelative[]>([])//d xe
  const [sLTypeVehicle, setSLTypeVehicle] = useState<IRelative[]>([])//Cung loiaj xe
  const [sLVersion, setSLVersion] = useState<ITag[]>([])
  //av
  const [sLFuel, setSLFuel] = useState<IRelative[]>([])
  const [sLOrigin, setSLOrigin] = useState<ICarCompany[]>([])
  const [sLDesigns, setSLDesigns] = useState<IRelative[]>([])
  const [sLColorCar, setSLColorCar] = useState<IRelative[]>([])
  const [sLBarrelsystem, setSLBarrelsystem] = useState<IRelative[]>([])

  const disabledDate = (current: dayjs.Dayjs) => {
    // Vô hiệu hóa các ngày sau năm hiện tại
    return current && current.year() > dayjs().year();
  };

  //thương hiệu
  const changeCategoryCar = useThrottle(async (id: number, isSearch?: boolean) => {
    if (isSearch) {
      form.setFieldValue('typecar', undefined)
      form.setFieldValue('categorycar', undefined)
      form.setFieldValue('versioncar', undefined)
    }
    const result = await getCLCarLines(id)
    if (result?.status) {
      setSLCarLines(result.data)
    } else {
      pushToast(result?.message, "warning")
    }
  }, 200)

  //dòng xe
  const changeCarLines = useThrottle(async (id: number, isSearch?: boolean) => {
    if (isSearch) {
      form.setFieldValue('categorycar', undefined)
      form.setFieldValue('versioncar', undefined)
    }
    const result = await getCLTypeVehicle(id)
    if (result?.status) {
      setSLTypeVehicle(result.data)
    } else {
      pushToast(result?.message, "warning")
    }
  }, 200)

  //chung loai
  const changeTypeVehicle = useThrottle(async (id: number, isSearch?: boolean) => {
    if (isSearch) {
      form.setFieldValue('versioncar', undefined)
    }
    const result = await getCLVersion(id)
    if (result?.status) {
      setSLVersion(result.data)
    } else {
      pushToast(result?.message, "warning")
    }
  }, 200)

  //////////////////////// AV
  const gCLCountry = async () => {
    const result = await getCLCountry(2000)
    if (result?.status) {
      setSLOrigin(result.data)
    } else {
      pushToast(result?.message, "warning")
    }
  }

  const gCLAdvanced = async (id: number) => {
    const result = await getListAttributeVehicle(id);
    if (result?.status) {
      switch (id) {
        case 5:
          //nl
          setSLFuel(result.data)
          break;
        case 3:
          //kd
          setSLDesigns(result.data)
          break;
        case 2:
          //mx
          setSLColorCar(result.data)
          break;
        case 4:
          //ht
          setSLBarrelsystem(result.data)
          break;
        default:
          break;
      }
    } else {
      pushToast(result?.message, "warning")
    }
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    setUploadedFile(file);
  };

  const handleUploadClick = (event: React.MouseEvent) => {
    event.preventDefault();
    const inputElement = document.querySelector('input[type="file"]') as HTMLInputElement | null;
    if (inputElement) {
      inputElement.click();
    }
  };
  const submitForm = () => {
    if (inputRef.current) {
      inputRef.current.submit();
    }
  };


  const onFinish: FormProps["onFinish"] = async (values) => {
    const {
      codecar, namecar, namebrand, segment, typecar, categorycar, yearmanufacture, versioncar,
      numberseat, fuel, origin, designs, colorcar, barrelsystem
    } = values;

    const yearCreate = Number(dayjs(yearmanufacture).format('YYYY'))

    const result = await editCar(
      Number(infoCar?.id), codecar, namecar, namebrand, segment, typecar, categorycar, yearCreate, versioncar, uploadedFile,
      numberseat, fuel, origin, designs, colorcar, barrelsystem
    )
    if (result?.status) {
      onSubmitFormModal?.(true)
    } else {
      onSubmitFormModal?.(false, result.message)
    }
  };

  const handleSetFileFromUrl = (url: string) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const file = new File([blob], 'image.png', { type: blob.type });
        setUploadedFile(file);
      });
  };


  useEffect(() => {
    if (advanced && sLOrigin.length === 0) {
      gCLAdvanced(5)
      gCLAdvanced(3)
      gCLAdvanced(2)
      gCLAdvanced(4)
      gCLCountry()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advanced])

  useEffect(() => {
    if (isOpen) {
      form.setFieldValue('codecar', infoCar?.code)
      form.setFieldValue('namecar', infoCar?.title)
      form.setFieldValue('namebrand', infoCar?.categoryCarId)
      form.setFieldValue('segment', infoCar?.segmentsVehicleId)
      form.setFieldValue('typecar', infoCar?.carLinesId)
      form.setFieldValue('categorycar', infoCar?.typeVehicleId)
      form.setFieldValue('yearmanufacture', dayjs(`${infoCar?.yearCreate}-01-01`, 'YYYY-MM-DD'));
      form.setFieldValue('versioncar', infoCar?.versionId)

      handleSetFileFromUrl(URL_CAR + infoCar?.carImage)
      //av
      setUploadedFile(null)
      setAdvanced(false)
      form.setFieldValue('numberseat', infoCar?.seatingCapacity)
      form.setFieldValue('fuel', infoCar?.fuelId)
      form.setFieldValue('origin', infoCar?.countryId)
      form.setFieldValue('designs', infoCar?.modelVehicleId)
      form.setFieldValue('colorcar', infoCar?.colorId)
      form.setFieldValue('barrelsystem', infoCar?.typeBodyVehicleId)

      changeCategoryCar(Number(infoCar?.categoryCarId))
      changeCarLines(Number(infoCar?.carLinesId))
      changeTypeVehicle(Number(infoCar?.typeVehicleId))

    } else {
      form.setFieldValue('codecar', undefined)
      form.setFieldValue('namecar', undefined)
      form.setFieldValue('namebrand', undefined)
      form.setFieldValue('segment', undefined)
      form.setFieldValue('typecar', undefined)
      form.setFieldValue('categorycar', undefined)
      form.setFieldValue('yearmanufacture', undefined)
      form.setFieldValue('versioncar', undefined)
      //av
      setUploadedFile(null)
      setAdvanced(false)
      form.setFieldValue('numberseat', undefined)
      form.setFieldValue('fuel', undefined)
      form.setFieldValue('origin', undefined)
      form.setFieldValue('designs', undefined)
      form.setFieldValue('colorcar', undefined)
      form.setFieldValue('barrelsystem', undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])
  return (
    <Form
      form={form}
      ref={inputRef}
      onFinish={onFinish}
      initialValues={{ remember: true }}
    >
      <Modal
        title={<div className="bg-FCFCFD d-flex justify-betweeen align-center py-4 px-6 w-full border-b-F0F0F0">
          <span className="text-base text-344054 fw-500 font-medium">Chỉnh sửa thông tin xe</span>
          <CloseOutlined className="text-base text-8B8B8B" onClick={closeModal} />
        </div>}
        open={isOpen}
        onCancel={closeModal}
        centered
        className="w-modal modal-content ant-modal-size"
        closeIcon={false}
        footer={<div className="bg-FCFCFD d-flex justify-betweeen align-center py-2 px-6 w-full border-t-F0F0F0">
          <span className="text-sm text-98A2B3 cursor-pointer" onClick={closeModal}>Huỷ</span>
          <button className="text-white text-sm bg-096DD9 border-none shadow-btn-save rounded-2 h-8 px-15-px"
            onClick={submitForm}
          >
            Lưu
          </button>
        </div>}
      >

        <div className="p-6 d-flex flex-column gap-18-px">
          <div className="d-flex flex-column gap-1">
            <p className="text-sm  font-regular text-344054">Mã xe</p>
            <Form.Item
              name="codecar"
              rules={[{ required: true, message: 'Nhập mã xe!' }]}
              className="m-none-form-item text-explan"
            >
              <Input
                placeholder="Mã xe"
                className="bg-FCFCFD border-F2F4F7 text-sm rounded-2  font-regular  input-custom  h-8"
              />
            </Form.Item>
          </div>

          <div className="d-flex flex-column gap-1">
            <p className="text-sm  font-regular text-344054">Tên xe</p>
            <Form.Item
              name="namecar"
              rules={[{ required: true, message: 'Nhập tên xe!' }]}
              className="m-none-form-item text-explan"
            >
              <Input
                placeholder="Tên xe"
                className="bg-FCFCFD border-F2F4F7 text-sm rounded-2 font-regular input-custom h-8"
              />
            </Form.Item>
          </div>

          <div className="d-flex flex-column gap-1">
            <p className="text-sm  font-regular text-344054">Tên thương hiệu xe</p>
            <Form.Item
              name="namebrand"
              rules={[{ required: true, message: 'Chọn tên thương hiệu!' }]}
              className="m-none-form-item text-explan"
            >
              <Select
                placeholder="Tên thương hiệu xe"
                // value={nameBrand}
                onChange={(e) => changeCategoryCar(e, true)}
                options={fommatSelectOption(sLCategoryCar)}
                className="custome-select-t "
                popupClassName='popup-select'
              />
            </Form.Item>
          </div>

          <div className="d-flex flex-column gap-1">
            <p className="text-sm  font-regular text-344054">Phân khúc</p>
            <Form.Item
              name="segment"
              rules={[{ required: true, message: 'Chọn phân khúc!' }]}
              className="m-none-form-item text-explan"
            >
              <Select
                placeholder="Phân khúc"
                // onChange={handleChangeSegment}
                options={fommatSelectOption(sLAttributeVehicle)}
                className="custome-select-t "
                popupClassName='popup-select'
              />
            </Form.Item>
          </div>

          <div className="d-flex flex-column gap-1">
            <p className="text-sm  font-regular text-344054">Dòng xe</p>
            <Form.Item
              name="typecar"
              rules={[{ required: true, message: 'Chọn dòng xe!' }]}
              className="m-none-form-item text-explan"
            >
              <Select
                placeholder="Dòng xe"
                // value={typeCar}
                onChange={(e) => changeCarLines(e, true)}
                options={fommatSelectOption(sLCarLines)}
                className="custome-select-t "
                popupClassName='popup-select'
              />
            </Form.Item>
          </div>

          <div className="d-flex flex-column gap-1">
            <p className="text-sm  font-regular text-344054">Chủng loại xe</p>
            <Form.Item
              name="categorycar"
              rules={[{ required: true, message: 'Chọn chủng loại xe!' }]}
              className="m-none-form-item text-explan"
            >
              <Select
                placeholder="Chủng loại xe"
                // value={categoryCar}
                onChange={(e) => changeTypeVehicle(e, true)}
                options={fommatSelectOption(sLTypeVehicle)}
                className="custome-select-t "
                popupClassName='popup-select'
              />
            </Form.Item>
          </div>
          <div className="d-flex flex-column gap-1">
            <p className="text-sm  font-regular text-344054">Năm sản xuất</p>
            <Form.Item
              name="yearmanufacture"
              rules={[{ required: true, message: 'Chọn năm sản xuất!' }]}
              className="m-none-form-item text-explan"
            >
              <DatePicker
                popupClassName="t-ctrl-date-picker"
                placeholder={"Năm sản xuất"}
                className="w-full h-8 rounded-2 font-regular text-sm p-ant-picker size-input-picker border-F2F4F7 bg-FCFCFD"
                picker="year"
                disabledDate={disabledDate}
              />
            </Form.Item>
          </div>
          <div className="d-flex flex-column gap-1">
            <p className="text-sm  font-regular text-344054">Phiên bản xe</p>
            <Form.Item
              name="versioncar"
              rules={[{ required: true, message: 'Chọn phiên bản xe!' }]}
              className="m-none-form-item text-explan"
            >
              <Select
                placeholder="Phiên bản xe"
                options={fommatSelectOption(sLVersion)}
                className="custome-select-t "
                popupClassName='popup-select'
              />
            </Form.Item>
          </div>



          <div className="border-F2F4F7 rounded-2-px w-104-px h-104-px bg-F9FAFB d-flex align-center justify-center relative">
            {uploadedFile ? (
              <div >
                <Form.Item
                  name="img1"
                  rules={[

                  ]}
                  className="m-none-form-item text-explan "
                >
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                </Form.Item>
                <img src={URL.createObjectURL(uploadedFile)} alt="Uploaded" className="w-104-px h-104-px rounded-3 cursor-pointer" onClick={handleUploadClick} />
              </div>
            ) : (
              <div >
                <Form.Item
                  name="img2"
                  rules={[
                    { required: true, message: 'Vui lòng chọn ảnh!' },
                  ]}
                  className="m-none-form-item text-explan absolute bottom_-22-px w-109-px left-0"
                >
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                </Form.Item>
                <div
                  className="d-flex flex-column align-center gap-2 cursor-pointer"
                  onClick={handleUploadClick}
                >
                  <PlusOutlined className="text-sm text-252626" />
                  <span className="text-sm text-98A2B3 text-center">Tải lên <br /> Logo</span>
                </div>
              </div>
            )}

          </div>
          <div className="d-flex align-center gap-1 cursor-pointer" onClick={() => setAdvanced(!advanced)}>
            <p className="text-sm font-regular text-1890FF">Nâng cao</p>
            <DownOutlined className="text-10-px text-1890FF" />
          </div>

          <div className={`${advanced ? 'd-flex flex-column gap-18-px' : 'none'}`}
          >
            <div className="d-flex flex-column gap-1">
              <p className="text-sm  font-regular text-344054">Số chỗ</p>
              <Form.Item
                name="numberseat"
                rules={[
                  { required: advanced, message: 'Nhập số chỗ!' },
                  // {
                  //   validator: (_, value) => {
                  //     if (!Number.isInteger(Number(value)) && advanced) {
                  //       return Promise.reject(new Error('Nhập số nguyên!'));
                  //     }
                  //     return Promise.resolve();
                  //   }
                  // }
                ]}
                className="m-none-form-item text-explan"
              >
                <Input
                  placeholder="Số chỗ"
                  type="number"
                  className="bg-FCFCFD border-F2F4F7 text-sm rounded-2  font-regular  input-custom  h-8"
                // value={aNumberseat}
                />
              </Form.Item>
            </div>

            <div className="d-flex flex-column gap-1">
              <p className="text-sm  font-regular text-344054">Nhiên liệu</p>
              <Form.Item
                name="fuel"
                rules={[{ required: advanced, message: 'Nhập nhiên liệu!' }]}
                className="m-none-form-item text-explan"
              >
                <Select
                  placeholder="Nhiên liệu"
                  // onChange={handleChangeFuel}
                  options={fommatSelectOption(sLFuel)}
                  className="custome-select-98A2B3 "
                  popupClassName='popup-select'
                // value={aFuel}
                />
              </Form.Item>
            </div>
            <div className="d-flex flex-column gap-1">
              <p className="text-sm  font-regular text-344054">Xuất xứ</p>
              <Form.Item
                name="origin"
                rules={[{ required: advanced, message: 'Chọn xuất xứ!' }]}
                className="m-none-form-item text-explan"
              >
                <Select
                  placeholder="Xuất xứ"
                  options={
                    sLOrigin.map(option => ({
                      value: option.id,
                      label: (
                        <div className="d-flex align-center gap-2">
                          <img src={URL_COUNTRY + option.image} alt="" className="w-5 h-4" />
                          <p>{option.title}</p>
                        </div>
                      )
                    }))}
                  className="custome-select-98A2B3 "
                  popupClassName='popup-select'
                // value={aOrigin}
                />
              </Form.Item>
            </div>

            <div className="d-flex flex-column gap-1">
              <p className="text-sm  font-regular text-344054">Kiểu dáng</p>
              <Form.Item
                name="designs"
                rules={[{ required: advanced, message: 'Chọn kiểu dáng ' }]}
                className="m-none-form-item text-explan"
              >
                <Select
                  placeholder="Kiểu dáng"
                  options={fommatSelectOption(sLDesigns)}
                  className="custome-select-98A2B3 "
                  popupClassName='popup-select'
                // value={aDesigns}
                />
              </Form.Item>
            </div>
            <div className="d-flex flex-column gap-1">
              <p className="text-sm  font-regular text-344054">Màu Xe</p>
              <Form.Item
                name="colorcar"
                rules={[{ required: advanced, message: 'Chọn màu xe!' }]}
                className="m-none-form-item text-explan"
              >
                <Select
                  placeholder="Màu Xe"
                  options={fommatSelectOption(sLColorCar)}
                  className="custome-select-98A2B3 "
                  popupClassName='popup-select'
                // value={aColorCar}
                />
              </Form.Item>
            </div>
            <div className="d-flex flex-column gap-1">
              <p className="text-sm  font-regular text-344054">Hệ thùng</p>
              <Form.Item
                name="barrelsystem"
                rules={[{ required: advanced, message: 'Chọn hệ thùng!' }]}
                className="m-none-form-item text-explan"
              >
                <Select
                  placeholder="Hệ thùng"
                  options={fommatSelectOption(sLBarrelsystem)}
                  className="custome-select-98A2B3 "
                  popupClassName='popup-select'
                // value={aBarrelsystem}
                />
              </Form.Item>
            </div>

          </div>
        </div>


      </Modal >
    </Form>
  )
}
export default ModalEditCar
