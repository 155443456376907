// import React, { useRef } from "react";
import EmptyComponentLinkText from '../../components/EmptyComponent/EmptyComponentLinkText';

const ZNS: React.FC = () => {
  return (
    <>
      <EmptyComponentLinkText
        content={"Không có chiến dịch nào trong thời gian này, thử"}
        link={'/'}
        namelink={'tạo ngay'}
      />
    </>
  )
}

export default ZNS
