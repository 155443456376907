import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from '../../api/login/loginApi';

const initialInfoUserSlice: IUser = {
    id: 0,
    image: "",
    username: ""
}

const InfoUserSlice = createSlice({
    name: 'infoUserSlice',
    initialState: initialInfoUserSlice,
    reducers: {
        setInfoUser: (state, action: PayloadAction<IUser>) => action.payload,
        clearInfoUser: (state) => initialInfoUserSlice
    }
})

export const selectorInfoUser = (state: { infoUserSlice: IUser }) => state.infoUserSlice;
export const { setInfoUser, clearInfoUser } = InfoUserSlice.actions;
export default InfoUserSlice.reducer;