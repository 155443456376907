import React from 'react'
import './VoucherDetailCard.scss'
import { CopyOutlined, GlobalOutlined } from '@ant-design/icons';
import { Switch, Tag, message } from 'antd';
import { IVoucherEdit } from '../../api/voucher/voucherApi';
import { statusVoucher, typeReduced, typeVoucher } from '../../services/data';
import Item from 'antd/es/list/Item';
import { formatPrice } from '../../until';

type Props = {
  infoVoucher?: IVoucherEdit
  onChangeActive?: (value: boolean) => void
};

const VoucherDetailCard: React.FC<Props> = ({
  infoVoucher,
  onChangeActive
}) => {


  async function copyTextToClipboard(text: any) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleCopyClick = (copyText: any) => {
    copyTextToClipboard(copyText)
      .then(() => {
        message.success({
          content: 'Copied',
          className: 'rs-message'
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className="voucher-detail-card">
      <Switch
        value={infoVoucher?.view ? true : false}
        className="rs-switch rs-switch--sm"
        onChange={(e) => onChangeActive?.(e)}
      />

      <div className="content">
        <div className="top">
          <div className="top-item">
            <GlobalOutlined className="neutral-gray-400" />
            <span className="neutral-gray-400">Mã chương trình Voucher</span>
            <span className="neutral-gray-400">{infoVoucher?.code}</span>
            <CopyOutlined
              className="primary-7"
              onClick={() => handleCopyClick(infoVoucher?.code)}
            />
          </div>
          <div className="top-item">
            <Tag className='rs-tag daybreak-blue'>{statusVoucher.find(item => item.Value === Number(infoVoucher?.status))?.label}</Tag>
          </div>
          <div className="top-item">
            <span className="title">{infoVoucher?.title}</span>
          </div>
        </div>

        <div className="neutral-gray-500">Mô tả ngắn về chương trình</div>

        <div className="bottom">
          <div className="info-list">
            <div className="info-list-item">
              <span className="font-medium fsz-16 lh-24">Hình thức</span>
              <Tag className='rs-tag polar-green'>
                {typeReduced.find(item => item.Value === Number(infoVoucher?.role))?.label}
              </Tag>
            </div>
            <div className="info-list-item">
              <span className="font-medium fsz-16 lh-24">Mức giảm</span>
              <span className="primary-7">{infoVoucher?.role === 1 ? infoVoucher.percentDecrease + " %" : formatPrice(infoVoucher?.reducedAmount) + " VNĐ"}</span>
            </div>
            {
              infoVoucher?.role === 1 &&
              <div className="info-list-item">
                <span className="font-medium fsz-16 lh-24">Giảm tối đa</span>
                <span className="primary-7">{formatPrice(infoVoucher?.reducedAmountMaximum) + " VNĐ"}</span>
              </div>
            }

            <div className="info-list-item">
              <span className="font-medium fsz-16 lh-24">Điều kiện</span>
              <span className="neutral-gray-500">{"Giá trị đơn hàng = " + formatPrice(infoVoucher?.orderValue) + " VNĐ"}</span>
            </div>
            {
              !(infoVoucher?.type !== 0) &&
              <div
                className={`info-list-item`}
                hidden={infoVoucher?.type !== 0}
              >
                <span className="font-medium fsz-16 lh-24">Số lượng Voucher</span>
                <span className="neutral-gray-500">{formatPrice(infoVoucher?.quantity)}</span>
              </div>
            }

          </div>

          <div className="info-list">
            <div className="info-list-item">
              <span className="font-medium fsz-16 lh-24">Thời gian áp dụng</span>
              <span className="primary-7">{`${infoVoucher?.timeStart} ${infoVoucher?.dateStart}`}</span>
            </div>
            <div className="info-list-item">
              <span className="font-medium fsz-16 lh-24">Thời gian hết hiệu lực</span>
              <span className="primary-7">{`${infoVoucher?.timeEnd} ${infoVoucher?.dateEnd}`}</span>
            </div>
            <div className="info-list-item">
              <span className="font-medium fsz-16 lh-24">Áp dụng cho</span>
              <span className="neutral-gray-500">{typeVoucher.find(item => item.Value === Number(infoVoucher?.type))?.label}</span>
            </div>
            <div className="info-list-item">
              <span className="font-medium fsz-16 lh-24">Mỗi khách áp dụng</span>
              <span className="neutral-gray-500">{formatPrice(infoVoucher?.usageCount)}</span>
            </div>
          </div>
        </div>
      </div>
      <img src="/images/brand-icon--180x120.svg" alt="" className="bg-img" />
    </div>
  )
}

export default VoucherDetailCard
