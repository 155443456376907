import { API_URL } from '../env';

export const mixFnum = (number : number) => {
  return new Intl.NumberFormat('vi-VN', { maximumSignificantDigits: 3 }).format(number);
}

export const mixGetV1 = async (url : string, params = {} as any) => {
  Object.keys(params).map((isKey, index) => {
    if (params[isKey] || params[isKey] === 0) {
      if (Array.isArray(params[isKey])) {
        params[isKey].map((val: any) => {
          url = `${url}${index === 0 ? '?' : '&'}${isKey}=${val}`
          return true
        })
      } else {
        url = `${url}${index === 0 ? '?' : '&'}${isKey}=${params[isKey]}`
      }
    }
    return null
  })
  const response = await fetch(`${API_URL}${url}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  if (!response.ok) {
    if (response.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/login';
    }
    throw new Error('Network response was not ok');
  }
  const temp = await response.json()
  return temp
}

export const mixPostV1 = async (url: string, param: {}) => {
  const response = await fetch(`${API_URL}${url}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(param),
  })
  if (!response.ok) {
    if (response.status === 401) {
      localStorage.removeItem('token')
      window.location.href = '/login'
    }
  }
  const temp = await response.json()
  return temp
}

export const mixDelV1 = async (url: string, param: {}) => {
  const response = await fetch(`${API_URL}${url}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(param),
  })
  if (!response.ok) {
    if (response.status === 401) {
      localStorage.removeItem('token')
      window.location.href = '/login'
    }
  }
  const temp = await response.json()
  return temp
}

export const mixTreeIds = (arr = [] as any) => {
  const res = [] as any
  arr.map( (item: any) => {
    if (typeof item === 'number' && !isNaN(item)) {
      res.push(item)
    } else {
      const temp = item.split('-')
      res.push(parseInt(temp[1]))
    }
    return true
  })
  return res
}

export const mixGender = (valx: any) => {
  const list = [
    {
      value: 1,
      title: 'Nam',
    },
    {
      value: 0,
      title: 'Nữ',
    },
    {
      value: 2,
      title: 'Không xác định',
    }
  ]
  const obs = {} as any
  list.map(val => {
    obs[`s${val.value}`] = val
    return true
  })
  if (valx || valx === 0) {
    return obs[`s${valx}`] || null
  }
  return list
}

export const mixGetTime = (dateString: any) => {
  const [day, month, year] = dateString.split("-")
  const dateObject = new Date(year, month - 1, day)
  return dateObject.getTime()
}
