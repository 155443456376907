import { ArrowLeftOutlined, DesktopOutlined, TabletOutlined } from "@ant-design/icons"
import { Breadcrumb, Table, TableColumnsType, TableProps } from "antd"
import { useState } from "react"
import { Link } from "react-router-dom"
import Layout from "../../Layout"
interface DataType {
  key: React.Key;
  title: string;
  content: string;
  text: string;
}


const data: DataType[] = [
  {
    key: 1,
    title: 'THACO AUTO',
    content: 'Tiêu đề',
    text: 'Nội dung'
  },
  {
    key: 2,
    title: 'THACO AUTO',
    content: 'Tiêu đề',
    text: 'Nội dung'
  },
  {
    key: 3,
    title: 'THACO AUTO',
    content: 'Tiêu đề',
    text: 'Nội dung'
  },
  {
    key: 4,
    title: 'THACO AUTO',
    content: 'Tiêu đề',
    text: 'Nội dung'
  },
  {
    key: 5,
    title: 'THACO AUTO',
    content: 'Tiêu đề',
    text: 'Nội dung'
  },
];
const PreviewSentEmail2: React.FC = () => {
  const [activeBtn, setActiveBtn] = useState(1)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const onChangeTB: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    console.log('params', pagination, filters, sorter, extra);
  };
  const columns: TableColumnsType<DataType> = [

    {
      title: <p className="text-base  font-bold fw-600 text-344054">THACO AUTO</p>,
      dataIndex: 'title',
      className: "w-122-px",
      render: (text: any) => <p className="text-base text-98A2B3 font-bold fw-600">{text}</p>
    },

    {
      title: <p className="text-base  font-bold fw-600 text-344054">THACO AUTO Khuyến mãi lên đến 10.000.000 đồng <span className="text-base  font-regular fw-400 text-344054"> - Xin trân trọng thông báo về chương trình khuyến mãi của THACO AUTO...</span></p>,
      className: "w-976-px",

      render: (text: DataType) => <p className="text-base text-98A2B3 font-bold fw-600">{text.content} - <span className="text-base text-98A2B3 font-regular fw-400">{text.text}</span></p>
    },

  ];
  return (
    <Layout>
      <div className="app-breadcrumb-block">
        <Breadcrumb
          items={[
            { title: <Link to="/">THACO AUTO CRM</Link>, },
            { title: <Link to="/marketing/notification-templates-management">Marketing</Link>, },
            { title: <Link to="/marketing/notification-templates-management">...</Link>, },
            { title: <Link to="/marketing/notification-templates-management/addemail">Tạo nội dung mẫu Email</Link>, },
            { title: 'Xem trước', }
          ]}
        />
        <div className="app-breadcrumb-title">
          <Link to='/marketing/notification-templates-management/addemail'><ArrowLeftOutlined /></Link>
          <div className="title">Xem trước </div>
        </div>
        <div className="py-6 d-flex justify-between pl-4">
          <div className="d-flex align-center gap-3">
            <img src="/images/exportImg/logo-mail.svg" alt="" className="h-8 w-88-px" />
            <span className="h-3 border-EDEDEE"></span>
            <p className="text-base text-344054 fw-600 font-bold">Giao diện khi bấm vào xem Email </p>
          </div>
          <div className="d-flex gap-2">
            <button
              className={`d-flex align-center gap-2 bg-white rounded-2  py-3 px-10-px  text-sm font-regular ${activeBtn === 1 ? 'border-40A9FF text-1890FF' : 'border-EAECF0 text-344054'}`}
              onClick={() => setActiveBtn(1)}
            >
              <DesktopOutlined className={`text-18-px ${activeBtn === 1 ? 'text-1890FF' : 'text-344054'}`} />
              Máy tính
            </button>
            <button className={`d-flex align-center gap-2 bg-white rounded-2  py-3 px-10-px  text-sm font-regular ${activeBtn === 2 ? 'border-40A9FF text-1890FF' : 'border-EAECF0 text-344054'}`}
              onClick={() => setActiveBtn(2)}
            >
              <TabletOutlined className={`text-18-px ${activeBtn === 2 ? 'text-1890FF' : 'text-344054'}`} />
              Điện thoại
            </button>
          </div>
        </div>
        <div className="t-table-block bg-header-table-F8F8F8">
          <Table
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
            onChange={onChangeTB}
            pagination={false}
            showSorterTooltip={false}
            className="w-fit-table border-end-table"
          />

        </div>
      </div>

    </Layout>
  )
}
export default PreviewSentEmail2
