import { CaretDownOutlined } from '@ant-design/icons';
import { Breadcrumb, Input, Tree } from 'antd';
import React, { useEffect, useState } from 'react';
import Layout from '../../../Layout';
import * as UnitApi from '../../../api/unit/unitApi';
import useToast from '../../../hook/useToast';
import './style.scss';

const IssuingUnit: React.FC = () => {
  const pushToast = useToast()
  const [dataIssuingUnit, setDataIssuingUnit] = useState<UnitApi.IIssuingUnitConvert[]>([])
  const [search, setSearch] = useState<string>('')
  const getListIssuingUnit = async (search: string = '') => {
    const result = await UnitApi.getListIssuingUnit(search);
    if (result.status) {
      setDataIssuingUnit(UnitApi.convertIssuingUnitValueToTree(result.data))
    } else {
      pushToast(result.message, 'error', 2000)
    }
  }

  useEffect(() => {
    document.title = 'THACO AUTO CRM';
    getListIssuingUnit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout>
      <Breadcrumb
        items={[
          {
            title: 'THACO AUTO CRM',
          },
          {
            title: <a href="/">Danh mục đơn vị</a>,
          },
          {
            title: 'Đơn vị ban hành',
          },
        ]}
      />
      <div className='title-page'>
        Đơn vị ban hành
      </div>
      <div className='d-flex align-center gap-10-px pb-18-px issuing-unit-filter'>
        <p className='text-base font-medium text-344054 white-space-nowrap'>Đơn vị ban hành</p>
        <Input
          placeholder="Tìm kiếm"
          value={search}
          onChange={(e)=> setSearch(e.target.value)}
          className="bg-white border-E1E1E1 text-sm rounded-2  font-regular  input-custom  h-38"
        />
        <button
        className='h-38 white-space-nowrap d-flex justify-center align-center border-096DD9 text-096DD9 bg-white rounded-2 text-sm font-regular'
        onClick={() => getListIssuingUnit(search)}
        >
          Tìm kiếm
        </button>
      </div>
      <div className='bg-096DD9 w-full h-12 d-flex align-center pl-6'>
        <p className='text-sm text-white font-bold fw-600'>Tên đơn vị ban hành</p>
      </div>
      <div className='pt-4'>
        <Tree
          draggable
          showLine={true}
          switcherIcon={<CaretDownOutlined className=' text-8E8E8E' />}
          // defaultExpandedKeys={['0-0-0', '0-0-2']}
          treeData={dataIssuingUnit}
          className="custom-ant-tree"
        />
      </div>
    </Layout>
  );
}

export default IssuingUnit;
