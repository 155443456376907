import React from "react";
import ARCustomerStatisticalCard from "../../../components/Cards/ARCustomerStatisticalCard";
import { CarOutlined, UserAddOutlined, UserOutlined } from "@ant-design/icons";
import './ARCStatisticalCards.scss'

const ARCCards: React.FC = () => {
  return(
    <div className="arc-statistical-cards">
      <ARCustomerStatisticalCard
          title={'Tổng khách hàng'}
          icon={<UserOutlined />}
          number={160000}
          type={'increase'}
          percent={12}          
        />
        <ARCustomerStatisticalCard
          title={'Khách hàng mới'}
          icon={<UserAddOutlined />}
          number={60000}
          type={'increase'}
          percent={12}
          className="sunset-orange"          
        />
        <ARCustomerStatisticalCard
          title={'Số xe bán ra'}
          icon={<CarOutlined />}
          number={63000}
          type={'increase'}
          percent={12}
          className="golden-purple"          
        />
    </div>
  )
}

export default ARCCards