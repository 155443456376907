import { CloseOutlined } from "@ant-design/icons";
import { Form, FormInstance, FormProps, Input, Modal, Select, TreeSelect } from "antd";
import { useForm } from 'antd/lib/form/Form';
import { useEffect, useRef } from "react";
import * as SelectOptionApi from '../../../../api/selectOptions/selectOptionsApi';
import * as DepartmentalUnitApi from '../../../../api/unit/departmentalUnitApi';
import useToast from "../../../../hook/useToast";
interface ModalProps {
  isOpen: boolean
  id: number | undefined
  dataBusinessUnit: SelectOptionApi.ISelectOption[]
  dataListNationwide: []
  closeModal: () => void
  getListDepartmentalUnit: () => void
}

const ModalEditDepartment: React.FC<ModalProps> = ({
  isOpen,
  id,
  dataListNationwide,
  dataBusinessUnit,
  getListDepartmentalUnit,
  closeModal
}) => {
  const [form] = useForm();
  const inputRef = useRef<FormInstance>(null);
  const pushToast = useToast();

  const submitForm = () => {
    if (inputRef.current) {
      inputRef.current.submit();
    }
  };
  const onFinish: FormProps["onFinish"] = async (values) => {
    if (id && values.codeadepartment && values.department && values.BusinessUnit && values.Nationwide) {
      const result = await DepartmentalUnitApi.updateDepartment(id, values.codeadepartment, values.department, values.BusinessUnit, values.Nationwide);
      if (result?.status) {
        pushToast(result.message, "success", 2000)
        closeModal();
        getListDepartmentalUnit()
      } else {
        pushToast(result.message, "error", 2000)
      }
    }
  };
  useEffect(() => {
    if (id) {
      handleDetail(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const handleDetail = async (id: number) => {
    const result = await DepartmentalUnitApi.detailDepartment(id);
    if (result?.status) {
      form.setFieldValue('department', result.data.title)
      form.setFieldValue('codeadepartment', result.data.code)
      form.setFieldValue('BusinessUnit', result.data.businessUnitId)
      form.setFieldValue('Nationwide', result.data.parentId)
    } else {
      pushToast(result.message, "error", 2000)

    }
  }

  return (
    <Modal
      title={<div className="bg-FCFCFD d-flex justify-betweeen align-center py-4 px-6 w-full border-b-F0F0F0">
        <span className="text-base text-344054 fw-500 font-medium">Chỉnh sửa phòng ban</span>
        <CloseOutlined className="text-base text-8B8B8B" onClick={closeModal} />
      </div>}
      open={isOpen}
      onCancel={closeModal}
      centered
      className="w-modal modal-content-creat-area ant-modal-size"
      closeIcon={false}
      footer={<div className="bg-FCFCFD d-flex justify-end align-center py-2 px-6 w-full border-t-F0F0F0 gap-2">
        <button className="text-sm text-344054 border-F2F4F7 bg-white h-8 px-15-px font-regular rounded-2 shadow-btn-save" onClick={closeModal}>Huỷ</button>
        <button className="text-white text-sm bg-096DD9 border-none shadow-btn-save rounded-2 h-8 px-15-px font-regular shadow-btn-save"
          onClick={submitForm}>
          Xác nhận
        </button>
      </div>}
    >
      <Form
        form={form}
        ref={inputRef}
        onFinish={onFinish}
        initialValues={{ remember: true }}
      >
        <div className="d-flex flex-column gap-18-px p-6">
          <div className="d-flex flex-column gap-1">
            <p className="text-sm  font-regular text-344054">Phòng ban</p>
            <Form.Item
              name="department"
              rules={[{ required: true, message: 'Nhập phòng ban!' }]}
              className="m-none-form-item text-explan"
            >
              <Input
                placeholder="Phòng ban"
                className="bg-FCFCFD border-F2F4F7 text-sm rounded-2  font-regular  input-custom  h-8"
              />
            </Form.Item>
          </div>
          <div className="d-flex flex-column gap-1">
            <p className="text-sm  font-regular text-344054">Mã phòng ban</p>
            <Form.Item
              name="codeadepartment"
              rules={[{ required: true, message: 'Nhập mã phòng ban!' }]}
              className="m-none-form-item text-explan"
            >
              <Input
                placeholder="Mã phòng ban"
                className="bg-FCFCFD border-F2F4F7 text-sm rounded-2  font-regular  input-custom  h-8"
              />
            </Form.Item>
          </div>
          <div className="d-flex flex-column gap-1">
            <p className="text-sm  font-regular text-344054">Khối / nghiệp vụ</p>
            <Form.Item
              name="BusinessUnit"
              rules={[{ required: true, message: 'Chọn khối / nghiệp vụ!' }]}
              className="m-none-form-item text-explan"
            >
              <Select
                placeholder="Khối / nghiệp vụ"
                options={dataBusinessUnit}
                className="custome-select-t "
                popupClassName='popup-select'
              />
            </Form.Item>
          </div>
          <div className="d-flex flex-column gap-1">
            <p className="text-sm  font-regular text-344054">Chọn văn phòng điều hành</p>
            <Form.Item
              name="Nationwide"
              rules={[{ required: true, message: 'Chọn văn phòng điều hành!' }]}
              className="m-none-form-item text-explan"
            >
              <TreeSelect
                showSearch
                placeholder="Chọn văn phòng điều hành"
                allowClear
                treeDefaultExpandAll={false}
                treeData={dataListNationwide}
                className="custom-tree-select "
                popupClassName="custom-dropdown-tree-select"
                filterTreeNode={(input: string, option: any) =>
                  typeof option?.label === 'string' && option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
            </Form.Item>
          </div>


        </div>
      </Form>
    </Modal>
  )
}
export default ModalEditDepartment
