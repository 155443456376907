import axiosClient, { ResponseSuccess, ResponseSuccessTotal } from "../axiosClient";

export interface IRank {
    id: number
    title: string
    icon: string
    price: number
}

export interface ICardCustomer {
    listBenefit: ListBenefit[]
    infoCustomer: InfoCustomer
    point: number
}

export interface ListBenefit {
    id: number
    title: string
    description: string
    status: number
}

export interface InfoCustomer {
    status: number
    timestamp: string
    customer: Customer
    rank: Rank
}

export interface Customer {
    username: string
    image: string
}

export interface Rank {
    id: number
    icon: string
    title: string
}
export interface IInfoRank {
    id: number
    title: string
    price: number
    icon: string
    benefits: number[]
}

export const getInfoRank = async (id: number
) => {
    const path = "rank/infoRank"
    const params = {
        id
    }
    const result: ResponseSuccess<IInfoRank> = await axiosClient.get(
        path, { params }
    );
    return result;
};

export const getListRank = async (
) => {
    const path = "rank/listRank"
    const params = {
    }
    const result: ResponseSuccessTotal<IRank[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};

export const addRank = async (title: string, price: number, image: File | null, listBenefits: number[]
) => {
    const path = "rank/addRank"
    const params = {
        title, price, image, listBenefits
    }
    const result: ResponseSuccess<[]> = await axiosClient.post(
        path, params
    );
    return result;
};
export const editRank = async (id: number, title: string, price: number, image: File | null, listBenefits: number[]
) => {
    const path = "rank/editRank"
    const params = {
        id, title, price, image, listBenefits
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        path, params
    );
    return result;
};

//Thông tin chi tiết thẻ phát hành
export const getInfoCardCustomer = async (
    id: number
) => {
    const path = "rank/infoCardCustomer"
    const params = {
        id
    }
    const result: ResponseSuccess<ICardCustomer> = await axiosClient.get(
        path, { params }
    );
    return result;
};

export const deleteRank = async (
    id: number
) => {
    const path = "rank/deleteRank"
    const params = {
        id
    }
    const result: ResponseSuccess<[]> = await axiosClient.delete(
        path, { params }
    );
    return result;
};