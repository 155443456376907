/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Progress } from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../../../Layout";
import * as customerApi from '../../../../api/customer/customerApi';
import * as selectOptionsApi from '../../../../api/selectOptions/selectOptionsApi';
import BreadcrumbBlock from "../../../../components/BreadcrumbBlock/BreadcrumbBlock";
import useToast from '../../../../hook/useToast';
import { regexPhoneNumber, validateEmail } from "../../../../until";
import InfoPersonal from "./Component/InfoPersonal";
import InfoRelative from "./Component/InfoRelative";
import './style.scss';


const AddCustomer: React.FC = () => {
  let errorsObj: {
    email: string,
    listRelatives: string,
    profession: string,
    userName: string;
    image: string;
    phone: string[];
    birthday: string;
    province: string;
    address: string;
    group: string;
    tag: string;
    hobby: string;
    car: string;
  } = { email: "", listRelatives: '', profession: "", userName: "", image: '', phone: [], birthday: "", province: "", address: "", group: "", tag: "", hobby: "", car: "" };
  const [errorObject, setErrorObject] = useState(errorsObj)
  const [error, setError] = useState(false)
  const [submit, setSubmit] = useState(true)
  const [province, setProvince] = useState<selectOptionsApi.ISelectOption[]>([])
  const [hobby, setHobby] = useState<selectOptionsApi.ISelectOption[]>([])
  const [tag, setTag] = useState<selectOptionsApi.ISelectOption[]>([])
  const [dataGroup, setDataGroup] = useState<selectOptionsApi.ISelectOption[]>([])
  const [dataRelatives, setDataRelatives] = useState<selectOptionsApi.ISelectOption[]>([])
  const [listRelatives, setListRelatives] = useState<selectOptionsApi.relationshipProp[]>([]);

  const [provinceId, setProvinceId] = useState<number | null>(null)
  const [valuesTag, setValuesTag] = useState<number[]>([]);
  const [valuesGroup, setValuesGroup] = useState<number[]>([]);
  const [valuesInterest, setValuesInterest] = useState<number[]>([]);
  const [valuesInterestRelative, setValuesInterestRelative] = useState<number[]>([]);
  const [valuesRelatives, setValuesRelatives] = useState<number | null>(null);
  const [userName, setUserName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [profession, setProfession] = useState<string>('');
  const [sex, setSex] = useState(1)
  const [marri, setMarri] = useState(0)
  const [date, setDate] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [phoneNumbers, setPhoneNumbers] = useState<selectOptionsApi.Phone[]>([{ phone: '', type: 1 }]);
  const [valueCar, setValueCar] = useState<number[]>([])
  const [percent, setPercent] = useState<number>(16)

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const pushToast = useToast();
  const navigator = useNavigate()

  const gListProvice = async () => {
    const result = await selectOptionsApi.getListProvince();
    if (result?.status) {
      setProvince(convertData(result.data))
    } else {
      console.log('object');
    }
  }
  const gListHobby = async () => {
    const result = await selectOptionsApi.getListHobby();
    if (result?.status) {
      setHobby(convertData(result.data))
    } else {
      console.log('object');
    }
  }

  const gListTag = async () => {
    const result = await selectOptionsApi.getListTag();
    if (result?.status) {
      setTag(convertData(result.data))
    } else {
      console.log('object');
    }
  }

  const getListRelatives = async () => {
    const result = await selectOptionsApi.getListRelatives();
    if (result?.status) {
      setDataRelatives(convertData(result.data))
    } else {
      console.log('object');
    }
  }

  const gListGroup = async () => {
    const result = await selectOptionsApi.getListGroup();
    if (result?.status) {
      setDataGroup(convertData(result.data))
    } else {
      console.log('object');
    }
  }
  // gValidatePhone(convertData2(phoneNumbers)).then(result => {

  //   console.log(result);
  // });
  const gValidatePhone = async (phoneArray: string[]) => {
    const result = await selectOptionsApi.getValidatePhone(phoneArray);
    if (result.data.length > 0) {
      pushToast(`${result.message}  ${convertData3(result.data)}`, 'error', 2000)
      return false
    } else {
      return true
    }
  }
  const gValidateMail = async (mail: string) => {
    const result = await selectOptionsApi.getValidateMail(mail);
    if (result.data.length > 0) {
      pushToast(result.message, 'error', 2000)
      return false
    } else {
      return true
    }
  }
  const convertData = (data: selectOptionsApi.IHobby[]) => {
    const convertData = data.map((item) => ({
      value: item.id,
      label: item.title,
    }))
    return convertData
  }
  const convertData2 = (data: selectOptionsApi.Phone[]) => {
    const convertData = data.map((item) => (item.phone))
    return convertData
  }
  const convertData3 = (data: { id: number, phone: string }[]) => {
    const convertData = data.map((item) => (item.phone))
    return convertData
  }
  useEffect(() => {
    gListProvice()
    gListHobby()
    gListTag()
    gListGroup()
    getListRelatives()
  }, [])
  async function validatePhoneFunction() {
    const isValid = await gValidatePhone(convertData2(phoneNumbers));
    return isValid
  }
  async function validateEmailFunction() {
    const isValid = await gValidateMail(email);
    return isValid;
  }
  // validatePhoneFunction()
  // validateEmailFunction()
  const handleSave = async () => {
    let test = false

    var x = false;
    var y = false;
    await validateEmailFunction().then(result => {
      x = result
    })
    await validatePhoneFunction().then(result => {
      y = result
    })
    if (!x || !y) {
      test = true
    }
    if (!submit) {
      if (listRelatives.length === 0) {
        pushToast("Vui lòng thêm ít nhất 1 người thân!", "warning", 2000)
      } else {
        const result = await customerApi.addCustomer(
          userName,
          sex,
          marri,
          profession,
          phoneNumbers,
          date,
          email,
          provinceId,
          address,
          uploadedFile,
          valuesGroup,
          valuesTag,
          valuesInterest,
          valueCar,
          listRelatives,
        );
        if (result?.status) {
          pushToast("Thêm mới thành công!", "success", 2000)
          setTimeout(() => {
            navigator('/customer/list')
          }, 2000)
        } else {
          pushToast(result.message, "error", 2000)
        }
      }
    }
    if (date === '') {
      errorsObj.birthday = 'Vui lòng chọn ngày sinh!';
      test = true
      setError(true)
    }
    if (regexPhoneNumber(phoneNumbers).filter((value) => value === false).length > 0) {
      const data: string[] = regexPhoneNumber(phoneNumbers).map((value) => {
        if (value === true) {
          return ''
        } else {
          return 'Số điện thoại không đúng định dạng!'
        }
      })
      errorsObj.phone = data;
      test = true
      setError(true)
    }
    if (userName === '') {
      errorsObj.userName = 'Vui lòng nhập họ và tên!';
      test = true
      setError(true)
    }
    if (email === '') {
      errorsObj.email = 'Vui lòng nhập địa chỉ email!';
      test = true
      setError(true)
    }
    if (!validateEmail(email)) {
      errorsObj.email = 'Email không đúng định dạng!';
      test = true
      setError(true)
    }
    if (profession === '') {
      errorsObj.profession = 'Vui lòng nhập nghề nghiệp!';
      test = true
      setError(true)
    }
    if (provinceId === null) {
      errorsObj.province = 'Vui lòng chọn thành phố!';
      test = true
      setError(true)
    }
    if (uploadedFile === null) {
      errorsObj.image = 'Vui lòng chọn ảnh đại diện!';
      test = true
      setError(true)
    }
    if (valuesGroup.length === 0) {
      errorsObj.group = 'Vui lòng chọn nhóm!';
      test = true
      setError(true)
    }
    if (valuesTag.length === 0) {
      errorsObj.tag = 'Vui lòng chọn thẻ tag!';
      test = true
      setError(true)
    }
    if (valuesInterest.length === 0) {
      errorsObj.hobby = 'Vui lòng chọn sở thích!';
      test = true
      setError(true)
    }
    if (valueCar.length === 0) {
      errorsObj.car = 'Vui lòng chọn xe quan tâm!';
      test = true
      setError(true)
    }
    setErrorObject(errorsObj)
    if (test) {
      return
    } else {
      setSubmit(false)
    }
  }
  return (
    <Layout>
      <BreadcrumbBlock
        items={[
          { title: <Link to="/">THACO AUTO CRM</Link>, },
          { title: <Link to="/customer/list">Khách hàng</Link>, },
          { title: <Link to="/customer/list">Danh sách khách hàng</Link>, },
          { title: 'Thêm mới khách hàng', },
        ]}
        title='Thêm mới khách hàng'
        backLink='/customer/list'
      />
      <div className="d-flex justify-center py-6 ">
        <div className="d-flex flex-column gap-34-px w-500-px">
          <p className="text-344054 text-sm fw-600 text-center font-bold">Thông tin cơ bản của khách hàng</p>
          <Form>
            <div className="d-flex align-center gap-6">
              <div className={`d-flex align-center  ${submit ? "gap-2" : ''}`}>
                {submit
                  ? (
                    <Progress
                      percent={percent}
                      className={`d-flex align-center custom-progress w-255-px h-progress text-progress d-none-block-progress bg-progress-slide gap-2 h-progress-outer`}

                    />
                  )
                  : (
                    <Progress
                      percent={percent}
                      className={`d-flex align-center custom-progress w-378-px h-progress text-progress d-none-block-progress size-check-circle w-none-progress gap-2  h-progress-outer m-none ant-progress-success`}
                    />
                  )}
                <p className="text-667085 text-sm font-regular"> {submit ? 'hoàn thiện thông tin' : ''} </p>
              </div>
              <button className="text-white text-base py-1 px-4 rounded-7-px  fw-500 bg-096DD9 border-none outline-none line-height-6 font-regular" onClick={handleSave} >
                {submit ? 'Tiếp theo' : 'Xác nhận'}
              </button>
            </div>
            {
              submit ?
                (
                  <InfoPersonal
                    sLProvince={province}
                    sLHobby={hobby}
                    sLTag={tag}
                    sLGroup={dataGroup}
                    setProvinceId={setProvinceId}
                    provinceId={provinceId}
                    valuesGroup={valuesGroup}
                    setValuesGroup={setValuesGroup}
                    valuesTag={valuesTag}
                    setValuesTag={setValuesTag}
                    valuesInterest={valuesInterest}
                    setValuesInterest={setValuesInterest}
                    userName={userName}
                    setUserName={setUserName}
                    sex={sex}
                    setSex={setSex}
                    marri={marri}
                    setMarri={setMarri}
                    date={date}
                    setDate={setDate}
                    address={address}
                    setAddress={setAddress}
                    phoneNumbers={phoneNumbers}
                    setPhoneNumbers={setPhoneNumbers}
                    valueCar={valueCar}
                    setValueCar={setValueCar}
                    errorObject={errorObject}
                    error={error}
                    setError={setError}
                    uploadedFile={uploadedFile}
                    setUploadedFile={setUploadedFile}
                    email={email}
                    setEmail={setEmail}
                    profession={profession}
                    setProfession={setProfession}
                    percent={percent}
                    setPercent={setPercent}
                  />
                ) : (
                  <InfoRelative
                    dataRelatives={dataRelatives}
                    setValuesRelatives={setValuesRelatives}
                    valuesRelatives={valuesRelatives}
                    sLHobby={hobby}
                    valuesInterestRelative={valuesInterestRelative}
                    setValuesInterestRelative={setValuesInterestRelative}
                    setListRelatives={setListRelatives}
                    listRelatives={listRelatives}
                    percent={percent}
                    setPercent={setPercent}
                  />
                )
            }
          </Form>
        </div>
      </div>
    </Layout>
  )
}
export default AddCustomer
