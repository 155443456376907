import React from "react"
import './EmptyComponentLink.scss'
import { Link } from "react-router-dom"

interface Props {
  content?: string,
  link?: string,
  namelink?: string,
}

const EmptyComponentLinkText: React.FC<Props> = (props) => {
  const { content, link, namelink } = props
  return (
    <div className="empty-component-link t-mt-80">
      <div className="empty-component-link__inner">
        <img src="/images/empty-image.svg" alt="empty" />
        <div className="note">
          <span>{content}</span>
          <Link to={link || ''}> {namelink}</Link>
        </div>
      </div>
    </div>
  )
}

export default EmptyComponentLinkText
