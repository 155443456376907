import {
  CrownOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import type { TableColumnsType } from 'antd';
import { Breadcrumb, ConfigProvider, Pagination, Switch, Table } from 'antd';
import viVN from 'antd/locale/vi_VN';
import React, { useEffect, useState } from 'react';
import Layout from '../../Layout';
import './ClassBenefits.scss';
import ShowAddBenefit from './ShowAddBenefit';
// import TextArea from 'antd/es/input/TextArea';
import * as BenefitApi from '../../api/rank/benefitApi';
import ModalDeleteManager from '../../components/ModalDeleteManager';
import useToast from '../../hook/useToast';
import DrawerEditBenefit from './Drawer/DrawerEditBenefit';




const onChangeSW = (checked: boolean) => {
  console.log(`switch to ${checked}`);
};

const ClassBenefits: React.FC = () => {

  const [dataBenefit, setDataBenefit] = useState<BenefitApi.DataTypeBenefit[]>([])
  const pushToast = useToast();
  const [id, setId] = useState<number>()
  // const [data, setData] = useState<any>();
  const [search, setSearch] = useState<string>()
  const [isAddCardUser, setAddCardUser] = useState(false)
  const [editBenefit, setEditBenefit] = useState(false)
  const [isOpenDeleteBenefit, setIsOpenDeleteBenefit] = useState(false)
  if (dataBenefit) {
    dataBenefit.sort((a, b) => a.stt - b.stt)
  }
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
  const [sort, setSort] = useState<boolean>(false)
  const columns: TableColumnsType<BenefitApi.DataTypeBenefit> = [
    {
      title: 'STT',
      dataIndex: 'stt',
      align: 'center',
      key: 'stt',
      sorter: {},
      onHeaderCell: (column) => {
        return {
          onClick: () => {
            if (sort) {
              setSort(!sort)
              getListBenefit(pagination.current, pagination.pageSize, search, 1)
            } else {
              setSort(!sort)
              getListBenefit(pagination.current, pagination.pageSize, search, 0)
            }
          },
        };
      },
    },
    {
      title: 'Hành động',
      align: 'center',
      render: (_, record) => (
        <div className="t-hd-table">
          < div onClick={() => { setEditBenefit(true); setId(record.key) }} className="item-hd-table cursor-pointer" > <span><EditOutlined /></span></div >

          <div className="item-hd-table t-cl-F5222D cursor-pointer" onClick={() => { setIsOpenDeleteBenefit(true); setId(Number(record.key)) }}><span><DeleteOutlined /></span></div>
          <div className="t-switch-block t-switch-block-sm">
            <Switch
              defaultChecked={record.status ? true : false}
              onChange={() => updateActiveBenefit(Number(record.key))}
            />
          </div>
        </div >
      ),
    },
    {
      title: 'Tên quyền lợi',
      dataIndex: 'title',
      render: (text) => (
        <div className="t-name-ql">
          <div className="icon-svg"><CrownOutlined /></div>
          <span className="neutral-gray-500 text-2-row">{text}</span>
        </div>
      ),
    },
    {
      title: 'Mô tả quyền lợi dễ hiểu',
      dataIndex: 'description',
      render: (text) => (
        <div className="text-2-row">{text}</div>
      ),
    },
  ];


  // const onChangeTB: TableProps<BenefitApi.DataTypeBenefit>['onChange'] = (pagination, filters, sorter, extra) => {
  //   console.log('params', pagination, filters, sorter, extra);
  // };
  const [dataInfoBenefit, setDataInfoBenefit] = useState<BenefitApi.IInfoBenefit>()
  const infoBenefit = async (id: number) => {
    const result = await BenefitApi.infoBenefit(id);
    if (result?.status) {
      setDataInfoBenefit(result.data)
      console.log(result.data);
    } else {
      pushToast(result.message, 'error')
    }
  }
  // thay đổi trang thái
  const updateActiveBenefit = async (id: number) => {
    const result = await BenefitApi.updateActiveBenefit(id);
    if (result?.status) {
      getListBenefit(pagination.current, pagination.pageSize)
      pushToast(result.message, "success", 2000)
    } else {
      pushToast(result.message, "error", 2000)

    }
  }
  const getListBenefit = async (pageCurent: number = 1, pageSize: number = 10, search: string = '', sort: number = 0) => {
    const result = await BenefitApi.getListBenefit(pageSize, pageCurent, search, sort);
    if (result?.status) {
      setDataBenefit(convertData(result.data, pageCurent, pageSize))
      setPagination({ current: pageCurent, total: result.total, pageSize })
    } else {
      pushToast(result.message, 'error', 2000)
    }
  }

  const convertData = (data: BenefitApi.IBenefit[], pageCurent: number, pageSize: number) => {
    var stt = (pageCurent - 1) * pageSize + 1;
    // console.log(pageCurent, pageSize);
    const convertData = data.map((item) => ({
      key: item.id,
      stt: stt++,
      title: item.title,
      description: item.description,
      status: item.status
    }))
    return convertData
  }

  const handleChangePagination = (current: number, pageSize: number) => {
    getListBenefit(current, pageSize)
  }
  useEffect(() => {
    document.title = 'THACO AUTO CRM';
    getListBenefit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (id) {
      infoBenefit(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const handleDelete = async () => {
    if (id) {
      const result = await BenefitApi.deleteBenefit(id);
      if (result?.status) {
        getListBenefit(pagination.current, pagination.pageSize)
        setIsOpenDeleteBenefit(false)
        pushToast(result.message, "success", 2000)

      } else {
        pushToast(result.message, "error", 2000)

      }
    }
  }
  return (
    <div className='card-issuance-list'>
      <Layout>
        <DrawerEditBenefit
          isOpen={editBenefit}
          closeDrawer={() => setEditBenefit(false)}
          dataInfoBenefit={dataInfoBenefit}
          getListBenefit={getListBenefit}
        />
        <ModalDeleteManager
          isOpen={isOpenDeleteBenefit}
          title="Bạn có chắc chắn xóa quyền lợi này?"
          titleDelete="Xoá quyền lợi này"
          content="Quyền lợi này sẽ bị xóa và vĩnh viễn không thể
khôi phục"
          onConfirm={handleDelete}
          closeModal={() => setIsOpenDeleteBenefit(false)}
        />
        <Breadcrumb
          items={[
            {
              title: 'THACO AUTO CRM',
            },
            {
              title: <a href="/">Cấu hình</a>,
            },
            {
              title: 'Quản lý quyền lợi',
            },
          ]}
        />

        <div className='title-page'>
          Quản lý quyền lợi
        </div>

        {/* Fillter block */}
        <div className='filter-block ctrl-filter-benefits'>
          <div className="title-block">Danh sách quyền lợi</div>

          <div className="btn-input btn-primary">
            <div
              className="t-btn-link-icon"
              onClick={() => setAddCardUser(true)}
            >
              <div className="icon-svg"><PlusCircleOutlined /></div>
              <div className="text">Thêm mới</div>
            </div>
          </div>
        </div>

        <div className="t-table-block t-table-benefits">
          <Table
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
            columns={columns}
            dataSource={dataBenefit}
            // onChange={onChangeTB}
            pagination={false}
            showSorterTooltip={false}
          />

          <ConfigProvider locale={viVN}>
            <div className="pagination-block">
              <Pagination
                total={pagination.total}
                current={pagination.current}
                pageSize={pagination.pageSize}
                showSizeChanger
                // showQuickJumper
                showTotal={() => `Tổng cộng ${Math.ceil(pagination.total / pagination.pageSize) === 0 ? 1 : Math.ceil(pagination.total / pagination.pageSize)} trang`}
                locale={{ items_per_page: 'trang' }}
                onChange={handleChangePagination}
              />
            </div>
          </ConfigProvider>
        </div>

        {
          isAddCardUser
            ? <ShowAddBenefit
              className="show"
              closeModal={() => setAddCardUser(!isAddCardUser)}
              getListBenefit={getListBenefit}
            />
            : null
        }

      </Layout>
    </div>
  );
}

export default ClassBenefits;
