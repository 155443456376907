import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Breadcrumb, ConfigProvider, Input, Pagination, Switch, Table, TableColumnsType } from 'antd';
import viVN from 'antd/locale/vi_VN';
import React, { useEffect, useState } from 'react';
import Layout from '../../../Layout';
import * as UnitApi from '../../../api/unit/unitApi';
import ModalDeleteManager from '../../../components/ModalDeleteManager';
import useToast from '../../../hook/useToast';
import ModalCreateArea from './Modal/ModalCreateArea';
import ModalEditArea from './Modal/ModalEditArea';
import './style.scss';

const Nationwide: React.FC = () => {


  const [openCreateArea, setOpenCreateArea] = useState(false)
  const [openEditArea, setOpenEditArea] = useState(false)
  const [id, setId] = useState<number>()
  const pushToast = useToast();
  const [openDeleteArea, setOpenDeleteArea] = useState(false)
  const columns: TableColumnsType<UnitApi.DataTypeNationwide> = [
    {
      title: 'Hành động',
      dataIndex: '',
      render: (_, record) => (
        <div className='d-flex align-center justify-center gap-10-px'>
          <DeleteOutlined
            className='text-F5222D text-sm border-EAECF0 p-5-px bg-white rounded-2'
            onClick={() => { setOpenDeleteArea(true); setId(Number(record.key)) }}
          />
          <EditOutlined
            className='text-98A2B3 text-sm border-EAECF0 p-5-px bg-white rounded-2'
            onClick={() => { setOpenEditArea(true); setId(Number(record.key)) }}
          />
        </div>

      ),
      className: "w-83-px",
      align: 'center'
    },

    {
      title: 'Mã toàn quốc',
      dataIndex: 'codeArea',
      className: "w-121-px"
    },
    {
      title: 'Tên ',
      dataIndex: 'nameArea',
      className: "w-813-px",
      sorter: (a, b) => a.nameArea.length - b.nameArea.length,
      // filters: [
      //   { text: 'Joe', value: 'Joe' },
      //   { text: 'Jim', value: 'Jim' },
      // ],
    },
    {
      title: 'Trạng thái',
      // dataIndex: 'view',
      className: "w-175-px",
      render: (_, record) => <div className='d-flex align-center gap-10-px'>
        <Switch
          defaultChecked={record.view ? true : false}
          onChange={() => updateActiveNationwide(Number(record.key))}
          className="rs-switch rs-switch--sm"
        />
        <p className='font-medium fw-500 text-344054'>{UnitApi.activeArray[record.view]}</p>
      </div>
    },
  ];
  const [dataNationwide, setDataNationwide] = useState<UnitApi.DataTypeNationwide[]>([])

  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
  const [search, setSearch] = useState<string>()

  const gListNationwide = async (pageCurent: number = 1, pageSize: number = 10, search: string = '') => {
    const result = await UnitApi.getListNationwide(pageSize, pageCurent, search);
    if (result?.status) {
      setDataNationwide(convertData(result.data, pageCurent, pageSize))
      setPagination({ current: pageCurent, total: result.total, pageSize })
    } else {
      console.log('object');
    }
  }
  const updateActiveNationwide = async (id: number) => {
    const result = await UnitApi.updateActiveNationwide(id);
    if (result?.status) {
      gListNationwide(pagination.current, pagination.pageSize)
      pushToast(result.message, "success", 2000)
      setTimeout(() => {
        setOpenDeleteArea(false)
      }, 2000)
    } else {
      console.log('object');
    }
  }

  const convertData = (data: UnitApi.INationwide[], pageCurent: number, pageSize: number) => {
    // var stt = (pageCurent - 1) * pageSize + 1;
    const convertData = data.map((item) => ({
      key: item.id,
      codeArea: item.code,
      nameArea: item.title,
      view: item.view
    }))
    return convertData
  }

  const handleChangePagination = (current: number, pageSize: number) => {
    gListNationwide(current, pageSize)
  }

  const handleDelete = async () => {
    if (id) {
      const result = await UnitApi.deleteActiveNationwide(id);
      if (result?.status) {
        gListNationwide(pagination.current, pagination.pageSize)
        setOpenDeleteArea(false)
        pushToast("Xóa đơn vị thành công!", "success", 2000)

      } else {
        console.log('object');
      }
    }
  }

  useEffect(() => {
    document.title = 'THACO AUTO CRM';
    gListNationwide()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <ModalCreateArea
        gListNationwide={gListNationwide}
        isOpen={openCreateArea}
        closeModal={() => setOpenCreateArea(!openCreateArea)}
      />
      <ModalEditArea
        id={id}
        isOpen={openEditArea}
        gListNationwide={gListNationwide}
        closeModal={() => setOpenEditArea(!openEditArea)}
      />
      <ModalDeleteManager
        isOpen={openDeleteArea}
        onConfirm={handleDelete}
        closeModal={() => setOpenDeleteArea(!openDeleteArea)}
        title="Bạn có chắc chắn xóa toàn quốc này"
        titleDelete="Xóa toàn quốc"
        content="Toàn quốc và dữ liệu liên quan sẽ bị xóa vĩnh viễn
    không thể khôi phục"/>
      <Breadcrumb
        items={[
          {
            title: 'THACO AUTO CRM',
          },
          {
            title: <a href="/">Danh mục đơn vị</a>,
          },
          {
            title: 'Toàn quốc',
          },
        ]}
      />
      <div className='title-page'>
        Toàn quốc
      </div>
      <div className='d-flex align-center gap-10-px pb-18-px departmental-unit-filter'>
        <p className='text-base font-medium text-344054 white-space-nowrap'>Danh sách toàn quốc</p>
        <Input
          placeholder="Tìm kiếm"
          onChange={(e) => setSearch(e.target.value)}
          className="bg-white border-E1E1E1 text-sm rounded-2 font-regular input-custom h-38"
        />
        <button className='h-38 white-space-nowrap d-flex justify-center align-center border-096DD9 text-096DD9 bg-white rounded-2 text-sm font-regular'
          onClick={() => gListNationwide(pagination.current, pagination.pageSize, search)}>
          Tìm kiếm
        </button>
        <button
          className='h-38 white-space-nowrap d-flex justify-center align-center border-none text-white bg-096DD9 rounded-2 text-sm font-regular gap-10-px'
          onClick={() => setOpenCreateArea(true)}
        >
          <PlusOutlined className='text-sm text-white' />
          Tạo mới
        </button>
      </div>
      <div className="t-table-block d-flex flex-column justify-between h-822-px">
        <Table
          rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
          columns={columns}
          dataSource={dataNationwide}
          pagination={false}
          showSorterTooltip={false}
          className="w-fit-table border-end-table zise-filter w-fit-table-filter"
        />
        <div className="pagination-block border-top-F2F4F7">
          <ConfigProvider locale={viVN}>
            <div className="pagination-block">
              <Pagination
                total={pagination.total}
                current={pagination.current}
                pageSize={pagination.pageSize}
                showSizeChanger
                // showQuickJumper
                showTotal={() => `Tổng cộng ${Math.ceil(pagination.total / pagination.pageSize) === 0 ? 1 : Math.ceil(pagination.total / pagination.pageSize)} trang`}
                // showTotal={() => `Tổng cộng ${total} trang`}

                // showTotal={(total, range) => <span className='text-[#8E8E8E]'>{`${range[0]}-${range[1]} trong ${total} dữ liệu`}</span>}
                locale={{ items_per_page: 'trang' }}
                onChange={handleChangePagination}
              />
            </div>
          </ConfigProvider>
        </div>
      </div>
    </Layout>
  );
}

export default Nationwide;
