import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../Layout';
import BreadcrumbBlock from '../../components/BreadcrumbBlock/BreadcrumbBlock';
import { Link } from 'react-router-dom';
import { Button, DatePicker, Form, FormInstance, FormProps, Input, Modal, Select, notification } from 'antd';
import { SearchProps } from 'antd/lib/input';
import './VoucherSearch.scss';
import Lottie from 'react-lottie';
import SearchLoading from '../../assets/json/search-loading.json'
import VoucherResultCard from '../../components/Cards/VoucherResultCard';
import { SearchOutlined } from '@ant-design/icons';
import * as VoucherApi from '../../api/voucher/voucherApi';
import * as CustomerApi from '../../api/customer/customerApi';
import * as OptionApi from '../../api/selectOptions/selectOptionsApi';
import useToast from '../../hook/useToast';
import { fommatSelectOption, fommatSelectOptionCustomer } from '../../until';
import { useForm } from 'antd/lib/form/Form';

const { Search } = Input;

type FieldType = {
  customerName: number;
  shopId: number;
  date: Date;
  code: string;
}

const VoucherSearch: React.FC = () => {


  const [isEmpty] = useState(true)
  const [isSearching, setSearching] = useState(false)
  const [isSearched, setSearched] = useState(false)
  const [statusVoucher, setStatusVoucher] = useState(false)

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: SearchLoading,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  // ON SEARCH
  const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
    getLoopupVoucher(value)
    setSearching(true)
    setTimeout(() => {
      setSearching(false)
      setSearched(true)
    }, 1000)
  }

  // HANDLE MODAL
  const [isModalShow, setModalShow] = useState(false)

  // ON SUBMIT FORM
  const formRef = useRef<FormInstance<FieldType>>(null);
  const onSubmitForm = () => {
    if (formRef.current) {
      formRef.current.submit()
    }
  }

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();

  const [dataLoopupVoucher, setDataLoopupVoucher] = useState<VoucherApi.ILoopupVoucher>()
  const [dataOptionCustomer, setDataOptionCustomer] = useState<OptionApi.ISelectOption[]>()
  const [dataOptionAgent, setDataOptionAgent] = useState<OptionApi.ISelectOption[]>()
  const pushToast = useToast();

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    if (dataLoopupVoucher) {
      const result = await VoucherApi.conversionVoucher(dataLoopupVoucher?.code, values.shopId, values.date.toISOString().slice(0, 10), values.code, values.customerName);
      if (result?.status) {
        pushToast(result.message, "success", 2000)
        setModalShow(false)
      } else {
        pushToast(result.message, "error", 2000)
      }
    }
  };

  const getListCustomer = async () => {
    const result = await CustomerApi.getListCustomer();
    if (result.status) {
      setDataOptionCustomer(fommatSelectOptionCustomer(result.data))
    } else {
      pushToast(result.message, "error", 2000)
    }
  }
  const getListAgent = async () => {
    const result = await OptionApi.getListAgent();
    if (result.status) {
      setDataOptionAgent(fommatSelectOption(result.data))
    } else {
      pushToast(result.message, "error", 2000)

    }
  }

  const getLoopupVoucher = async (code: string) => {
    const result = await VoucherApi.getLoopupVoucher(code);
    if (result.status) {
      setDataLoopupVoucher(result.data)
      setStatusVoucher(result.data.statusConversion === 0 ? true : false)
    } else {
      setSearched(false)
      setSearching(false)
      setDataLoopupVoucher(undefined)
      pushToast(result.message, "error", 2000)

    }
  }
  const [form] = useForm();

  const [dataVoucherDetail, setDataVoucherDetail] = useState<VoucherApi.IVoucherEdit>()

  const getInfoVoucher = async (id: number) => {
    const result = await VoucherApi.getInfoVoucher(id);
    if (result.status) {
      setDataVoucherDetail(result.data)

    } else {
      pushToast(result.message, "error", 2000)

    }
  }
  useEffect(() => {
    getListCustomer()
    getListAgent()
  }, []);

  useEffect(() => {
    if (isModalShow) {
      form.resetFields();
    }
  }, [isModalShow])
  return (
    <Layout
      contentClassName="voucher-search-content"
    >
      {contextHolder}

      <BreadcrumbBlock
        items={[
          { title: <Link to="/">THACO AUTO CRM</Link>, },
          { title: 'Marketing', },
          { title: 'Tra cứu Voucher', },
        ]}
        title='Tra cứu Voucher'
      />

      <div className="voucher-search-block">
        <div className="title">Tra cứu Voucher</div>
        <Search
          placeholder="Tra cứu Voucher"
          allowClear
          onSearch={onSearch}
          className="rs-input-search"
        />

        {
          (isEmpty && !dataLoopupVoucher)
          &&
          <>
            <img
              src="/images/search-with-no-result.svg"
              alt="no-result"
              className="no-result-img"
            />
            <span>Nhập mã Voucher và nhấn Enter để tìm kiếm</span>
          </>
        }

        {
          isSearching
          &&
          <>
            <div className="search-loading-img">
              <Lottie
                options={defaultOptions}
              />
            </div>
            <span>Hệ thống đang tìm kiếm...</span>
          </>
        }

        {
          isSearched && dataLoopupVoucher
          &&
          <VoucherResultCard
            statusVoucher={statusVoucher}
            id={dataLoopupVoucher.code}
            promotionName={dataLoopupVoucher.title}
            status={dataLoopupVoucher.statusConversion ? 'Đã đổi' : 'Chưa đổi'}
            discountType={dataLoopupVoucher.status ? 'Giảm theo phần trăm' : 'Giảm theo số tiền'}
            condition={`Giá trị đơn hàng = ${dataLoopupVoucher.orderValue.toLocaleString('en-US')}`}
            voucherType={dataLoopupVoucher.type === 0 ? 'Toàn bộ khách hàng' : `${dataLoopupVoucher.customerName}`}
            maxDiscount={dataLoopupVoucher.reducedAmountMaximum.toLocaleString('en-US')}
            period={`${dataLoopupVoucher.timeStart} ${dataLoopupVoucher.dateStart} - ${dataLoopupVoucher.timeEnd} ${dataLoopupVoucher.dateEnd}`}
            ctaClick={() => { setModalShow(true); getInfoVoucher(dataLoopupVoucher.voucherId) }}
          />
        }
      </div>

      <Modal
        title="Cập nhật Voucher thủ công"
        open={isModalShow}
        centered
        // confirmLoading={}
        footer={[
          <span
            key="back"
            className="neutral-gray-400 cursor-pointer"
            onClick={() => setModalShow(false)}
          >Hủy</span>,
          <Button
            key="submit"
            type="primary"
            // loading={confirmLoading}
            onClick={onSubmitForm}
            className="rs-button"
          >
            Xác nhận đã quy đổi
          </Button>,
        ]}
        className="rs-modal w-400"
        onCancel={() => setModalShow(false)}
      >
        <Form
          form={form}
          autoComplete="off"
          className="rs-form voucher-update-form"
          onFinish={onFinish}
        >
          {dataLoopupVoucher?.type === 0 ?
            <Form.Item
              name="customerName"
            > <Select
                placeholder="Tìm và chọn khách hàng"
                suffixIcon={<SearchOutlined />}
                className="rs-select"
                popupClassName="rs-select-dropdown"
                virtual={false}
                options={dataOptionCustomer}
              />
            </Form.Item> :
            <Form.Item
            >
              <Input disabled value={dataLoopupVoucher?.customerName} />
            </Form.Item>}
          <Form.Item<FieldType>

            name="shopId"
            rules={[{ required: true, message: 'Vui lòng nhập ID cửa hàng!' }]}
          >
            <Select
              placeholder="Chọn đại lý"
              suffixIcon={<SearchOutlined />}
              className="rs-select"
              popupClassName="rs-select-dropdown"
              virtual={false}
              options={dataOptionAgent}
            />
          </Form.Item>
          <Form.Item<FieldType>
            name="date"
            rules={[{ required: true, message: 'Vui chọn ngày quy đổi!' }]}
          >
            <DatePicker
              placeholder='Ngày quy đổi'
              className="rs-date-picker d-flex"
              popupClassName="rs-date-picker-dropdown"
            />
          </Form.Item>
          <Form.Item<FieldType>
            name="code"
            rules={[{ required: true, message: 'Vui chọn nhập mã đơn hàng!' }]}
          >
            <Input
              placeholder='Mã đơn hàng'
              className="rs-date-picker d-flex"
              maxLength={50}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  );
}

export default VoucherSearch;
