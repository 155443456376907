import { CloseOutlined } from "@ant-design/icons"
import { Input, Modal, Select } from "antd"
import './style.scss'
import { ICarCompany } from "../../../../../api/selectOption/selectOptionsApi"
import { fommatSelectOption } from "../../../../../until"
import { useEffect, useState } from "react"
import { ICarLineEdit, editCarLines } from "../../../../../api/carLine/carLineApi"
import useThrottle from "../../../../../hook/useThrottle"
interface ModalProps {
  isOpen: boolean
  infoCarLine?: ICarLineEdit
  sLCategoryCar: ICarCompany[]
  onSubmitFormModal?: (iSsuccess: boolean, errorMessage?: string) => void;
  closeModal: () => void
}
const ModalEditVehicle: React.FC<ModalProps> = ({
  isOpen, infoCarLine, sLCategoryCar,
  onSubmitFormModal, closeModal
}) => {

  let errorsObj = { editTitle: "", editCategoryCar: "", result: "" };
  const [errors, setErrors] = useState(errorsObj);

  const [editTitle, setEditTitle] = useState<string>("")
  const [editCategoryCar, setEditCategoryCar] = useState<number>()

  const checkVariables = useThrottle(() => {
    let error = false;
    const errorObj = { ...errorsObj };
    if (!editCategoryCar) {
      errorObj.editCategoryCar = "Vui lòng chọn tên thương hiệu xe!"
      error = true;
    }
    if (editTitle === undefined || !editTitle.trim()) {
      errorObj.editTitle = "Vui lòng nhập Dòng xe!"
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    } else {
      eCarLine()
    }

  }, 1000)

  const eCarLine = async () => {
    const result = await editCarLines(Number(infoCarLine?.id), editTitle, Number(editCategoryCar))
    if (result?.status) {
      onSubmitFormModal?.(true)

    } else {
      onSubmitFormModal?.(false, result.message)
    }
  }


  useEffect(() => {
    if (isOpen) {
      setEditTitle(infoCarLine?.title ?? "")
      setEditCategoryCar(infoCarLine?.categoryCarId)
    } else {
      setEditTitle("")
      setEditCategoryCar(undefined)

      setErrors(errorsObj)
    }

  }, [isOpen, infoCarLine])
  return (
    <Modal
      title={<div className="bg-FCFCFD d-flex justify-betweeen align-center py-4 px-6 w-full border-b-F0F0F0">
        <span className="text-base text-344054 fw-500 font-medium">Chỉnh sửa dòng xe</span>
        <CloseOutlined className="text-base text-8B8B8B" onClick={closeModal} />
      </div>}
      open={isOpen}
      onCancel={closeModal}
      centered
      className="w-modal modal-content-vehicle ant-modal-size"
      closeIcon={false}
      footer={<div className="bg-FCFCFD d-flex justify-betweeen align-center py-2 px-6 w-full border-t-F0F0F0">
        <span className="text-sm text-98A2B3 cursor-pointer" onClick={closeModal}>Huỷ</span>
        <button className="text-white text-sm bg-096DD9 border-none shadow-btn-save rounded-2 h-8 px-15-px"
          onClick={checkVariables}>
          Lưu chỉnh sửa
        </button>
      </div>}
    >
      <div className="p-6 d-flex flex-column gap-18-px">
        <div className="d-flex flex-column gap-1">
          <p className="text-sm  font-regular text-344054">Tên thương hiệu xe</p>
          <div className='w-full'>
            <Select
              showSearch
              placeholder="Tên thương hiệu xe"
              className="custome-select-t "
              popupClassName='popup-select'
              options={fommatSelectOption(sLCategoryCar)}
              value={editCategoryCar}
              onChange={(e) => setEditCategoryCar(e)}

            />
            {
              errors.editCategoryCar &&
              <span className="text-F5222D text-base font-regular pl-2">{errors.editCategoryCar}</span>
            }
          </div>
        </div>
        <div className="d-flex flex-column gap-1">
          <p className="text-sm  font-regular text-344054">Dòng xe</p>
          <div className="d-flex flex-column ">
            <div className="w-full border-F2F4F7 bg-FCFCFD rounded-2 pl-3  py-1 h-8  overflow-hidden">
              <Input
                placeholder="Dòng xe"
                className="bg-FCFCFD text-sm border-none custom-input-focus none-focus-erro font-regular p-none-input  hover-none"
                value={editTitle}
                onChange={(e) => setEditTitle(e.target.value)}
              />
            </div>
            {
              errors.editTitle &&
              <span className="text-F5222D text-base font-regular pl-2">{errors.editTitle}</span>
            }
          </div>
        </div>


      </div>
    </Modal>
  )
}
export default ModalEditVehicle
