import { DeleteOutlined, DownloadOutlined, EditOutlined, PlusOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { Breadcrumb, ConfigProvider, Input, Pagination, PaginationProps, Select, Table, TableColumnsType, TableProps, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import Layout from '../../../Layout';
import viVN from 'antd/locale/vi_VN';
import './style.scss'
import ModalCreateVehicle from './Component/Modal/ModalCreateVehicle';
import ModalEditVehicle from './Component/Modal/ModalEditVehicle';
import * as carLineApi from '../../../api/carLine/carLineApi';
import { dataPagination, exportExcel } from '../../../until';
import useDebounce from '../../../hook/useDebounce';
import * as selectOptionsApi from '../../../api/selectOption/selectOptionsApi';
import useToast from '../../../hook/useToast';
import useThrottle from '../../../hook/useThrottle';
import ModalDeleteManager from '../../../components/ModalDeleteManager';
import ModalUpload from '../../../components/Modal/ModalUpload';
import * as XLSX from 'xlsx';
import { LIMIT_EXPORT_EXCEL } from '../../../env';
import { DataStatusButton } from '../../../services/data';

interface DataType {
  key: React.Key;
  stt: number;
  carLines: string;
  categoryCar: string;
  status: number
}

const VehicleTypeManagement: React.FC = () => {

  useEffect(() => {
    gCLCarCompany()

    return () => {
      setFilterName(undefined)
      setFilterSort(undefined)

      setIdCarlines(undefined)

      setPagination({ current: 1, pageSize: 10, total: 0 })

      setListCarLine([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Hành động',
      dataIndex: '',
      render: (_, record) => (
        <div className='t-hd-table'>
          <div
            className="item-hd-table cursor-pointer"
            onClick={() => gInfoCarLine(record.key)}>
            <span><EditOutlined /></span>
          </div>
          <DeleteOutlined
            className='text-F5222D text-sm border-EAECF0 p-5-px bg-white rounded-2'
            onClick={() => openModalDeleteVehicle(Number(record.key))}
          />
        </div>
        // <div className='d-flex align-center gap-10-px'>
        //   <EditOutlined
        //     className='text-98A2B3 text-base border-EAECF0 p-2 bg-white rounded-2'
        //     onClick={() => gInfoCarLine(record.key)}
        //   />
        //   <DeleteOutlined
        //     className='text-F5222D text-base border-EAECF0 p-2 bg-white rounded-2'
        //     onClick={() => openModalDeleteVehicle(Number(record.key))}
        //   />
        // </div>
      ),
      className: "w-93-px",
      align: "center"
    },
    {
      title: 'STT',
      dataIndex: 'stt',
      sorter: {
        compare: (a, b) => a.stt - b.stt,
        multiple: 1,
      },
      className: "w-60-px",
      align: "center"

    },
    {
      title: 'Tên thương hiệu',
      dataIndex: 'categoryCar',
      className: "w-501-px",
      sorter: {
        compare: (a, b) => a.categoryCar.length - b.categoryCar.length,
        multiple: 1,
      },
      render: (text) => <div className='text-2-row'>{text}</div>
    },

    {
      title: 'Dòng xe',
      dataIndex: 'carLines',
      sorter: {
        compare: (a, b) => a.carLines.length - b.carLines.length,
        multiple: 1,
      },
      className: "w-501-px",
      render: (text) => <div className='text-2-row'>{text}</div>
    },
    {
      title: 'Trạng thái',
      dataIndex: '',
      className: "w-175-px",
      render: (_, record) => <div className='d-flex align-center gap-10-px'>
        <Switch
          defaultChecked={record.status ? true : false}
          onChange={() => updateActiveCarLine(Number(record.key))}
          className="rs-switch rs-switch--sm"
        />
        <p className='font-medium fw-500 text-344054'>{selectOptionsApi.activeArray[record.status]}</p>
      </div>
    },
  ];

  const pushToast = useToast();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpenCreateVehicle, setIsOpenModalCreateVehicle] = useState<boolean>(false)
  const [isOpenEditVehicle, setIsOpenModalEditVehicle] = useState<boolean>(false)
  const [isOpenDeleteVehicle, setIsOpenDeleteVehicle] = useState<boolean>(false)
  const [openModalUpload, setOpenModalUpload] = useState(false);

  const [filterName, setFilterName] = useState<string>()
  const [filterSort, setFilterSort] = useState<carLineApi.SortType>()

  const [idCarlines, setIdCarlines] = useState<number>()

  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });

  const [sLCategoryCar, setSLCategoryCar] = useState<selectOptionsApi.ICarCompany[]>([])

  const [infoCarLine, setInfoCarLine] = useState<carLineApi.ICarLineEdit>()

  const [listCarLine, setListCarLine] = useState<DataType[]>([])

  const gCLCarCompany = async () => {
    const result = await selectOptionsApi.getListCarCompany()
    if (result?.status) {
      setSLCategoryCar(result.data)
    } else {
      pushToast(result?.message, "warning")
    }
  }

  const gListCarLine = useDebounce(async (pageCurent: number = 1, pageSize: number = 10) => {
    const result = await carLineApi.getListCarLine(pageSize, pageCurent, filterName, filterSort)
    if (result?.status) {
      setListCarLine(convertData(result.data, pageCurent, pageSize))
      setPagination({ current: pageCurent, total: result.total, pageSize })
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const convertData = (data: carLineApi.ICarLine[], pageCurent: number, pageSize: number) => {
    var stt = (pageCurent - 1) * pageSize + 1;
    const convertData = data.map((item) => ({
      key: item.id,
      stt: stt++,
      categoryCar: item.categoryCar,
      carLines: item.carLines,
      status: item.status
    }))
    return convertData
  }

  const gInfoCarLine = useThrottle(async (id: number) => {
    const result = await carLineApi.getInfoCarLine(id)
    if (result?.status) {
      setInfoCarLine(result.data)
      setIsOpenModalEditVehicle(true)
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const aCarLines = async (addTitle: string, addCategoryCar: number) => {
    const result = await carLineApi.addCarLines(addTitle, addCategoryCar)
    if (result?.status) {
      pushToast("Thêm dòng xe thành công", "success")
      gListCarLine()
    } else {
      pushToast(result?.message, "warning")
    }
  }

  const onSubmitFormModalEdit = (iSsuccess: boolean, errorMessage?: string) => {
    if (iSsuccess) {
      setIsOpenModalEditVehicle(false);
      pushToast("Sửa dòng xe thành công", "success")
      gListCarLine()
    }
    else {
      pushToast(errorMessage, "warning")
    }
  }

  const openModalDeleteVehicle = (id: number) => {
    setIdCarlines(id)
    setIsOpenDeleteVehicle(true)
  }

  const closeModalDeleteVehicle = () => {
    setIdCarlines(undefined)
    setIsOpenDeleteVehicle(false)
  }

  const onConfirmDelete = async () => {
    const result = await carLineApi.deleteCarLines(Number(idCarlines))
    if (result?.status) {
      pushToast("Xóa dòng xe thành công", "success")
      closeModalDeleteVehicle()
      gListCarLine()
    } else {
      pushToast(result?.message, "warning")
    }
  }

  const onPaginationChange: PaginationProps['onChange'] = (pageNumber, pageSize) => {
    gListCarLine(pageNumber, pageSize)
  }




  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const onChangeTB: TableProps<DataType>['onChange'] = (pagination, filters, sorter, extra) => {
    // console.log('params', pagination, filters, sorter, extra);
  };

  const updateActiveCarLine = async (id: number) => {
    const result = await carLineApi.updateActiveCarLine(id);
    if (result?.status) {
      gListCarLine()
      pushToast(result.message, "success", 2000)
    } else {
      pushToast(result.message, "error", 2000)

    }
  }

  const glistByExcel = useThrottle(async () => {
    const result = await await carLineApi.getListCarLine(LIMIT_EXPORT_EXCEL, 1, filterName, filterSort)
    if (result?.status) {
      formattedDataExcel(result.data, result?.message)
    } else {
      pushToast(result?.message, "warning")
    }
  }, 1000)

  const formattedDataExcel = (data: carLineApi.ICarLine[], message?: string) => {
    const formattedData = data.map((item) => {
      return {
        "Tên": item.categoryCar,
        "Dòng xe": item.carLines,
        "Trạng thái": DataStatusButton[item.status].label
      }
    }
    );
    exportExcel(formattedData, message)
  }

  useEffect(() => {

    gListCarLine()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterName, filterSort])
  return (
    <Layout>

      <ModalCreateVehicle
        isOpen={isOpenCreateVehicle}
        sLCategoryCar={sLCategoryCar}
        aCarLine={aCarLines}
        closeModal={() => setIsOpenModalCreateVehicle(!isOpenCreateVehicle)}
      />

      <ModalEditVehicle
        isOpen={isOpenEditVehicle}
        infoCarLine={infoCarLine}
        sLCategoryCar={sLCategoryCar}
        onSubmitFormModal={onSubmitFormModalEdit}
        closeModal={() => setIsOpenModalEditVehicle(!isOpenEditVehicle)}
      />
      <ModalDeleteManager
        isOpen={isOpenDeleteVehicle}
        closeModal={() => setIsOpenDeleteVehicle(!isOpenDeleteVehicle)}
        title="Bạn có chắc chắn xóa dòng xe này"
        titleDelete="Xóa thương hiệu"
        content="Dòng xe và dữ liệu liên quan sẽ bị xóa vĩnh viễn
       không thể khôi phục"
        onConfirm={onConfirmDelete}
      />
      <ModalUpload
        isOpen={openModalUpload}
        closeModal={() => setOpenModalUpload(!openModalUpload)}
      />
      <Breadcrumb
        items={[
          {
            title: 'THACO AUTO CRM',
          },
          {
            title: <a href="/">Quản lý xe</a>,
          },
          {
            title: 'Quản lý dòng xe',
          },
        ]}
      />
      <div className='title-page'>
        Quản lý dòng xe
      </div>
      <div className='d-flex gap-3 pb-18-px vehicle-type-management-filter'>
        <div className="w-468-px border-F2F4F7 bg-FCFCFD rounded-2 d-flex align-center pl-3  py-1 h-8 justify-between overflow-hidden">
          <Input
            placeholder="Tìm kiếm dòng xe"
            className="bg-FCFCFD text-sm border-none custom-input-focus none-focus-erro font-regular p-none-input place-holder-input-667085 hover-none"
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
          />
          <div className=" border-l-F2F4F7 h-full d-flex align-center">
            <SearchOutlined className="text-sm text-98A2B3 px-9-px" />
          </div>
        </div>
        <div className='w-352-px'>
          <Select
            showSearch
            className="custome-select"
            popupClassName='popup-select'
            placeholder="Chọn bộ lọc"
            options={[
              { value: 1, label: 'Từ A-Z' },
              { value: 0, label: 'Từ Z-A' },
            ]}
            value={filterSort}
            onChange={(e) => setFilterSort(e)}
          />
        </div>
        <button
          className='border-EAECF0 bg-white rounded-6-px w-106-px py-2 h-8 d-flex justify-center align-center gap-10-px shadow-btn-save'
          onClick={glistByExcel}
        >
          <DownloadOutlined className='text-sm text-344054' />
          <span className='text-sm fw-400 font-regular text-344054 white-space-nowrap'>Xuất DS</span>
        </button>
        {/* <button
          className='border-EAECF0 bg-white rounded-6-px w-111-px py-2 h-8 d-flex justify-center align-center gap-10-px shadow-btn-save'
          onClick={() => setOpenModalUpload(true)}
        >
          <UploadOutlined className='text-sm text-344054' />
          <span className='text-sm fw-400 font-regular text-344054 white-space-nowrap'>Nhập DS</span>
        </button> */}
        <button className='border-none bg-096DD9 rounded-6-px  py-2 h-8 d-flex justify-center align-center gap-10-px w-107-px' onClick={() => setIsOpenModalCreateVehicle(true)}>
          <PlusOutlined className='text-sm text-white' />
          <span className='text-sm fw-400 font-regular text-white white-space-nowrap'>Tạo mới</span>
        </button>
      </div>
      <div className="t-table-block">
        <Table
          rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={listCarLine}
          onChange={onChangeTB}
          pagination={false}
          showSorterTooltip={false}
          className="w-fit-table border-end-table"
        />
        <ConfigProvider locale={viVN}>
          <div className="pagination-block">
            <Pagination
              // showQuickJumper
              showSizeChanger
              current={pagination.current}
              pageSize={pagination.pageSize}
              total={pagination.total}
              // showTotal={(total) => `Tổng cộng ${total} trang`}
              showTotal={(total) => `Tổng cộng ${dataPagination(pagination.total, pagination.pageSize, pagination.current).totalPage} trang`}
              className='rs-pagination'
              // locale={{ items_per_page: 'trang' }}
              onChange={onPaginationChange}
            // total={85}
            // showSizeChanger
            // showQuickJumper
            // showTotal={(total) => `Tổng cộng ${total} trang`}
            // locale={{ items_per_page: 'trang' }}
            />
          </div>
        </ConfigProvider>
      </div>
    </Layout>

  );
}

export default VehicleTypeManagement;
