import { Button, Table, TableColumnsType, Tag } from 'antd';
import React, { useState } from 'react';
import { DownloadOutlined, EyeOutlined, SettingOutlined, UploadOutlined } from '@ant-design/icons';
import { SearchProps } from 'antd/es/input';
import { Input } from 'antd';
import fakeData from './fake-data';

const { Search } = Input;
const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
  // console.log(info?.source, value);
}

interface DataType {
  key: React.Key;
  vip: string;
  unit: string;
  status: number;
  careCounted: number;
  careStaff: string;
}

const columns: TableColumnsType<DataType> = [
  {
    title: 'Hành động',
    dataIndex: '',
    key: 'action',
    render: () => <Button className="rs-button rs-button--icon-only"><EyeOutlined /></Button>,
  },
  {
    title: 'VIP',
    dataIndex: 'vip',
    key: 'vip',
  },
  {
    title: 'Đơn vị',
    dataIndex: 'unit',
    key: 'unit',
    sorter: true,
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    key: 'status',
    render: (status) => (
      status === 0
        ? <Tag className='rs-tag dust-red'>Chưa chăm sóc</Tag>
        : <Tag className='rs-tag daybreak-blue'>Đã chăm sóc</Tag>
    ),
    sorter: true,
  },
  {
    title: 'Lần chăm sóc trong tháng',
    dataIndex: 'careCounted',
    key: 'careCounted',
    sorter: true,
  },
  {
    title: 'Nhân viên chăm sóc',
    dataIndex: 'careStaff',
    key: 'careStaff',
    sorter: true,
  },
];

const VIPCareReportTable: React.FC = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    // console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div className="vip-care-report-table">
      <div className="top">
        <Search
          placeholder="Tìm lượt chăm sóc khách VIP theo số điện thoại, hoặc email"
          allowClear
          className="rs-input-search"
          onSearch={onSearch}
        />

        <div className="control">
          <Button className="rs-button">
            <UploadOutlined />
            <span>Nhập DS</span>
          </Button>

          <Button className="rs-button">
            <DownloadOutlined />
            <span>Xuất DS</span>
          </Button>

          <Button className="rs-button">
            <SettingOutlined />
          </Button>
        </div>
      </div>

      <Table
        id="table"
        columns={columns}
        dataSource={fakeData}
        showSorterTooltip={false}
        rowSelection={rowSelection}
        className="rs-table"
        pagination={{
          position: ['bottomCenter'],
          defaultPageSize: 15,
          className: 'rs-pagination',
          showQuickJumper: true,
        }}
      />
    </div>
  );
}

export default VIPCareReportTable;