import { CloseCircleOutlined, CloseOutlined, EditOutlined, LinkOutlined, SearchOutlined, StarOutlined, UserOutlined } from "@ant-design/icons";
import { Form, Input, Select } from "antd";
import { useState } from "react";
import * as selectOptionsApi from '../../../../../api/selectOptions/selectOptionsApi';
import { changePercent, changePercentGroup } from "../../../../../until";
import useToast from "../../../../../hook/useToast";

interface Props {
  dataRelatives: selectOptionsApi.ISelectOption[],
  setValuesRelatives: (value: number | null) => void,
  sLHobby: selectOptionsApi.ISelectOption[],
  valuesRelatives: number | null,
  setValuesInterestRelative: (value: number[]) => void,
  valuesInterestRelative: number[],
  setListRelatives: (value: selectOptionsApi.relationshipProp[]) => void,
  listRelatives: selectOptionsApi.relationshipProp[],
  percent: number
  setPercent: (e: number) => void
}

const InfoRelative: React.FC<Props> = ({
  dataRelatives, valuesRelatives, sLHobby, valuesInterestRelative, listRelatives, percent, setPercent, setValuesRelatives, setValuesInterestRelative, setListRelatives
}) => {
  let errorsObj = { userName: "", hobby: "", relationship: "" };

  const [errorObject, setErrorObject] = useState(errorsObj)

  const [openInterest, setOpenInterest] = useState(false);

  const [name, setName] = useState<string>('');
  const [percentChanged, setPercentChanged] = useState(false);

  const handleAdd = () => {
    // if (listRelatives.length === 0) {
    //   pushToast("Vui lòng thêm ít nhất 1 người thân!", "warning", 2000)

    // }
    if (valuesInterestRelative.length === 0) {
      errorsObj.hobby = 'Vui lòng chọn sở thích!';
    }
    if (valuesRelatives === null) {
      errorsObj.relationship = 'Vui lòng chọn mối quan hệ!';
    }
    if (name === '') {
      errorsObj.userName = 'Vui lòng nhập họ và tên!';
    } else {
      setListRelatives([...listRelatives, { name, relationship: valuesRelatives, hobby: valuesInterestRelative }])
      setName('')
      setValuesRelatives(null)
      setValuesInterestRelative([])
    }
    setErrorObject(errorsObj)
  }


  const handleEdit = (index: number) => {
    const { name, relationship, hobby } = listRelatives[index]
    setName(name)
    setValuesInterestRelative(hobby)
    setValuesRelatives(Number(relationship))
    const newList = listRelatives.filter((element, i) => i !== index);
    setListRelatives(newList)
  }
  const handleDelete = (index: number) => {
    const newList = listRelatives.filter((value, i) => i !== index)
    setListRelatives(newList)
  }
  const handleChangeInterest = (selectedValues: number[]) => {
    setPercent(changePercentGroup(percent, 6, valuesInterestRelative, selectedValues))
    setValuesInterestRelative(selectedValues);
    errorObject.hobby = ''
    setOpenInterest(false)
  };
  return (
    <div className="d-flex flex-column align-baseline gap-14-px pt-29-px">
      <div className="d-flex flex-column  w-full gap-3 ">
        <p className="text-344054 text-base fw-500 font-medium pl-3">Thông tin người thân</p>
        <Form.Item
          className="m-none-form-item text-explan"
        >
          <div className="w-full border-F2F4F7 bg-FCFCFD rounded-2 d-flex align-center gap-2 px-3 py-2-px h-10  overflow-hidden">
            <UserOutlined className="text-sm text-344054" />
            <Input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setPercent(changePercent(percent, 6, name, e.target.value))
                errorObject.userName = ''

              }}
              placeholder="Họ và tên"
              className="bg-FCFCFD text-sm border-none custom-input-focus none-focus-erro font-regular p-none-input place-holder-input-98A2B3 hover-none"
            />
          </div>
        </Form.Item>
        {errorObject.userName &&
          <div className=" ant-form-item-explain-error " style={{ color: '#ff4d4f' }}>
            {errorObject.userName}
          </div>}
        <Form.Item
          className="m-none-form-item text-explan"
        >
          <div className="w-full border-F2F4F7 bg-FCFCFD rounded-2 d-flex align-center pl-3  py-2-px h-10 justify-between overflow-hidden">
            <div className="w-full d-flex align-center gap-2">
              <LinkOutlined className="text-sm text-344054" />

              <Select
                placeholder="Mối quan hệ"
                options={dataRelatives}
                value={valuesRelatives}
                className="custome-select-relative "
                popupClassName='popup-select'
                variant="borderless"
                filterOption={(input, option) =>
                  typeof option?.label === 'string' && option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(e) => {
                  setValuesRelatives(e);
                  errorObject.relationship = ''
                  if (!percentChanged) {
                    setPercent(percent + 6);
                    setPercentChanged(true);
                  }
                }}
              />
            </div>
          </div>
        </Form.Item>
        {errorObject.relationship &&
          <div className=" ant-form-item-explain-error " style={{ color: '#ff4d4f' }}>
            {errorObject.relationship}
          </div>}
        <Form.Item
          className="m-none-form-item text-explan"
        >

          <div className="w-full border-F2F4F7 bg-FCFCFD rounded-2 d-flex align-center pl-3  py-2-px h-10 justify-between overflow-hidden">
            <div className="w-full d-flex align-center gap-2">
              <StarOutlined className="text-sm text-344054" />

              <Select
                mode="multiple"
                placeholder="Tìm thẻ tag và chọn"
                value={valuesInterestRelative}
                options={sLHobby}
                className="custome-select-relative "
                popupClassName='popup-select'
                onChange={handleChangeInterest}
                onDropdownVisibleChange={setOpenInterest}
                open={openInterest}
                suffixIcon={<SearchOutlined className="text-sm text-98A2B3" />}
                variant="borderless"
                filterOption={(input, option) =>
                  typeof option?.label === 'string' && option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              />
            </div>
          </div>
          <div className={` ${valuesInterestRelative.length !== 0 ? 'd-flex align-center gap-2' : 'none'}`}>
            {valuesInterestRelative.map((value, index) => {
              const selectedOption = sLHobby.find(option => option.value === value);
              if (!selectedOption) return null;
              return (
                <p key={index} className="bg-F0F5FF  border-ADC6FF rounded-2 text-2F54EB text-xs px-2 d-flex align-center gap-3-px py-1 font-regular">
                  {selectedOption.label}
                  <CloseOutlined className="text-84878C text-xs cursor-pointer"
                    onClick={() => {
                      setValuesInterestRelative(valuesInterestRelative.filter(v => v !== value));
                      if (valuesInterestRelative.length === 1) { setPercent(percent - 6) }
                    }
                    } />
                </p>
              )
            })}
          </div>
        </Form.Item>
        {errorObject.hobby &&
          <div className=" ant-form-item-explain-error " style={{ color: '#ff4d4f' }}>
            {errorObject.hobby}
          </div>}
      </div>
      <button
        className="rounded-2  py-1 w-full bg-white border-1890FF text-1890FF line-height-22-px text-sm font-regular"
        onClick={handleAdd}>
        Thêm người thân
      </button>

      <div className="d-flex flex-column gap-10-px w-full">
        {
          listRelatives.map((item, id) => (
            <div key={id} className="d-flex flex-column gap-6  rounded-2 border-f2f4f7 py-13-px px-6">
              <div className="d-flex align-center justify-between">
                <div className="d-flex align-center gap-19-px">
                  <p className="rounded-100-px bg-E6F7FF px-2  text-1890FF font-regular text-xs">
                    {dataRelatives.find((v) => v.value === item.relationship)?.label ?? ""}
                  </p>
                  <p className="text-344054 fw-500 font-medium text-sm">{item.name}</p>
                </div>
                <div className="d-flex align-center gap-2  ">
                  <CloseCircleOutlined
                    className="text-F5222D  text-sm rounded-1 shadow-btn p-1 cursor-pointer"
                    onClick={() => handleDelete(id)}
                  />
                  <EditOutlined
                    className="text-sm rounded-1 shadow-btn  p-1 cursor-pointer text-344054"
                    onClick={() => handleEdit(id)}
                  />
                </div>
              </div>
              <div className="d-flex align-center gap-6">
                <p className="font-regular text-sm">Sở thích</p>
                <div className="d-flex align-center gap-2">
                  {
                    sLHobby.filter(v => item.hobby.includes(v.value)).map((item, i) =>
                      <p key={i} className="rounded-2 bg-F9F0FF px-2 border-D3ADF7 text-722ED1 py-1-px text-xs font-regular"> {item.label}</p>
                    )
                  }
                </div>

              </div>
            </div>
          ))
        }
      </div>


    </div>
  )
}
export default InfoRelative