import { paserParams } from "../../until";
import axiosClient, { ResponseSuccess, ResponseSuccessTotal } from "../axiosClient";
import { SortType } from "../carLine/carLineApi";

export interface ITypeVehicle {
    id: number
    categoryCar: string
    carLines: string
    typeVehicle: string
    status: number
}

export interface ITypeVehicleEdit {
    id: number
    title: string
    categoryCarId: number
    carLinesId: number
}

export const getListTypeVehicle = async (
    limit?: number, pageNumber?: number, searchNameTypeVehicle?: string, sort?: SortType) => {
    const path = "typeVehicle/listTypeVehicle"
    const params = {
        limit, pageNumber, searchNameTypeVehicle, sort
    }
    const result: ResponseSuccessTotal<ITypeVehicle[]> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Thông tin chủng loại xe
export const getInfoTypeVehicle = async (
    id: number) => {
    const path = "typeVehicle/infoTypeVehicle"
    const params = {
        id
    }
    const result: ResponseSuccessTotal<ITypeVehicleEdit> = await axiosClient.get(
        path, { params }
    );
    return result;
};

//Thêm mới chủng loại xe
export const addTypeVehicle = async (
    title: string, idCategoryCar: number, idCarlines: number
) => {
    const url = `typeVehicle/addTypeVehicle`;
    const params = {
        title, idCategoryCar, idCarlines
    }
    const result: ResponseSuccess<[]> = await axiosClient.post(
        url, paserParams(params, undefined)
    );
    return result;
}

//Chỉnh sửa chủng loại xe
export const editTypeVehicle = async (
    id: number, idCarlines: number, title: string, idCategoryCar: Number
) => {
    const url = `typeVehicle/editTypeVehicle`;
    const params = {
        id, idCarlines, title, idCategoryCar
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        url, paserParams(params, undefined)
    );
    return result;
}

//Xóa chủng loại xe
export const deleteTypeVehicle = async (
    id: Number
) => {
    const url = `typeVehicle/deleteTypeVehicle`;
    const params = {
        id
    }
    const result: ResponseSuccess<[]> = await axiosClient.delete(
        url, { params }
    );
    return result;
}

export const updateActiveTypeVehicle = async (
    id: number) => {
    const path = "typeVehicle/activeTypeVehicle"
    const params = {
        id
    }
    const result: ResponseSuccess<[]> = await axiosClient.put(
        path, params
    );
    return result;
};